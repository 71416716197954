import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fNumber(number) {
  return numeral(number).format();
}

export const fRatio = (value) => {
  if (typeof value !== 'number') {
    console.error('fRatio was called with a non-number value:', value);
    return '1 : 0.00'; // or some other default or error indication
  }
  return `1 : ${value.toFixed(2)}`;
};



export function fCurrency(number) {
  const format = number ? numeral(number).format('$0,0.00') : '$0';

  return result(format, '.00');
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.00%') : '0%';

  return result(format, '.00');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function roundValue(value) {
  return value ? Number(value).toFixed(2) : 0;
}
export function roundForex(value) {
  return value ? Number(value).toFixed(5) : 0;
}