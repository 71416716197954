import React, { useEffect, useState } from 'react';
import { Grid, Container, Typography, FormControl, InputLabel, Select, MenuItem , Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { alpha } from '@mui/material/styles';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { db } from '../../firebase';
import { collection, doc, setDoc, getDocs, query, where } from "firebase/firestore";
import { Helmet } from 'react-helmet-async';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

//sections
import CoursesListAdmin from '../../sections/admin/CoursesListAdmin'
import ReviewVerification from '../../sections/admin/ReviewVerification';
import AdminEnrollComponent from '../../sections/admin/AdminEnrollComponent';
import AdminUpdateMembershipComponent from '../../sections/admin/AdminUpdateMembershipComponent';
import AdminScheduleZoom from '../../sections/admin/AdminScheduleZoom';

export default function AllCourses() {
    const [openDialog, setOpenDialog] = useState(false);
const [newCourseId, setNewCourseId] = useState("");
const [getCert, setGetCert] = useState(null);
const [enrolled, setEnrolled] = useState(null);

        
        const [loading, setLoading] = useState(true);
        const [fetchedCourses, setFetchedCourses] = useState([]);
        
        const fetchCourses = async () => {
          const userCollection = collection(db, 'users');

          // Create a query against the 'users' collection.
          const q = query(userCollection, where('purchased', 'array-contains', 'introduction-in-financial-markets'));
          const specificCertificateId = 'introduction-in-financial-markets';

          const q1 = query(userCollection, where(`certificates.${specificCertificateId}`, '!=', null));
          

          const querySnapshot = await getDocs(q);
          const querySnapshot1 = await getDocs(q1);
          
          // Count of how many users have 'introduction-in-financial-markets' in their 'purchased' field.
          const count = querySnapshot.size;
          const count1 = querySnapshot1.size;
          setEnrolled(count);
          setGetCert(count1);
                const coursesCollection = collection(db, "courses");
                const courseSnapshot = await getDocs(coursesCollection);
                const courseList = courseSnapshot.docs.map(doc => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    }
                });
                setFetchedCourses(courseList);
            }
        
            useEffect(() => {
                const fetchData = async () => {
                    setLoading(true);
                    await fetchCourses();
                    setLoading(false);
                };
        
                fetchData();
            }, []);
            
            const handleDialogOpen = () => {
                setOpenDialog(true);
              };
            
              const handleDialogClose = () => {
                setOpenDialog(false);
                setNewCourseId(""); // Reset the new course ID
              };
            
              const createNewCourse = async () => {
              // Logic to add new course
              const newCourseRef = doc(db, "courses", newCourseId);
              await setDoc(newCourseRef, {
                id: newCourseId,
                associatedCertification: [],
                description: { en: '', ru: '' },
                difficulty: { en: '', ru: '', value: 1 },
                duration: '',
                faq: { ru: [], en: [] },
                htmlDescription: { ru: 'Новый курс' },
                introVideo: { en: '' , ru: 'Новый курс' },
                longDescription: { en: '', ru: 'Новый курс' },
                name: { en: '', ru: 'Новый курс' },
                price: '',
                product: '',
                type: 'course',
                newFaqQuestionEN: '',
                newFaqAnswerEN: '',
                newFaqQuestionRU: '',
                newFaqAnswerRU: '',
                private: false,
                specializations: [],
                // ... other fields
              });
              // Refresh your list or state as needed
              await fetchCourses();
              handleDialogClose();
            };

if (loading) {
return (
        <Container maxWidth="xl">
        <Skeleton variant="text" width='100%' height={70} />
        <Skeleton variant="rectangular" width="100%" height={300} sx={{
                            border: "1px solid rgba(209, 213, 219, 0.3)", // border
                            borderRadius: 5,
                            padding: 2,
                            mt: 5,
        }}/>
        </Container>
);
}
  return (
    <>
    <Helmet>
        <html lang="ru" /> 
        <title>Курсы | DEFAUST</title>
        <meta name="description" content="Отслеживайте и ведите статистику сделок с помощью Журнал Трейдера." />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "Курсы",
              "description": "Отслеживайте и ведите статистику сделок с помощью Журнал Трейдера."
            }
          `}
        </script>
    </Helmet>
    <Container maxWidth="xl">
      <Typography>{enrolled}</Typography>
      <Typography>{getCert}</Typography>
            <AdminScheduleZoom />
<CoursesListAdmin courses={fetchedCourses} />
<Button variant="contained" color="primary" onClick={handleDialogOpen}>
    Create New Course
  </Button>
  <Dialog open={openDialog} onClose={handleDialogClose}>
    <DialogTitle>Add New Course</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Please enter the ID for the new course.
      </DialogContentText>
      <TextField
        autoFocus
        margin="dense"
        label="Course ID"
        fullWidth
        value={newCourseId}
        onChange={(e) => setNewCourseId(e.target.value)}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleDialogClose} color="primary">
        Cancel
      </Button>
      <Button onClick={createNewCourse} color="primary">
        Add
      </Button>
    </DialogActions>
  </Dialog>
</Container>
    </>
  );
}