import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { TextField, Button, Typography, Container, Grid, Box, Chip } from '@mui/material';
import { List, ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CourseStructure from '../../sections/admin/CourseStructure';
import { Snackbar, Alert } from '@mui/material';

export default function EditCourse() {
  const { courseId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [courseFields, setCourseFields] = useState({
    id: '',
    associatedCertification: [],
    description: { en: '', ru: '' },
    difficulty: { en: '', ru: '', value: 1 },
    duration: '',
    faq: { ru: [], en: [] },
    htmlDescription: { ru: '', en: ''},
    introVideo: { en: '' , ru: '' },
    longDescription: { en: '', ru: '' },
    name: { en: '', ru: '' },
    price: '',
    product: '',
    type: '',
    newFaqQuestionEN: '',
    newFaqAnswerEN: '',
    newFaqQuestionRU: '',
    newFaqAnswerRU: '',
    private: false,
    specializations: [],
    features: [],
    newFeature: ''
  });

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const coursesCollection = collection(db, 'courses');
        const q = query(coursesCollection, where('id', '==', courseId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const courseDoc = querySnapshot.docs[0];
          setCourseFields(courseDoc.data());
          setLoading(false);
        } else {
          setError("No such course!");
          setLoading(false);
        }
      } catch (err) {
        setError("An error occurred while fetching data");
        setLoading(false);
        console.error(err);
      }
    };

    fetchCourseData();
  }, [courseId]);

  const handleInputChange = (field, subfield = null) => event => {
    if (subfield) {
      setCourseFields({
        ...courseFields,
        [field]: {
          ...courseFields[field],
          [subfield]: event.target.value
        }
      });
    } else {
      setCourseFields({
        ...courseFields,
        [field]: event.target.value
      });
    }
  };

  const updateCourse = async () => {
    try {
      const coursesCollection = collection(db, 'courses');
      const q = query(coursesCollection, where('id', '==', courseId));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.size !== 0) {
        const courseDoc = querySnapshot.docs[0];
  
         // Convert difficulty.value and duration to integer
        courseFields.difficulty.value = parseInt(courseFields.difficulty.value, 10);
        courseFields.duration = parseInt(courseFields.duration, 10);
        courseFields.private = JSON.parse(courseFields.private);


        // Update the Firestore document
        await updateDoc(doc(db, 'courses', courseDoc.id), { ...courseFields });
  
        // Log success
        ////console.log("Course updated successfully.");
        setOpenSuccess(true);
      } else {
        ////console.log("Course not found.");
        setOpenError(true);
      }
    } catch (err) {
      setError("An error occurred while updating data");
      console.error(err);
    }
  };
  

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Container>
      <Typography variant="h3" gutterBottom>Редактировать курс</Typography>
      <form>
      <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
            <TextField fullWidth label="ID" variant="outlined" value={courseFields.id} onChange={handleInputChange('id')} />
          </Grid>
      <Grid item xs={12} sm={12}>
            <TextField fullWidth label="Private" variant="outlined" value={courseFields.private} onChange={handleInputChange('private')} />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Type" variant="outlined" value={courseFields.type} onChange={handleInputChange('type')} />
          </Grid>
          <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>Associated Certifications</Typography>
      <TextField
        fullWidth
        label="New Certification"
        variant="outlined"
        value={courseFields.newCertification}
        onChange={handleInputChange('newCertification')}
        InputProps={{
          startAdornment: (
            <div>
              {courseFields.associatedCertification.map((certification, index) => (
                <Chip
                  key={index}
                  label={certification}
                  onDelete={() => {
                    const updatedCertifications = [...courseFields.associatedCertification];
                    updatedCertifications.splice(index, 1);
                    setCourseFields({
                      ...courseFields,
                      associatedCertification: updatedCertifications
                    });
                  }}
                  style={{ margin: '0 5px 5px 0' }}
                />
              ))}
            </div>
          )
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            const updatedCertifications = [...courseFields.associatedCertification, courseFields.newCertification];
            setCourseFields({
              ...courseFields,
              associatedCertification: updatedCertifications,
              newCertification: ''  // Clear the input
            });
          }
        }}
      />
    </Grid>
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        Features
      </Typography>
      <TextField
        fullWidth
        label="Features"
        variant="outlined"
        value={courseFields.newFeature}
        onChange={handleInputChange('newFeature')}
        InputProps={{
          startAdornment: (
            <div>
              {courseFields.features.map((feature, index) => (
                <Chip
                  key={index}
                  label={feature}
                  onDelete={() => {
                    const updatedFeatures = [...courseFields.features];
                    updatedFeatures.splice(index, 1);
                    setCourseFields({
                      ...courseFields,
                      features: updatedFeatures
                    });
                  }}
                  style={{ margin: '0 5px 5px 0' }}
                />
              ))}
            </div>
          )
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            if (courseFields.newFeature.trim()) { // Ensure feature is not just whitespace
              const updatedFeatures = [...courseFields.features, courseFields.newFeature];
              setCourseFields({
                ...courseFields,
                features: updatedFeatures,
                newFeature: ''  // Clear the input
              });
            }
          }
        }}
      />
    </Grid>
          <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>Specializations</Typography>
      <TextField
        fullWidth
        label="New Specialization"
        variant="outlined"
        value={courseFields.newSpecialization}
        onChange={handleInputChange('newSpecialization')}
        InputProps={{
          startAdornment: (
            <div>
              {courseFields.specializations.map((specialization, index) => (
                <Chip
                  key={index}
                  label={specialization}
                  onDelete={() => {
                    const updatedSpecializations = [...courseFields.specializations];
                    updatedSpecializations.splice(index, 1);
                    setCourseFields({
                      ...courseFields,
                      specializations: updatedSpecializations
                    });
                  }}
                  style={{ margin: '0 5px 5px 0' }}
                />
              ))}
            </div>
          )
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            const updatedSpecializations = [...courseFields.specializations, courseFields.newSpecialization];
            setCourseFields({
              ...courseFields,
              specializations: updatedSpecializations,
              newSpecializations: ''  // Clear the input
            });
          }
        }}
      />
    </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Name (EN)" variant="outlined" value={courseFields.name.en} onChange={handleInputChange('name', 'en')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Name (RU)" variant="outlined" value={courseFields.name.ru} onChange={handleInputChange('name', 'ru')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Description (EN)" variant="outlined" value={courseFields.description.en} onChange={handleInputChange('description', 'en')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Description (RU)" variant="outlined" value={courseFields.description.ru} onChange={handleInputChange('description', 'ru')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Long Description (EN)" variant="outlined" value={courseFields.longDescription.en} onChange={handleInputChange('longDescription', 'en')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Long Description (RU)" variant="outlined" value={courseFields.longDescription.ru} onChange={handleInputChange('longDescription', 'ru')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="HTML (EN)" variant="outlined" value={courseFields.htmlDescription.en} onChange={handleInputChange('htmlDescription', 'en')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="HTML (RU)" variant="outlined" value={courseFields.htmlDescription.ru} onChange={handleInputChange('htmlDescription', 'ru')} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField fullWidth label="Difficulty (EN)" variant="outlined" value={courseFields.difficulty.en} onChange={handleInputChange('difficulty', 'en')} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField fullWidth label="Difficulty (RU)" variant="outlined" value={courseFields.difficulty.ru} onChange={handleInputChange('difficulty', 'ru')} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField fullWidth label="Value" variant="outlined" value={courseFields.difficulty.value} onChange={handleInputChange('difficulty', 'value')} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField fullWidth label="Duration" variant="outlined" value={courseFields.duration} onChange={handleInputChange('duration')} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Intro Video (EN)" variant="outlined" value={courseFields?.introVideo?.en} onChange={handleInputChange('introVideo', 'en')} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Intro Video (RU)" variant="outlined" value={courseFields?.introVideo?.ru} onChange={handleInputChange('introVideo', 'ru')} />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Price" variant="outlined" value={courseFields.price} onChange={handleInputChange('price')} />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Product" variant="outlined" value={courseFields.product} onChange={handleInputChange('product')} />
          </Grid>
          <Grid item xs={6}>
  <Typography variant="h6" gutterBottom>FAQ (EN)</Typography>
  <List>
    {courseFields.faq?.en?.map((faqItem, index) => (
      <ListItem key={index}>
        <ListItemIcon>
          <IconButton edge="end" aria-label="delete" onClick={() => {
            const updatedFAQ = [...(courseFields.faq?.en ?? [])];
            updatedFAQ.splice(index, 1);
            setCourseFields({
              ...courseFields,
              faq: { en: updatedFAQ }
            });
          }}>
            <DeleteIcon />
          </IconButton>
        </ListItemIcon>
        <ListItemText
          primary={faqItem.question}
          secondary={faqItem.answer}
        />
      </ListItem>
    ))}
  </List>
  <TextField
    fullWidth
    label="New Question"
    variant="outlined"
    onChange={handleInputChange('newFaqQuestionEN')}
  />
  <TextField
    fullWidth
    label="New Answer"
    variant="outlined"
    onChange={handleInputChange('newFaqAnswerEN')}
  />
  <Button variant="contained" color="primary" onClick={() => {
    const newFaqItem = { question: courseFields.newFaqQuestionEN, answer: courseFields.newFaqAnswerEN };
    const existingFaq = courseFields.faq?.en ?? [];
    setCourseFields({
      ...courseFields,
      faq: { en: [...existingFaq, newFaqItem] }
    });
  }}>Add FAQ</Button>
</Grid>

<Grid item xs={6}>
  <Typography variant="h6" gutterBottom>FAQ (RU)</Typography>
  <List>
    {courseFields.faq?.ru?.map((faqItem, index) => (
      <ListItem key={index}>
        <ListItemIcon>
          <IconButton edge="end" aria-label="delete" onClick={() => {
            const updatedFAQ = [...(courseFields.faq?.ru ?? [])];
            updatedFAQ.splice(index, 1);
            setCourseFields({
              ...courseFields,
              faq: { ru: updatedFAQ }
            });
          }}>
            <DeleteIcon />
          </IconButton>
        </ListItemIcon>
        <ListItemText
          primary={faqItem.question}
          secondary={faqItem.answer}
        />
      </ListItem>
    ))}
  </List>
  <TextField
    fullWidth
    label="New Question"
    variant="outlined"
    onChange={handleInputChange('newFaqQuestionRU')}
  />
  <TextField
    fullWidth
    label="New Answer"
    variant="outlined"
    onChange={handleInputChange('newFaqAnswerRU')}
  />
  <Button variant="contained" color="primary" onClick={() => {
    const newFaqItem = { question: courseFields.newFaqQuestionRU, answer: courseFields.newFaqAnswerRU };
    const existingFaq = courseFields.faq?.ru ?? [];
    setCourseFields({
      ...courseFields,
      faq: { ru: [...existingFaq, newFaqItem] }
    });
  }}>Add FAQ</Button>
</Grid>
<Grid item xs={12}>
<Box mt={2}>
              <Button variant="contained" color="primary" onClick={updateCourse}>Save Changes</Button>
            </Box>
<Typography variant="h3" sx={{ mt: 5}}>
        Структура курса
        </Typography>
<CourseStructure courseId={courseId} db={db} />
</Grid>


          <Grid item xs={12}>

            <Snackbar open={openSuccess} autoHideDuration={6000} onClose={() => setOpenSuccess(false)}>
            <Alert onClose={() => setOpenSuccess(false)} severity="success">
              Lesson updated successfully!
            </Alert>
          </Snackbar>

          <Snackbar open={openError} autoHideDuration={6000} onClose={() => setOpenError(false)}>
            <Alert onClose={() => setOpenError(false)} severity="error">
              An error occurred while updating the lesson.
            </Alert>
          </Snackbar>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
