import React, { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, createTheme, Typography, Container} from "@mui/material";
import { styled } from '@mui/material/styles';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
//
import Header from '../header';
import Nav from '../nav';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 20;
const APP_BAR_DESKTOP = 20;

const HEADER_MOBILE = 40;
const HEADER_DESKTOP = 60;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundImage: '#FFF',  // added this line
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'hidden',
  minHeight: '100%',
  
  paddingTop: `calc(${APP_BAR_MOBILE + 24}px + ${HEADER_MOBILE}px)`, // Updated paddingTop value
  paddingBottom: theme.spacing(10),

  [theme.breakpoints.up('lg')]: {
    paddingTop: `calc(${APP_BAR_DESKTOP + 24}px + ${HEADER_DESKTOP}px)`,  // Updated paddingTop value
  },
}));

const Tabs = styled(MuiTabs)(({theme}) => ({
  "&::before": {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: -1
  },
  minHeight: 44,
  minWidth: 100,
}));

  
  const Tab = styled(MuiTab)(({ theme }) => ({
      borderRadius: 10,
      minHeight: 44,
      minWidth: 96,
      '&.Mui-selected': {
        borderRadius: 10,
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.common.black,
      },
      '&:hover': {
        borderRadius: 10,
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.common.black,
      },
      '&.MuiTab-root': {
        textTransform: 'initial',
        color: theme.palette.text.primary,
      },
      
      [theme.breakpoints.up('md')]: {
        minWidth: 120
      },
    }));
// ----------------------------------------------------------------------

export default function CatalogLayout() {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    navigate(newValue);
  };

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      <Main>
      <Container maxWidth="xl">
            <Box sx={{ mt: 0, mb: 5, px: 0, }}>
            <Typography variant="h3" sx={{ mt: 0, mb: 0 }}>
Курсы
</Typography>
<Box sx={{mt: [2, 2, -6,],  display: 'flex', justifyContent: { xs: 'center', md: 'center'} }}>
  <Box sx={{ 
    border: '1px solid var(--stroke-grey, #E5E7E9)', 
    borderRadius: '10px', 
    display: 'inline-block'
  }}>
                <Tabs 
                  indicatorColor="transparent"
                  value={
                    location.pathname !== "/dashboard/catalog/courses" &&
                    location.pathname !== "/dashboard/catalog/trading" &&
                    location.pathname !== "/dashboard/catalog/crypto" &&
                    location.pathname !== "/dashboard/catalog/investing"
                      ? false
                      : location.pathname
                  }
                  onChange={handleTabChange}
                >
                  <Tab value="/dashboard/catalog/courses" label="Все курсы" />
                  <Tab value="/dashboard/catalog/trading" label="Трейдинг" />
                  <Tab value="/dashboard/catalog/crypto" label="Крипто" />
                  <Tab value="/dashboard/catalog/investing" label="Инвестиции" />
                </Tabs>
              </Box>

              </Box>
            </Box>
            </Container>
            <Outlet /> 
          </Main>
        </StyledRoot>
      );
  }