import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { TextField, Button, Typography, Container, Grid, Box } from '@mui/material';
import { Snackbar, Alert } from '@mui/material';

export default function LessonEditSection({ courseId, lessonId }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [courseDocState, setCourseDocState] = useState(null);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const [lessonFields, setLessonFields] = useState({
    id: '',
    duration: 0,
    name: { en: '', ru: '' },
    shortDescription: { en: '', ru: '' },
    type: '',
    url: { en: '', ru: '' }
  });

  useEffect(() => {
    const fetchLessonData = async () => {
      try {
        // First find the course with the provided courseId
        const coursesCollection = collection(db, 'courses');
        const q = query(coursesCollection, where('id', '==', courseId));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          const courseDoc = querySnapshot.docs[0];
          setCourseDocState(courseDoc.id);
          // Now find the lesson with the provided lessonId
          const itemDoc = await getDoc(doc(db, `courses/${courseDoc.id}/lessons/${lessonId}`));

          if (itemDoc) {
            setLessonFields(itemDoc.data());
            setLoading(false);
          } else {
            setError("No such lesson!");
            setLoading(false);
          }
        } else {
          setError("No such course!");
          setLoading(false);
        }

      } catch (err) {
        setError("An error occurred while fetching data");
        setLoading(false);
        console.error(err);
      }
    };

    fetchLessonData();
  }, [courseId, lessonId]);

  const handleInputChange = (field, subfield = null) => event => {
    if (subfield) {
      setLessonFields({
        ...lessonFields,
        [field]: {
          ...lessonFields[field],
          [subfield]: event.target.value
        }
      });
    } else {
      setLessonFields({
        ...lessonFields,
        [field]: event.target.value
      });
    }
  };

  const updateLesson = async () => {
    try {
      const lessonDoc = doc(db, `courses/${courseDocState}/lessons/${lessonId}`);
      // Convert duration to integer
      lessonFields.duration = parseInt(lessonFields.duration, 10);
      await updateDoc(lessonDoc, { ...lessonFields });
      ////console.log("Lesson updated successfully.");
      setOpenSuccess(true); // Open the success alert
    } catch (err) {
      setError("An error occurred while updating data");
      console.error(err);
      setOpenError(true); // Open the error alert
    }
  };
  

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Container>
      <Typography variant="h3" gutterBottom>Редактировать урок</Typography>
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField fullWidth label="ID" variant="outlined" value={lessonFields.id} onChange={handleInputChange('id')} />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Type" variant="outlined" value={lessonFields.type} onChange={handleInputChange('type')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Name (EN)" variant="outlined" value={lessonFields.name.en} onChange={handleInputChange('name', 'en')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Name (RU)" variant="outlined" value={lessonFields.name.ru} onChange={handleInputChange('name', 'ru')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Short Description (EN)" variant="outlined" value={lessonFields.shortDescription.en} onChange={handleInputChange('shortDescription', 'en')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Short Description (RU)" variant="outlined" value={lessonFields.shortDescription.ru} onChange={handleInputChange('shortDescription', 'ru')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="URL (EN)" variant="outlined" value={lessonFields.url.en} onChange={handleInputChange('url', 'en')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="URL (RU)" variant="outlined" value={lessonFields.url.ru} onChange={handleInputChange('url', 'ru')} />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Duration" variant="outlined" value={lessonFields.duration} onChange={handleInputChange('duration')} />
          </Grid>
          <Grid item xs={12}>
          <Box mt={2}>
      <Button variant="contained" color="primary" onClick={updateLesson}>Save Changes</Button>
          </Box>

          <Snackbar open={openSuccess} autoHideDuration={6000} onClose={() => setOpenSuccess(false)}>
            <Alert onClose={() => setOpenSuccess(false)} severity="success">
              Lesson updated successfully!
            </Alert>
          </Snackbar>

          <Snackbar open={openError} autoHideDuration={6000} onClose={() => setOpenError(false)}>
            <Alert onClose={() => setOpenError(false)} severity="error">
              An error occurred while updating the lesson.
            </Alert>
          </Snackbar>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
