import React from 'react';
import { Box, Typography, Button, Grid, Rating} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {getLessonWordForm} from '../../../utils/getLessonWordForm'
  
const CourseCard = ({ course, account, handleRowClick, isMobile, step, index}) => (
    <Box  
    onClick={course.soon === undefined ? () => handleRowClick(course.id) : undefined}
    sx={{ 
      boxShadow:  `0px 4px 8px 0px #00000020`,
        backgroundColor: '#f5f5f5', 
        p: '16px', 
        borderRadius: 2.5, 
        border: '1px solid #00000005',
        minHeight: '160px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '&:hover': course.soon === undefined ? { backgroundColor: '#ededed', cursor: 'pointer' } : {},
    }}
    >
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'start'}}>
        <Typography variant="body" sx={{ alignSelf: 'flex-start', fontSize: '12px', lineHeight: '16px' }}>{step}: модуль {index}</Typography>
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', pt: ['8px','8px','8px','10px'],pb: ['10px','10px','10px','14px'] }}>
        <Typography variant="h6">{course.name.ru}</Typography>
      {(account.certificates && account.certificates[course.id]) ? 
        <CheckCircleIcon sx={{ color: '#548dff', fontSize: '24px' }} /> : 
        <CheckCircleOutlineIcon sx={{fontSize: '24px'}}/>
      }
     </Box>
     <Typography variant="body2">{course.description.ru}</Typography>
    </Box>
   
    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', py: ['10px','10px','10px','14px'] }}>
    
    {!isMobile && (
      <>
<Typography variant="body2">
  <span style={{fontWeight: 600}}>{course.structure?.length ?? 10}</span> {getLessonWordForm(course.structure?.length ?? 10)}
</Typography>

        <Typography variant="body2"><span style={{fontWeight: 600}}>•</span></Typography>
        <Typography variant="body2"><span style={{fontWeight: 600}}>{course.duration}</span> ч.</Typography>
        {course.rating && (
        <>
        <Typography variant="body2"><span style={{fontWeight: 600}}>•</span></Typography>
        <Rating name="course-rating" 
        value={course.rating} 
        size={'small'} 
        readOnly 
        precision={0.1}
      />
        <Typography variant="body2"><span style={{fontWeight: 600}}>({course.enrolled}</span>)</Typography>
        </>
        )}
      </>
    )}

    </Box>
    <Button
      variant="outlined"
      disabled={course.soon ? true : false}
      onClick={() => handleRowClick(course.id)}
    >
      {course.soon ? 'Доступно с 16 октября' : account.certificates && account.certificates[course.id] ? 'Курс пройден' : account.purchased && account.purchased.includes(course.id) ?  `Продолжить обучение` : `Подробнее про модуль`}
    </Button>
  </Box>
  </Box>
);

const SpecificCoursesSection = ({ filteredCourses, account, handleRowClick, isMobile, step }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', mt: ['14px', '14px', '14px', '20px'], mb: ['40px', '40px', '40px', '60px'] }}>
    <Grid container spacing={3} alignItems="stretch">
        {filteredCourses.map((course, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <CourseCard 
              course={course} 
              account={account} 
              handleRowClick={handleRowClick} 
              isMobile={isMobile}
              step={step}
              index={index+1}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SpecificCoursesSection;
