import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { useContext, useState } from 'react';
import { Grid, Container, Typography, Box} from '@mui/material';
// ----------------------------------------------------------------------

export default function ThankYou() {

  const [loading, setLoading] = useState(false);



if (loading === true) {
  return (
    <>
      <Helmet>
        <title>Аналитика трейдера | DEFAUST</title>
      </Helmet>
      
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">

      </Box>
    </>
  );
} else {
  return (
    <>
      <Helmet>
        <title>Аналитика трейдера | DEFAUST</title>
      </Helmet>

      <Container maxWidth="xl">
      <Box display="flex" flexDirection='column' gap={'7px'} >
      <Box display="flex" alignItems="center" gap={2}>
      <Typography sx={{ color: '#211C37', fontFamily: 'PT Sans', fontSize: 30, fontStyle: 'normal', fontWeight: 700 }}>
        Привет, Defaust
      </Typography>
      </Box>

      <Typography sx={{ color: '#85878D', fontFamily: 'PT Sans', fontSize: 18, fontStyle: 'normal', fontWeight: 700 }}>
      Трейдинг – это наука, требующая знаний
      </Typography>
      </Box>

        {/* Карточки сверху */}
        <Grid container spacing={3} sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      </Grid>
      </Container>
    </>
);
}}