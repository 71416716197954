import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from "firebase/auth";
import {db, auth } from '../../../firebase'; // path to your firebase config file
import { collection, doc, setDoc, updateDoc } from 'firebase/firestore';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function RegistrationForm() {
    
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // error state

  let navigate = useNavigate();

  async function getAccessToken() {
    const response = await fetch(
        "https://api.sendpulse.com/oauth/access_token",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                grant_type: "client_credentials",
                client_id: "d1918368a949e7670d0ed7295d46f0ec", // Replace with your client ID
                client_secret: "b3ab03c635b1d13a3b92be2936e59e9a", // Replace with your client secret
            }),
        }
    )

    const data = await response.json()
    if (!response.ok) {
        throw new Error(data.message || "Failed to obtain access token")
    }

    return data.access_token
}

  async function addSendPulseContact(email) {
    console.log('starting adding to sendpulse contact')
    const mailingListId = "596347" 
    const requestBody = {
      emails: [
          {
              email: email,
          },
      ],
  }
    try {
      console.log('trying to create access token')
        const accessToken = await getAccessToken();
        const response = await fetch(
          `https://api.sendpulse.com/addressbooks/${mailingListId}/emails`,
          {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`, // Include the access token here
              },
              body: JSON.stringify(requestBody),
          }
      )
      console.log('response', response)
        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.message || "Failed to add email to SendPulse contacts");
        }
    } catch (error) {
        console.error("SendPulse Error:", error.message);
        // Optionally set an error state to inform the user
    }
}

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(''); // reset the error before a new attempt


  
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await addSendPulseContact(email);
      const usersCollection = collection(db, 'users');
      const userDoc = doc(usersCollection, userCredential.user.uid);
      await updateDoc(userDoc, { email: email });
      await updateDoc(userDoc, { role: 'default' });
      await updateDoc(userDoc, { registrationStep: 1 });
      
      // Add the user to SendPulse contacts
     

      navigate("/onboarding", { replace: true });
    } catch (error) {
      console.error(error.message);

      // handle error messages
      switch (error.code) {
        case "auth/email-already-in-use":
          setError("Email уже используется.");
          break;
        case "auth/invalid-email":
          setError("Неправильный формат email.");
          break;
        case "auth/operation-not-allowed":
          setError("Регистрация пользователей отключена.");
          break;
        case "auth/weak-password":
          setError("Пароль слишком слабый.");
          break;
        default:
          setError("Произошла ошибка при регистрации аккаунта.");
      }
    }
  }

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email"
          onChange={(e) => setEmail(e.target.value)}
          sx={{
            "& label.Mui-focused": {
              color: "white",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "rgba(255, 255, 255, 0.2)",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(255, 255, 255, 0.6)",
              },
              "&:hover fieldset": {
                borderColor: "rgba(255, 255, 255, 0.6)",
              },
              "&.Mui-focused fieldset": {
                borderColor: "rgba(255, 255, 255, 0.6)",
              },
            },
          }}
          InputLabelProps={{
            style: { color: 'white' },
          }}
          InputProps={{
            style: { color: 'white' },
          }}
        />

        <TextField
          name="password"
          label="Пароль"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setPassword(e.target.value)}
          sx={{
            "& label.Mui-focused": {
              color: "white",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "rgba(255, 255, 255, 0.2)",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(255, 255, 255, 0.6)",
              },
              "&:hover fieldset": {
                borderColor: "rgba(255, 255, 255, 0.6)",
              },
              "&.Mui-focused fieldset": {
                borderColor: "rgba(255, 255, 255, 0.6)",
              },
            },
          }}
          InputLabelProps={{
            style: { color: 'white' },
          }}
          InputProps={{
            style: { color: 'white' },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {error && (
          <Alert
            severity="error"
            sx={{
              backgroundColor: '#F5252510',
              color: '#F52525',
              borderRadius: 2,
              mt: 2,
            }}
          >
            {error}
          </Alert>
        )}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>


      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleRegister}
        sx={{
          borderRadius: 3,
          backgroundColor: "#FFF",
          color: 'black',
          '&:hover': {
            backgroundColor: "#FFFFFF99",
          },
        }}
      >
        Создать аккаунт
      </LoadingButton>
    </>
  );
}