import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

function VideoPlayer({ url, onEnded, controls = true }) {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreenChange = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      setIsFullScreen(true);
    } else {
      setIsFullScreen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    document.addEventListener("MSFullscreenChange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener("webkitfullscreenchange", handleFullScreenChange);
      document.removeEventListener("mozfullscreenchange", handleFullScreenChange);
      document.removeEventListener("MSFullscreenChange", handleFullScreenChange);
    };
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: 0,
        paddingBottom: '56.25%', /* Aspect ratio */
        position: 'relative',
        borderRadius: isFullScreen ? '0px' : '10px',
        overflow: isFullScreen ? 'visible' : 'hidden'
      }}
    >
      <ReactPlayer
        onEnded={onEnded}
        url={url}
        controls={controls}
        width='100%'
        height='100%'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        config={{ file: { 
          attributes: {
            controlsList: 'nodownload'
          }
        }}}
      />
    </div>
  );
}

export default VideoPlayer;
