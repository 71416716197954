import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { doc, setDoc, onSnapshot, collection,query,where,getDocs } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore } from "firebase/firestore";
import { Card, Container, Typography, Button, Paper, Box,  List, ListItem, ListItemIcon, ListItemText, CardMedia } from '@mui/material';
import { styled } from '@mui/material/styles';
import { loadStripe } from '@stripe/stripe-js';
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from 'uuid'
import { db, analytics } from '../../firebase';
import { useTheme, alpha } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import { LoadingButton } from '@mui/lab';
import { logEvent} from 'firebase/analytics';
import { useNavigate } from 'react-router-dom';
const stripePromise = loadStripe('pk_live_51Naf23CIeBGLQArtMHCLkfITSFISrqQ3DnJdXFIKM8xOqI85Mo5LIg9kzoUA0bnD8CbnmllOro3kHNAYKH5rSGM7006rh1NiAX');
const prices = [
  {
    id: 'price_1NvepoCIeBGLQArtJCGYCUnM',
    product: {
      name: 'Self-Trader',
      items: ['introduction-in-financial-markets',
                'introduction-in-trading',
                'market-structure',
                'orderflow',
                'volume-profile',
                'cumulative-delta',
                'trend-continuation',
                'provocation',
                'risk-management',
                ],
      description: 'Получи доступ к основным курсам обучающей программы для самостоятельного изучения стратегии DEFAUST или оплачивай и проходи модули отдельно.',
      images: ['/assets/images/products/logo-basic.png'],
      includedItems: [
        'Модуль 1: Cтруктура фин. рынков, фьючерсы, опционы',
        'Модуль 2: Order-book, Auctio Market Theory, лента, типы ордеров',
        'Модуль 3: Market Structure, Balance, Trend Structure, SM Manipulations',
        'Модуль 4: Order-flow, Liquidity sweeps and Liquidity Levels',
        'Модуль 5: Volume Analysis, liquidity levels, volume clusters',
        'Модуль 6: Cumulative Delta, Divergence, Buy/Sell Volumes',
        'Модуль 7: Торговый сетап Trend Continuation, алгоритм торговли',
        'Модуль 8: Торговый сетап: Provocation, алгоритм торговли',
        'Модуль 9: Риск-менеджмент, margin, risk per trade, liquidity',
        ],
      excludedItems: [
        'Модуль 10: Статистика в трейдинге, alpha, beta, volatility, correlation',
        'Модуль 11: Психология в трейдинге, дисциплина, работа с эмоциями',
        'Модуль 12: Журнал трейдера, улучшение торговли',
        'Модуль 13: Проп-компании: получение капитала до 600,000$',
        'Модуль 14: Торговый терминал MetaTrader 4/5',
        'Бонус: Налоги в трейдинге',
        'Полный доступ в Discord',
        'Доступ ко всем конференциям',
        'Доступ к Recap сессиям для анализа сделок и рынка',
        'Поддержка от ментора DEFAUST'
        ],
    },
    unit_amount: 45000
  },
  {
    id: 'price_1NvddcCIeBGLQArt7AyWWznB',
    product: {
      name: 'DEFAUST MENTORSHIP',
      items: ['introduction-in-financial-markets',
            'introduction-in-trading',
            'market-structure',
            'orderflow',
            'volume-profile',
            'cumulative-delta',
            'trend-continuation',
            'provocation',
            'statistics',
            'risk-management',
            'psychology',
            'journal',
            'prop-firms',
            'metatrader'
      ],
      description: 'Получи полный доступ к полной программе обучения, включая доступ в закрытый дискорд и постоянную поддержку от ментора DEFAUST. Самый быстрый путь обучения трейдингу.',
      images: ['/assets/images/products/logo-pro-max.png'],
      includedItems: [
        'Модуль 1: Cтруктура фин. рынков, фьючерсы, опционы',
        'Модуль 2: Order-book, Auctio Market Theory, лента, типы ордеров',
        'Модуль 3: Market Structure, Balance, Trend Structure, SM Manipulations',
        'Модуль 4: Order-flow, Liquidity sweeps and Liquidity Levels',
        'Модуль 5: Volume Analysis, liquidity levels, volume clusters',
        'Модуль 6: Cumulative Delta, Divergence, Buy/Sell Volumes',
        'Модуль 7: Торговый сетап Trend Continuation, алгоритм торговли',
        'Модуль 8: Торговый сетап: Provocation, алгоритм торговли',
        'Модуль 9: Риск-менеджмент, margin, risk per trade, liquidity',
        'Модуль 10: Статистика в трейдинге, alpha, beta, volatility, correlation',
        'Модуль 11: Психология в трейдинге, дисциплина, работа с эмоциями',
        'Модуль 12: Журнал трейдера, улучшение торговли',
        'Модуль 13: Проп-компании: получение капитала до 600,000$',
        'Модуль 14: Торговый терминал MetaTrader 4/5',
        'Полный доступ в Discord',
        'Доступ ко всем конференциям',
        'Доступ к Recap сессиям для анализа сделок и рынка',
        'Поддержка от ментора DEFAUST'
        ],
      excludedItems: [
        ],
        gifts: ['Бонус: Легализация прибыли с трейдинга, методы уплаты налогов в Украине, Европе и Дубай',]
    },
    unit_amount: 65000
  },

  // other tariffs...
];

const CardWrapper = styled(Card)({
  display: 'flex',
  maxWidth: '440px',
  height: '100%',
  flexDirection: 'column',
  padding: '20px',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
  textAlign: 'center',
  backgroundColor: alpha("#fff", 0.025), // semi-transparent background
  backdropFilter: "blur(10px)", // glassmorphism effect
  border: "0.5px solid rgba(209, 213, 219, 0.3)", // border
  boxShadow: "0px 2px 1px -1px #ffffff10", //shadow
  borderRadius: '15px', 
});

export default function Prices() {
const auth = getAuth();
const user = auth.currentUser;
const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasAnySubscription, setHasAnySubscription] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const functions = getFunctions();
  const handleBuy = () => {
    navigate(`/login`);
  };
  const handleBuySeparate = () => {
    navigate(`/dashboard/catalog/certification/defaust-trader`);
  };
  const createCoinbaseCheckout = httpsCallable(functions, 'createCoinbaseCheckout');
  const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');

  async function getSubscriptionForUser(userId) {
    try {
      setLoading(true);
      const activeQuery = query(collection(db, 'customers', userId, 'subscriptions'), where("status", "==", "active"));
      const trialingQuery = query(collection(db, 'customers', userId, 'subscriptions'), where("status", "==", "trialing"));
      
      const activeSubs = await getDocs(activeQuery);
      const trialingSubs = await getDocs(trialingQuery);
      
      const querySnapshot = [...activeSubs.docs, ...trialingSubs.docs];
      
      let userSubscriptions = [];
      querySnapshot.forEach((doc) => {
        userSubscriptions.push(doc.data());
      });
      //console.log('subs',userSubscriptions)
      setLoading(false);
      return userSubscriptions;
    } catch (e) {
      console.error("Error getting subscriptions: ", e);
      setError(e);
    }
  }
  
useEffect(() => {
    // Separate the logic to fetch subscriptions into a function
    const fetchSubscriptions = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            const userId = user.uid;
            const subs = await getSubscriptionForUser(userId);
            setSubscriptions(subs);
        }
    };

    // Fetch subscriptions when the component mounts
    fetchSubscriptions();

    // Set up the event listener for the window focus event
    const handleWindowFocus = () => {
        fetchSubscriptions();
    };

    window.addEventListener('focus', handleWindowFocus);

    // Clean up the event listener when the component is unmounted
    return () => {
        window.removeEventListener('focus', handleWindowFocus);
    };
}, []);


  useEffect(() => {
    const checkAnySubscription = () => {
      for (const price of prices) {
        if (hasSubscription(price.id)) {
          setHasAnySubscription(true);
          return; // Exit the loop if a subscription is found
        }
      }
      setHasAnySubscription(false); // Set to false if no subscriptions are found
    };

    checkAnySubscription();
  }, [subscriptions]);

  function hasSubscription(priceId) {
    for (let i = 0; i < subscriptions.length; i++) {
      const sub = subscriptions[i];
      
      if (sub.items && Array.isArray(sub.items) && sub.items.length > 0) {
        for (let j = 0; j < sub.items.length; j++) {
          const item = sub.items[j];
          
          if (item.plan && item.plan.id === priceId) {
            
            return true;
            
          }
        }
      }
    }
  
    return false;
  }
  const createLineItemsFromCartCoinbase = (cart) => {
    // Extract items directly since it's an array of strings
    const items = cart.product.items;
  
    // Extract name directly since it's a string
    const names = [cart.product.name];

    // Calculate the total sum of item prices
    const total = ((cart.unit_amount)/100).toFixed(2);
  
    // Return a single object containing both items and total
    return {
      items,
      total,
      names,
    };
};



  const handleSubscribe = async (paymentMethod, priceId) => {
    if (paymentMethod === 'stripe') {
    try {
      setLoading(true);
      const auth = getAuth();
      const user = auth.currentUser;
  
      if (!user) {
        throw new Error("No user is signed in.");
      }
  
      const userId = user.uid;
      const checkoutSessionId = uuidv4();
  
      // Create a new checkout session in Firestore
      await setDoc(doc(db, 'customers', userId, 'checkout_sessions', checkoutSessionId),
        { price: priceId,
          success_url: window.location.href,
          cancel_url: window.location.href});
  
      // Listen for the checkout session ID from Firestore
      const unsubscribe = onSnapshot(doc(db, 'customers', userId, 'checkout_sessions', checkoutSessionId), async (docSnap) => {
        const { sessionId } = docSnap.data();
  
        if (sessionId) {
          // We have a session ID, let's redirect!
          unsubscribe(); // stop listening for updates
  
          const stripe = await stripePromise;
          stripe.redirectToCheckout({ sessionId });

        }
      });
  
    } catch (e) {
      console.error("Error starting checkout: ", e);
      setError(e);
    }
    }
    if (paymentMethod === 'coinbase') {
        setLoading(true);
        const data = createLineItemsFromCartCoinbase(priceId);
        //console.log('data', data);
        try {
          const result = await createCoinbaseCheckout(data);
          const { checkoutUrl } = result.data;
          if (checkoutUrl) {
            //console.log("Checkout URL:", checkoutUrl);
            logEvent(analytics, 'coinbase_payment_created');
            window.location.href = checkoutUrl;  // Redirect the user to complete the payment
          }
          setLoading(false);
        } catch (e) {
          logEvent(analytics, 'coinbase_payment_failed', { error_message: e.message });
          console.error("Error initiating Coinbase Commerce payment:", e);
        }
      }
  };
  

const handleManageSubscription = async () => {
    try {
        setLoading(true);
        const link = await createPortalLink({});
        window.location.href = link.data.url;
    } catch (error) {
        console.error('Error creating customer portal link:', error);
    }
};


  return (
    <>
    <Helmet>
        <html lang="ru" /> 
        <title>Тарифы обучения | DEFAUST</title>
        <meta name="description" content="Выбирай удобный тариф обучения и начинай обучаться торговой стратегии DEFAUST." />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "Тарифы обучения | DEFAUST",
              "description": "Выбирай удобный тариф обучения и начинай обучаться торговой стратегии DEFAUST."
            }
          `}
        </script>
    </Helmet>

    <Container maxWidth="lg">
    <Box  sx={{display: 'flex',  justifyContent: 'center', flexDirection: ['column-reverse', 'row'], gap: '20px',alignItems: 'stretch', }}>
      {prices.map((price) => (
        <Box key={price.id}>
                    <CardWrapper component={Paper} 
        sx={{ 
            display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: '#F5F7F9',
            border: "1px solid rgba(209, 213, 219, 0.3)",
            borderRadius: 5,
            px: 2,
            pb: '40px',
            mt: '40px',
        }}>                        
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 140, width: '100%' }}>
                    <CardMedia
                component="img"
                image={price.product.images[0]}
                alt={price.product.name}
                sx={{objectFit: 'cover', width: 150, height: 150,}}
              />
              </Box>
              <Box sx={{ flexGrow: 1, height: 'auto', overflow: 'hidden' }}>
            <Typography sx={{mt: 3, textAlign: 'center',}} fontSize={'1.5rem'} fontWeight={700}>{price.product.name}</Typography>
            <Typography sx={{pt: 0, textAlign: 'center', fontSize: ['16px','16px','16px','18px'], fontWeight: '400', lineHeight: ['24px','24px','24px','28px']}}>{price.product.description}</Typography>
            </Box>

          <List sx={{mt: '10px', pt: 0, }}>
                <Typography sx={{mt: 0, textAlign: 'center',}} fontSize={'1.3rem'} fontWeight={700}>В тариф входит:</Typography>
                {price.product.includedItems.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <CheckCircleIcon sx={{color: '#548dff'}} />
                      
                    </ListItemIcon>
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
                {price.product.excludedItems.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <ClearIcon color="error" /> {/* use ClearIcon with color error for cross icon */}
                    </ListItemIcon>
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
                {price.product.gifts.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <Typography sx={{ml: '2px', fontSize: '20px'}} variant='body'>🎁</Typography>
                    </ListItemIcon>
                    <ListItemText primary={item} sx={{'.MuiListItemText-primary': {fontWeight: '700'}}} />
                  </ListItem>
                ))}
              </List>

<Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px',   alignContent: 'center',
  alignItems: 'center', pt: '10px'}}>
            <Typography 
                textAling={'center'}
                fontSize={'2.5rem'} 
                fontWeight={700}
            >
                {price.unit_amount/100} $
            </Typography>

            <Typography fontSize={'1.25rem'} fontWeight={200} color={"#2F2F2F"} lineHeight={0.8}>за 6 месяцев</Typography>
            </Box>
            <Box sx={{display: 'flex',width: '100%', flexDirection: 'column'}}>
                
<LoadingButton 
    fullwidth
             variant="outlined" color="primary" sx={{ width: ['100%','100%','100%', 'auto'], boxShadow: '3px 3px 1px 1px rgba(24, 24, 24, 0.25)', color: '#000', background: '#A0C0FF' ,fontSize: ['14px','14px','14px','15px'], fontWeight: '600', lineHeight: ['16px','16px','16px','20px'], px: '20px', mt: 3,  borderRadius: 3, py: '15px', textTransform: 'none',
                '&:hover': {
                 color: '#000', background: '#A0C0FF', 
                }}} 
  loading={loading}

  disabled={hasAnySubscription && !hasSubscription(price.id)}
  onClick={() => { user? handleSubscribe('coinbase', price) : handleBuy()}}
>
{ 
    hasAnySubscription ? 
      'Управлять подпиской' : 
      hasSubscription(price.id) ? 
        'Улучшить подписку' : 
        <>
         Оплатить криптой <img src={'/assets/images/crypto-payments.png'}  height='20px' style={{ marginLeft: '5px' }} />
        </>
  }
</LoadingButton>
<LoadingButton 
fullwidth
             variant="outlined" color="primary" sx={{ width: ['100%','100%','100%', 'auto'], boxShadow: '3px 3px 1px 1px rgba(24, 24, 24, 0.25)', color: '#000', background: '#A0C0FF' ,fontSize: ['14px','14px','14px','15px'], fontWeight: '600', lineHeight: ['16px','16px','16px','20px'], px: '20px', mt: 3,  borderRadius: 3, py: '15px', textTransform: 'none',
                '&:hover': {
                 color: '#000', background: '#A0C0FF', 
                }}} 
  loading={loading}

  disabled={hasAnySubscription && !hasSubscription(price.id)}
  onClick={() => {user? handleSubscribe('stripe', price.id) : handleBuy()}}
>
{ 
    hasAnySubscription ? 
      'Управлять подпиской' : 
      hasSubscription(price.id) ? 
        'Улучшить подписку' : 
        <>
          Оплатить картой Visa/Mastercard 
          <img src='/assets/images/card-payments.png' height='20px' style={{ marginLeft: '5px' }} />
        </>
  }
</LoadingButton>
{price.id !== 'price_1NvepoCIeBGLQArtJCGYCUnM' &&
(
    <Button variant="text"  sx={{ color: 'transparent', background: 'transparent', cursor: 'none',width: ['100%','100%','100%', 'auto'],  background: 'transparent' ,fontSize: ['14px','14px','14px','15px'], fontWeight: '400', lineHeight: ['16px','16px','16px','20px'], mt: 3,  borderRadius: 3, py: '15px', textTransform: 'none', px: '20px',
    '&:hover': {
     color: 'transparent', background: 'transparent', cursor: 'default'
   }}} >
None
</Button>
)}
</Box>
          </CardWrapper>
        </Box>
      ))}
</Box>
    </Container>
    </>
  );
}
