import React, { useEffect, useState, useContext } from 'react';
import { Box, Grid, Container, Typography, FormControl, InputLabel, Select, MenuItem , Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { db } from '../../firebase';
import { getFunctions, httpsCallable } from "firebase/functions";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Helmet } from 'react-helmet-async';
import { CartContext } from '../../CartContext';
//sections
import ProductsList from '../../sections/@dashboard/cart/ProductsList';
import AddOns from '../../sections/@dashboard/cart/AddOns';
import TotalCart from '../../sections/@dashboard/cart/TotalCart';
import { OpenCheckoutList } from '../../sections';
import { loadStripe } from '@stripe/stripe-js';
import Skeleton from '@mui/material/Skeleton';
import { getAuth } from 'firebase/auth';
import UserContext from '../../UserContext';
const addOnTotal = 0;



export default function Cart() {
    const account = useContext(UserContext);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const { cart, setCart } = useContext(CartContext);
    const [total, setTotal] = useState(0);
    const [items, setItems] = useState([]);
    const [otherCourses, setOtherCourses] = useState([]);

    const [loadingPrices, setLoadingPrices] = useState(true);
    const [loading, setLoading] = useState(true);
    const functions = getFunctions();
    const fetchPricesFunction = httpsCallable(functions, 'fetchPrices');
    const calculateTotal = (itemsWithPrices) => {
        const totalAmount = itemsWithPrices.reduce((acc, item) => acc + item.price, 0);
        setTotal(totalAmount);
    };
    
    const fetchCartItems = async () => {
        try {
          setLoading(true);
          const userId = getAuth().currentUser?.uid;
      
          if (!userId) {
            throw new Error("User is not signed in.");
          }
      
          const purchasedIds = account.purchased || [];
          let updatedCart = {
            courses: cart.courses?.filter(id => !purchasedIds.includes(id)) || [],
            certificates: cart.certificates?.filter(id => !purchasedIds.includes(id)) || []
          };
      
          let combinedData = [];
      
          // Combining Firestore fetches
          const fetchCollectionData = async (collectionName, ids) => {
            if (ids.length === 0) return [];
            const collectionRef = collection(db, collectionName);
            const queryRef = query(collectionRef, where("id", "in", ids));
            const snapshot = await getDocs(queryRef);
            return snapshot.docs.map(doc => ({ ...doc.data(), type: collectionName === 'courses' ? 'course' : 'certification' }));
          };
      
          const [coursesData, certsData] = await Promise.all([
            fetchCollectionData("courses", updatedCart.courses),
            fetchCollectionData("certifications", updatedCart.certificates)
          ]);
      
          combinedData = [...coursesData, ...certsData];
      
          if (combinedData.length > 0) {
            const priceIds = combinedData.map(item => item.price);
            fetchPricesFunction({ priceIds })
              .then(result => {
                const prices = result.data;
                const updatedItems = combinedData.map(item => {
                  const stripePrice = prices.find(p => p.id === item.price);
                  return stripePrice ? { ...item, price: stripePrice.unit_amount / 100, price_id: stripePrice.id } : item;
                });
                setItems(updatedItems);
                calculateTotal(updatedItems);
              })
              .catch(error => console.error("Error fetching prices from Firebase function:", error));
          }
      
        } catch (error) {
          console.error("Error fetching cart items:", error);
        } finally {
          setLoading(false);
          setLoadingPrices(false);
        }
      };
      const fetchAllOtherCourses = async () => {
        try {
          setLoading(true);
          const userId = getAuth().currentUser?.uid;
      
          if (!userId) {
            throw new Error("User is not signed in.");
          }
      
          // IDs of courses that are in the cart or already purchased
          const excludedIds = [...(cart.courses || []), ...(account.purchased || [])];
      
          // Fetch all courses from Firestore
          const coursesCollection = collection(db, "courses");
          const coursesSnapshot = await getDocs(coursesCollection);
      
          // Filter out courses based on their 'id' field
          let allCourses = coursesSnapshot.docs.map(doc => doc.data());
          let otherCourses = allCourses.filter(course => !excludedIds.includes(course.id));
      
          if (otherCourses.length > 0) {
            const priceIds = otherCourses.map(item => item.price);
            
            fetchPricesFunction({ priceIds })
              .then(result => {
                const prices = result.data;
                const updatedItems = otherCourses.map(item => {
                  const stripePrice = prices.find(p => p.id === item.price);
                  return stripePrice ? { ...item, price: stripePrice.unit_amount / 100, price_id: stripePrice.id } : item;
                });
                const limitedItems = updatedItems.slice(0, 3); // Limit to max 3 items
                setOtherCourses(limitedItems);
                 // Assuming you have a state variable to hold these
              })
              .catch(error => console.error("Error fetching prices from Firebase function:", error));
          }
      
        } catch (error) {
          console.error("Error fetching other courses:", error);
        } finally {
          setLoading(false);
        }
      };
      

    useEffect(() => {
        fetchCartItems();
        fetchAllOtherCourses();
    }, [ account.purchased]);
    


if (loadingPrices === true) {
    return (
        <Container maxWidth="lg">
        <Typography variant="h3">Корзина</Typography>
            <Skeleton variant="rectangular" width="100%" height={120} sx={{
                                border: "1px solid rgba(209, 213, 219, 0.3)", // border
                                borderRadius: 5,
                                padding: 2,
                                mt: 5,
            }}/>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            <Skeleton variant="rectangular" width="100%" height={300} sx={{
                                border: "1px solid rgba(209, 213, 219, 0.3)", // border
                                borderRadius: 5,
                                padding: 2,
                                mt: 5,
            }}/>
          </Grid>
            <Grid item xs={12} sm={6}>
            <Skeleton variant="rectangular" width="100%" height={300} sx={{
                                border: "1px solid rgba(209, 213, 219, 0.3)", // border
                                borderRadius: 5,
                                padding: 2,
                                mt: 5,
            }}/>
          </Grid>
            </Grid>
            </Container>
    );
    }
else {
  return (
    <>
    <Helmet>
        <html lang="ru" /> 
        <title>Корзина | DEFAUST</title>
        <meta name="description" content="Выбирайте курсы по трейдингу, криптовалютам и инвестициям от DEFAUST ACADEMY!" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "Корзина",
              "description": "Выбирайте курсы по трейдингу, криптовалютам и инвестициям от DEFAUST ACADEMY!"
            }
          `}
        </script>
    </Helmet>

    <Container maxWidth="lg">
    <Typography variant="h3">Корзина</Typography>

    {(cart.courses && cart.courses.length > 0) || (cart.certificates && cart.certificates.length > 0) ? (
        <>
            <ProductsList courses={items} />
            <Grid container spacing={3} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center'  }}>
{/*                 <Grid item xs={12} sm={12} md={6} lg={6} sx={{ flex: 1, display: "flex" }}>
                    <AddOns courses={otherCourses} selectedCourses={selectedCourses} setSelectedCourses={setSelectedCourses} />
                </Grid> */}
                <Grid item xs={12} sm={12} md={6} lg={6} sx={{ display: "flex", flexDirection: "column",}}>
                    <Box sx={{ 
                        textAlign: 'start',
                        flex: 1, display: "flex", flexDirection: 'column',  justifyContent: "center", alignItems: "start",
                        height: "100%",
                        backgroundColor: '#F5F7F9',
                        border: "1px solid rgba(209, 213, 219, 0.3)",
                        borderRadius: 5,
                        px: 2,
                        py: 1,
                        mt: 5,
                    }}>
                          {(items.length > 0) ? (
                        <TotalCart allCourses={otherCourses} selectedCourses={selectedCourses} total={total} addOnTotal={addOnTotal} cart={items} cartFull={cart} loadingButton={loading}/>
                          ) : null}
                    </Box>
                </Grid>
                
            </Grid>
            <OpenCheckoutList db={db} />
        </>
    ) : null}
</Container>



    </>
  )};
}