import { useState, useEffect } from 'react';
import {  doc, updateDoc, arrayUnion, arrayRemove, increment, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { format } from 'date-fns';
import { useTheme, useMediaQuery, Dialog, DialogContent, DialogActions, Card,CardContent, CardActions,List, ListItem, ListItemText, Typography, Box, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import VideoPlayer from '../course/VideoPlayer';
export default function EventsRowList({navigate, events, db, account}) {
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [isAttending, setIsAttending] = useState(false);
    const [attendingStatus, setAttendingStatus] = useState({});
    const [attending, setAttending] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  


  // Use useEffect to initialize the attending status based on the events and account info
  useEffect(() => {
    if (events && account) {
      const newAttendingStatus = {};
      events.forEach((event) => {
        newAttendingStatus[event.id] = isUserAttending(event.id, account.attendingMeetings);
      });
      setAttendingStatus(newAttendingStatus);
    }
  }, [events, account]);
  
  const handleAttendingChange = (eventId, status) => {
    setAttendingStatus(prevStatus => ({
        ...prevStatus,
        [eventId]: status
    }));
};

      
    
    
    const handleSubscribeNavigate = () => {
      navigate(`/settings/subscriptions`);
    };
  
    const handleWatchRecording = () => {
      navigate(`/dashboard/conferences`);
    };
    const handleConnectMeeting = (zoomLink) => {
      window.open(zoomLink, '_blank');
    };
  
    const attendMeeting = async (meetingId) => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            const userDocRef = doc(db, 'users', user.uid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists() && userDoc.data().attendingMeetings && userDoc.data().attendingMeetings.includes(meetingId)) {
                //console.log('User is already attending this meeting');
                return Promise.resolve();
            }

            await updateDoc(userDocRef, {
                attendingMeetings: arrayUnion(meetingId),
            });

            const meetingDocRef = doc(db, 'resources', 'streams', 'meetings', meetingId);
            await updateDoc(meetingDocRef, {
                attending: increment(1),
            });

            return Promise.resolve();
        } else {
            console.error('User is not authenticated');
            return Promise.reject(new Error('User is not authenticated'));
        }
    };

    const cancelAttending = async (meetingId) => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            const userDocRef = doc(db, 'users', user.uid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists() && !userDoc.data().attendingMeetings.includes(meetingId)) {
                //console.log('User is not attending this meeting');
                return Promise.resolve();
            }

            await updateDoc(userDocRef, {
                attendingMeetings: arrayRemove(meetingId),
            });

            const meetingDocRef = doc(db, 'resources', 'streams', 'meetings', meetingId);
            await updateDoc(meetingDocRef, {
                attending: increment(-1),
            });

            return Promise.resolve();
        } else {
            console.error('User is not authenticated');
            return Promise.reject(new Error('User is not authenticated'));
        }
    };

    const isUserAttending = (meetingId) => {
        return account?.attendingMeetings?.includes(meetingId);
    };

    const onClickHandler = async (event) => {
        const isCurrentlyAttending = isUserAttending(event.id);
        
        if (isCurrentlyAttending) {
            await cancelAttending(event.id);
        } else {
            await attendMeeting(event.id);
        }
        
        // Assuming your account state updates elsewhere when Firebase data changes
    };
    
      
  

  return (
    //upcoming events
    <>
      {events.length > 0 && (
    <>
    {events.length > 0 && (
      <>
    <List>
      {events.map((event, index) => (
        <ListItem  key={index}    
        sx={{ 
          backgroundColor: '#f5f5f5', 
          p: '16px', 
          mb: '20px',
          borderRadius: 2.5, 
          border: '1px solid #00000005',
          minHeight: '160px',
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'space-between', 
          alignItems: 'flex-start',
          gap: '15px',
          '&:hover': {  cursor: 'default' },
        }}>

           <Box sx={{display: 'flex', flexDirection: ['column','column','column', 'row'], justifyContent: 'space-between', width: '100%', gap: '30px'}}>
           <Box sx={{  display: 'flex', width: ['100%', '100%', '100%', '40%'] ,justifyContent: 'center', alignItems: 'center',  borderRadius: '20px'}}>
            {event.recordUrl && account.membership == 'active'? (
                                <VideoPlayer
                                url={event.recordUrl}
                              />
            ) : (
                <img src={event.image} alt="стрим defaust"  style={{  borderRadius: '8px', boxShadow:  `0px 0px 10px 4px #00000055`,}} />
            )}
                </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', gap: '10px'}}>
            <Typography variant="body" sx={{fontSize: ['18px','18px','18px','24px'], fontWeight: 'medium', lineHeight: ['34px','34px','34px','42px']}}>
          {event.eventName}
        </Typography>
        <Typography variant="body" sx={{ fontSize: '14px', lineHeight: '16px' }}>{(event.timestamp.toDate()).toLocaleString('ru-RU', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</Typography>
            <Typography  sx={{mt: '10px',fontSize: ['16px','16px','16px','18px'], fontWeight: '400', lineHeight: ['24px','24px','24px','28px']}}>
                {event.description}
            </Typography>
    <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap', justifyContent: 'flex-start', mt: '0px'}}>
          {event.tags.map((tag, i) => (
            <Box
              key={i}
              sx={{
                fontSize: ['12px','12px','12px','12px'], lineHeight: ['24px','24px','24px','14px'],
                padding: '5px 10px',
                borderRadius: '12px',
                fontWeight: '600',
                color: '#fff',
                backgroundColor:
                  tag === 'trading'
                    ? '#548dff'
                    : tag === 'psychology'
                    ? '#0D160B'
                    : tag === 'crypto'
                    ? '#2C514C'
                    : '#2C514C',
              }}
            >
              {tag}
            </Box>
          ))}
        </Box>
     
        <Box sx={{display: 'flex', flexDirection: 'row', gap: '10px',  px: '10px', pb: '10px',justifyContent: 'flex-end', width: '100%' }}>
                {account.membership === 'active' ? (
                  event.recordUrl ? (
                    <Button  onClick={() => handleWatchRecording()}
                    variant="outlined" color="primary" sx={{  boxShadow: '3px 3px 1px 1px rgba(24, 24, 24, 0.25)', color: '#000', background: '#A0C0FF' ,fontSize: ['14px','14px','14px','15px'], fontWeight: '600', lineHeight: ['16px','16px','16px','20px'], px: '20px', borderRadius: 3, py: '10px', textTransform: 'none',
                    '&:hover': {
                     color: '#000', background: '#A0C0FF', 
                    }}}
                    >
                      Конференция записана
                    </Button>
                  ) : (event.timestamp.toDate() < new Date() ? (
                    <Button
                      disabled={new Date() > new Date(event.timestamp.toDate().getTime() + 3 * 60 * 60 * 1000)}
                      onClick={() => handleConnectMeeting(event.url)}
                      variant="outlined" color="primary" sx={{  boxShadow: '3px 3px 1px 1px rgba(24, 24, 24, 0.25)', color: '#000', background: '#A0C0FF' ,fontSize: ['14px','14px','14px','15px'], fontWeight: '600', lineHeight: ['16px','16px','16px','20px'], px: '20px', borderRadius: 3, py: '10px', textTransform: 'none',
                      '&:hover': {
                       color: '#000', background: '#A0C0FF', 
                      }}}
                      >
                        {new Date() > new Date(event.timestamp.toDate().getTime() + 3 * 60 * 60 * 1000)? 'Нет записи' : 'Присоединиться к конференции' }
                    </Button> ) : 
                    (
                        <Button 
                        disabled={new Date() > new Date(event.timestamp.toDate().getTime() + 3 * 60 * 60 * 1000)}
                        variant="outlined" color="primary" sx={{  boxShadow: '3px 3px 1px 1px rgba(24, 24, 24, 0.25)', color: '#000', background: '#A0C0FF' ,fontSize: ['14px','14px','14px','15px'], fontWeight: '600', lineHeight: ['16px','16px','16px','20px'], px: '20px', borderRadius: 3, py: '10px', textTransform: 'none',
                        '&:hover': {
                         color: '#000', background: '#A0C0FF', 
                        }}}
                        onClick={() => onClickHandler(event)}>
                            {account && account.attendingMeetings && account.attendingMeetings.includes(event.id) ? 'Отписаться' : 'Записаться на конференцию'}
                    </Button>
                    ))
  ) : (
    <Button
      onClick={handleSubscribeNavigate}
      variant="outlined" color="primary" sx={{ boxShadow: '3px 3px 1px 1px rgba(24, 24, 24, 0.25)', color: '#000', background: '#A0C0FF' ,fontSize: ['14px','14px','14px','15px'], fontWeight: '600', lineHeight: ['16px','16px','16px','20px'], px: '20px', borderRadius: 3, py: '10px', textTransform: 'none',
      '&:hover': {
       color: '#000', background: '#A0C0FF', 
      }}}
      >
      Получить доступ за 50$/мес.
    </Button>
  )}
              </Box>
              </Box>
                </Box>
        </ListItem>
      ))}
    </List>
    </>
    )}
</>
      )}
    </>
  );
};


