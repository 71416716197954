import React from 'react';
import usePendingCheckouts from '../../../hooks/usePendingCheckouts';
import { CircularProgress, Grid, ListItem, Box, Typography, Button } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';

const OpenCheckoutList = ({db}) => {
    const auth = getAuth();
    const user = auth.currentUser;

    const { pendingCheckouts, loadingPendingCheckouts, refetch } = usePendingCheckouts(user.uid);

  const handleCancelPayment = async (checkoutId) => {
    try {
      // Constructing the path to the specific checkout session
      const sessionRef = doc(db, "cryptoCustomers", user.uid, "checkout_sessions", checkoutId);
      
      // Updating the status
      await updateDoc(sessionRef, {
        status: "canceled"
      });
      
      refetch();
      // Logging or notifying the user of the success might be good here
      //console.log("Payment successfully canceled for:", checkoutId);
    } catch (error) {
      console.error("Error cancelling the payment:", error);
    }
  };

  if (loadingPendingCheckouts) {
    return ;
  }

  if (pendingCheckouts.length > 0) {
  return (
    <div>
<Typography variant='h4'sx={{mb: ['25px', '25px', '25px', '30px'], mt: ['25px', '25px', '25px', '30px']}}>
        Неоплаченные ордера
        </Typography>
        <Grid container spacing={3}>
        {pendingCheckouts.map((checkout) => (
          <Grid item xs={12} md={4} key={checkout.id}>
            <ListItem
              sx={{
                backgroundColor: '#f5f5f5',
                p: '16px',
                mb: '20px',
                borderRadius: 2.5,
                minHeight: '160px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                gap: '15px',
                '&:hover': { cursor: 'default' },
              }}
            >
            <Box sx={{display: 'flex', flexDirection: 'row', gap: '20px', justifyContent: 'center', alignItems: 'center'}}>

 

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
              <Typography variant="body" sx={{ fontSize: '18px', fontWeight: 'medium', lineHeight: '34px' }}>
                Ордер {checkout.id}
              </Typography>
              <Typography sx={{ fontSize: '16px', fontWeight: '400', lineHeight: '24px' }}>
              <span style={{fontWeight: '600'}}>Состав заказа:</span> {checkout.names.join(", ")},
              </Typography>
              <Typography variant="body" sx={{ fontSize: '16px', lineHeight: '24px' }}>
                <span style={{fontWeight: '600'}}>Сумма:</span> {checkout.newCheckout.local_price.amount} {checkout.newCheckout.local_price.currency}
              </Typography>
              <Typography variant="body" sx={{ fontSize: '16px', lineHeight: '24px' }}>
                <span style={{fontWeight: '600'}}>Способ оплаты:</span> криптовалюта
              </Typography>
            </Box>
            </Box>
            <Box sx={{display: 'inline-flex', flexDirection: 'row', gap: '10px',  px: '10px', pb: '10px', width: '100%' }}>
            <Button
                 onClick={() => handleCancelPayment(checkout.id)}
                variant="text" color="primary" sx={{ fontWeight: 400, color: '#2E2E2E', background: 'transparent' ,fontSize: ['14px','14px','14px','15px'],  lineHeight: ['16px','16px','16px','20px'],  borderRadius: 3, py: '10px', textTransform: 'none', px: '10px',
                     '&:hover': {
                      color: 'white', background: '#2E2E2E', 
                     }}} 
                >
                Отменить оплату
            </Button>
            <Button
                onClick={() => {window.location.href = 'https://commerce.coinbase.com/checkout/'+checkout.newCheckout.id}} 
                variant="outlined" color="primary" sx={{ boxShadow: '3px 3px 1px 1px rgba(24, 24, 24, 0.25)', color: '#000', background: '#A0C0FF' ,fontSize: ['14px','14px','14px','15px'], fontWeight: '600', lineHeight: ['16px','16px','16px','20px'], px: '10px', borderRadius: 3, py: '10px', textTransform: 'none',
                '&:hover': {
                 color: '#000', background: '#A0C0FF', 
                }}}
                >
                Перейти к оплате
            </Button>
            </Box>
            </ListItem>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
}

export default OpenCheckoutList;
