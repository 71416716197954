import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';
import { useState } from 'react';
import { Button, TextField, Box } from '@mui/material';

const storage = getStorage();

export const ImageUploader = ({ onUpload }) => {
  const [base64Image, setBase64Image] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      setBase64Image(e.target.result);
    };

    reader.readAsDataURL(file);
  };

  const handleUpload = async () => {
    try {
      const file = base64Image; // assuming base64Image holds the file
      const filename = `${new Date().getTime()}-${file.name}`; // this creates a unique filename with a timestamp
      const storageRef = ref(storage, `meetings/${filename}`);
      await uploadString(storageRef, base64Image, 'data_url');
      const downloadURL = await getDownloadURL(storageRef);
      onUpload(downloadURL);
    } catch (error) {
      console.error("Error uploading the image:", error);
    }
  };
  

  return (
    <Box>
      <TextField type="file" onChange={handleImageChange} />
      <Button variant="contained" color="primary" onClick={handleUpload}>Upload Image</Button>
    </Box>
  );
};
