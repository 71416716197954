import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { Container} from '@mui/material';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { query, where } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
// ----------------------------------------------------------------------

export default function TakeCourse() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { courseId } = useParams();

  fetchCourseAndNavigate(courseId, navigate);

  function navigateToNextItem(course, navigateFunction) {
    // Get the next item's type and id
    const nextItem = course.structure[0];
    const nextItemType = Object.keys(nextItem)[0];
    const nextItemId = nextItem[nextItemType];

    // Construct the path for the next item
    const itemPath = `/course/take/${course.id}/${nextItemType}/${nextItemId}`;

    // Navigate to the next item
    navigateFunction(itemPath);
}
  async function fetchCourseAndNavigate(courseId, navigateFunction) {
    try {
        // Fetch the course document based on the provided courseId
        const courseCollection = collection(db, 'courses');
        const courseSnapshot = await getDocs(query(courseCollection, where('id', '==', courseId)));

        // If no course matches the provided courseId, handle the error
        if (courseSnapshot.empty) {
            console.error("No matching course found");
            return;
        }

        // Extract the course data from the first (and likely only) matching document
        const courseData = courseSnapshot.docs[0].data();

        // Navigate to the next item using the previously provided function
        navigateToNextItem(courseData, navigateFunction);

    } catch (error) {
        console.error("Error fetching course:", error);
    }
}


if (loading) {
  return (
          <Container maxWidth="lg">
          <Skeleton variant="text" width={200} height={70} />
          <Skeleton variant="text" width={100} height={70} />
          <Skeleton variant="rectangular" width="100%" height={500} sx={{
                              border: "1px solid rgba(209, 213, 219, 0.3)", // border
                              borderRadius: 5,
                              padding: 2,
                              mt: 2,
          }}/>
          </Container>
  );
  } else {
  return (
    <>
      <Helmet>
        <title>Курс | DEFAUST</title>
      </Helmet>

      <Container maxWidth="xl">

      </Container>
    </>
);
}}