import { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy} from 'firebase/firestore';
import { db } from '../firebase';

const useFetchEvents = () => {
  const [events, setEvents] = useState([]);
  const [loadingEvents, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const meetingCollection = collection(db, 'resources', 'streams', 'meetings');
        const q = query(meetingCollection, orderBy("timestamp", "asc"));
        const querySnapshot = await getDocs(q);

        const fetchedEvents = [];
        querySnapshot.forEach((doc) => {
          fetchedEvents.push({
            id: doc.id,
            ...doc.data()
          });
        });
        //console.log(fetchedEvents);
        setEvents(fetchedEvents);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  return { events, loadingEvents, error };
};

export default useFetchEvents;
