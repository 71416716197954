// AnimatedText.js
import { useState, useEffect } from 'react';

const words = ['в трейдинге', 'в инвестициях', 'в DeFi', 'на FOREX', 'в Liquidity Analysis', 'в Volume Analysis', "в академии"];

const AnimatedText = ({ minWidth }) => {
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [reverse, setReverse] = useState(false);
  
  useEffect(() => {
    if (index === words.length) return;

    if ( subIndex === words[index].length + 1 && 
         index !== words.length - 1 && 
         !reverse ) {
      setReverse(true);
      return;
    }

    if (subIndex === 0 && reverse) {
      setReverse(false);
      setIndex((prevIndex) => prevIndex + 1);
      return;
    }

    const timeout = setTimeout(() => {
      setSubIndex((prevSubIndex) => 
        reverse ? prevSubIndex - 1 : prevSubIndex + 1
      );
    }, 150);

    return () => clearTimeout(timeout);
  }, [subIndex, index, reverse]);

  return (
    <>
      <span style={{ minWidth: `${minWidth}px`, display: 'inline-block' }}>
        {`${words[index].substring(0, subIndex)}${subIndex === words[index].length ? ' ' : ''}`}
      </span>
    </>
  );
};

export default AnimatedText;
