import { useState, useEffect } from 'react';
import {  doc, updateDoc, arrayUnion, arrayRemove, increment } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { format } from 'date-fns';
import { useTheme, useMediaQuery, Dialog, DialogContent, DialogActions, Card,CardContent, CardActions,List, ListItem, ListItemText, Typography, Box, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

export default function AppCalendar({navigate, events, db, account}) {
  const currentDateNew = format(new Date(), 'dd');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isAttending, setIsAttending] = useState(false);
  const [attending, setAttending] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (account && account.attendingMeetings && selectedEvent) {
      setIsAttending(account.attendingMeetings.includes(selectedEvent.id));
    }
    if (selectedEvent && selectedEvent.attending) {
      setAttending(selectedEvent.attending);
    }
  }, [account, selectedEvent]);
  
  
  const handleOpenDialog = (event) => {
    setSelectedEvent(event);
    setOpenDialog(true);
  };
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleNavigateClick = () => {
    navigate(`/dashboard/conferences`);
  };
  const handleSubscribeNavigate = () => {
    navigate(`/settings/subscriptions`);
  };

  const handleWatchRecording = () => {
    navigate(`/dashboard/conferences`);
  };
  const handleConnectMeeting = (zoomLink) => {
    window.open(zoomLink, '_blank');
  };

  const attendMeeting = async (meetingId) => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, {
        attendingMeetings: arrayUnion(meetingId),
      });
  
      // Increment the 'attending' field in the meeting doc
      const meetingDocRef = doc(db, 'resources', 'streams', 'meetings', meetingId);
      await updateDoc(meetingDocRef, {
        attending: increment(1),
      });
  
      setIsAttending(true);
    } else {
      console.error('User is not authenticated');
    }
  };
  
  const cancelAttending = async (meetingId) => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, {
        attendingMeetings: arrayRemove(meetingId),
      });
  
      // Decrement the 'attending' field in the meeting doc
      const meetingDocRef = doc(db, 'resources', 'streams', 'meetings', meetingId);
      await updateDoc(meetingDocRef, {
        attending: increment(-1),
      });
  
      setIsAttending(false);
    } else {
      console.error('User is not authenticated');
    }
  };
  


  const [currentDate, setCurrentDate] = useState(new Date());
  const [month, setMonthDays] = useState([]);

  useEffect(() => {
    let currDate = new Date(currentDate.getFullYear(), currentDate.getMonth());
    let lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    let daysArray = [];
    let weekArray = Array(7).fill(null);

    for(let day = 1; day <= lastDay; day++) {
      currDate.setDate(day);
      let index = (currDate.getDay()+6) % 7;
      weekArray[index] = currDate.getDate();

      if(index === 6 || day === lastDay) {
        daysArray.push([...weekArray]);
        weekArray = Array(7).fill(null);
      }
    }
    setMonthDays(daysArray);
  }, [currentDate]);

  const nextMonth = () => {
    setCurrentDate(prev => new Date(prev.getFullYear(), prev.getMonth() + 1));
  };

  const previousMonth = () => {
    setCurrentDate(prev => new Date(prev.getFullYear(), prev.getMonth() - 1));
  };

  const currentMonth = () => {
    setCurrentDate(new Date());
  };

  const getBackground = (matchingEvents, account) => {
    for (const event of matchingEvents) {
      if (account.attendingMeetings && account.attendingMeetings.includes(event.id)) {
        return '#A0C0FF50';
      }
    }
    return '#f5f5f5';
  };
  


  // Mobile Event List
const MobileEventList = ({ events }) => {
  return (
    <>
    {events.length > 0 && (
      <>
    <Typography variant='h4'sx={{mb: ['25px', '25px', '25px', '30px']}}>Расписание конференций</Typography>
    <List>
      {events.map((event, index) => (
        <ListItem button key={index}     onClick={() => handleOpenDialog(event)}
        sx={{ 
          backgroundColor: '#f5f5f5', 
          p: '16px', 
          mb: '20px',
          borderRadius: 2.5, 
          minHeight: '160px',
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'space-between', 
          alignItems: 'flex-start',
          gap: '15px',
          '&:hover': { backgroundColor: '#ededed', cursor: 'pointer' },
        }}>
          <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
            <Typography variant="body" sx={{ fontSize: '12px', lineHeight: '16px' }}>Конференция</Typography>
            <Typography variant="body" sx={{ fontSize: '14px', lineHeight: '16px' }}>{(event.timestamp.toDate()).toLocaleString('ru-RU', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</Typography>
           </Box>
            <Typography variant="h6" sx={{ alignSelf: 'flex-start' }}>{event.eventName}</Typography>
    
    <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap', justifyContent: 'flex-end', mt: '0px'}}>
          {event.tags.map((tag, i) => (
            <Box
              key={i}
              sx={{
                fontSize: ['12px','12px','12px','12px'], lineHeight: ['24px','24px','24px','14px'],
                padding: '5px 10px',
                borderRadius: '12px',
                fontWeight: '600',
                color: '#fff',
                backgroundColor:
                  tag === 'trading'
                    ? '#548dff'
                    : tag === 'psychology'
                    ? '#0D160B'
                    : tag === 'crypto'
                    ? '#2C514C'
                    : '#2C514C',
              }}
            >
              {tag}
            </Box>
          ))}
        </Box>
        <Button
            fullWidth
            variant="outlined"
            onClick={() => handleOpenDialog(event.id)}
          >
      Подробнее
      </Button>
        </ListItem>
      ))}
    </List>
    </>
    )}

</>
  );
};

  return (
    <>
    
    {!isMobile && (
      <>
      <Typography variant='h4'sx={{mb: ['25px', '25px', '25px', '30px']}}>Календарь конференций</Typography>
    <Card
    sx={{
      position: 'relative',
      textAlign: 'left',
      backgroundColor: 'transparent', // semi-transparent background
      border: 'none',
      boxShadow: 'none',
    }}
>
      <Box sx={{ display: 'flex', gap: '5px', paddingLeft: 0.5, py: 1}}>
        <Button 
          sx={{ border: 1,}}
          onClick={previousMonth}
        >
          Назад
        </Button>
        <Button 
          sx={{ border: 1, backgroundColor: '#A0C0FF50',}}
          onClick={currentMonth}
        >
          {`${currentDate.toLocaleString('ru-RU', { month: 'long' }).charAt(0).toUpperCase() + currentDate.toLocaleString('ru-RU', { month: 'long' }).slice(1)}`}
        </Button>
        <Button 
          sx={{ border: 1}}
          onClick={nextMonth}
        >
          Вперед
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ backgroundColor: "transparent" }}>
        <Table sx={{ borderCollapse: 'separate', borderSpacing: '5px'}}>
          <TableHead>
            <TableRow>
              {['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'].map((day, index) => (
                <TableCell key={index} sx= {{
                  background: 'transparent',
                  color: '#2E2E2E',
                  padding: "5px",
                  border: "0.5px solid #505050", 
                  borderRadius: "5px",
                  maxWidth: 40,
                  minWidth: 40,
                  minHeight: 40,
                  maxHeight: 20,
                  textAlign: 'center',
                  fontWeight: 700}}>
                  {day}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
          {month.map((week, index) => (
  <TableRow key={index}>
    {week.map((dayNumber, i) => {
      const year = currentDate.getFullYear();
      const matchingEvents = events.filter(event => {
        const eventDate = event.timestamp.toDate(); 
        return eventDate.getDate() === dayNumber && 
               eventDate.getMonth() === currentDate.getMonth() && 
               eventDate.getFullYear() === year;
      });

        return dayNumber ? (
          
<TableCell
  key={i}
  sx={{
    padding: "10px",
    border: "0.5px solid #505050",
    borderRadius: "5px",
    maxWidth: 40,
    minWidth: 40,
    height: "80px",
    cursor: matchingEvents.length > 0 ? 'pointer' : 'default',
    background: getBackground(matchingEvents, account),
    
    '&:hover': {
      background: matchingEvents.length > 0 ? '#A0C0FF50' : '#f5f5f5'
    }
  }}
  onClick={() => {
    if (matchingEvents.length > 0) {
      handleOpenDialog(matchingEvents[0]);  // Opens dialog for the first matching event
    }
  }}
>
  <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
    alignItems: 'flex-end'
  }}>
                      {String(dayNumber) === currentDateNew ? (
                    <div style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      border: "1px solid #548dff", 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#A0C0FF' // Or any other color you like
                    }}>
    <Typography variant="body2">
      {dayNumber}
    </Typography>
                    </div>
                  ) : (
                    <Typography variant="body2">
                    {dayNumber}
                  </Typography>
                  )}
    
    {matchingEvents.map((event, index) => (
      <div key={index}>
        <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap', justifyContent: 'flex-end', mt: '5px'}}>
          {event.tags.map((tag, i) => (
            <Box
              key={i}
              sx={{
                fontSize: ['9px','9px','9px','12px'], lineHeight: ['24px','24px','24px','14px'],
                padding: '5px 10px',
                borderRadius: '12px',
                fontWeight: '600',
                color: '#fff',
                backgroundColor:
                  tag === 'trading'
                    ? '#548dff'
                    : tag === 'psychology'
                    ? '#0D160B'
                    : tag === 'crypto'
                    ? '#2C514C'
                    : '#2C514C',
              }}
            >
              {tag}
            </Box>
          ))}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', mt: '10px'}}>
        <Typography variant="body2" component="span" sx={{ fontWeight: 'bold', textAlign: 'end', fontSize: ['16px','16px','16px','15px'], lineHeight: ['24px','24px','24px','20px']}}>
          {event.eventName}
        </Typography>
        </Box>
      </div>
    ))}
  </Box>
</TableCell>

        ) : (
          <TableCell
            key={i}
            sx={{
              background: '#f9f9f9',
              color: '#ffffff',
              padding: "10px",
              border: "0.5px solid #505050", 
              borderRadius: "5px",
              maxWidth: 40,
              minWidth: 40,
              minHeight: 40,
              maxHeight: 40
            }}
          >
            {dayNumber}
          </TableCell>
        )
      })}
    </TableRow>
  ))}
</TableBody>

        </Table>
        </TableContainer>
    </Card>
    </>
    )}
{/* 
    {isMobile && (
       <MobileEventList events={events} />
    )} */}
    <Dialog 
  open={openDialog} 
  onClose={handleCloseDialog}

  maxWidth="lg"
  PaperProps={{ 
    sx: { 
      borderRadius: "10px",
      width: ['100%','60%','40%', '40%'], // this sets the maximum width to 70% of the viewport width
    },
  }}
  BackdropProps={{ style: { backgroundColor: '#2F2F2F80'} }}
>
  <DialogContent>
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '20px'}}>
      {/* Image on the left */}
      <Box sx={{  display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center',  borderRadius: '20px'}}>
        <img src={selectedEvent ? selectedEvent.image : ''} alt="стрим defaust"  style={{  borderRadius: '10px', boxShadow:  `5px 8px 7px -1px #00000060`,}} />
      </Box>
      
      {/* Content on the right */}
      <Box sx={{ }}>

      <Typography variant="body" sx={{mt: '40px', fontSize: ['26px','26px','26px','28px'], fontWeight: 'medium', lineHeight: ['34px','34px','34px','38px']}}>
          {selectedEvent ? selectedEvent.eventName : ''}
        </Typography>
        <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap', justifyContent: 'flex-start', mt: '5px'}}>
        {selectedEvent ? selectedEvent.tags.map((tag, i) => (
            <Box
              key={i}
              sx={{
                fontSize: ['12px','12px','12px','12px'], lineHeight: ['24px','24px','24px','14px'],
                padding: '5px 10px',
                borderRadius: '12px',
                fontWeight: '600',
                color: '#fff',
                backgroundColor:
                  tag === 'trading'
                    ? '#548dff'
                    : tag === 'psychology'
                    ? '#0D160B'
                    : tag === 'crypto'
                    ? '#2C514C'
                    : '#2C514C',
                  }}
                  >
                    {tag}
                  </Box>
                )) : null}
        </Box>
        <Typography  sx={{mt: '10px',fontSize: ['16px','16px','16px','18px'], fontWeight: '400', lineHeight: ['24px','24px','24px','28px']}}>
          {selectedEvent ? selectedEvent.description : ''}
        </Typography>
        
        {/* Date & Time at the bottom */}
        <Typography variant="body1" sx={{ marginTop: '20px', fontWeight: 'bold'}}>
          Когда: 
          <Box sx={{    
    color:'55FDFD',
    border: "0.5px solid #505050", 
    borderColor: '55FDFD10',
    boxShadow: '-1px -1px 2px 0px #55FDFD20',
    borderRadius: "5px",
    padding: "5px",
    margin: "0 5px",
    display: "inline",}}>
{selectedEvent ? (selectedEvent.timestamp.toDate()).toLocaleString('ru-RU', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }) : ''}
    </Box>
        </Typography>
        {attending ? selectedEvent.attending? (
                  <Typography variant="body1" sx={{ marginTop: '20px', fontWeight: 'bold'}}>
                  Уже записались:  {selectedEvent.attending}
                </Typography>
        ) : null : null}

      </Box>
    </Box>
  </DialogContent>
  
  {/* Dialog actions */}
  <DialogActions>
  {selectedEvent && (
    <>
    <Box sx={{display: 'inline-flex', flexDirection: 'row', gap: '10px', width: '100%', px: '10px', pb: '10px'}}>
  <Button  onClick={() => handleNavigateClick()} variant="text" color="primary" sx={{ fontWeight: 400, width: ['100%','100%','100%', '100%'],color: '#2E2E2E', background: 'transparent' ,fontSize: ['14px','14px','14px','15px'],  lineHeight: ['16px','16px','16px','20px'], borderRadius: 3, py: '15px', textTransform: 'none', px: '20px',
                     '&:hover': {
                      color: 'white', background: '#2E2E2E', 
                     }}}>
                    Все конференции
                </Button>
                {account.membership === 'active' ? (
                  selectedEvent.recordUrl ? (
                    <Button  onClick={() => handleWatchRecording()}
                    variant="outlined" color="primary" sx={{ width: ['100%','100%','100%', '100%'], boxShadow: '3px 3px 1px 1px rgba(24, 24, 24, 0.25)', color: '#000', background: '#A0C0FF' ,fontSize: ['14px','14px','14px','15px'], fontWeight: '600', lineHeight: ['16px','16px','16px','20px'], px: '20px', borderRadius: 3, py: '10px', textTransform: 'none',
                    '&:hover': {
                     color: '#000', background: '#A0C0FF', 
                    }}}
                    >
                      Смотреть запись
                    </Button>
                  ) : (selectedEvent.timestamp.toDate() < new Date() ? (
                    <Button
                      disabled={new Date() > new Date(selectedEvent.timestamp.toDate().getTime() + 3 * 60 * 60 * 1000)}
                      onClick={() => handleConnectMeeting(selectedEvent.url)}
                      variant="outlined" color="primary" sx={{ width: ['100%','100%','100%', '100%'], boxShadow: '3px 3px 1px 1px rgba(24, 24, 24, 0.25)', color: '#000', background: '#A0C0FF' ,fontSize: ['14px','14px','14px','15px'], fontWeight: '600', lineHeight: ['16px','16px','16px','20px'], px: '20px', borderRadius: 3, py: '10px', textTransform: 'none',
                      '&:hover': {
                       color: '#000', background: '#A0C0FF', 
                      }}}
                      >
                      Присоединиться к конференции
                    </Button> ) : 
                    (
                      <Button
                        disabled={new Date() > new Date(selectedEvent.timestamp.toDate().getTime() + 3 * 60 * 60 * 1000)}
                        onClick={() => (isAttending ? cancelAttending(selectedEvent.id) : attendMeeting(selectedEvent.id))}
                        variant="outlined" color="primary" sx={{ width: ['100%','100%','100%', '100%'], boxShadow: '3px 3px 1px 1px rgba(24, 24, 24, 0.25)', color: '#000', background: '#A0C0FF' ,fontSize: ['14px','14px','14px','15px'], fontWeight: '600', lineHeight: ['16px','16px','16px','20px'], px: '20px', borderRadius: 3, py: '10px', textTransform: 'none',
                        '&:hover': {
                         color: '#000', background: '#A0C0FF', 
                        }}}
                        >
                        {isAttending ? 'Отписаться' : 'Записаться на конференцию'}
                      </Button>
                    ))
  ) : (
    <Button
      onClick={handleSubscribeNavigate}
      variant="outlined" color="primary" sx={{ width: ['100%','100%','100%', '100%'], boxShadow: '3px 3px 1px 1px rgba(24, 24, 24, 0.25)', color: '#000', background: '#A0C0FF' ,fontSize: ['14px','14px','14px','15px'], fontWeight: '600', lineHeight: ['16px','16px','16px','20px'], px: '20px', borderRadius: 3, py: '10px', textTransform: 'none',
      '&:hover': {
       color: '#000', background: '#A0C0FF', 
      }}}
      >
      Получить доступ за 50$/мес.
    </Button>
  )}
              </Box>
              </>
  )}
  </DialogActions>
</Dialog>


    </>
  );
}