import React, { useState, useEffect, useContext, createContext, useRef } from 'react';
import UserContext from '../../../UserContext';
import {db} from '../../../firebase';
import { setDoc, collection, getDocs, getDoc, doc, updateDoc, query, where, increment, arrayUnion } from 'firebase/firestore';
import BackIcon from '@mui/icons-material/KeyboardBackspace';
import { alpha, Typography, Box, Divider, RadioGroup, FormControlLabel, Radio, Paper, Button, Container, LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer, } from '@mui/material';
import useUser from '../../../hooks/userHook';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useQuizResult} from '../../../hooks/useQuizResult';

const QuizContext = createContext();

function useQuiz() {
    return useContext(QuizContext);
}

function QuizProvider({ children }) {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userAnswers, setUserAnswers] = useState([]);
    const [quizStarted, setQuizStarted] = useState(false);
    const [timeLeft, setTimeLeft] = useState(null);
    const [openDialogNew, setOpenDialogNew] = useState(false);
    const value = {
        currentQuestionIndex,
        setCurrentQuestionIndex,
        userAnswers,
        setUserAnswers,
        quizStarted,
        setQuizStarted,
        timeLeft,
        setTimeLeft,
        openDialogNew,
        setOpenDialogNew
    };

    return <QuizContext.Provider value={value}>{children}</QuizContext.Provider>;
}

function QuizSection({ courseId, quizId }) {
    const [showDetails, setShowDetails] = useState(false);
    const [currentAttempt, setCurrentAttempt] = useState(null);
  
    const showAttemptDetails = (attempt) => {
      setCurrentAttempt(attempt);
      setShowDetails(true);
    };
    const account = useContext(UserContext);
    const [pastAttempts, setPastAttempts] = useState([]);
    const navigate = useNavigate();
    const user = useUser();
    const { loadingQuizResult, errorQuizResult, quizResult } = useQuizResult(courseId,quizId);
    const [isAnswerSelected, setIsAnswerSelected] = useState(false);
    const [quizData, setQuizData] = useState(null);
    const [loading, setLoading] = useState(true);
    const language = 'ru';
    const [error, setError] = useState(null);
    const { 
        currentQuestionIndex, 
        setCurrentQuestionIndex, 
        userAnswers, 
        setUserAnswers,
        quizStarted,
        setQuizStarted,
        timeLeft,
        setTimeLeft,
        setOpenDialogNew,
        openDialogNew
    } = useQuiz();

    async function saveQuizResult(userId, courseId, quizId, mark, userAnswers) {
        const userRef = doc(db, "users", userId);
        const userSnapshot = await getDoc(userRef);
    
        if (!userSnapshot.exists()) {
            console.error('User does not exist!');
            return;
        }
    
        const userData = userSnapshot.data();
    
        // Initialize if not present
        userData.quizResults ??= {};
        userData.quizResults[courseId] ??= {};
        userData.quizResults[courseId][quizId] ??= [];
    
        // Construct the quiz result
        const quizResult = {
            courseId,
            quizId,
            mark,
            dateTaken: new Date(),
            userAnswers
        };
    
        // Append this quiz attempt to the list of quiz attempts
        userData.quizResults[courseId][quizId].push(quizResult);
    
        // Save back to Firestore
        await setDoc(userRef, { quizResults: userData.quizResults }, { merge: true });
    }


    const timerRef = useRef(null);

    function navigateToNextItem(course, currentItemId, currentType, navigateFunction) {
        // Find the index of the current item
        ////console.log('currentItemId:', currentItemId, 'currentType:', currentType);
        const currentIndex = course.structure.findIndex((item) => {
          return item.hasOwnProperty(currentType) && item[currentType] === currentItemId;
      });
      
        ////console.log(currentIndex, course.structure.length);
        // If the current item is not found or it's the last item, do nothing or handle it differently
        if (currentIndex === -1 || currentIndex === course.structure.length - 1) {
            ////console.log("Item not found or it's the last item");
            return;
        }
        
        // Get the next item's type and id
        const nextItem = course.structure[currentIndex + 1];
        const nextItemType = Object.keys(nextItem)[0];
        const nextItemId = nextItem[nextItemType];
    
        // Construct the path for the next item
        const itemPath = `/course/take/${course.id}/${nextItemType}/${nextItemId}`;
    
        // Navigate to the next item
        navigateFunction(itemPath);
    }
      async function fetchCourseAndNavigate(courseId, currentItemId, navigateFunction) {
        try {
            // Fetch the course document based on the provided courseId
            const courseCollection = collection(db, 'courses');
            const courseSnapshot = await getDocs(query(courseCollection, where('id', '==', courseId)));
    
            // If no course matches the provided courseId, handle the error
            if (courseSnapshot.empty) {
                console.error("No matching course found");
                return;
            }
    
            // Extract the course data from the first (and likely only) matching document
            const courseData = courseSnapshot.docs[0].data();
    
            // Navigate to the next item using the previously provided function
            navigateToNextItem(courseData, currentItemId, 'quiz',  navigateFunction);
    
        } catch (error) {
            console.error("Error fetching course:", error);
        }
    }

    useEffect(() => {
        async function fetchQuiz() {
            try {
                // Fetch all course documents
                const coursesCollectionRef = collection(db, 'courses');
                const coursesSnapshot = await getDocs(coursesCollectionRef);
                let specificCourseRef = null;
    
                // Loop through the courses to find the one matching the courseId
                coursesSnapshot.forEach(courseDoc => {
                    if (courseDoc.data().id === courseId) {
                        specificCourseRef = courseDoc.ref;
                    }
                });
    
                
                if (!specificCourseRef) {
                    throw new Error('Course not found');
                }
    
                // Fetch quizzes within the specific course
                const quizzesCollectionRef = collection(specificCourseRef, 'quiz');
                const quizzesSnapshot = await getDocs(quizzesCollectionRef);
                let quizRef = null;




    
                // Loop through the quizzes to find the one matching the quizId
                quizzesSnapshot.forEach(quizDoc => {
                    if (quizDoc.data().id === quizId) {
                        quizRef = quizDoc.ref;
                    }
                });
    
                if (!quizRef) {
                    throw new Error('Quiz not found');
                }
    
                const quizDocSnap = await getDoc(quizRef);
                // Fetch questions for the identified quiz
                const questionsCollectionRef = collection(quizRef, 'questions');
                const questionsSnapshot = await getDocs(questionsCollectionRef);
                let questions = [];
    
                questionsSnapshot.forEach(questionDoc => {
                    questions.push(questionDoc.data());
                });
                ////console.log("Quiz document data:", quizDocSnap.data());
                setQuizData({ quiz: quizRef, questions: questions, quizData: quizDocSnap.data()});
               
                
            } catch (e) {
                setError(e.message);
            } finally {
                setLoading(false);
            }
        }
    
        async function fetchResults() {
            if (!user) {
                return; // exit if user is not defined
            }
            const userId = user.uid;  
            try {
                if (userId) {
                setLoading(true);
            const userDocRef = doc(db, 'users', userId);
            const userDocSnap = await getDoc(userDocRef);
            ////console.log('found user', user.uid);
            
            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                const quizResults = userData?.quizResults || {};
                const attemptsForThisQuiz = quizResults[courseId]?.[quizId] || [];
                setPastAttempts(attemptsForThisQuiz); // Update state with past attempts
            }}
            } catch (e) {
                setError(e.message);
            } finally {
                setLoading(false);
            }
        }
        fetchQuiz();
        fetchResults();
    }, [courseId, quizId, user]);

    


    const startQuiz = () => {
        setQuizStarted(true);
        ////console.log("Quiz started", quizData?.quizData);
        if (quizData?.quizData?.duration) {
            setTimeLeft(quizData.quizData.duration * 60); // assuming duration is in minutes

            if (timerRef.current) clearInterval(timerRef.current); // Clear existing timer if any

            timerRef.current = setInterval(() => {
                setTimeLeft(prevTime => {
                    if (prevTime <= 1) {
                        clearInterval(timerRef.current);
                        handleSubmitQuiz(); // marking the quiz as complete
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }
    };

    function RetakeQuizDialog({ open, onClose, onRetake }) {
        return (
            <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"  
            sx={{
                '.MuiPaper-root': {
                width: '30%',
                p: 2,
                border: "0.5px solid rgba(209, 213, 219, 0.3)",
                borderRadius: 5,
                color: '#fff',
            }}}>
            <DialogTitle sx={{color: '#000'}} id="alert-dialog-title">{"Пересдать тест?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{color: 'primary'}} id="alert-dialog-description">
                        Оценка за тест ниже допустимой. Перепройти тест?
                    </DialogContentText>
                </DialogContent>
            <DialogActions>
            <Button 
    variant={'contained'}  
    color="primary" 
    autoFocus 
    onClick={onRetake}
>
    Попрбовать снова
            </Button>
                </DialogActions>
          </Dialog>
        );
      }
      
    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, []);

    const handleChoiceSelect = (choiceIndex) => {
        let updatedAnswers = [...userAnswers];
        updatedAnswers[currentQuestionIndex] = choiceIndex;
        setUserAnswers(updatedAnswers);
        setIsAnswerSelected(true);
    };
    
    const goToNextQuestion = () => {
        setCurrentQuestionIndex(prevIndex => prevIndex + 1);
        setIsAnswerSelected(false);
    }
    
    const goToPreviousQuestion = () => {
        setCurrentQuestionIndex(prevIndex => prevIndex - 1);
        setIsAnswerSelected(false);
    };
    
    const handleSubmitQuiz = async () => {
        // 1. Compare Answers and Calculate Mark
        let correctAnswersCount = 0;
        const gradedUserAnswers = quizData.questions.map((question, index) => {
            const isCorrect = question.correctChoice === userAnswers[index];
            if (isCorrect) correctAnswersCount++;
            return {
                answerIndex: userAnswers[index],
                isCorrect
            };
        });
    
        const totalQuestions = quizData.questions.length;
        const mark = ((correctAnswersCount / totalQuestions) * 100).toFixed(0);
    
        // 2. Save the quiz result for this attempt
        await saveQuizResult(user.uid, courseId, quizId, mark, gradedUserAnswers);
    
        // 3. Update User's Points and Completion Status if Mark > 80
        const completedQuizzes = account.completed?.courses?.[courseId]?.quiz || [];

        // If this quiz hasn't been completed with a mark > 80 before
        if (!completedQuizzes.includes(quizId)) {
            if (mark > 80) {
                const userRef = doc(db, "users", user.uid);
                await updateDoc(userRef, {
                    points: increment(5),
                    [`completed.courses.${courseId}.quiz`]: arrayUnion(quizId)
                });
                fetchCourseAndNavigate(courseId, quizId, navigate);
            } else {
                setOpenDialogNew(true);
            }
        }
    };
    

    function QuizSkeleton() {
        return (
            <Container maxWidth="lg">
                <Paper elevation={1} style={{ padding: '20px', marginBottom: '20px' }} sx={{backgroundColor: '#f5f7f9', border: '1px solid var(--stroke-grey, #E5E7E9)', borderRadius: 5,}}>
                    
                    <Skeleton variant="text" height={40} width="60%" style={{ marginBottom: '20px' }} />
                    <Skeleton variant="text" height={30} width="40%" style={{ marginBottom: '20px' }} />
                    
                    <Skeleton variant="rectangular" height={6} width="100%" style={{ borderRadius: 4, marginBottom: '25px' }} />
    
                    <Skeleton variant="text" height={20} width="30%" align="right" style={{ marginBottom: '20px' }} />
    
                    <CurrentQuestionSkeleton />
    
                    <Box display="flex" justifyContent="space-between" marginTop={3}>
                        <Skeleton variant="rectangular" height={36} width={80} />
                        <Skeleton variant="rectangular" height={36} width={80} />
                    </Box>
                </Paper>
            </Container>
        );
    }
    
    function CurrentQuestionSkeleton() {
        return (
            <Box my={4}>
                <Skeleton variant="text" height={30} width="80%" gutterBottom />
                <Skeleton variant="rectangular" height={1} width="100%" style={{ marginBottom: '15px' }} />
                <Skeleton variant="rectangular" height={30} width="100%" style={{ marginBottom: '10px' }} />
                <Skeleton variant="rectangular" height={30} width="100%" style={{ marginBottom: '10px' }} />
                <Skeleton variant="rectangular" height={30} width="100%" style={{ marginBottom: '10px' }} />
                <Skeleton variant="rectangular" height={30} width="100%" style={{ marginBottom: '10px' }} />
            </Box>
        );
    }

    function CurrentQuestion({ onChoiceSelect, onNext, onPrevious, onSubmit, quizData, language }) {
        const { currentQuestionIndex, userAnswers } = useQuiz();
        const currentQuestion = quizData.questions[currentQuestionIndex];
    
        return (
            <Box my={4}>
                {currentQuestion?.image && (
                    <img src={currentQuestion.image} alt="Quiz Image" loading="lazy" style={{ width: '100%', marginBottom: '15px' }} />
                )}
                <Typography variant="h5" gutterBottom>
                    {currentQuestion?.text}
                </Typography>
                <Divider style={{ marginBottom: '15px' }} />
    
    
                    <RadioGroup 
                        value={userAnswers[currentQuestionIndex]?.toString() || ''} 
                        onChange={(e) => onChoiceSelect(Number(e.target.value))}
                    >
                        {currentQuestion?.choices?.map((choice, idx) => (
                            <FormControlLabel 
                                key={idx} 
                                value={idx.toString()} 
                                control={<Radio />} 
                                label={choice?.[language]} 
                            />
                        ))}
                    </RadioGroup>
    
            </Box>
        );
    }
    ////console.log('quizData',quizData);
    ////console.log('pastAttempts', pastAttempts);
    ////console.log('quizResult', quizResult);

    const CustomTableCell = (props) => {
        return (
          <TableCell sx={{
            background: 'transparent',
            color: '#000',
            padding: "15px",
            textAlign: 'left',
            fontWeight: 700,
            borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
          }} {...props} />
        );
      }
    const CustomTableBodyCell = (props) => {
        return (
          <TableCell sx={{
            background: 'transparent',
            color: '#000',
            padding: "15px",
            textAlign: 'left',
            fontWeight: 400,
          }} {...props} />
        );
      }

      
    if (loading) {
        return (
            <Container maxWidth="lg">
               <QuizSkeleton />
            </Container>
        );
    }
    if (!quizStarted) {
        return (
            <Container maxWidth="lg">
                <Paper elevation={1} style={{ padding: '20px', marginBottom: '20px' }} sx={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 2, height: '20rem', backgroundColor: '#f5f7f9', border: '1px solid var(--stroke-grey, #E5E7E9)', borderRadius: 5,}}>
                <Typography variant="h3">{ 
                ((quizResult?.mark < 100 && quizResult.mark)? `Пересдать тест: ${quizData?.quizData?.name?.[language] || ''}` :
                `${quizData?.quizData?.name?.[language] || ''}`)}</Typography>
                <Typography variant="h6" sx={{fontWeight: 400}}>{quizData?.quizData?.description?.ru}</Typography>
                <Typography variant="h6" sx={{fontWeight: 400}}>Продолжительность: {quizData?.quizData?.duration} минут</Typography>
                <Button variant={'contained'} sx={{backgroundColor: '#3366FF'}} disabled={(quizResult?.mark > 100 && quizResult.mark) || quizData?.quizData?.name === undefined} onClick={startQuiz}>{(quizResult?.mark < 100 && quizResult.mark)? `Пересдать` : 'Начать'} </Button>
                </Paper>
                
                
                {showDetails ? (
        <>  
        <Box sx={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
            <Button 
    size="medium" 
    color="primary" 
    sx={{
        my: 2,
        textTransform: 'none', 
        "&:hover .increaseIcon": {
            width: 30,
            transition: 'width 0.3s'
        }
    }}
    onClick={() => setShowDetails(false)}
>
    <BackIcon 
        className="increaseIcon" 
        color="primary" 
        sx={{ 
            marginRight: 1,
            transition: 'width 0.3s'
        }} 
    />
Вернуться ко всем попыткам
</Button>
</Box>
          <TableContainer component={Paper} style={{ padding: '20px', marginBottom: '20px' }} sx={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 2, backgroundColor: '#f5f7f9', border: '1px solid var(--stroke-grey, #E5E7E9)', borderRadius: 5,}}>
            <Table>
              <TableHead>
                <TableRow>
                  <CustomTableCell>Номер</CustomTableCell>
                  <CustomTableCell>Вопросы</CustomTableCell>
                  <CustomTableCell>Данный ответ</CustomTableCell>
                  <CustomTableCell>Правильный ответ</CustomTableCell>
                  <CustomTableCell>Результат</CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
  {/* Render detailed view for `currentAttempt` */}
  {currentAttempt.userAnswers.map((answer, index) => {
    // Find the corresponding question using the index
    const questionData = quizData?.questions[index];

    // Extract the user's answer and correct answer from the question data
    const userAnswerText = questionData?.choices[answer.answerIndex]?.ru;
    const correctAnswerText = questionData?.choices[questionData?.correctChoice]?.ru;

    return (
      <TableRow key={index}>
        <CustomTableBodyCell>{index + 1}</CustomTableBodyCell>

        {/* Display the question text */}
        <CustomTableBodyCell>{questionData?.text}</CustomTableBodyCell>

        {/* Display the user's answer */}
        <CustomTableBodyCell>{userAnswerText}</CustomTableBodyCell>

        {/* Display the correct answer */}
        <CustomTableBodyCell>{correctAnswerText}</CustomTableBodyCell>

        {/* Display the result */}
        <CustomTableBodyCell>
        <Box sx={{
                                        color: answer.isCorrect ? '#204631' : '#F52525',
                                        background: answer.isCorrect ? '#6BEBA470' : '#F5252570',
                                        border: answer.isCorrect ? '1px solid #6BEBA4' : '1px solid #F52525',
                                        borderRadius: "50px",
                                        py: 0,
                                        px: 2,
                                        display: 'inline-block', // To make the box size according to content
                                    }}>
                                    {answer.isCorrect ? "Правильно" : "Неправильно"}
                                    </Box>
            </CustomTableBodyCell>
      </TableRow>
    );
  })}
</TableBody>

            </Table>
          </TableContainer>
          </>
      ) : (<>
                {pastAttempts.length > 0 && (
                    <Paper elevation={1} style={{ padding: '20px', marginBottom: '20px' }} sx={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 2,  backgroundColor: '#f5f7f9', border: '1px solid var(--stroke-grey, #E5E7E9)', borderRadius: 5,}}>
            <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <CustomTableCell>Дата</CustomTableCell>
                        <CustomTableCell>Вопрос</CustomTableCell>
                        <CustomTableCell>Правильный ответ</CustomTableCell>
                        <CustomTableCell>Неправильный ответ</CustomTableCell>
                        <CustomTableCell>Оценка</CustomTableCell>
                        <CustomTableCell>Результат</CustomTableCell>
                        <CustomTableCell>Детали</CustomTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pastAttempts.map((attempt, index) => (
                        <TableRow sx={{
                            py: 4,
                            "&:hover": {
                              backgroundColor: alpha("#FFF", 0.15),
                            },
                            "&:last-child td, &:last-child th": {
                              borderBottom: 0,
                            },
                            borderBottom: '1px solid rgba(209, 213, 219, 0.3)', // Set color of border
                            }} key={index}>
                            <CustomTableBodyCell sx={{maxWidth: 120}}>
                            {new Intl.DateTimeFormat('ru-RU', { weekday: 'long', day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric' }).format(attempt.dateTaken.toDate())}
                            </CustomTableBodyCell>
                            <CustomTableBodyCell>{attempt.userAnswers.length}</CustomTableBodyCell>
                            {(() => {
                                const correctAnswers = attempt.userAnswers.filter(answer => answer.isCorrect).length;
                                const wrongAnswers = attempt.userAnswers.length - correctAnswers;
                                
                                return (
                                    <>
                                        <CustomTableBodyCell>{correctAnswers}</CustomTableBodyCell>
                                        <CustomTableBodyCell>{wrongAnswers}</CustomTableBodyCell>
                                    </>
                                );
                            })()}
                            <CustomTableBodyCell>{attempt.mark}</CustomTableBodyCell>
                            <CustomTableBodyCell>
                            <Box sx={{
                                        color: attempt.mark > 80? '#204631' : '#F52525',
                                        background: attempt.mark > 80? '#6BEBA470' : '#F5252570',
                                        border: attempt.mark > 80? '1px solid #6BEBA4' : '1px solid #F52525',
                                        borderRadius: "50px",
                                        py: 0,
                                        px: 2,
                                        display: 'inline-block', // To make the box size according to content
                                    }}>
                                    {(attempt.mark > 80? 'Зачет' : 'Не зачет')}
                                    </Box>
                                    </CustomTableBodyCell>
                            <CustomTableBodyCell>
                                <Button variant="contained" onClick={() => showAttemptDetails(attempt)}>Детали</Button>
                            </CustomTableBodyCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            </TableContainer>
            </Paper>
            
        )}
        </>)}
            </Container>
        )
        };

    return (
        
        <Container maxWidth="lg">
            <Paper elevation={1} style={{ padding: '20px', marginBottom: '20px' }} sx={{backgroundColor: '#f5f7f9', border: '1px solid var(--stroke-grey, #E5E7E9)', borderRadius: 5,}}>
            <RetakeQuizDialog 
            open={openDialogNew}
            onClose={() => setOpenDialogNew(false)} 
            onRetake={() => {
                
                navigate(`/course/take/${courseId}/quiz/${quizId}`);
                window.location.reload();
            }}
        />
                <Typography variant="h3" sx={{ marginBottom: 2 }}>
                    {quizData?.quizData?.name?.[language] || "Quiz"}
                </Typography>

                <Typography variant="h6"  sx={{ marginBottom: 2 }}>
                    Вопрос: {currentQuestionIndex + 1}/{quizData?.questions.length}
                </Typography>
    
                    <LinearProgress 
        variant="determinate" 
        value={(currentQuestionIndex + 1) / quizData?.questions.length * 100}
        sx={{ 
            borderRadius: 4, 
            height: 6,
            bgcolor: '#e0e0e0',
            '& .MuiLinearProgress-bar': {
                backgroundColor: '#000',
            }
        }}
    />
    
                <Typography variant="subtitle1" align="right" style={{ marginBottom: '20px', marginTop: '5px'}}>
                    Осталось время: {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? '0' : ''}{timeLeft % 60}
                </Typography>

                <CurrentQuestion 
                    onChoiceSelect={handleChoiceSelect}
                    onNext={goToNextQuestion}
                    onPrevious={goToPreviousQuestion}
                    onSubmit={handleSubmitQuiz}
                    quizData={quizData}  
                    language={language}  
                />
    
                <Box display="flex" justifyContent="space-between" marginTop={3}>
                    <Button
                        variant="contained"
                        disabled={currentQuestionIndex === 0}
                        onClick={goToPreviousQuestion}
                    >
                        Назад
                    </Button>
                    {currentQuestionIndex === (quizData?.questions.length - 1 ?? 0) ? (
                        <Button variant="contained" color="primary" onClick={handleSubmitQuiz}  sx={{backgroundColor: "#3366FF"}} disabled={!isAnswerSelected}>
                            Завершить
                        </Button>
                    ) : (
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={goToNextQuestion}  
                            sx={{backgroundColor: "#3366FF"}}
                            disabled={!isAnswerSelected}
                        >
                            Дальше
                        </Button>

                    )}
                </Box>

            </Paper>
        </Container>
    );
}



export default function WrappedQuizSection(props) {
    return (
        <QuizProvider>
            <QuizSection {...props} />
        </QuizProvider>
    );
}
