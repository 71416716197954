import React, { useState, useEffect, useCallback } from 'react';
import { getDocs, getDoc, query, where, collection, doc, updateDoc, arrayUnion, addDoc, setDoc, arrayRemove, deleteDoc } from 'firebase/firestore';
import { Skeleton, Popover, Typography, Box, TextField, alpha, Table, TableBody, TableCell, TableHead, TableRow, Button, Dialog, DialogContent, TableContainer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import QuizIcon from '@mui/icons-material/Quiz';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
const CourseStructure = ({ structureItems, loading}) => {
    const navigate = useNavigate();
  const [error, setError] = useState(null);

  if (loading) {
    return (<>
            <Skeleton variant="rectangular" width="100%" height={150} sx={{
                                border: "1px solid rgba(209, 213, 219, 0.3)", // border
                                borderRadius: 5,
                                padding: 2,
                                mt: 0,
            }}/>
            <Skeleton variant="rectangular" width="100%" height={150} sx={{
                                border: "1px solid rgba(209, 213, 219, 0.3)", // border
                                borderRadius: 5,
                                padding: 2,
                                mt: 4,
            }}/>
            <Skeleton variant="rectangular" width="100%" height={150} sx={{
                                border: "1px solid rgba(209, 213, 219, 0.3)", // border
                                borderRadius: 5,
                                padding: 2,
                                mt: 4,
            }}/>
            <Skeleton variant="rectangular" width="100%" height={150} sx={{
                                border: "1px solid rgba(209, 213, 219, 0.3)", // border
                                borderRadius: 5,
                                padding: 2,
                                mt: 4,
            }}/>
            <Skeleton variant="rectangular" width="100%" height={150} sx={{
                                border: "1px solid rgba(209, 213, 219, 0.3)", // border
                                borderRadius: 5,
                                padding: 2,
                                mt: 4,
            }}/>
            <Skeleton variant="rectangular" width="100%" height={150} sx={{
                                border: "1px solid rgba(209, 213, 219, 0.3)", // border
                                borderRadius: 5,
                                padding: 2,
                                mt: 4,
            }}/>
            <Skeleton variant="rectangular" width="100%" height={150} sx={{
                                border: "1px solid rgba(209, 213, 219, 0.3)", // border
                                borderRadius: 5,
                                padding: 2,
                                mt: 4,
            }}/>
            </>
    );
    }
  return (
    <div>
      {error && <p>{error}</p>}
      {!loading && (
        <>
        <TableContainer>
        <Table >

          <TableBody>
            {structureItems.map((item, index) => (
               <>
              <TableRow sx={{
                border: "1px solid rgba(209, 213, 219, 0.3)",
                  "&:last-child td, &:last-child th": {
                    borderBottom: 'none',
                    borderTop: 'none',
                  },
                  borderBottom: '0px solid rgba(209, 213, 219, 0.3)', // Set color of border
                }}
                key={index}>
                            

                <TableCell sx={{
                  background: 'transparent',
                  borderRadius: '20px 0px 0px 20px',
                  color: '#000',
                  borderBottom: '0px solid rgba(209, 213, 219, 0.3)', // Set color of border
                  backgroundColor: 
                  item?.type==='lesson'? "#E1E2F640" : 
                  item?.type==='quiz'?"#F8EFE240" : 
                  item?.type==='exam'? "#EFF7E240" :  "#F8EFE240", 

              }}>
                            <Box sx={{display: 'flex', flexDirection: 'column',justifyContent: 'end', alignItems: 'end', gap: 1.5, 
                backgroundColor: 'transparent'}}>
                <Typography variant="span" fontWeight={700} fontSize={'1.4rem'} textAlign={'center'}>
                {index+1}
                </Typography>
                </Box>
                </TableCell>
                <TableCell sx={{
                  backgroundColor: 
                  item?.type==='lesson'? "#E1E2F640" : 
                  item?.type==='quiz'?"#F8EFE240" : 
                  item?.type==='exam'? "#EFF7E240" :  "#F8EFE240", 
                  color: '#000',
                  py: 5,
                  borderBottom: '0px solid rgba(209, 213, 219, 0.3)', // Set color of border
                  textAlign: 'start',
              }}>
            <Box sx={{display: 'flex', flexDirection: 'column',justifyContent: 'start', alignItems: 'center', gap: 1.5, 
                backgroundColor: 
                item?.type==='lesson'? "#E1E2F6" : 
                item?.type==='quiz'?"#F8EFE2" : 
                item?.type==='exam'? "#EFF7E2" :  "#F8EFE2", 
                borderRadius: 3, py: 1.5, px: 1}}>
                {(item?.type==='lesson')? <OndemandVideoIcon sx={{fontSize: '1.7rem'}} /> : 
                (item?.type==='quiz')? <QuizIcon sx={{fontSize: '1.7rem'}}/> : 
                (item?.type==='exam')? <WorkspacePremiumIcon sx={{fontSize: '1.7rem'}}/> :  <OndemandVideoIcon sx={{fontSize: '1.7rem'}}/>
                }
                </Box>
                </TableCell>
                <TableCell sx={{ 
                  borderRadius: '0px 20px 20px 0px',
                  backgroundColor: 
                  item?.type==='lesson'? "#E1E2F640" : 
                  item?.type==='quiz'?"#F8EFE240" : 
                  item?.type==='exam'? "#EFF7E240" :  "#F8EFE240", 
                  color: '#000',
                  padding: "10px",
                  borderBottom: '0px solid rgba(209, 213, 219, 0.3)', // Set color of border
                  textAlign: 'left',
              }}>
                <Typography variant="h5" fontSize={'1.2rem'}>
                {item.data?.name?.ru}
                </Typography>
                <Typography variant="body" color={'gray'} fontSize={'1rem'} fontStyle={'italic'}>
                {item.data?.description?.ru}
                {item.data?.shortDescription?.ru}
                </Typography>
                </TableCell>


              </TableRow>
                  <TableRow sx={{ height: '10px', backgroundColor: 'transparent', borderRadius: '20px 0px 0px 20px',  borderBottom: 0 }}>
                  <TableCell colSpan={5} sx={{ borderRadius: '20px 0px 0px 20px', border: '0px solid rgba(209, 213, 219, 0.3)',}}/>
                </TableRow>
                 </>
            ))}
          </TableBody>
        </Table>
        </TableContainer>
                  </>
      )}
    </div>
  );
};

export default CourseStructure;
