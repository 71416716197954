import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { alpha } from '@mui/material';
const PrivacyPolicy = () => {
  return (
    <>
        <Helmet>
            <html lang="ru" /> 
            <title>Политика приватности | DEFAUST</title>
            <meta name="description" content="Политика приватности DEFAUST Analytics." />
            <script type="application/ld+json">
              {`
                {
                  "@context": "http://schema.org",
                  "@type": "WebPage",
                  "name": "Политика приватности",
                  "description": "Политика приватности DEFAUST Analytics."
                }
              `}
            </script>
        </Helmet>

        <Container maxWidth="xl">
        <Typography variant="h3" sx={{ mt: -12, mb: 5 }}>
        Политика приватности
  </Typography>
      <Box sx={{
            px: 5,
            py: 2,
              backgroundColor: alpha("#111219", 0.225), // semi-transparent background
              backdropFilter: "blur(10px)", // glassmorphism effect
              border: "0.5px solid rgba(209, 213, 219, 0.3)", // border
              boxShadow: "0px 2px 1px -1px #ffffff10", //shadow
              borderRadius: '15px', 
      }}>
          <Typography variant="h6" gutterBottom>
            Introduction
          </Typography>
          <Typography paragraph>
          <p>This privacy notice sets forth the privacy practices for OneStake &Ouml;U.</p>

<p>OneStake &Ouml;U (&ldquo;us&rdquo;, &ldquo;we&rdquo;, or &ldquo;our&rdquo;) operates the app.defaust.com.</p>

<p>This page informs you of our policies concerning the collection, use, and disclosure of personal data when you use our Service and the choices you have related to that data.</p>

<p>We utilize your data to offer and enhance the Service. By utilizing the Service, you consent to the collection and use of information in accordance with this policy.</p>

<p>This privacy notice applies exclusively to information gathered by this website. It will inform you of the following:</p>

<p>What personally identifiable information is collected from you through the website, how it is utilized and with whom it may be shared. What options are accessible to you regarding the use of your data. The security procedures in place to safeguard against the misuse of your information. How you can rectify any inaccuracies in the information.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Information Collection, Use, and Sharing
          </Typography>
          <Typography paragraph>
          <p>We are the sole proprietors of the information collected on this site. We only have access to/collect information that you voluntarily provide us when using the site. We will not sell or rent this information to anyone.</p>

<p>We use your information to deliver the services of the site. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request. Specifically:</p>

<p>If you openly share a trade publicly, certain information about your trade will be made available on the site (you can see examples by looking at other shared trades on the site). If you do not wish this information to be public, please do not share trades.</p>

<p>Unless you request us not to, we may contact you via email in the future to inform you about specials, new products or services, or changes to this privacy policy.</p>

<p>We collect statistical information on web traffic using a third-party web analytics service; this may use a cookie on your computer, but no user-identifiable information is ever shared with this third party other than &quot;typical&quot; web analytics such as IP address, browser, etc.</p>

<p>Under no circumstances will your individual data be made available to anyone, nor will any personally identifiable information be made available or associated with any of the data.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Your Access to and Control Over Information
          </Typography>
          <Typography paragraph>
          <p>You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via email at support@defaust.com</p>

<p>Request us to delete any data we have about you. Express any concern you have about our use of your data.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Security
          </Typography>
          <Typography paragraph>
          <p>We take measures to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.</p>

<p>Wherever we collect sensitive information (such as your trade data), that information is encrypted and transmitted to us in a secure way.</p>

<p>If you believe that we are not abiding by this privacy policy, please contact us immediately via email at support@defaust.com</p>
          </Typography>
      </Box>
    </Container>
    </>
  );
}

export default PrivacyPolicy;
