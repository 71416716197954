import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography, Image } from '@mui/material';
// components
import Iconify from '../../../components/iconify';
//
import AccountPopover from './AccountPopover';
import AccountSwitcher from './AccountSwitcher';
import { getAuth } from "firebase/auth";
import Logo from '../../../components/logo/Logo';
// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 0;

const StyledRoot = styled((props) => <AppBar variant="dense" position="absolute" {...props} />)(({ theme }) => ({
    backgroundColor: 'transparent',

  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  return (
    <StyledRoot>
      <StyledToolbar 
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between', // This creates space between the start and end components
        }}
      >
        <IconButton
          onClick={onOpenNav}
          sx={{
            color: 'text.primary',
            display: { lg: 'none' }
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* Logo */}
        <Logo 
          sx={{
            display: { xs: 'block', lg: 'none' }, // Show only for mobile, hide for larger screens
            position: 'absolute', // Absolutely position the logo
            left: '50%', // Move it 50% from the left (centering it)
            transform: 'translateX(-50%)', // Shift it back by half its width to ensure it's perfectly centered

          }} 
        />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
          sx={{
            display: { xs: 'none', lg: 'flex' } // Hide on mobile, display on larger screens
          }}
        >
          {/* Additional Components can be added here */}
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
