import React, { useState } from 'react';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import {
  Button,
  Container,
  TextField,
  Typography,
  List,
  ListItem,
  Box,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import { db } from '../../firebase';

const AdminScheduleZoom = () => {
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [zoomLink, setZoomLink] = useState('');
    const [logs, setLogs] = useState([]);
  
    const scheduleMeeting = async () => {
      if (!title || !date || !time || !zoomLink) {
        setLogs([...logs, 'All fields are required.']);
        return;
      }
  
      const dateTime = new Date(`${date}T${time}`);
      const timestamp = Timestamp.fromDate(dateTime);
  
      try {
        await addDoc(collection(db, 'resources', 'streams', 'meetings'), {
          title,
          timestamp,
          zoomLink,
        });
        setLogs([...logs, 'Successfully scheduled the meeting.']);
      } catch (error) {
        setLogs([...logs, 'Failed to schedule the meeting.']);
        console.error('Failed to schedule meeting:', error);
      }
    };

  return (
    <Container sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Admin: Schedule Zoom Meeting
      </Typography>
      <TextField label="Title" variant="outlined" fullWidth sx={{ marginBottom: 2 }} value={title} onChange={(e) => setTitle(e.target.value)} />
      <TextField label="Date" type="date" variant="outlined" fullWidth sx={{ marginBottom: 2 }} value={date} onChange={(e) => setDate(e.target.value)} />
      <TextField label="Time" type="time" variant="outlined" fullWidth sx={{ marginBottom: 2 }} value={time} onChange={(e) => setTime(e.target.value)} />
      <TextField label="Zoom Link" variant="outlined" fullWidth sx={{ marginBottom: 2 }} value={zoomLink} onChange={(e) => setZoomLink(e.target.value)} />
      <Button variant="contained" color="primary" onClick={scheduleMeeting}>
        Schedule Meeting
      </Button>
      <Box sx={{ marginY: 2 }}>
        <Typography variant="h6">Logs</Typography>
        <List>
          {logs.map((log, index) => (
            <ListItem key={index}>
              {log.startsWith('Successfully') ? <CheckCircleOutlineIcon color="success" /> : <CancelIcon color="error" />}
              <Typography color={log.startsWith('Successfully') ? 'green' : 'red'}>
                {log}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Box>
    </Container>
  );
};

export default AdminScheduleZoom;
