//Homepage.js
import React, { useState, useEffect, useRef, Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { Link as RouterLink, Link } from 'react-router-dom';
import { Button, Container, Typography, Box, Card,  CardContent, Dialog, DialogContent} from '@mui/material';
import { AnimatedCounter } from '../../sections/landing';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import useAuth from '../../hooks/useAuth';
import './styles.css'
import { styled } from '@mui/material/styles';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import { alpha } from '@mui/material/styles';
import background_image from '../../sections/assets/back-main.png';
import chart_manipulation from '../../sections/assets/chart-manipulation.png';
import { motion, useTransform, useScroll } from "framer-motion";
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import LandingHeader from '../../layouts/landing/LandingHeader';
import { useTheme, useMediaQuery} from "@mui/material";
import { Grid, Avatar, IconButton } from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ReviewSlider from '../../sections/landing/ReviewsSectionSlider';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import { ArrowForward, Reviews } from '@mui/icons-material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ImageVerticalSlider from '../../sections/landing/ImageVerticalSlider';
import Prices from '../dashboard/Prices';
const HEADER_MOBILE = 20;
const HEADER_DESKTOP = 15;


const TrembleButton = styled(Button)(({ theme }) => ({
  color: '#2E2E2E',
  fontSize: '1.05rem',
  fontWeight: '400',
  backgroundColor: '#FFF',
  borderRadius: 50,
  paddingRight: 30,
  paddingLeft: 30,
  paddingTop: 12,
  paddingBottom: 12,
  boxShadow: '0px 3px 2px 0px rgba(0, 0, 0, 0.30)',
  '&:hover': {
    backgroundColor: '#FFF',
    boxShadow: '0px 2px 1px 0px rgba(0, 0, 0, 0.30)',
  },
  '& .rocket': {
    transition: 'all 1s ease-in-out',
    marginLeft: '4px',
    fontSize: '1rem',
  },
  '&:hover .rocket': {
    animation: 'tremble 0.7s ease-in-out infinite',
  },
  '@keyframes tremble': {
    '0%, 100%': {
      transform: 'translate(0, 0)',
    },
    '25%': {
      transform: 'translate(4px, 2px)',
    },
    '50%': {
      transform: 'translate(-3px, -3px)',
    },
    '75%': {
      transform: 'translate(4px, 1px)',
    },
  },
}));

const StyledRoot = styled('div')({
  });
  

  const Main = styled('div')(({ theme }) => ({
    flexGrow: 1,
    minHeight: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: HEADER_DESKTOP,
    [theme.breakpoints.down('sm')]: {
      paddingTop: HEADER_MOBILE,  // Updated paddingTop value
    },
  }));

const GlassEffectWord = ({ children, blue = false }) => (
  <Box sx={{
    backgroundImage:  blue? 'linear-gradient(101deg, rgba(51, 50, 50, 0.00) 0%, #55FDFD50 100%)' : "linear-gradient(101deg, rgba(51, 50, 50, 0.00) 0%, #40444E 100%)",
    backdropFilter: "blur(3px)",
    color: blue? '55FDFD' : '#A0C0FF',
    border: '0.1px solid rgba(255, 255, 255, 0.2)',
    borderColor: blue? '55FDFD10' : alpha('#A0C0FF',0.1),
    boxShadow: blue? '-1px -1px 2px 0px #55FDFD20' : '-1px -1px 2px 0px #A0C0FF20',
    borderRadius: "5px",
    padding: "3px",
    margin: "0 5px",
    display: "inline",
  }}>
    {children}
  </Box>
);

export default function Homepage() {
  const [open, setOpen] = useState(false);
  const [showFooter, setShowFooter] = useState(true);
  const [followers, setFollowers] = useState(1000);
  const [traders] = useState(343);
  const [students, setStudents] = useState(421);
  const aboutStrategy = React.createRef();
  const howYouWillLearn = React.createRef();
  const successStory = React.createRef();
  const community = React.createRef();
  const forWhat = React.createRef();
  const prices = React.createRef();

  
  useEffect(() => {
    const fetchFollowersAndUsers = async () => {
      const functions = getFunctions();

      try {
        // Fetch Telegram Followers
        const getTelegramFollowers = httpsCallable(functions, "getTelegramFollowers");
        const telegramResult = await getTelegramFollowers({});
        setFollowers(telegramResult.data.followers);
        //console.log('Fetched Telegram followers:', telegramResult.data.followers);

        // Fetch Total Users
        const docRef = doc(db, "app_statistics", "overall");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setStudents(data.users);
        } else {
          //console.log("No such document!");
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchFollowersAndUsers();
  }, []);
  
  

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const defaultColor = '#FFFFFF'; // The color you want as default
  const [bgColor, setBgColor] = useState("#2E2E2E"); // Initial background color
  useEffect(() => {
    // Set the background color when the component mounts
    document.body.style.backgroundColor = bgColor;
  
    // Reset the background color when the component unmounts
    return () => {
      document.body.style.backgroundColor = defaultColor;
    };
  }, [bgColor]);
  
  const scrollToRef = (targetRef) => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const textStyle = {
    textTransform: 'none',
    fontSize: isMobile ? '1.5rem' : '4.4rem',
    color: '#202832',
    fontWeight: 'medium',
    lineHeight: isMobile ? '2rem' : '5.8rem',
  };

  const counterStyle = {
    textTransform: 'uppercase',
    fontSize: isMobile ? '3rem' : '1.5rem',
    color: '#FFF',
    fontWeight: 500,
    color: '#FFF', 
    backdropFilter: 'blur(4px)',
    backgroundColor: 'rgba(255, 255, 255, 0.02)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    borderRadius: 5,

  };
  
  return (
    <>
<Helmet>
  <html lang="ru" />
  <title>DEFAUST® – лучшая академия по обучению трейдингу, крипте и инвестициях</title>
  <meta name="description" content={`Профессиональное обучение трейдингу и крипте от академии DEFAUST, начиная от 15$. ${followers} в комьюнити, ${students} учеников.`} />
  <link rel="canonical" href="https://defaust.com" />
  <meta name="robots" content="index, follow" />
  
  {/* OpenGraph tags */}
  <meta property="og:title" content="DEFAUST® – лучшая академия по обучению трейдингу, крипте и инвестициях" />
  <meta property="og:description" content="Профессиональное обучение трейдингу и крипте от академии DEFAUST, начиная от 15$" />
  <meta property="og:image" content="https://defaust.com/assets/images/previews/preview-homepage.jpg" />
  <meta property="og:url" content="https://defaust.com" />

  {/* Twitter Card tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="DEFAUST® – лучшая академия по обучению трейдингу, крипте и инвестициях" />
  <meta name="twitter:description" content="Профессиональное обучение трейдингу и крипте от академии DEFAUST, начиная от 15$" />
  <meta name="twitter:image" content="https://defaust.com/assets/images/previews/preview-homepage.jpg" />

  {/* Dynamic Schema */}
  <script type="application/ld+json">
    {`
      {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "DEFAUST® – лучшая академия по обучению трейдингу, крипте и инвестициях",
        "description": "Профессиональное обучение трейдингу и крипте от академии DEFAUST, начиная от 15$, ${followers} в комьюнити, ${students} учеников."
      }
    `}
  </script>
</Helmet>

<StyledRoot>

<LandingHeader         
        scrollToRef={scrollToRef}
        aboutStrategy={aboutStrategy}
        successStory={successStory}
        howYouWillLearn={howYouWillLearn}
        community={community}
        forWhat={forWhat}
        prices={prices}
        isUserAuthenticated={false} openNav={open} onCloseNav={() => setOpen(false)} onOpenNav={() => setOpen(true)}/>

<Main>
{!isMobile && (
<Box sx={{
fontWeight: 'semi-bold',
display: 'flex',
flexDirection: 'row',
borderRadius: 5,
background: `url(${background_image})`,
backgroundSize: 'cover',
height: '60vh'
}}>
<Box sx={{
display: 'flex',
flexDirection: 'row',
justifyContent: 'center',
flexGrow: 1,
alignItems: 'center',
flexWrap: 'nowrap',
ml: '20%',
}}>
<div style={textStyle}>
<span style={{ fontWeight: '600' }}>Научись</span> <span style={{ fontWeight: '200' }}>зарабатывать</span><br />
<span style={{ fontWeight: '200' }}>с помощью</span> <span style={{ fontWeight: '600' }}>стратегии</span><br />
<Box sx={{
display: 'flex',
alignItems: 'start',
flexWrap: 'wrap',
}}>
<span style={{ fontWeight: '600', flexShrink: 0 }}>Defaust Strategy</span>
<Box sx={{
display: 'flex',
flexDirection: 'row',
maxWidth: "35%",
ml: '2%',
flexWrap: 'wrap',
whiteSpace: 'pre-wrap',
overflowWrap: 'break-word'
}}>
<span style={{ fontWeight: '400', fontSize: '1rem', flex: '1', lineHeight: '1.2rem'}}>
Defaust Strategy – торговая стратегия с использованием Volume Profile и Cumulative Delta для определения провокаций от смарт-мани для торговли вместе с крупными участниками рынка.
</span>
</Box>
</Box>

</div>
</Box>
</Box>
)}
{isMobile && (
<Box sx={{
fontWeight: 'semi-bold',
display: 'flex',
flexDirection: 'row',
borderRadius: 5,
background: `url(${background_image})`,
backgroundSize: 'cover',

}}>
<Box sx={{
display: 'flex',
flexGrow: 1,
flexWrap: 'nowrap', flexDirection: 'column',
p: 5,
alignItems: 'start',
mt: 8
}}>
<div style={textStyle}>
  <span style={{ fontWeight: '600' }}>Научись</span> <span style={{ fontWeight: '200' }}>зарабатывать</span><br />
  <span style={{ fontWeight: '200' }}>с помощью</span> <span style={{ fontWeight: '600' }}>стратегии</span><br />
  <span style={{ fontWeight: '600', flexShrink: 0 }}>Defaust Strategy</span>
</div>
<span style={{paddingTop: 50, fontWeight: '400', fontSize: '1rem', flex: '1', lineHeight: '1.2rem'}}>
Defaust Strategy – торговая стратегия с использованием Volume Profile и Cumulative Delta и определения провокаций от смарт-мани для торговли вместе с крупными участниками рынка.
</span>
  <Box sx={{
display: 'flex',
alignItems: 'start',
flexWrap: 'wrap',
}}>

{!isMobile && (
  <Box sx={{
display: 'flex',
flexDirection: 'row',
maxWidth: "100%",
mt: 2,
flexWrap: 'wrap',
whiteSpace: 'pre-wrap',
overflowWrap: 'break-word'
}}>
<span style={{ fontWeight: '400', fontSize: '1rem', flex: '1', lineHeight: '1.2rem'}}>
Defaust Strategy – торговая стратегия с использованием Volume Profile и Cumulative Delta и определения провокаций от смарт-мани для торговли вместе с крупными участниками рынка.
</span>
</Box>
)}

</Box>


</Box>
</Box>
)}


<Container  maxWidth="xl">
<Box sx={{display: 'flex', flexDirection: ['column', 'column', 'column','row'], gap: [0,0,0,5],}}>
      <Box sx={{
        display: 'flex', flexDirection: 'column', ...counterStyle, mt: 4,py: 3, px: 3, pt: 4,  width: ['100%', '100%', '100%','45%'],
      }}>
        <Box sx={{display: 'flex',  flexDirection: 'row', gap: 3}}>
        <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: [ '1rem' ,'1.3rem'] }}>
    <span style={{ color: '#55FDFD' }}>Defaust</span> – это академия, в которой каждый может обучиться профессиональной торговле на рынке
    <GlassEffectWord><Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: [ '0.9rem' ,'1.3rem'] }}>FOREX</Typography></GlassEffectWord>,
    <GlassEffectWord><Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: [ '0.9rem' ,'1.3rem'] }}>CRYPTO</Typography></GlassEffectWord>и
    <GlassEffectWord><Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: [ '0.9rem' ,'1.3rem'] }}>STOCKS</Typography></GlassEffectWord>.
  </Typography>
  
      {!isMobile && (
    <Box component="img" src={'/assets/images/products/logo-pro-max.png'} alt={'defaust обучение'} 
    sx={{  borderRadius: 5,
          backgroundColor: '#fff',
          p: 1,
          width: 100,
          height: 100,}} />
      )}
    </Box>
      <Box sx={{
  display: 'inline-flex', // Changed from 'flex' to 'inline-flex'
  flexDirection:  ['column','row'],
  mt: [2,4],
  gap: 2,
  width: 'auto',  // Explicitly set the width to 'auto'
  flex: 'none', // Make sure it doesn't grow or shrink
  justifyContent: 'space-evenly',
  alignItems: 'center',
  textAlign: 'center',
  backgroundImage: "linear-gradient(120deg, rgba(51, 50, 50, 0.00) 50%, #40444E 100%)",
  backdropFilter: "blur(3px)",
  borderRadius: 5,
  color: '#FFF',
  border: '1px solid #A0C0FF40',
  p: 1.5,
}}>
      <AnimatedCounter number={followers} title="Комьюнити" />
      <AnimatedCounter number={students} title="Учеников" />
      <AnimatedCounter number={traders} title="Трейдеров" />
</Box>
</Box>

<Box sx={{
        display: 'inline-flex', flexDirection: 'column', width: 'auto', ...counterStyle, mt: 4,py: 3, px: 3, pt: 4, width: ['100%', '100%', '100%','35%'],
       alignItems: 'center',
      }}>
        <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: '1.3rem' }}>
          Обучаясь на <span style={{ color: '#55FDFD' }}>новой платформе</span>
        </Typography>
        <Box component="img" src={'/assets/images/previews/dashboard-preview.png'} alt={'defaust обучение'} 
    sx={{ borderRadius: 5,   justifyContent: 'center',
          backgroundColor: '#fff',
          border: '1px solid #A0C0FF40',
          p: 1,
          mt: 2,
          width: '100%',
          height: 'auto',}} />
  </Box>


<Box sx={{
        display: 'flex', flexDirection: ['row','row','row','column'], ...counterStyle, mt: 4,py: 3, px: 3, pt: 4,  width: ['100%', '100%', '100%','20%'], gap: 1,  justifyContent: ['center', 'space-evenly'], alignItems: 'center', 
      }}>
    <Box sx={{
        width: ['50%','50%','50%','auto'],  // Explicitly set the width to 'auto'
        flex: 'none', // Make sure it doesn't grow or shrink
        justifyContent: 'space-evenly',
        alignItems: 'center',
        textAlign: 'center',
        backgroundImage: "linear-gradient(110deg, rgba(51, 50, 50, 0.00) 10%, #40444E 60%)",
        backdropFilter: "blur(3px)",
        borderRadius: 5,
        color: '#A0C0FF',
        border: '1px solid #A0C0FF40',
        p: [0,1.5],
    }}>
    <Typography variant='body' sx={{  color: '#A0C0FF',fontWeight: '400', textTransform: 'none', fontSize: '1.3rem', verticalAlign: 'middle'}}>
          18+ курсов
        </Typography>
    </Box>
    <Box sx={{
        width: ['50%','50%','50%','auto'], // Explicitly set the width to 'auto'
        flex: 'none', // Make sure it doesn't grow or shrink
        justifyContent: 'space-evenly',
        alignItems: 'center',
        textAlign: 'center',
        backgroundImage: "linear-gradient(110deg, rgba(51, 50, 50, 0.00) 10%, #384F4F 60%)",
        border: '1px solid #A0C0FF40',
        backdropFilter: "blur(3px)",
        borderRadius: 5,
        color: '#FFF',
       
        p: [0,1.5],
    }}>
    <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: '1.3rem',verticalAlign: 'middle' }}>
    <span style={{ color: '#55FDFD' }}>11+ рекапов</span>
        </Typography>
    </Box>
    {!isMobile && (
    <Box sx={{
      width: '100%',  // Explicitly set the width to 'auto'
      flex: 'none', // Make sure it doesn't grow or shrink
      justifyContent: 'space-evenly',
      alignItems: 'start',
      textAlign: 'start',
      lineHeight: '1.1rem',
      p: 1.5,
  }}>

  <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: '1rem',verticalAlign: 'middle' }}>
        <span style={{ color: '#FFF' }}>Сотни сделок учеников, а также еженедельные разборы рынка и сделок</span>
      </Typography>
  
</Box>
    )}
    </Box>
        </Box>
 
 {/* Что такое Defaust Strategy */}
        <Box sx={{display: 'flex', flexDirection: ['column', 'column', 'column','row'], gap: [0,0,0,5],}}>
      <Box sx={{
        display: 'inline-flex', flexDirection: 'column', mt: 4,py: 3, pt: 4,  width: ['100%', '100%', '100%','45%'], 
        justifyContent: 'start', alignItems: 'start',
        fontWeight: 'semi-bold',
        display: 'flex',
        flexDirection: 'row',
        //backgroundColor: 'rgba(255,255,255,1)',
        backgroundSize: 'cover',
      }}>
                <Typography variant='body' sx={{ fontWeight: '700', textTransform: 'none', fontSize: ['2.2rem' ,'2.2rem' ,'2.2rem' ,'4.3rem'], lineHeight: ['2.5rem','2.5rem','2.5rem', '5rem'], color: '#fff', textAlign: 'left'}}>
                Что такое<span style={{ color: '#55FDFD' }}><br/>Defaust Strategy</span>?
        </Typography>
        </Box>
      <Box ref={aboutStrategy}
      sx={{
        display: 'flex', flexDirection: 'column', mt: [2,4],py: [1,3], px: [0,3], pt: 4,  width: ['100%', '100%', '100%','55%'], 
        justifyContent: 'start', alignItems: 'center',
        fontWeight: 'semi-bold',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 5,

      }}>
                <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: '1.3rem',  color: '#fff', textAlign: 'left'}}>
        На рынке <span style={{ color: '#A0C0FF', fontWeight: '700', }}>крупные участники забирают ликвидность</span> (деньги) у слабых участников <span style={{ color: '#A0C0FF', fontWeight: '700',  }}>с помощью манипуляций</span>.
        Крупные участники делают это, так как они управляют очень большим капиталом и для того, чтобы открыть или закрыть позиции <span style={{ color: '#A0C0FF', fontWeight: '700', textDecoration: 'underline' }}>им нужно, чтобы ты получил стоп-лосс.</span>
        </Typography>
        </Box>
      </Box>
  {/* Что такое Defaust Strategy 2*/}
        <Box sx={{display: 'flex', flexDirection: ['column', 'column', 'column','row'], gap: [0,0,0,5],}}>
        <Box sx={{
        display: 'inline-flex', flexDirection: 'column', width: 'auto', ...counterStyle, mt: 4,py: 3, px: 3, pt: 4, width: ['100%', '100%', '100%','60%'],
       alignItems: 'center',
      }}>
        <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: '1.3rem' }}>
        Вот как это выглядит на <span style={{ color: '#55FDFD', fontWeight: '700', }}>реальном графике</span>
        </Typography>
        <Box component="img" src={chart_manipulation} alt={'defaust обучение'} 
    sx={{ borderRadius: 5,   justifyContent: 'center',
          mt: 2,
          width: '100%',
          height: 'auto',}} />
  </Box>
        <Box sx={{
        display: 'inline-flex', flexDirection: 'column', width: 'auto', mt: 4,py: 3, px: 3, pt: 4, width: ['100%', '100%', '100%','40%'],
        color: 'white',  ...counterStyle, gap: 2,
      }}>
        <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: ['1.2rem', '1.3rem'], textAlign: 'center'}}>
        <span style={{ color: '#55FDFD', fontWeight: '700', }}>Преимущества стратегии</span> Defaust Strategy
        </Typography>
        <Box sx={{display: 'flex', flexDirection: 'column',  justifyContent: 'space-evenly', alignItems: 'center', height: '100%'}}>
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', gap: 2}}>
          <Box sx={{display: 'flex', ...counterStyle, borderRadius: 3, p: 3}}>
          <Typography variant='body' sx={{ fontWeight: '700', textTransform: 'none', fontSize: '1.2rem' }}>1</Typography>
          </Box>
          <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: ['1.1rem', '1.2rem'] }}>Стратегия построена на  <GlassEffectWord>логично-следственных связях</GlassEffectWord>, а не потому что "оно должно работать"</Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 2}}>
          <Box sx={{display: 'flex', ...counterStyle, borderRadius: 3, p: 3}}>
          <Typography variant='body' sx={{ fontWeight: '700', textTransform: 'none', fontSize: '1.2rem' }}>2</Typography>
          </Box>
          <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize:  ['1.1rem', '1.2rem']}}>Стратегия показывает <br/><GlassEffectWord>стабильные результаты</GlassEffectWord> на реальном рынке уже больше 3 лет</Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 2}}>
          <Box sx={{display: 'flex', ...counterStyle, borderRadius: 3, p: 3}}>
          <Typography variant='body' sx={{ fontWeight: '700', textTransform: 'none', fontSize: '1.2rem' }}>3</Typography>
          </Box>
          <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize:  ['1.1rem', '1.2rem'] }}>Стратегия позволяет <GlassEffectWord>пройти отбор</GlassEffectWord> в проп-компании и получить от 20,000$ до 250,000$ в управление</Typography>
        </Box>
        </Box>
  </Box>
      </Box>


      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 4}} ref={howYouWillLearn}>
      <Typography variant='body' sx={{ fontWeight: '700', textTransform: 'none', fontSize: ['2.1rem' ,'2.2rem' ,'2.2rem' ,'4.3rem'], lineHeight: ['2.5rem','2.5rem','2.5rem', '5rem'], color: '#fff', pt: 3}}>
      Как <span style={{ color: '#55FDFD' }}>ты научишься</span> торговать?
    </Typography>
    </Box>

    {isMobile && (
  <>
<Box sx={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'start', alignItems: 'start', pt: 3}}>
<Typography variant='body' sx={{ color: '#FFF', fontSize: '1.5rem'}}>
    <span style={{ color: '#A0C0FF', fontWeight: '700'}}>1.</span> Регистрируйся бесплатно на платформе для обучения
        </Typography>
        <Box component="img" src={'/assets/images/previews/mobile/mobile-dashboard.png'} alt={'defaust обучение'} 
    sx={{ borderRadius: 5,   justifyContent: 'center',
          border: '1px solid #A0C0FF40',
          p: 1,
          mt: 2,
          width: '100%',
          height: 'auto',}} />
</Box>
<Box sx={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'start', alignItems: 'start', pt: 3}}>
<Typography variant='body' sx={{ color: '#FFF', fontSize: '1.5rem'}}>
    <span style={{ color: '#A0C0FF', fontWeight: '700'}}>2.</span> Изучи план программы DEFAUST TRADER
        </Typography>
        <Box component="img" src={'/assets/images/previews/mobile/mobile-defaust-trader.png'} alt={'defaust обучение'} 
    sx={{ borderRadius: 5,   justifyContent: 'center',
          border: '1px solid #A0C0FF40',
          p: 1,
          mt: 2,
          width: '100%',
          height: 'auto',}} />
</Box>
<Box sx={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'start', alignItems: 'start', pt: 3}}>
<Typography variant='body' sx={{ color: '#FFF', fontSize: '1.5rem'}}>
    <span style={{ color: '#A0C0FF', fontWeight: '700'}}>3.</span> Оплачивай и проходи каждый модуль отдельно!
        </Typography>
        <Box component="img" src={'/assets/images/previews/mobile/mobile-courses.png'} alt={'defaust обучение'} 
    sx={{ borderRadius: 5,   justifyContent: 'center',
          border: '1px solid #A0C0FF40',
          p: 1,
          mt: 2,
          width: '100%',
          height: 'auto',}} />
</Box>
</>
)}
        </Container>
</Main>
{!isMobile && (
  <>
  <HorizontalScrollCarousel cards={cards} />
<HorizontalScrollCarousel last={true} reverse={true} cards={cardsPlanProgram} />
</>
)}




<Container maxWidth="xl">
  {/* Путь от новичка до профессионала */}
<Box sx={{display: 'flex', flexDirection: ['column', 'column', 'column','row'], width: '100%', justifyContent: 'center', alignItems: 'center'}}>
<Typography variant='body' sx={{ fontWeight: '700', textTransform: 'none', textTransform: 'none', fontSize: ['2.2rem' ,'2.2rem' ,'2.2rem' ,'4.3rem'], lineHeight: ['2.5rem','2.5rem','2.5rem', '5rem'], verticalAlign: 'middle', textAlign: 'center', mt: [3,3,3,0] }}>
    <span style={{ color: '#A0C0FF' }}>{isMobile? `Получи уникальный сертификат` : `Получаешь уникальный сертификат`}</span>
        </Typography>
</Box>
<Box sx={{display: 'flex', flexDirection: ['column', 'column', 'column','row'], gap: [0,0,0,5], width: '100%'}}>

<Box sx={{
        display: 'flex', flexDirection: 'column', py: 3, px: 3, pt: 4, width: ['100%', '100%', '100%','60%'],
       alignItems: 'center',
      }}>
        <Box component="img" src={'/assets/images/previews/Group37.png'} alt={'defaust обучение'} 
    sx={{ borderRadius: 5,   justifyContent: 'center',
          border: '1px solid #A0C0FF40',
          p: 1,
          width: '100%',
          height: 'auto',}} />
  </Box>
    
    <Box sx={{
        display: 'flex', gap: 0, justifyContent: 'flex-start', flexDirection: 'column',  width: ['100%', '100%', '100%','40%']}}>

  <Box sx={{
        display: 'inline-flex', flexDirection: 'column', width: 'auto', mt: 4,py: 3, px: 3, pt: 4, width: ['100%', '100%', '100%','100%'],
        color: 'white',  ...counterStyle, gap: 2, 
      }}>
        <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: '1.3rem', textAlign: 'center'}}>
        Твой путь <span style={{ color: '#55FDFD', fontWeight: '700', }}>от новичка до профессионала</span> 
        </Typography>
        <Box sx={{display: 'flex', flexDirection: 'column',  justifyContent: 'space-evenly', alignItems: 'start', height: '100%', gap: 2}}>
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', gap: 2}}>
          <Box sx={{display: 'flex', ...counterStyle, borderRadius: 3, p: 3}}>
          <Typography variant='body' sx={{ fontWeight: '700', textTransform: 'none', fontSize: '1.2rem' }}>1</Typography>
          </Box>
          <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: '1.2rem' }}>Изучение <GlassEffectWord>Defaust Strategy</GlassEffectWord> на удобной платформе.</Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 2}}>
          <Box sx={{display: 'flex', ...counterStyle, borderRadius: 3, p: 3}}>
          <Typography variant='body' sx={{ fontWeight: '700', textTransform: 'none', fontSize: '1.2rem' }}>2</Typography>
          </Box>
          <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: '1.2rem' }}>Практикатическое применение <GlassEffectWord>навыков и знаний</GlassEffectWord> усвоенных на курсах.</Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 2}}>
          <Box sx={{display: 'flex', ...counterStyle, borderRadius: 3, p: 3}}>
          <Typography variant='body' sx={{ fontWeight: '700', textTransform: 'none', fontSize: '1.2rem' }}>3</Typography>
          </Box>
          <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: '1.2rem' }}>Прохождение проп-отбора для получения <br/><GlassEffectWord>до 250,000$</GlassEffectWord> в управление.</Typography>
        </Box>
        </Box>
  </Box>

        <Box sx={{
            display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection: 'column', ...counterStyle, mt: 4,py: 2, px: 3,  width: '100%',
          }}>
                  <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: '1.3rem' }}>
    Получи <span style={{ color: '#55FDFD' }}>уникальный именной сертификат</span> после прохождения курса в качестве подтверждения <GlassEffectWord>знаний</GlassEffectWord> и <GlassEffectWord>навыков</GlassEffectWord> полученных при обучении.
  </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: [ 'column-reverse','row'], alignItems: ['center', 'center'], justifyContent: 'center', mt: 3 }}>
              <Typography sx={{ paddingRight: [0,2], color: '#FFF', fontWeight: '600', fontSize: ['0.9rem', '1rem'], mt: [0.5, 0]}}>
                Пройди 1-й модуль бесплатно 🎁
              </Typography>
              <TrembleButton
                variant='contained'
                component={Link}
                to={'/dashboard'}
              >
                <span className="text-start-studying">Обучаться</span><span className="rocket">🚀</span>
              </TrembleButton>
            

            </Box>


  </Box>
  </Box>
  {/* Отзывы и результаты учеников */}
  <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} ref={successStory}>
      <Typography variant='body' sx={{  textTransform: 'none', fontWeight: '700', textTransform: 'none', fontSize: ['2.2rem' ,'2.2rem' ,'2.2rem' ,'4.3rem'], lineHeight: ['2.5rem','2.5rem','2.5rem', '5rem'], color: '#fff', textAlign: 'center', pt: 3}}>
      Отзывы и <span style={{ color: '#55FDFD'}}>результаты</span> учеников
    </Typography>
    </Box>
    <MainSlider reviews={reviewSample} />

 {/* Комьюнити */}
 <Box sx={{display: 'flex', flexDirection: ['column', 'column', 'column','row'], gap: [0,0,0,5],}}>
      <Box sx={{
        display: 'inline-flex', flexDirection: 'column', mt: 4,py: 3, pt: 4,  px: 2, width: ['100%', '100%', '100%','55%'], gap: 2,
        justifyContent: 'start', alignItems: 'start',
        fontWeight: 'semi-bold',
        display: 'flex',
        //backgroundColor: 'rgba(255,255,255,1)',
        backgroundSize: 'cover',
      }}>
                <Typography  ref={community} variant='body' sx={{ fontWeight: '700', textTransform: 'none', fontWeight: '700', textTransform: 'none', fontSize: ['2.2rem' ,'2.2rem' ,'2.2rem' ,'4.3rem'], lineHeight: ['2.5rem','2.5rem','2.5rem', '5rem'], color: '#fff', textAlign: 'left'}}>
                Мы торгуем<br/><span style={{ color: '#55FDFD' }}>в команде</span>, go к нам!
        </Typography>


        <Box  sx={{ 
        display: 'inline-flex', flexDirection: 'column', width: 'auto', mt: 4,py: 3, px: 3, pt: 4, width: ['100%', '100%', '100%','100%'],
        color: 'white',  ...counterStyle, gap: 2, 
      }}>
        <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: '1.3rem', textAlign: 'center'}}>
        Что <span style={{ color: '#55FDFD', fontWeight: '700', }}>ты получишь</span> в нашем комьюнити?
        </Typography>
        <Box sx={{display: 'flex', flexDirection: 'column', height: '100%', gap: 2, alignItems: 'start'}}>
        <Box sx={{display: 'flex', flexDirection:  ['column', 'row'], justifyContent: 'space-evenly', alignItems: ['start','center'], gap: 2}}>
          <Box sx={{display: 'flex', ...counterStyle, borderRadius: 3, p: 3, }}>
          <Typography variant='body' sx={{ fontWeight: '700', textTransform: 'none', fontSize: '1.2rem' }}>RECAPS</Typography>
          </Box>
          <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: '1.2rem' }}>Каждую пятницу <GlassEffectWord>RECAP сессия</GlassEffectWord> для разбора рынка и сделок учеников.</Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection:  ['column', 'row'], justifyContent: 'center', alignItems: ['start','center'], gap: 2}}>
          <Box sx={{display: 'flex', ...counterStyle, borderRadius: 3, p: 3}}>
          <Typography variant='body' sx={{ fontWeight: '700', textTransform: 'none', fontSize: '1.2rem' }}>SUPPORT</Typography>
          </Box>
          <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: '1.2rem' }}>Поддежка от участников команды, <GlassEffectWord>советы и рекомендации</GlassEffectWord> от тех, кто уже вышел на стабильные результаты.</Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: ['column', 'row'], justifyContent: 'center', alignItems: ['start','center'], gap: 2}}>
          <Box sx={{display: 'flex', ...counterStyle, borderRadius: 3, p: 3}}>
          <Typography variant='body' sx={{ fontWeight: '700', textTransform: 'none', fontSize: '1.2rem' }}>IDEAS</Typography>
          </Box>
          <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: '1.2rem' }}>Каждый ученик и DEFAUST публикуют<GlassEffectWord>торговые идеи</GlassEffectWord>, чтобы все в команде смогли зарабатывать.</Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: ['column', 'row'], justifyContent: 'center', alignItems: ['start','center'], gap: 2}}>
          <Box sx={{display: 'flex', ...counterStyle, borderRadius: 3, p: 3}}>
          <Typography variant='body' sx={{ fontWeight: '700', textTransform: 'none', fontSize: '1.2rem' }}>MENTORSHIP</Typography>
          </Box>
          <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: '1.2rem' }}>Возможность напрямую <GlassEffectWord>консультироваться с DEFAUST</GlassEffectWord> и получать обратную связь.</Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection:  ['column', 'row'], justifyContent: 'center', alignItems: ['start','center'], gap: 2}}>
          <Box sx={{display: 'flex', ...counterStyle, borderRadius: 3, p: 3}}>
          <Typography variant='body' sx={{ fontWeight: '700', textTransform: 'none', fontSize: '1.2rem' }}>CONFERENCES</Typography>
          </Box>
          <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: '1.2rem' }}>Дополнительные <GlassEffectWord>ежемесячные конференции</GlassEffectWord> с бэктест-сессиями, а также на тему психологии, инвестиции и крипты.</Typography>
        </Box>
        </Box>
        <Box sx={{display: 'flex', flexDirection:  ['column', 'row'], justifyContent: 'center', alignItems: ['center','center'], gap: 2}}>
          <Box sx={{display: 'flex', p: 1}}>
          <Typography variant='body' sx={{ fontWeight: '700', textTransform: 'none', fontSize: '1.5rem' }}>Всего за 50$/мес 🔥</Typography>
          </Box>
          </Box>
        </Box>
        
  </Box>
        <Box 
        sx={{
          display: 'flex', flexDirection: 'column', mt: 4,py: 3, px: 3, pt: 4,  width: ['100%', '100%', '100%','45%'], 
          justifyContent: 'start', alignItems: 'center',
          fontWeight: 'semi-bold',
          display: 'flex',
          justifyContent: 'space-evenly', 
          borderRadius: 5,
  
        }}>
        <Box sx={{display: 'flex', flexDirection: 'column', width: ['100%', '100%', '100%','85%'],}}>
        <Typography  variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: '1.3rem',  color: '#fff', textAlign: 'left'}}>
        Новички, которые сами пытаются обучиться трейдингу <span style={{ color: '#A0C0FF', fontWeight: '700', }}>без ментора и комьюнити, в 99% случаев сливают</span> свой капитал! 
        <span style={{ color: '#A0C0FF', fontWeight: '700', }}> Но работа в команде</span> дает возможность развиваться быстрее, эффективнее и <span style={{ color: '#A0C0FF', fontWeight: '700', }}>прибыльнее</span>.
        </Typography>
        </Box>
        <ImageDiscordSlider images={cardsDiscord} />
          </Box>
      </Box>

       {/* Why Defaust Teach */}
       <Box sx={{
        flexDirection: 'column', mt: 0,py: 3, pt: 4,  px: 2, width: '100%', gap: 2,
        justifyContent: 'start', alignItems: 'center',
        fontWeight: 'semi-bold',
        display: 'flex',
      }}>
                <Typography  ref={forWhat} variant='body' sx={{ fontWeight: '700', textTransform: 'none', textTransform: 'none', fontSize: ['2.2rem' ,'2.2rem' ,'2.2rem' ,'4.3rem'], lineHeight: ['2.5rem','2.5rem','2.5rem', '5rem'],color: '#fff', textAlign: 'center'}}>
                Зачем <span style={{ color: '#55FDFD' }}>DEFAUST</span> обучает?
        </Typography>
        <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: ['1rem','1rem','1rem','1.5rem'], lineHeight: '2rem', color: '#fff', textAlign: 'center', ml: ['0%','0%','0%','50%'], textDecoration: 'underline'}}>
                И делится <span style={{ color: '#55FDFD' }}>своей стратегией</span>
        </Typography>
      </Box>
 <Box sx={{display: 'flex', flexDirection: ['column', 'column', 'column','row'], gap: [0,0,0,5],}}>

        <Box sx={{display: 'flex', flexDirection: ['column', 'column', 'column', 'column'], width: ['100%','100%','50%','50%'], justifyContent: 'center', alignItems: 'center',  mt: 5}}>
        <Typography variant='body' sx={{color: '#FFF', fontWeight: '400', textTransform: 'none', fontSize: '1.2rem' }}>
            Более трех лет я торговал по своей разработанной стратегии, проходя отборы в проп-компаниях и <GlassEffectWord>наращивая свой капитал до 2 МЛН$</GlassEffectWord>. Но, как в инвестициях
               достигается наибольшая прибыльность путем распределения капитала между лучшими активами, так
                и в трейдинге – наибольшая доходность достигается путем <GlassEffectWord>распределения капитала между лучшими трейдерами</GlassEffectWord>.
                <br/><br/>
                Обучая новичков своей торговой стратегии, я закладываю фундамент в создание <GlassEffectWord>своей команды</GlassEffectWord>, которая в скором времени будет <GlassEffectWord>управлять моим капиталом</GlassEffectWord> и капиталом инвесторов.
                Кроме того, я <GlassEffectWord>уверен в своей стратегии</GlassEffectWord> и знаю какие результаты она показывает. Поэтому обучая вас, я знаю на какие результаты можете выйти Вы, если проявите <GlassEffectWord>усердие</GlassEffectWord>,
                будете иметь <GlassEffectWord>мотивацию</GlassEffectWord> и готовность <GlassEffectWord>изменить свою жизнь</GlassEffectWord>.
          </Typography>

          </Box>

          <Box sx={{    borderRadius: 5,
          p: 1,display: 'flex', flexDirection: ['column', 'column', 'column', 'column'], width: ['100%','100%','50%','50%'], justifyContent: 'center', alignItems: 'center',  mt: 5, border: '1px solid #A0C0FF40',}}>
          <Box component="img" src={'assets/images/previews/meet-with-students.jpeg'} alt={'defaust обучение'} 
    sx={{    justifyContent: 'center',
          width: '100%',
          height: 'auto',}} />
                          <Typography sx={{ fontStyle: 'italic', color: '#FFF', fontWeight: '400', fontSize: ['0.9rem', '1rem'], mt: 2}}>
                          <a  style={{textDecoration: 'underline', color: 'white'}} href="https://t.me/defaust/716" target="_blank" rel="noopener noreferrer">Встреча с учениками в Варшаве, 2023 г.</a>
              </Typography>
            </Box>
        </Box>
        


        <Box sx={{display: 'flex', flexDirection: ['column', 'column', 'column','row'], gap: [0,0,0,5],}}>
      <Box sx={{
        flexDirection: 'column', mt: 0,py: 3, pt: 4,  px: 2, width: '100%', gap: 2,
        justifyContent: 'start', alignItems: 'center',
        fontWeight: 'semi-bold',
        display: 'flex',
      }}>
                <Typography  ref={prices} variant='body' sx={{ fontWeight: '700', textTransform: 'none', textTransform: 'none', fontSize: ['2.2rem' ,'2.2rem' ,'2.2rem' ,'4.3rem'], lineHeight: ['2.5rem','2.5rem','2.5rem', '5rem'],color: '#fff', textAlign: 'center'}}>
                <span style={{ color: '#55FDFD' }}>Тарифы</span> обучения
        </Typography>
        <Prices />
{/*         <Box sx={{display: 'flex', flexDirection: ['column', 'column', 'column','row'], gap: [0,0,0,5],}}>
      <Box sx={{
        display: 'flex', flexDirection: 'column', ...counterStyle, background: 'none', border: 'none', mt: 4,py: 3, px: 3, pt: 4,  width: ['100%', '100%', '100%','45%'],
      }}>
        <Box sx={{display: 'flex',  flexDirection: 'row', gap: 3}}>
        <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: [ '1.2rem' ,'1.3rem'] }}>
    <span style={{ color: '#55FDFD' }}>Программа обучения DEFAUST TRADER®</span> теперь разделена на  <GlassEffectWord><Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: [ '0.9rem' ,'1.3rem'] }}>модули</Typography></GlassEffectWord>, (отдельные курсы), чтобы каждый, назависимо от его финансов, смог начать свой путь в трейдинге, стать профессионалом и присоединиться к команде DEFAUST.
  </Typography>
  
      {!isMobile && (
    <Box component="img" src={'/assets/images/products/logo-pro-max.png'} alt={'defaust обучение'} 
    sx={{  borderRadius: 5,
          backgroundColor: '#fff',
          p: 1,
          width: 100,
          height: 100,}} />
      )}
    </Box>
      <Box sx={{
  display: 'inline-flex', // Changed from 'flex' to 'inline-flex'
  flexDirection:  ['column','row'],
  mt: [2,4],
  gap: 2,
  width: 'auto',  // Explicitly set the width to 'auto'
  flex: 'none', // Make sure it doesn't grow or shrink
  justifyContent: 'space-evenly',
  alignItems: 'center',
  textAlign: 'center',
  backgroundImage: "linear-gradient(120deg, rgba(51, 50, 50, 0.00) 50%, #40444E 100%)",
  backdropFilter: "blur(3px)",
  borderRadius: 5,
  color: '#FFF',
  border: '1px solid #A0C0FF40',
  p: 1.5,
}}>
      <AnimatedCounter number={15} title="Модулей" />
      <AnimatedCounter number={400} title="Уроков" />
      <AnimatedCounter number={200} title="Тестов" />
</Box>
</Box>

<Box sx={{
        display: 'inline-flex', flexDirection: 'column', width: 'auto', ...counterStyle, mt: 4,py: 1, px: 1, pt: 4, width: ['100%', '100%', '100%','35%'],
       alignItems: 'center',
      }}>
        <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: '1.3rem' }}>
          Оплачивай обучение <span style={{ color: '#55FDFD' }}>поэтапно</span>
        </Typography>
        <Box component="img" src={'/assets/images/previews/course-plan-mini.png'} alt={'defaust обучение'} 
    sx={{ borderRadius: 5,   justifyContent: 'center',
          mt: 2,
          width: '100%',
          height: 'auto',}} />
  </Box>


<Box sx={{
        display: 'flex', flexDirection: 'column', mt: 2,py: 1, pt: 2,  width: ['100%', '100%', '100%','15%'], gap: 1,  justifyContent: ['center', 'space-evenly'], alignItems: 'center', 
      }}>
        <Box sx={{...counterStyle,
                          width: '100%',  // Explicitly set the width to 'auto'
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                          textAlign: 'center',
                          backgroundImage: "linear-gradient(110deg, rgba(51, 50, 50, 0.00) 10%, #40444E 60%)",
                          backdropFilter: "blur(3px)",
                          borderRadius: 5,
                          color: '#A0C0FF',
                          border: '1px solid #A0C0FF40',
                          p: 1.5,}}>
                        <Typography variant='body' sx={{  color: '#FFF',fontWeight: '400', textTransform: 'none', fontSize: '1.3rem', verticalAlign: 'middle'}}>
          Стоимость
        </Typography>
                        <Typography variant='body' sx={{  color: '#A0C0FF',fontWeight: '400', textTransform: 'none', fontSize: '1.3rem', verticalAlign: 'middle'}}>
          20 – 50$ / модуль
        </Typography>
    </Box>
        <Box sx={{...counterStyle,
                          width: '100%',  // Explicitly set the width to 'auto'
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                          textAlign: 'center',
                          backgroundImage: "linear-gradient(110deg, rgba(51, 50, 50, 0.00) 10%, #55FDFD20 60%)",
                          backdropFilter: "blur(3px)",
                          borderRadius: 5,
                          color: '#A0C0FF',
                          border: '1px solid #A0C0FF40',
                          p: 1.5}}>
                        <Typography variant='body' sx={{  color: '#FFF',fontWeight: '400', textTransform: 'none', fontSize: '1.3rem', verticalAlign: 'middle'}}>
          DEFAUST<br/>MEMBERSHIP
        </Typography>
                        <Typography variant='body' sx={{  color: '#A0C0FF',fontWeight: '400', textTransform: 'none', fontSize: '1.3rem', verticalAlign: 'middle'}}>
          9.99$/мес
        </Typography>
    </Box>
    <TrembleButton sx={{mt: 2}}
                variant='contained'
                component={Link}
                to={'/dashboard'}
              >
                <span className="text-start-studying">Обучаться</span><span className="rocket">🚀</span>
              </TrembleButton>
    </Box>
        </Box> */}
          </Box>
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'end', py: 5}}>
<Typography variant='body' sx={{color: '#FFF', fontWeight: '400', textTransform: 'none', fontSize: '1rem', textAlign: 'center'}}>
Made in 🇺🇦 with ❤️.<br/>OneStake OÜ, 2023. All rights reserved. 
  </Typography>
</Box>
</Container>


</StyledRoot>
</>
  )
};
  

  const MainSlider = ({ reviews }) => {
    const [currentReview, setCurrentReview] = useState(0);

  
    const nextReview = () => setCurrentReview((prev) => (prev === reviews.length - 1 ? 0 : prev + 1));
    const prevReview = () => setCurrentReview((prev) => (prev === 0 ? reviews.length - 1 : prev - 1));

  
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const counterStyle = {
      textTransform: 'uppercase',
      fontSize: isMobile ? '3rem' : '1.5rem',
      color: '#FFF',
      fontWeight: 500,
      color: '#FFF', 
      backdropFilter: 'blur(4px)',
      backgroundColor: 'rgba(255, 255, 255, 0.02)',
      border: '1px solid rgba(255, 255, 255, 0.2)',
      borderRadius: 5,
  
    };
    return (
      <>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: ['center','center','center','flex-end'], gap: 2, pr: 3, mt: 5, py: [3,3,3,0]}}>
      <IconButton 
              onClick={prevReview}
              sx={{ '&:hover': { background: alpha('#A0C0FF', 0.9) }, borderRadius: 2, background: '#A0C0FF', fontSize: { xs: 'small', md: 'medium' } }}
            >
              <ArrowBack sx={{color: '#FFF'}} />
            </IconButton>
            <IconButton 
              onClick={nextReview}
              sx={{ fontWeight: 700, fontSize: '1rem', color: '#FFF', '&:hover': { background: alpha('#A0C0FF', 0.9) }, borderRadius: 2, background: '#A0C0FF'}}
            >
              Следующий ученик<ArrowForward sx={{color: '#FFF'}} />
            </IconButton>
      </Box>
      <Box sx={{ mt: -5 }}>
        {reviews.map((review, index) => (
          <Box key={index} sx={{ display: index === currentReview ? 'block' : 'none' }}>
            {review?.last ?? (
    <CardContent sx={{ display: 'flex', flexDirection: ['column','column','column','row'], gap: 2}}>
    {/* Header with Avatar and Name */}
          <Box sx={{display: 'flex', flexDirection: 'row',gap: 0, display: 'flex', flexDirection: 'column',  justifyContent: 'space-evenly', maxWidth:  ['100%','100%','100%','50%'] }}>
            <Box sx={{ maxWidth: ['100%','100%','100%','100%'], display: 'flex', flexDirection: 'row', justifyContent: ['center','center','center', 'start'], gap: 2}}>
             <Box sx={{
                display: 'inline-flex', alignItems: 'center', justifyContent: 'start',flexDirection: 'row', ...counterStyle, mt: 4,py: 2, px: 3, gap: 0,   background: "transparent",
                color: '#A0C0FF',
                border: 'none',
              }}>
                <Avatar alt={review.name} src={review.avatar} />
                <Typography variant="h6" component="div" ml={2} noWrap textTransform={"none"}>
                  {review.name}
                </Typography>
              </Box>
              {!isMobile && (
                <>
              <Box sx={{
                display: 'inline-flex', alignItems: 'center', justifyContent: 'center',flexDirection: 'row', ...counterStyle, mt: 4,py: 1, px: 1, gap: 2, border: 'none', backgroundColor: 'transparent',
                backdropFilter: "blur(3px)",
                color: '#A0C0FF',
              }}>
                <Typography variant="body" fontSize={"1rem"} ml={2}>
                Сделки ученика <ArrowForward sx={{verticalAlign: 'top'}}/>
               </Typography>
               </Box>
               </>
                )}
          </Box>

      <Box sx={{ 
        display: 'flex', alignItems: 'start', justifyContent: 'center',flexDirection: 'column', ...counterStyle, mt: 2, py: 2, px: 3,  maxWidth: '100%', gap: 0, border: 'none', backgroundColor: 'transparent',
      }}>
        <Typography variant='body' sx={{ fontWeight: '700', textTransform: 'none', fontSize: '1.2rem',  }}>
        <GlassEffectWord>Точка "A"</GlassEffectWord> ученика
        </Typography>
      <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: '1.2rem',  }}>
      {review.a}
</Typography>
      </Box>
      <Box sx={{
        display: 'flex', alignItems: 'start', justifyContent: 'center',flexDirection: ['column', 'row'], ...counterStyle, mt: 2, py: 2, px: 3,  maxWidth: '100%', gap: 2,         backgroundImage: "linear-gradient(110deg, rgba(51, 50, 50, 0.00) 10%, #384F4F70 60%)",
        border: '1px solid #A0C0FF40',
        backdropFilter: "blur(3px)",
      }}>
        <FormatQuoteIcon sx={{fontSize: '2.5rem'}}/>
      <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: ['1rem','1rem','1rem','1.2rem'],  }}>
      {review.text}
</Typography>
      </Box>
      <Box sx={{display: 'inline-flex',  justifyContent: ['center','center','center','start']}}>
      <Button 
        sx={{ 
          background: 'transparent', 
          color: '#A0C0FF',
          border: '1px solid #A0C0FF40',
          borderRadius: 3, 
          py: 1.5, 
          mt: 2, 
          fontSize: '1rem' 
        }} 
        variant={'contained'} 
        component="a" 
        href="https://discord.gg/wqDBvmBm2q"
        target="_blank" 
        rel="noopener noreferrer"
        >
        Посмотреть все отзывы в Discord
      </Button>

    </Box>
      </Box>
      <Box sx={{maxWidth: ['100%','100%','100%','50%'], mt: '2%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
  <ImageSlider images={review} />
  </Box>
    </CardContent>
            )}
        {review?.last && (
          <ParallaxBackground />
        )}
        </Box>
        ))}
      </Box>
      </>
    );
  };

  const ImageDiscordSlider = ({ images }) => {
    const [current, setCurrent] = useState(0);
    const [open, setOpen] = useState(false);
    
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const nextSlide = () => {
      setCurrent((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    };
  
    const prevSlide = () => {
      setCurrent((prev) => (prev === 0 ? images.length - 1 : prev - 1));
    };
  
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const counterStyle = {
      textTransform: 'uppercase',
      fontSize: isMobile ? '3rem' : '1.5rem',
      color: '#FFF',
      fontWeight: 500,
      color: '#FFF', 
      backdropFilter: 'blur(4px)',
      backgroundColor: 'rgba(255, 255, 255, 0.02)',
      border: '1px solid rgba(255, 255, 255, 0.2)',
      borderRadius: 5,
  
    };
    return (
      <>

        <Box sx={{ position: 'relative', width: '100%', height: 'auto' }}>
        <IconButton 
        color="primary" 
        onClick={prevSlide}
        sx={{ '&:hover': { background: alpha('#A0C0FF', 0.5) }, borderRadius: 2, background: '#A0C0FF40', fontSize: { xs: 'small', md: 'medium' } ,
        position: 'absolute', 
        top: { xs: '40%', md: '50%' }, 
        left: { xs: 8, md: 16 }, 
        zIndex: 1,}}
        >
          <ArrowBack sx={{color: '#FFF'}} />
      </IconButton>

      <IconButton 
        color="primary" 
        onClick={nextSlide}
        sx={{ '&:hover': { background: alpha('#A0C0FF', 0.5) }, borderRadius: 2, background: '#A0C0FF40', fontSize: { xs: 'small', md: 'medium' },
        position: 'absolute', 
        top: { xs: '40%', md: '50%' }, 
        right: { xs: 8, md: 16 }, 
        zIndex: 1,}}
        >
          <ArrowForward sx={{color: '#FFF'}} />
      </IconButton>

      <Box sx={{ 
        display: 'flex', alignItems: 'start', justifyContent: 'start',flexDirection: 'column', ...counterStyle, py: 2, px: 3, height: '100%', border: 'none', backgroundColor: 'transparent',
      }}>
          <Box
            component="img"
            src={images[current]}
            alt={`Slide ${current}`}
            sx={{
              mt: 2,
              borderRadius: 5,
              //border: '1px solid rgba(255, 255, 255, 0.2)',
              p: 1,
              width: '100%',
              height: 'auto',
            }}
          />
        </Box>
        </Box>

      </>
    );
  };
  const ImageSlider = ({ images }) => {
    const [current, setCurrent] = useState(0);
    const [open, setOpen] = useState(false);
    
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const nextSlide = () => {
      setCurrent((prev) => (prev === images.sliderImages.length - 1 ? 0 : prev + 1));
    };
  
    const prevSlide = () => {
      setCurrent((prev) => (prev === 0 ? images.sliderImages.length - 1 : prev - 1));
    };
  
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const counterStyle = {
      textTransform: 'uppercase',
      fontSize: isMobile ? '3rem' : '1.5rem',
      color: '#FFF',
      fontWeight: 500,
      color: '#FFF', 
      backdropFilter: 'blur(4px)',
      backgroundColor: 'rgba(255, 255, 255, 0.02)',
      border: '1px solid rgba(255, 255, 255, 0.2)',
      borderRadius: 5,
  
    };
    return (
      <>

        <Box sx={{ position: 'relative', width: '100%', height: 'auto' }}>
        <IconButton 
        color="primary" 
        onClick={prevSlide}
        sx={{ '&:hover': { background: alpha('#A0C0FF', 0.5) }, borderRadius: 2, background: '#A0C0FF40', fontSize: { xs: 'small', md: 'medium' } ,
        position: 'absolute', 
        top: { xs: '40%', md: '50%' }, 
        left: { xs: 8, md: 16 }, 
        zIndex: 1,}}
        >
          <ArrowBack sx={{color: '#FFF'}} />
      </IconButton>

      <IconButton 
        color="primary" 
        onClick={nextSlide}
        sx={{ '&:hover': { background: alpha('#A0C0FF', 0.5) }, borderRadius: 2, background: '#A0C0FF40', fontSize: { xs: 'small', md: 'medium' },
        position: 'absolute', 
        top: { xs: '40%', md: '50%' }, 
        right: { xs: 8, md: 16 }, 
        zIndex: 1,}}
        >
          <ArrowForward sx={{color: '#FFF'}} />
      </IconButton>

      <Box sx={{ 
        display: 'flex', alignItems: 'start', justifyContent: 'start',flexDirection: 'column', ...counterStyle, py: 2, px: 3, height: '100%', border: 'none', backgroundColor: 'transparent',
      }}>
                <Typography variant='body' sx={{ fontWeight: '700', textTransform: 'none', fontSize: '1.2rem',  }}>
        <GlassEffectWord>Точка "B"</GlassEffectWord> ученика
          </Typography>
            <Typography variant='body' sx={{ fontWeight: '400', textTransform: 'none', fontSize: '1.2rem',  }}>
              {images.b}
            </Typography>
          <Box
            component="img"
            src={images.sliderImages[current]}
            alt={`Slide ${current}`}
            onClick={handleClickOpen}
            sx={{
              mt: 2,
              borderRadius: 5,
              //border: '1px solid rgba(255, 255, 255, 0.2)',
              p: 1,
              width: '100%',
              height: 'auto',
              cursor: 'pointer',
            }}
          />
        </Box>
        </Box>
        <Dialog open={open} onClick={handleClose} fullWidth maxWidth="xl">
        <DialogContent sx={{p:0, background: 'transparent',}}>
          
          <img src={images.sliderImages[current]} alt="Enlarged" style={{ background: 'transparent', width: '100%', height: 'auto', p: 0 }} />
        </DialogContent>
      </Dialog>
      </>
    );
  };
  
  const HorizontalScrollCarousel = ({cards,  reverse = false, last = false}) => {
    const targetRef = useRef(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const { scrollYProgress } = useScroll({
      target: targetRef,
    });
  
    let fromTo = isMobile ? (last ?  reverse ? ['-90%', '-0%'] : ['1%', '-40%'] :  reverse ? ['-60%', '1%'] : ['1%', '-85%']) : (last ?  reverse ? ['-60%', '8%'] : ['1%', '-40%'] :  reverse ? ['-60%', '1%'] : ['1%', '-40%']);

    
    const x = useTransform(scrollYProgress, [0, 1], fromTo);
  
    return (
      <>


<section ref={targetRef} className="relative h-[200vh]">


  {/* Horizontal Scrolling Section */}
  <div className="sticky bottom-0 top-0 flex h-[90vh] items-end overflow-hidden">
    <motion.div style={{ x }} className="flex gap-0">
      {cards.map((card) => {
        return <CardPreview card={card} key={card.id} />;
      })}
    </motion.div>
  </div>
</section>

      </>
    );
  };
  const CardPreview = ({ card }) => {
    return (
      <div
        key={card.id}
        className="group relative h-[610px] w-[1035px] overflow-hidden"
      >
        <div
          style={{
            backgroundImage: `url(${card.url})`,
            backgroundSize: "contain",  // Changed from 'cover' to 'contain'
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat" // Added this to prevent image repetition
          }}
          className="absolute inset-0 z-0"
        ></div>
      </div>
    );
  };

  const cardsPlanProgram = [
    {
      url: "/assets/images/previews/Group30.png",
      title: "Ты получишь доступ к платформе для обучения",
      id: 1,
    },
    {
      url: "/assets/images/previews/Group32.png",
      title: "Ты получишь доступ к платформе для обучения",
      id: 1,
    },
    {
      url: "/assets/images/previews/Group29.png",
      title: "Ты получишь доступ к платформе для обучения",
      id: 1,
    },

  ];
  const cardsDiscord = [
    "/assets/images/previews/discord/test-2.png",
    "/assets/images/previews/discord/test-3.png",
    "/assets/images/previews/discord/test-1.png",
    "/assets/images/previews/discord/test-5.png",
    "/assets/images/previews/discord/test-4.png",
  ];
  const cards = [
    {
      url: "/assets/images/previews/Group27.png",
      title: "Ты получишь доступ к платформе для обучения",
      id: 1,
    },
    {
      url: "/assets/images/previews/Group28.png",
      title: "Title 2",
      id: 2,
    },
  ];
  const reviewSample = [

    {
      avatar: '',
      name: 'Serhii Ponomarov',
      a: 'Ученик пришел полным новичком в трейдинге без каких-либо знаний и предыдущего опыта.',
      b: (
          <>
            Ученик закончил программу DEFAUST TRADER и спустя 2 месяца активной практики вышел на стабильные результаты. Ученик прошел отбор в проп-компанию и <GlassEffectWord blue={true}>получил 25,000$</GlassEffectWord> в управление.
          </>
        ),      
      text: `Имея год опыта торговли купил обучение на второй поток
      Все четко понятно рассказано ( до обучения я знал процентов 10 от того что знаю сейчас)
      В дискоре класное комьюнити с которым приятно делиться результатами +всегда можно попросить @defaust лично разобрать сделки или торговую неделю
      Особенно реккомендую обучение новичкам ! 
      Это  гораздо дешевле чем учиться на свои деньги ( до обучения я 2000$ слил как лох )
      Сейчас получил капитал в 25000$ в пропе ( с 3ей попытки но там я сильно ошибался )
      Не думайте что прослушав курс сразу будете зарабатывать ( нужно самому много работать и научиться пользоваться стратегией ) , но если решили заниматься трейдиннгом (серьезно каждый день, а не просто видосики посмотреть и стать милионером через месяц )  то курс очень сильно поможет в этом и сохранит много времени и денег.
      Со следующей недели буду в дискорде публиуовать результаты с проп фирмы ( надеюсь положительные 🙂        )
      Так что я однозначно доволен`,
      sliderImages: [
        '/assets/images/reviews/Serhii-Ponomarov@aa@fa29493a10eef.jpg',
        '/assets/images/students-trades/_s54/trade-0.png',
        '/assets/images/students-trades/_s54/trade-2.png',
        '/assets/images/students-trades/_s54/trade-3.png',
        '/assets/images/students-trades/_s54/trade-4.png',
        '/assets/images/students-trades/_s54/trade-5.png',
        '/assets/images/students-trades/_s54/trade-6.png',
        '/assets/images/students-trades/_s54/trade-7.png',
        '/assets/images/students-trades/_s54/trade-8.png',
        '/assets/images/students-trades/_s54/trade-9.png',
        '/assets/images/students-trades/_s54/trade-10.png',
        '/assets/images/students-trades/_s54/trade-11.png',
        '/assets/images/students-trades/_s54/trade-12.png',
      ]
  },
  {
    avatar: '',
    name: 'Cospetix',
    a: "Основные знания при работе на крипто-рынке. Базовое понимание трейдинга, графиков и анализа.",
    b: (
      <>
        После освоения стратегии Defaust Trader, Cospetix закрыл все пробелы в понимании трейдинга, <GlassEffectWord blue={true}>получил четкую стратегию</GlassEffectWord>, что дало ему возможность совершать прибыльные сделки.
      </>
    ),  
    text: ' Хотел всетаки еще раз лично сказать спасибо. Респект, шикарно обьясняешь, прекрасно поставленная речь, видно спокойствие и дисципюлина. очень рад что мы смогли таки попасть на поток. Вообщем спасибо, удачи, и надеюсь что получится рано или поздно подобраться к твоему эмоциональному/профессиональному уровню',
    reviewPhoto: '/assets/images/reviews/review-21.png',
    sliderImages: [
      '/assets/images/students-trades/cospetix/trade-0.png',
      '/assets/images/students-trades/cospetix/trade-1.png',
      '/assets/images/students-trades/cospetix/trade-2.png',
      '/assets/images/students-trades/cospetix/trade-3.png',
      '/assets/images/students-trades/cospetix/trade-4.png',
      '/assets/images/students-trades/cospetix/trade-5.png',
      '/assets/images/students-trades/cospetix/trade-6.png',
    ]
},
{
  avatar: '/assets/images/avatars/anna-review.jpeg',
  name: 'Anna',
  a: "Полный новичок без знаний в трейдинге и крипте.",
  b: (
    <>
      Anna прошла программу DEFAUST TRADER и <GlassEffectWord blue={true}>активно торгует</GlassEffectWord> на рынке FOREX, готовясь проходить отбор в проп-компанию.
    </>
  ),  
  text: 'Хочу выразить благодарность за создание такого крутого и полезного курса! В первую очередь этот курс сэкономил большое количество времени и сил, но также дал информацию, которую мало где можно найти. В курсе вся нужная база, хорошо структурирована и максимально понятно рассказана, и особенность лично для меня еще в том, что он направлен в первую очередь на использование этой информации и знаний и очень чувствуется, что главная его цель - привести нас к результату, а не сложить знания в тумбочку. А также спасибо огромное за твое профессиональное сопровождение! Спасибо!',
  reviewPhoto: '/assets/images/avatars/anna-review.jpeg',
  sliderImages: [
    '/assets/images/students-trades/anna/trade-0.png',
    '/assets/images/students-trades/anna/trade-1.png',
    '/assets/images/students-trades/anna/trade-2.png',
    '/assets/images/students-trades/anna/trade-3.png',
    '/assets/images/students-trades/anna/trade-4.png',
  ]
},
{
  last: true
},
];

class ParallaxBackground extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
    };
    this.parallaxShift = this.parallaxShift.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.parallaxShift);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.parallaxShift);
  }

  parallaxShift() {
    this.setState({ offset: window.pageYOffset });
  }
  

  render() {
    const { offset } = this.state;
    return (
      <Box
        sx={{
          height: ['60vh','100vh'],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundImage: `url(/assets/images/reviews/back-reviews.png)`,
          backgroundSize: 'fill',
          backgroundPosition: `center ${offset * 0.5}px`,
        }}
      >

        <Box sx={{  textTransform: 'uppercase',
  fontSize: '3rem',
  color: '#FFF',
  fontWeight: 500,
  color: '#FFF', 
  backdropFilter: 'blur(4px)',
  backgroundColor: 'rgba(255, 255, 255, 0.02)',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  borderRadius: 5,display: 'flex', alignItems: 'center', justifyContent: 'center', p: 3, flexDirection: 'column'}}>
            <Typography variant='body' sx={{ textTransform: 'none', fontWeight: '700', textTransform: 'none', fontSize: ['2.2rem' ,'2.2rem' ,'2.2rem' ,'4.3rem'], lineHeight: ['2.5rem','2.5rem','2.5rem', '5rem'], color: '#fff', textAlign: 'center',  pt: 3}}>
              Ты тоже <span style={{ color: '#55FDFD' }}>сможешь</span> научиться!
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: [ 'column-reverse','row'], alignItems: ['center', 'center'], justifyContent: 'center', mt: 3 }}>
              <TrembleButton
                variant='contained'
                component={Link}
                to={'/dashboard'}
              >
                <span className="text-start-studying">Обучаться</span><span className="rocket">🚀</span>
              </TrembleButton>
            

            </Box>
        </Box>
      </Box>
      
    );
  }
}