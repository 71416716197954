import { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { Box, TextField, Button, Typography, Select, MenuItem, List, ListItem } from '@mui/material';
import { getFunctions } from "firebase/functions";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

export default function AdminUpdateMembershipComponent() {
  const [emails, setEmails] = useState('');
  const [membershipStatus, setMembershipStatus] = useState('active');
  const [logs, setLogs] = useState([]);

  const handleUpdateMembership = async () => {
    const functions = getFunctions();
    const updateMembershipFunction = httpsCallable(functions, 'updateMembershipStatus');
    const emailArray = emails.split(/[\s,]+/).filter(email => email.length > 0);
    const response = await updateMembershipFunction({ emails: emailArray, status: membershipStatus });
    setLogs(response.data.logs);
  };
  

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4">Admin Membership Update Page</Typography>
      <Box sx={{ marginY: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          label="Enter emails separated by comma"
          value={emails}
          onChange={e => setEmails(e.target.value)}
        />
      </Box>
      <Box sx={{ marginY: 2 }}>
        <Select
          fullWidth
          value={membershipStatus}
          onChange={e => setMembershipStatus(e.target.value)}
        >
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="disabled">Disabled</MenuItem>
        </Select>
      </Box>
      <Button variant="contained" color="primary" onClick={handleUpdateMembership}>Update Membership</Button>
      <Box sx={{ marginY: 2 }}>
      <Typography variant="h6">Logs</Typography>
      <List>
        {logs.map((log, index) => (
          <ListItem key={index}>
            {log.startsWith('Successfully') ? <CheckCircleOutlineIcon color="success" /> : <CancelIcon color="error" />}
            <Typography color={log.startsWith('Successfully') ? 'green' : 'red'}>
              {log}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  </Box>
);
}
