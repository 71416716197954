import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, useTheme, useMediaQuery, Container} from "@mui/material";
import { styled } from '@mui/material/styles';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
//
import LandingHeader from './LandingHeader';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 20;
const APP_BAR_DESKTOP = 60;

const HEADER_MOBILE = 50;
const HEADER_DESKTOP = 15;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundImage: '#FFF',  // added this line
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: `calc(${APP_BAR_MOBILE + 24}px + ${HEADER_MOBILE}px)`, // Updated paddingTop value
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: `calc(${APP_BAR_DESKTOP + 24}px + ${HEADER_DESKTOP}px)`,  // Updated paddingTop value
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const Tabs = styled(MuiTabs)(({theme}) => ({
  backdropFilter: 'blur(10px)',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  borderRadius: 10,
  "&::before": {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: -1,
  },
  minHeight: 44,
  minWidth: 100,
}));

  
  const Tab = styled(MuiTab)(({ theme }) => ({

      borderRadius: 10,
      minHeight: 44,
      minWidth: 96,
      '&.Mui-selected': {
        borderRadius: 10,
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.common.black,
      },
      '&:hover': {
        borderRadius: 10,
        backgroundColor: theme.palette.action.selected,
        color: "#FFF",
      },
      '&.MuiTab-root': {
        textTransform: 'initial',
        color: "#FFF",
        fontWeight: '400',
        
      },
      
      [theme.breakpoints.up('md')]: {
        minWidth: 120
      },
    }));
// ----------------------------------------------------------------------

export default function CatalogLayoutLanding() {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const defaultColor = '#FFFFFF'; // The color you want as default
  const homepageColor = '#000'; // The color you want for the Homepage
  useEffect(() => {
    // Set the background color when the component mounts
    document.body.style.backgroundColor = homepageColor;
  
    // Reset the background color when the component unmounts
    return () => {
      document.body.style.backgroundColor = defaultColor;
    };
  }, []);
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    navigate(newValue);
  };

  return (
    <StyledRoot>
      <LandingHeader isUserAuthenticated={false} openNav={open} onCloseNav={() => setOpen(false)} onOpenNav={() => setOpen(true)}/>

      <Main>
      <Container maxWidth="xl">
            <Box sx={{ mb: 5, px: 0, }}>

<Box sx={{mt: [2, 2, 2,],  display: 'flex', justifyContent: { xs: 'center', md: 'center'} }}>
  <Box sx={{ 
    border: '1px solid var(--stroke-grey, #E5E7E9)', 
    borderRadius: '10px', 
    display: 'inline-block'
  }}>
                <Tabs 
                  indicatorColor="transparent"
                  value={
                    location.pathname !== "/courses" &&
                    location.pathname !== "/courses/trading" &&
                    location.pathname !== "/courses/crypto" &&
                    location.pathname !== "/courses/investing"
                      ? false
                      : location.pathname
                  }
                  onChange={handleTabChange}
                >
                  <Tab value="/courses" label="Все курсы" />
                  <Tab value="/courses/trading" label="Трейдинг" />
                  <Tab value="/courses/crypto" label="Крипто" />
                  <Tab value="/courses/investing" label="Инвестиции" />
                </Tabs>
              </Box>

              </Box>
            </Box>
            </Container>
            <Outlet /> 
          </Main>
        </StyledRoot>
      );
  }