import React, { useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UserContext from './UserContext';

const PurchasedBasedRoute = ({ component: Component, children }) => {
  const account = useContext(UserContext);
  const navigate = useNavigate();
  const { courseId } = useParams();
  const hasAccess = account?.purchased?.includes(courseId);
  
  useEffect(() => {
    if (account && !hasAccess) {
    ////console.log('user', account);
    ////console.log('user does not have access to this course');
    navigate(`/dashboard/catalog/course/${courseId}`);
    }
  }, [hasAccess, navigate]);

  if (!hasAccess) return null;

  return <Component>{children}</Component>;
};

export default PurchasedBasedRoute;
