import React, { useEffect, useState } from 'react';
import { getDoc, doc, onSnapshot } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { db, auth } from './firebase'; // replace with the path to your Firestore instance
import UserContext from './UserContext';

export default function UserProvider({ children }) {
  const [account, setAccount] = useState(undefined);

  useEffect(() => {
    let unsubscribeFirestore;
    let unsubscribeAuth;

    unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if(user) {
        const userDocRef = doc(db, 'users', user.uid);
        unsubscribeFirestore = onSnapshot(userDocRef, (doc) => {
          if (doc.exists()) {
            const points = doc.data().hasOwnProperty('points') ? doc.data().points : 0;
            setAccount({
              ...doc.data(),
              points, // set the points
              email: user.email, // include the email from the Firebase Auth user
              avatarURL: doc.data().avatarURL || '/assets/images/avatars/user_avatar.png',
              name: doc.data().name || 'Defaust Trader',
              role: doc.data().role || 'default',
              admin: doc.data().admin || 'none'

            });
          } else {
            setAccount(null);
          }
        });
      } else {
        setAccount(null); // reset account data when user is undefined
      }
    });

    return () => {
      if (unsubscribeFirestore) {
        // Detach the Firestore listener
        unsubscribeFirestore();
      }
      if (unsubscribeAuth) {
        // Detach the Auth listener
        unsubscribeAuth();
      }
    }
  }, []);
  
  return (
    <UserContext.Provider value={account}>
      {children}
    </UserContext.Provider>
  );
}
