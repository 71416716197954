import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/logo';
import Iconify from '../../components/iconify';
// sections
import { RegistrationForm } from '../../sections/auth/login';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, db } from '../../firebase';
import { setDoc, doc, collection } from 'firebase/firestore';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));


const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(8, 0),
}));

// ----------------------------------------------------------------------

export default function RegistrationPage() {
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();
  async function getAccessToken() {
    const response = await fetch(
        "https://api.sendpulse.com/oauth/access_token",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                grant_type: "client_credentials",
                client_id: "d1918368a949e7670d0ed7295d46f0ec", // Replace with your client ID
                client_secret: "b3ab03c635b1d13a3b92be2936e59e9a", // Replace with your client secret
            }),
        }
    )

    const data = await response.json()
    if (!response.ok) {
        throw new Error(data.message || "Failed to obtain access token")
    }

    return data.access_token
}

  async function addSendPulseContact(email) {
    console.log('starting adding to sendpulse contact')
    const mailingListId = "596347" 
    const requestBody = {
      emails: [
          {
              email: email,
          },
      ],
  }
    try {
      console.log('trying to create access token')
        const accessToken = await getAccessToken();
        const response = await fetch(
          `https://api.sendpulse.com/addressbooks/${mailingListId}/emails`,
          {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`, // Include the access token here
              },
              body: JSON.stringify(requestBody),
          }
      )
      console.log('response', response)
        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.message || "Failed to add email to SendPulse contacts");
        }
    } catch (error) {
        console.error("SendPulse Error:", error.message);
        // Optionally set an error state to inform the user
    }
}

  const handleLoginWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      await addSendPulseContact(user.email);
      // You can add any additional setup for the user here,
      await setDoc(doc(db, 'customers', user.uid), { email: user.email });

      const usersCollection = collection(db, 'users');
      const userDoc = doc(usersCollection, user.uid);
      await setDoc(userDoc, { role: 'default' });
      ////console.log(user);
      navigate("/", { replace: true });
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <>
      <Helmet>
        <title> Регистрация | DEFAUST ACADEMY </title>
      </Helmet>

      <StyledRoot>

        <Container maxWidth="sm" sx={{
          mt: 2,
          color: '#FFF',
          borderRadius: 10,
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          border: '1px solid rgba(255, 255, 255, 0.2)',
        }}>
          <StyledContent>
            <Typography variant="h4" gutterBottom textAlign={'center'}>
              Зарегистрируйтесь 
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }} textAlign={'center'}>
            Уже есть аккаунт? {''}
                <Link
                    component="button"
                    variant="subtitle2"
                    underline="hover"
                    sx={{ color: '#FFF',}}
                    onClick={() => navigate('/login')}
                >
            Ввойти
            </Link>
            </Typography>

            <Stack direction="row" spacing={2} >
            <Button
              fullWidth
              size="large"
              color="inherit"
              variant="outlined"
              onClick={handleLoginWithGoogle}
            >
              <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
              <Typography variant="body2" sx={{ ml: 2, color: '#FFF', fontWeight: 700}}>
              Ввойти с помощью Google
              </Typography>
            </Button>
            </Stack>

            <Divider sx={{ my: 3,  color: 'rgba(255, 255, 255, 0.2)',}}>
              <Typography variant="body2" sx={{ color: '#FFF',}}>
                или
              </Typography>
            </Divider>

            <RegistrationForm />
          </StyledContent>
        </Container>


      </StyledRoot>
    </>
  );
}
