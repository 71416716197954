import React from 'react';
import { Box, Typography, LinearProgress, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const CourseBox = ({ course, navigate}) => (
 
<Grid item xs={12} sm={6} md={4}>
  
  <Box 
    onClick={() => navigate(`/dashboard/catalog/course/${course.id}`)}
    sx={{ 
      backgroundColor: '#f5f5f5', 
      p: '16px', 
      borderRadius: 2.5, 
      minHeight: '160px',
      display: 'flex', // add this line
      flexDirection: 'column', // add this line
      justifyContent: 'space-between', // add this line
      '&:hover': { backgroundColor: '#ededed', cursor: 'pointer' },
    }}
  >
     <Typography variant="body" sx={{ alignSelf: 'flex-start', fontSize: '12px', lineHeight: '16px' }}>Курс</Typography>
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography variant="h6" sx={{ alignSelf: 'flex-start' }}>{course.title}</Typography>
      <Box
        component="img"
        src={'assets/images/034-thesis.svg'}
        alt={'defaust обучение'}
        sx={{
          p: 1,
          borderRadius: 3,
          backgroundColor: '#A0C0FF50',
          height: '56px', // Adjust this according to your text size
          width: '56px',
          alignSelf: 'flex-start'
        }}
      />
    </Box>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px'}}>
    <Typography variant="body2"><span style={{fontWeight: 600}}>{course.lessons}</span> видео</Typography>

    </Box>
  </Box>
</Grid>
);

const FreeCoursesSection = ({courses}) => {
  const navigate = useNavigate();
  if (courses.length > 0) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', mt: [4,4,4,12] }}>
      <Typography variant="h4" gutterBottom sx={{pb: 3}}>
        Бесплатные курсы
      </Typography>
      <Grid container spacing={3}>
        {courses.map((course, index) => (
          <CourseBox key={index} course={course} navigate={navigate}/>
        ))}
      </Grid>
    </Box>
  );
};
}

export default FreeCoursesSection;
