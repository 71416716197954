import React, { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, createTheme, Typography, Container} from "@mui/material";
import { styled } from '@mui/material/styles';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
//
import Header from '../header';
import Nav from './nav';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 0;
const APP_BAR_DESKTOP = 0;

const HEADER_MOBILE = 50;
const HEADER_DESKTOP = 15;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundImage: 'linear-gradient(174.2deg, rgba(255,255,255,1) 7.1%, rgba(240,246,238,0.5) 67.4%)',  // added this line
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: `calc(${APP_BAR_MOBILE + 24}px + ${HEADER_MOBILE}px)`, // Updated paddingTop value
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: `calc(${APP_BAR_DESKTOP + 24}px + ${HEADER_DESKTOP}px)`,  // Updated paddingTop value
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const Tabs = styled(MuiTabs)(({theme}) => ({

        "&::before": {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -1
        },
    minHeight: 44,
    minWidth: 100,
  }));
  
  const Tab = styled(MuiTab)(({ theme }) => ({
        borderRadius: 10,
      minHeight: 44,
      minWidth: 96,
      '&.Mui-selected': {
        borderRadius: 10,
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.common.black,

      },
      '&.MuiTab-root': {
        textTransform: 'initial',
        color: theme.palette.text.primary,
      },
      [theme.breakpoints.up('md')]: {
        minWidth: 120
      },
    }));
// ----------------------------------------------------------------------

export default function AdminLayout() {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    navigate(newValue);
  };

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      <Main>
      <Container maxWidth="xl">
            <Box sx={{ mt: 0, mb: 5, px: 0, }}>
            <Typography variant="h3" sx={{ mt: 0, mb: 0 }}>
Курсы и программы
</Typography>
              <Box sx={{ mt: 3, mb: 0 }}> 

                <Tabs 
                indicatorColor="transparent"
                  value={
                    location.pathname !== "/admin/courses" &&
                    location.pathname !== "/admin/certifications"
                      ? false
                      : location.pathname
                  } 
                  onChange={handleTabChange}
                >
                  <Tab value="/admin/courses" label="Курсы" />
                  <Tab value="/admin/certifications" label="Сертификации" />
                </Tabs>
              </Box>
            </Box>
            </Container>
            <Outlet /> 
          </Main>
        </StyledRoot>
      );
  }