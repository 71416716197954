import { Box, Typography, Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTour } from '../../../TourProvider';
function ProgramSection({navigate, account}) {
  const { isTourOpen, setTourOpen } = useTour();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleLearnClick = () => {
    navigate(`/dashboard/catalog/certification/defaust-trader`);
  };
  const handleTariff = (courseId) => {
    // Open the URL in a new window or tab
    window.open("https://defaust.com/pricing", '_blank');
};

  function identifyDaytime() {
    const currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 11) {
      return "отличное утро";
    } else if (currentHour >= 11 && currentHour < 18) {
      return "хороший день";
    } else if (currentHour >= 18 && currentHour < 24) {
      return "прекрасный вечер";
    } else {
      return "хорошая ночь";
    }
  }

  const boxStyle = {

    borderRadius: 5,

    display: 'flex',
    flexDirection: 'column'
  };

  if (isMobile) {
        return (
          <Grid container spacing={3} sx={{ mt: '0px', mb: '64px' }}>
          <Grid item xs={12} >
            <Box sx={{ height: '100%' }}>
            <Box sx={{...boxStyle, }}>
                {/* Course name */}
                <Typography variant="body" sx={{fontSize: ['26px','26px','26px','32px'], fontWeight: 'medium', lineHeight: ['34px','34px','34px','42px']}}>{account.name}, сегодня {identifyDaytime()}, чтобы научиться трейдингу.</Typography>
                {/* Course description */}
                <Box sx={{display: 'flex', justifyContent: 'center', py: '24px'}}>
                <Box
                    component="img"
                    src={'assets/images/man-trade.svg'}
                    alt={'defaust обучение'}
                    sx={{
                      width: '50%',
                      height: 'auto',
                    }}
                  />
                  </Box>
                <Typography variant="body" sx={{mt: 2,fontSize: ['16px','16px','16px','18px'], fontWeight: '400', lineHeight: ['24px','24px','24px','28px']}}>
                  DEFAUST TRADER® – это полноценная программа обучения для становления профессиональным трейдером на рынке FOREX, фьючерсов и криптовалют.
                </Typography>
                <Box sx={{display: 'flex', flexDirection: ['column','column','column','row'], gap: [0,0,0,2] }}>
                {/* Button */}
                <Box sx={{display: 'inline-flex' }}>
                <Button  className="my-first-step" variant="outlined" color="primary" sx={{ width: ['100%','100%','100%', 'auto'], boxShadow: '3px 3px 1px 1px rgba(24, 24, 24, 0.25)', color: '#000', background: '#A0C0FF' ,fontSize: ['14px','14px','14px','15px'], fontWeight: '600', lineHeight: ['16px','16px','16px','20px'], px: '20px', mt: 3,  borderRadius: 3, py: '15px', textTransform: 'none',
                     '&:hover': {
                      color: '#000', background: '#A0C0FF', 
                     }}}onClick={() => handleLearnClick()}>
                    Перейти к программе
                </Button>
                </Box>
                <Box sx={{display: 'inline-flex' }}>
                <Button variant="text" color="primary" sx={{ fontWeight: 400, width: ['100%','100%','100%', 'auto'],color: '#2E2E2E', background: 'transparent' ,fontSize: ['14px','14px','14px','15px'],  lineHeight: ['16px','16px','16px','20px'], mt: 3,  borderRadius: 3, py: '15px', textTransform: 'none', px: '20px',
                     '&:hover': {
                      color: 'white', background: '#2E2E2E', 
                     }}} onClick={() => handleTariff()}>
                    Перейти к тарифам обучения
                </Button>
                </Box>
              </Box>
   
            </Box>
            </Box>
              </Grid>
            </Grid>
        );
}
  if (!isMobile) {
        return (
          <Grid container spacing={3} sx={{ mt: '64px', mb: '80px' }}>
          <Grid item xs={12} sm={6} md={7}>
            <Box sx={{ height: '100%' }}>
            <Box sx={{...boxStyle, }}>
                {/* Course name */}
                <Typography variant="body" sx={{fontSize: ['26px','26px','26px','32px'], fontWeight: 'medium', lineHeight: ['34px','34px','34px','42px']}}>{account.name}, сегодня {identifyDaytime()}, чтобы научиться трейдингу.</Typography>
                {/* Course description */}
                <Typography variant="body" sx={{mt: 2,fontSize: ['16px','16px','16px','18px'], fontWeight: '400', lineHeight: ['24px','24px','24px','28px']}}>
                  DEFAUST TRADER® – это полноценная программа обучения для становления профессиональным трейдером на рынке FOREX, фьючерсов и криптовалют.
                </Typography>
                <Box sx={{display: 'flex', flexDirection: ['column','column','column','row'], gap: [0,0,0,2] }}>
                {/* Button */}
                <Box sx={{display: 'inline-flex' }}>
                <Button  className="#my-second-step" variant="outlined" color="primary" sx={{ width: ['100%','100%','100%', 'auto'], boxShadow: '3px 3px 1px 1px rgba(24, 24, 24, 0.25)', color: '#000', background: '#A0C0FF' ,fontSize: ['14px','14px','14px','15px'], fontWeight: '600', lineHeight: ['16px','16px','16px','20px'], px: '20px', mt: 3,  borderRadius: 3, py: '15px', textTransform: 'none',
                     '&:hover': {
                      color: '#000', background: '#A0C0FF', 
                     }}} onClick={() => handleTariff()}>
                    
                    Тарифы обучения
                </Button>
                </Box>
                <Box sx={{display: 'inline-flex' }}>
                <Button variant="text" color="primary" sx={{ width: ['100%','100%','100%', 'auto'],color: '#2E2E2E', background: 'transparent' ,fontSize: ['14px','14px','14px','15px'], fontWeight: '400', lineHeight: ['16px','16px','16px','20px'], mt: 3,  borderRadius: 3, py: '15px', textTransform: 'none', px: '20px',
                     '&:hover': {
                      color: 'white', background: '#2E2E2E', 
                    }}} onClick={() => handleLearnClick()}>
                    Перейти к программе
                </Button>
                </Box>
              </Box>
   
            </Box>
            </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <Box sx={{ height: '100%', position: 'relative' }}>
                  <Box
                    component="img"
                    src={'assets/images/man-trade.svg'}
                    alt={'defaust обучение'}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
        );
}

}


export default ProgramSection;
