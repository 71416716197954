import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
// @mui
import { styled, alpha} from '@mui/material/styles';
import { Box, createTheme, Typography, Grid} from "@mui/material";
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
//
import Header from './header';
import Nav from './nav';
import hi_icon from './header/hand-hi.svg'

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 20;
const APP_BAR_DESKTOP = 20;

const HEADER_MOBILE = 40;
const HEADER_DESKTOP = 60;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundImage: '#FFF',  // added this line
});


const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'hidden',
  minHeight: '100%',
  
  paddingTop: `calc(${APP_BAR_MOBILE + 24}px + ${HEADER_MOBILE}px)`, // Updated paddingTop value
  paddingBottom: theme.spacing(10),

  [theme.breakpoints.up('lg')]: {
    paddingTop: `calc(${APP_BAR_DESKTOP + 24}px + ${HEADER_DESKTOP}px)`,  // Updated paddingTop value
  },
}));


// ----------------------------------------------------------------------

export default function OtherPageLayout() {
  const [open, setOpen] = useState(false);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)}/>

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>

        <Outlet />

      </Main>
    </StyledRoot>
  );
}
