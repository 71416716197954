import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../../firebase';
import Nav from '../nav-course';
import { collection, getDocs, query, where, doc, getDoc, onSnapshot} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Header from '../header';
import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';
import Footer from '../footer/footer';
import Skeleton from '@mui/material/Skeleton';
import { Box, Drawer } from '@mui/material';
function CourseTakingPage() {
  const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const { courseId } = useParams();
    const [course, setCourse] = useState(null);
    const [lessons, setLessons] = useState([]);
    const [structure, setStructure] = useState([]);
    const [quiz, setQuiz] = useState([]);
    const [exam, setExam] = useState([]);
    const [completedItems, setCompletedItems] = useState([]);
    const auth = getAuth();
    const user = auth.currentUser;
    const APP_BAR_MOBILE = 0;
    const APP_BAR_DESKTOP = 0;
    const NAV_WIDTH = 320;
    const HEADER_MOBILE = 50;
    const HEADER_DESKTOP = 15;
    
    
    const StyledRoot = styled('div')({
      display: 'flex',
      minHeight: '100%',
      overflow: 'hidden',
    });
    
    const Main = styled('div')(({ theme }) => ({
      flexGrow: 1,
      overflow: 'auto',
      minHeight: '100%',
      
      paddingTop: `calc(${APP_BAR_MOBILE + 24}px + ${HEADER_MOBILE}px)`, // Updated paddingTop value
      paddingBottom: theme.spacing(10),
    
      [theme.breakpoints.up('lg')]: {
        paddingTop: `calc(${APP_BAR_DESKTOP + 24}px + ${HEADER_DESKTOP}px)`,  // Updated paddingTop value
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    }));
    if (!user) {
        throw new Error("No user is signed in.");
    }

    const userId = user.uid;
    async function fetchCompletedItems(userId, courseId) {
      const userRef = doc(db, 'users', userId);
      const userSnap = await getDoc(userRef);
    
      if (!userSnap.exists) {
        console.error('User not found');
        return null;
      }
    
      const userData = userSnap.data();
      const courseData = userData.completed?.courses?.[courseId];
      
      if (!courseData) {
        ////console.log('No completed data for this course');
        return {
          completedLessons: [],
          completedQuiz: [],
          completedExam: []
        };
      }
      
      return {
        completedLessons: courseData.lessons,
        completedQuiz: courseData.quiz,
        completedExam: courseData.exam
      };
    }
    
    useEffect(() => {
      let isMounted = true; // To track the mounted state
  
      const fetchCourseData = async () => {
          try {
          setLoading(true);
            // Fetch the course data based on courseId field
            const courseCollection = collection(db, 'courses');
            const courseSnapshot = await getDocs(query(courseCollection, where('id', '==', courseId)));

            if (!courseSnapshot.empty) {
                const courseData = courseSnapshot.docs[0].data();
                setCourse(courseData);
                setStructure(courseData.structure); // set structure data
                const courseDocumentRef = doc(db, 'courses', courseSnapshot.docs[0].id);

                // Lessons
                const lessonSnapshot = await getDocs(collection(courseDocumentRef, 'lessons'));
                ////console.log('lessonSnapshot',lessonSnapshot.docs.map(doc => doc.data()));
                setLessons(lessonSnapshot.docs.map(doc => doc.data()));

                // Quiz - Assuming there can be multiple quizzes, adjust if needed
                const quizSnapshot = await getDocs(collection(courseDocumentRef, 'quiz'));
                if (!quizSnapshot.empty) {
                  setQuiz(quizSnapshot.docs.map(doc => doc.data()));
                }

                // Exam - Assuming there can be multiple exams, adjust if needed
                const examSnapshot = await getDocs(collection(courseDocumentRef, 'exam'));
                if (!examSnapshot.empty) {
                  setExam(examSnapshot.docs.map(doc => doc.data()));
                }
            } else {
                ////console.log("Course not found!");
            }
            const completedItemsReturn = await fetchCompletedItems(userId, courseId);
            if (isMounted) setCompletedItems(completedItemsReturn);
        } catch (error) {
            console.error('There was an error fetching the data:', error);
        } finally {
            if (isMounted) setLoading(false);
        }
    };

        fetchCourseData();

        return () => { isMounted = false }; // Cleanup on unmount
    }, [courseId, userId]);


    useEffect(() => {
      const docRef = doc(db, 'users', userId);
    
      const unsubscribe = onSnapshot(docRef, async (doc) => {
        if (doc.exists()) {
          const newCompletedItems = await fetchCompletedItems(userId, courseId);
          setCompletedItems(newCompletedItems); // Update state here
        } else {
          ////console.log("No such document!");
        }
      });
      
      return () => unsubscribe(); // Cleanup
    }, [userId, courseId]);
    

    
////console.log('completedItems', completedItems);
if (loading) {
  return (
<>
<Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      <Drawer
        open
        variant="permanent"
        PaperProps={{
          sx: {
            width: NAV_WIDTH,
            bgcolor: '#F5F7F9',
          },
        }}
      >
        <Box
          sx={{
            flexShrink: { lg: 0 },
            width: { lg: NAV_WIDTH },
            display: 'flex',
            flexDirection: 'column',
            height: 1,
          }}
        >
          <Box sx={{ mt: 5, ml: 11, pb: 0, alignContent: 'center', justifyContent: 'center'}}>
            <Skeleton variant="rectangular" width={145} height={30} />
          </Box>
          <Box sx={{ mt: 3, ml: 2, mr: 2 }}>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="60%" />
          </Box>
          <Box sx={{ mt: 3, ml: 2, mr: 2 }}>
            <Skeleton variant="text" width="30%" height={50} />
          </Box>
          <Box sx={{ mt: 3, ml: 2, mr: 2 }}>
            {[...Array(5)].map((_, index) => (
              <Skeleton key={index} variant="text" width="90%" height={90} />
            ))}
          </Box>
        </Box>
      </Drawer>
    </Box>
</>
  );
  }

    return (
      <StyledRoot>
          <Header onOpenNav={() => setOpen(true)}/>
            <Nav openNav={open} onCloseNav={() => setOpen(false)} course={course} lessons={lessons} quiz={quiz} exam={exam} structure={structure} completedItems={completedItems} />
            <Main>
        <Outlet />
      </Main>

    </StyledRoot>
    );
}

export default CourseTakingPage;
