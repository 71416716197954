import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { useContext, useState } from 'react';
import { Grid, Container, Typography, Box} from '@mui/material';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import styled from '@emotion/styled';
import { db } from '../../firebase.js';
import { collection, getDocs} from 'firebase/firestore';
// sections
import { VideoSection,
        VideoSectionPromo,
        MeetingSection,
        EventsRowList,
        AppCalendar} from '../../sections/index.js';
// utils
import { roundValue } from '../../utils/roundValue.js';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../UserContext.js';
import useFetchEvents from '../../hooks/useFetchEvents.js'
// ----------------------------------------------------------------------

export default function Recaps() {
  const account = useContext(UserContext);
  const navigate = useNavigate();
  const { events, loadingEvents, error } = useFetchEvents();
  
  const currentDate = new Date();
  const twoHoursAgo = new Date(currentDate.getTime() - 2 * 60 * 60 * 1000);
  const currentMonth = currentDate.getMonth();

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  
  // Filtering functions
    const isUpcomingEvent = (eventDate) => 
    (eventDate >= twoHoursAgo || eventDate >= currentDate);
    
    const isPastEvent = (eventDate) => 
    eventDate <= twoHoursAgo || eventDate <= currentDate;

    const isAttending = (event) => 
    account && account.attendingMeetings && Array.isArray(account.attendingMeetings) && account.attendingMeetings.includes(event.id);

    const isRecap = (event) => 
    event.tags && Array.isArray(event.tags) && event.tags.includes('recap');
    
    const isDT3 = (event) => 
    event.tags && Array.isArray(event.tags) && event.tags.includes('DT3.0');


  // Filters
  const sortEvents = (eventsArray) => {
    return eventsArray.sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate());
  };

  const sortEventsAsc = (eventsArray) => {
    return eventsArray.sort((a, b) => a.timestamp.toDate() - b.timestamp.toDate());
  };
  
  const upcomingEvents = events.filter(event => isUpcomingEvent(event.timestamp.toDate()));
  const sortedUpcomingEvents = sortEventsAsc(upcomingEvents);
  
  const myEvents = upcomingEvents.filter(isAttending);
  const sortedMyEvents = sortEvents(myEvents);
  
  const pastEvents = events.filter(event => isPastEvent(event.timestamp.toDate()));
  const sortedPastEvents = sortEvents(pastEvents);
  
  const recaps = events.filter(event => isRecap(event));
  const sortedRecaps = sortEvents(recaps);
  
  const DT3 = events.filter(event => isDT3(event));
  const sortedDT3Recaps = sortEventsAsc(DT3);

  //console.log(upcomingEvents);
  const mockData = [
    {
      id: 1,
      title: 'Recap 21.08 – 25.08',
      date: '2023-08-21',
      videoUrl: 'https://vimeo.com/864457890?share=copy'
    },
    {
      id: 2,
      title: 'Recap 21.07  – 28.07',
      date: '2023-07-28',
      videoUrl: 'https://vimeo.com/864457890?share=copy'
    },
    {
      id: 3,
      title: 'Recap 17.07 – 21.07',
      date: '2023-07-21',
      videoUrl: 'https://vimeo.com/864457890?share=copy'
    },
    {
      id: 4,
      title: 'Recap 10.07 – 14.07',
      date: '2023-07-14',
      videoUrl: 'https://vimeo.com/864457890?share=copy'
    },
    // ... more data
  ];
  
  const Tabs = styled(MuiTabs)(({theme}) => ({

    "&::before": {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: -1
    },

minHeight: 44,
minWidth: 96,
}));

const Tab = styled(MuiTab)(({ theme }) => ({
    borderRadius: 10,
  minHeight: 44,
  minWidth: 96,
  marginLeft: 2.5,
  marginRight: 2.5,
  marginTop: 5,
  '&.Mui-selected': {
    border: '1px solid rgba(0, 0, 0, 0.5)',
    borderRadius: 10,
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.common.black,

  },
  '&.MuiTab-root': {
    border: '1px solid rgba(0, 0, 0, 0.5)',
    borderRadius: 10,
    textTransform: 'initial',
    color: theme.palette.text.primary,
  },
  [theme.breakpoints.up('md')]: {
    minWidth: 120
  },
}));

if (loadingEvents === true) {
  return (
    <>
      <Helmet>
        <title>Конференции | DEFAUST</title>
      </Helmet>
      
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">

      </Box>
    </>
  );
} else {
  return (
    <>
      <Helmet>
        <title>Конференции | DEFAUST</title>
      </Helmet>

      <Container maxWidth="lg">
        <AppCalendar className=".step2" navigate={navigate} events={events} db={db} account={account}/>
        <Tabs 
          allowScrollButtonsMobile={true}
          scrollable={true}
          centered
          indicatorColor="transparent"
          value={activeTab} 
          onChange={handleTabChange} 
          sx={{
            mt: [0,4,6,8], 
            pb: 2,
            flexWrap: 'wrap',
            '.MuiTabs-flexContainer': {
              flexWrap: 'wrap',
            }
          }}
        >
          <Tab label="Будущие" />
          <Tab label="Прошлые" />
          <Tab label="Recaps" />
          <Tab label="DEFAUST TRADER 3.0" />
          <Tab label="Избранные" />
        </Tabs>

          {activeTab === 4 && (
            <>
        {myEvents && myEvents.length > 0 && (
          <>
        <Typography variant='h4'sx={{mb: ['25px', '25px', '25px', '30px'], mt: ['25px', '25px', '25px', '30px']}}>Избранные конференции</Typography>
        <EventsRowList navigate={navigate} events={sortedMyEvents} db={db} account={account}/>
            </>
          )}
          </>
        )}
        {activeTab === 0 && (
            <>
        {upcomingEvents && upcomingEvents.length > 0 && (
          <>
        <Typography variant='h4'sx={{mb: ['25px', '25px', '25px', '30px'], mt: ['25px', '25px', '25px', '30px']}}>Будущие конференции</Typography>
          <EventsRowList navigate={navigate} events={sortedUpcomingEvents} db={db} account={account}/>
          </>
          )}
          </>
        )}
        {activeTab === 1 && (
            <>
        {pastEvents && pastEvents.length > 0 && (
          <>
        <Typography variant='h4'sx={{mb: ['25px', '25px', '25px', '30px'], mt: ['25px', '25px', '25px', '30px']}}>Прошлые конференции</Typography>
          <EventsRowList navigate={navigate} events={sortedPastEvents} db={db} account={account}/>
          </>
          )}
          </>
        )}
        {activeTab === 2 && (
            <>
        {recaps.length > 0 && (
          <>
        <Typography variant='h4'sx={{mb: ['25px', '25px', '25px', '30px'], mt: ['25px', '25px', '25px', '30px']}}>Прошлые рекапы</Typography>
          <EventsRowList navigate={navigate} events={sortedRecaps} db={db} account={account}/>
            </>
          )}
          </>
        )}
        {activeTab === 3 && (
            <>
        {sortedDT3Recaps.length > 0 && (
          <>
        <Typography variant='h4'sx={{mb: ['25px', '25px', '25px', '30px'], mt: ['25px', '25px', '25px', '30px']}}>DEFAUST TRADER 3.0</Typography>
          <EventsRowList navigate={navigate} events={sortedDT3Recaps} db={db} account={account}/>
            </>
          )}
          </>
        )}  
      </Container>
    </>
  );
}}