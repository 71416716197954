import React, { useEffect } from 'react';
import { Link as RouterLink, Link } from 'react-router-dom';
import ButtonBase from '@mui/material/ButtonBase';
import { StyledNavItem, StyledNavItemIcon } from '../../components/nav-section/styles';
import { Typography, AppBar, Toolbar, Button, IconButton, Drawer, List, ListItem, Container, useMediaQuery, useTheme, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../components/logo/Logo';
import { styled, alpha } from '@mui/material';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { SocialIcon } from 'react-social-icons/component'
import background_image from '../../sections/assets/back-main.png';
const NAV_WIDTH = 290;

const MARGIN_TOP = 15;
const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 80;

const TrembleButton = styled(Button)(({ theme }) => ({
  color: '#2E2E2E',
  fontSize: '1.05rem',
  fontWeight: '400',
  backgroundColor: '#FFF',
  borderRadius: 50,
  paddingRight: 30,
  paddingLeft: 30,
  paddingTop: 12,
  paddingBottom: 12,
  boxShadow: '0px 3px 2px 0px rgba(0, 0, 0, 0.30)',
  '&:hover': {
    boxShadow: '0px 2px 1px 0px rgba(0, 0, 0, 0.30)',
    backgroundColor: '#FFFFFF99',
  },
  '& .rocket': {
    transition: 'all 1s ease-in-out',
    marginLeft: '4px',
    fontSize: '1rem',
  },
  '&:hover .rocket': {
    animation: 'tremble 0.7s ease-in-out infinite',
  },
  '@keyframes tremble': {
    '0%, 100%': {
      transform: 'translate(0, 0)',
    },
    '25%': {
      transform: 'translate(4px, 2px)',
    },
    '50%': {
      transform: 'translate(-3px, -3px)',
    },
    '75%': {
      transform: 'translate(4px, 1px)',
    },
  },
}));

const StyledAppBar = styled((props) => <AppBar variant="dense" position="absolute" {...props} />)(({ theme }) => ({
  backgroundColor: 'transparent',
  paddingBottom: '15px',
  marginTop: MARGIN_TOP,
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.down('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 2.5),
  },
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 3.5),
  },
  width: '100%',
  top: 0,  // Ensures that the AppBar sticks to the top
}));



const MenuContainer = styled('div')(({ theme }) => ({
  borderRadius: '20px',
  paddingBottom: '2px',

}));

LandingHeader.propTypes = {
  isUserAuthenticated: PropTypes.bool,
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
  onOpenNav: PropTypes.func
};

export default function  LandingHeader ({ scrollToRef,aboutStrategy, successStory, howYouWillLearn, community, forWhat, prices, isUserAuthenticated,  openNav, onCloseNav, onOpenNav  }) {
  const theme = useTheme();
  
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { pathname } = useLocation();


  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const MenuItemButton = styled(Button)(({ theme }) => ({
    color: '#2E2E2E',
    textAlignment: 'start',
    justifyContent: 'start',
    fontSize: '0.9rem',
    fontWeight: '700',
    marginTop: '10px',
    marginRight: 15,
    paddingLeft: 10,
    paddingRight: 10,
    textTransform: 'none',
    borderRadius: 10,
    border: '0px solid rgba(209, 213, 219, 0.2)',
    backdropFilter: 'blur(4px)',
    '&:hover': {
      backgroundColor: alpha('#2E2E2E99', 0.2),
    },
    '&:active': {
      border: '1px solid rgba(209, 213, 219, 0.2)',
      backgroundColor: 'transparent',
    },
  }));

  return (
    <>

      <StyledAppBar >
        <MenuContainer >
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mt: 2}}>

            {/* Left Section: Logo, Menu Items, and Mobile Menu Toggle */}
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'flex-start' }}>
              {isMobile && (<>
                  <IconButton
                    edge="start"
                    color="#2E2E2E"
                    aria-label="menu"
                    sx={{ ml: 1, mt: 1 }}
                    onClick={openNav ? onCloseNav : onOpenNav}
                  >
                    <MenuIcon />
                  </IconButton>

                  </>
                )}
              <Logo landing={true} logoColor={true} customSize={[60, 60]} />
              {!isMobile && (
              <Logo landing={true} white={true} customSize={[90, 60]} />
              )}

              </Box>
            </Box>
            {/* Right Section: Text and Button */}
            <Box sx={{ display: 'flex', flexDirection: [ 'column-reverse','row'], alignItems: ['end', 'center'] }}>

              <TrembleButton
                variant='contained'
                component={Link}
                to={isUserAuthenticated ? 'https://academy.defaust.com' : '/dashboard'}
              >
                {isUserAuthenticated ?
                  (
                    <>
                      <span className="text-start-studying">Перейти в приложение</span><span className="rocket">🚀</span>
                    </>
                  ):(
                    <>
                      <span className="text-start-studying">Обучаться</span><span className="rocket">🚀</span>
                    </>
                  )
                }
              </TrembleButton>
            

            </Box>
          </Toolbar>
        </MenuContainer>
      </StyledAppBar>

      {isMobile && (
  <Drawer 
  open={openNav}
  onClose={onCloseNav}
  sx={{
   
    '.MuiDrawer-paper': {
      width: NAV_WIDTH,
      borderRadius: "0px 25px 25px 0px",
      backdropFilter: 'blur(8px)',
      backgroundColor: alpha('#fff', 0.9),
      boxShadow: '2px 6px 5px 0px rgba(0, 0, 0, 0.40)',
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
  }}
  ModalProps={{
    keepMounted: true,
    disableBackdropTransition: true,
    disableEnforceFocus: true,
  }}
>
<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', pt: 2, }}>
<Box sx={{  display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', width: '100%', justifyContent: 'center',}}>
              <Box sx={{  display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center'}}>
                <Logo landing={true} logoColor={true} customSize={[45,45]}/> {/* Assuming Logo is another component */}
                <Logo  landing={true} customSize={[90,60]}/> {/* Assuming Logo is another component */}
              </Box>
              </Box>
              <List sx={{ display: 'flex', flexDirection: 'column', gap: 1, px: 3,pt: 4, }}>

<ButtonBase onClick={() => scrollToRef(aboutStrategy)} >
  <StyledNavItem sx={{
    border: '1px solid rgba(255, 255, 255, 0.1)',
    py: 3,
    color: '#2E2E2E', 
    background: alpha('#2E2E2E', 0.1),
    '&:hover': { background: alpha('#2E2E2E', 0.2) },
  }}>
    <ListItem sx={{ color: '#2E2E2E',  pl: 3,fontSize: '0.9rem', fontWeight: '700'}}>
    Про Defaust Strategy
    </ListItem>
  </StyledNavItem>    
</ButtonBase>
<ButtonBase onClick={() => scrollToRef(howYouWillLearn)} >
  <StyledNavItem sx={{
    border: '1px solid rgba(255, 255, 255, 0.1)',
    py: 3,
    color: '#2E2E2E', 
    background: alpha('#2E2E2E', 0.1),
    '&:hover': { background: alpha('#2E2E2E', 0.2) },
  }}>
    <ListItem sx={{ color: '#2E2E2E', pl: 3, fontSize: '0.9rem', fontWeight: '700'}}>
    <Box sx={{  display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
    <Typography sx={{fontSize: '0.9rem', fontWeight: '700'}}>Как проходит обучение</Typography>
      </Box>
    </ListItem>
  </StyledNavItem>     
</ButtonBase>
<ButtonBase onClick={() => scrollToRef(successStory)} >
  <StyledNavItem sx={{
    border: '1px solid rgba(255, 255, 255, 0.1)',
    py: 3,
    color: '#2E2E2E', 
    background: alpha('#2E2E2E', 0.1),
    '&:hover': { background: alpha('#2E2E2E', 0.2) },
  }}>
    <ListItem sx={{ color: '#2E2E2E', pl: 3, fontSize: '0.9rem', fontWeight: '700'}}>
    <Box sx={{  display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
    <Typography sx={{fontSize: '0.9rem', fontWeight: '700'}}>Отзывы и результаты</Typography>
      </Box>
    </ListItem>
  </StyledNavItem>     
</ButtonBase>
<ButtonBase onClick={() => scrollToRef(community)} >
  <StyledNavItem sx={{
    border: '1px solid rgba(255, 255, 255, 0.1)',
    py: 3,
    color: '#2E2E2E', 
    background: alpha('#2E2E2E', 0.1),
    '&:hover': { background: alpha('#2E2E2E', 0.2) },
  }}>
    <ListItem sx={{ color: '#2E2E2E', pl: 3, fontSize: '0.9rem', fontWeight: '700'}}>
    <Box sx={{  display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
    <Typography sx={{fontSize: '0.9rem', fontWeight: '700'}}>Комьюнити и ментор</Typography>
      </Box>
    </ListItem>
  </StyledNavItem>     
</ButtonBase>
<ButtonBase onClick={() => scrollToRef(forWhat)} >
  <StyledNavItem sx={{
    border: '1px solid rgba(255, 255, 255, 0.1)',
    py: 3,
    color: '#2E2E2E', 
    background: alpha('#2E2E2E', 0.1),
    '&:hover': { background: alpha('#2E2E2E', 0.2) },
  }}>
    <ListItem sx={{ color: '#2E2E2E', pl: 3, fontSize: '0.9rem', fontWeight: '700'}}>
    <Box sx={{  display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
    <Typography sx={{fontSize: '0.9rem', fontWeight: '700'}}>Цель проекта DEFAUST</Typography>
      </Box>
    </ListItem>
  </StyledNavItem>     
</ButtonBase>
<ButtonBase onClick={() => scrollToRef(prices)} >
      <StyledNavItem sx={{
        border: '1px solid rgba(255, 255, 255, 0.1)',
        py: 3,
        color: '#2E2E2E', 
        background: alpha('#2E2E2E', 0.1),
        '&:hover': { background: alpha('#2E2E2E', 0.2) },
      }}>
        <ListItem sx={{ color: '#2E2E2E', pl: 3, fontSize: '0.9rem', fontWeight: '700'}}>
        <Box sx={{  display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
        <Typography sx={{fontSize: '0.9rem', fontWeight: '700'}}>Cтоимость</Typography>
          </Box>
        </ListItem>
      </StyledNavItem>     
    </ButtonBase>
{
  isUserAuthenticated ? (
    <Button sx={{background: '#2E2E2E',
    border: '1px solid rgba(255, 255, 255, 0.5)',borderRadius: 3, py: 1.5, mt: 2, fontSize: '1rem'}} variant={'contained'} component={RouterLink} to="/login">
      Перейти в приложение 🚀
    </Button>
  ) : (
    <Button sx={{background: '#2E2E2E',
    border: '1px solid rgba(255, 255, 255, 0.5)',borderRadius: 3, py: 1.5, mt: 2, fontSize: '1rem'}} variant={'contained'} component={RouterLink} to="/login">
      Обучаться 🚀
    </Button>
  )
}
</List>

</Box>

  </Drawer>
)}
    </>
  );
};
