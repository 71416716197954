import React, { useState, useEffect, useContext } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import UserContext from '../../UserContext';
// @mui
import { styled, alpha} from '@mui/material/styles';
import { useTheme, Box, Typography, Grid} from "@mui/material";
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
//
import {useMediaQuery} from '@mui/material';
import Header from './header';
import Nav from './nav';
import { ProfileSidebar } from '../../sections/@dashboard/app';
// ----------------------------------------------------------------------
const APP_BAR_MOBILE = 20;
const APP_BAR_DESKTOP = 20;

const HEADER_MOBILE = 40;
const HEADER_DESKTOP = 60;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundImage: '#FFF',  // added this line
});


const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'hidden',
  minHeight: '100%',
  
  paddingTop: `calc(${APP_BAR_MOBILE + 24}px + ${HEADER_MOBILE}px)`, // Updated paddingTop value
  paddingBottom: theme.spacing(10),

  [theme.breakpoints.up('lg')]: {
    paddingTop: `calc(${APP_BAR_DESKTOP + 24}px + ${HEADER_DESKTOP}px)`,  // Updated paddingTop value
  },
}));
// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const theme = useTheme();
  const account = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      setTabIndex(0);
    } else if (location.pathname === "/dashboard/courses") {
      setTabIndex(1);
    }
  }, [location.pathname]);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)}/>
  
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
  
      <Main>

  <Outlet />




        
{/*         <Grid item xs={12} sm={12} md={3} lg={3}>
          <ProfileSidebar />
        </Grid> */}

    </Main>
  </StyledRoot>
);
}
