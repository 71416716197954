import { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { Box, TextField, Button, Typography, List, ListItem } from '@mui/material';
import { getFunctions } from "firebase/functions";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

export default function AdminGrantMentorshipComponent() {
  const [email, setEmail] = useState('');
  const [logs, setLogs] = useState([]);

  const handleGrantAccess = async () => {
    const functions = getFunctions();
    const grantCourseAccessFunction = httpsCallable(functions, 'grantMentorship');
    const response = await grantCourseAccessFunction({ email });
    setLogs(response.data.logs);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4">Grant Course Access</Typography>
      <Box sx={{ marginY: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          label="Enter email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </Box>
      <Button variant="contained" color="primary" onClick={handleGrantAccess}>
        Grant Access
      </Button>
      <Box sx={{ marginY: 2 }}>
        <Typography variant="h6">Logs</Typography>
        <List>
          {logs.map((log, index) => (
            <ListItem key={index}>
              {log.startsWith('Successfully') ? <CheckCircleOutlineIcon color="success" /> : <CancelIcon color="error" />}
              <Typography color={log.startsWith('Successfully') ? 'green' : 'red'}>
                {log}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
}
