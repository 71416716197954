import React, { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, Typography} from "@mui/material";
import { styled } from '@mui/system';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import Header from '../dashboard/header';
import Nav from '../dashboard/nav';
import { alpha } from '@mui/material/styles';

const APP_BAR_MOBILE = 20;
const APP_BAR_DESKTOP = 20;

const HEADER_MOBILE = 40;
const HEADER_DESKTOP = 60;
const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundImage: '#FFF',  // added this line
});


const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'hidden',
  minHeight: '100%',
  
  paddingTop: `calc(${APP_BAR_MOBILE + 24}px + ${HEADER_MOBILE}px)`, // Updated paddingTop value
  paddingBottom: theme.spacing(10),

  [theme.breakpoints.up('lg')]: {
    paddingTop: `calc(${APP_BAR_DESKTOP + 24}px + ${HEADER_DESKTOP}px)`,  // Updated paddingTop value
  },
}));
const Tabs = styled(MuiTabs)(({theme}) => ({
  "&::before": {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: -1
  },
  minHeight: 44,
  minWidth: 100,
}));

  
  const Tab = styled(MuiTab)(({ theme }) => ({
      borderRadius: 10,
      minHeight: 44,
      minWidth: 96,
      marginLeft: 2.5,
      marginRight: 2.5,
      border: '1px solid rgba(0, 0, 0, 0.5)',
      '&.Mui-selected': {
        borderRadius: 10,
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.common.black,
      },
      '&:hover': {
        borderRadius: 10,
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.common.black,
      },
      '&.MuiTab-root': {
        textTransform: 'initial',
        color: theme.palette.text.primary,
      },
      
      [theme.breakpoints.up('md')]: {
        minWidth: 120
      },
    }));

export default function CustomTheme() {
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
  
    const handleTabChange = (event, newValue) => {
      navigate(newValue);
    };
  
    return (
        <StyledRoot>
          <Header onOpenNav={() => setOpen(true)} />
          <Nav openNav={open} onCloseNav={() => setOpen(false)} />
          <Main>
          <Container maxWidth="lg">


                <Tabs 
                centered
                indicatorColor="transparent"
                  value={
                    location.pathname !== "/settings/subscriptions" &&
                    location.pathname !== "/settings/profile" 
                      ? false
                      : location.pathname
                  } 
                  onChange={handleTabChange}
                >
                  <Tab value="/settings/subscriptions" label="Membership" />
                  <Tab value="/settings/profile" label="Профиль" />
                </Tabs>

              </Container>
            <Outlet /> 
          </Main>
        </StyledRoot>
      );
  }