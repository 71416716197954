import { useContext, useEffect } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import { Button, Box, Typography, useTheme, LinearProgress } from "@mui/material";
import QuizIcon from '@mui/icons-material/Quiz';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SchoolIcon from '@mui/icons-material/School';
import { useMediaQuery } from '@mui/material';
import { CartContext } from '../../../CartContext';
import { analytics } from '../../../firebase';
import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { alpha } from '@mui/material/styles';
import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import Rating from '@mui/material/Rating';


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    fontSize: 13,
    padding: theme.spacing(1.5, 2.5),
    backdropFilter: 'blur(8px)',
    WebkitBackdropFilter: 'blur(8px)', // Fix on Mobile
    borderRadius: 10,
    borderColor: theme.palette.grey[500_8],

  },
}));
const HtmlTooltipSoon = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    color: '#FFF',
    backgroundImage: "linear-gradient(100deg, #F5BB78 0%, #EE7575 15.1%, #BF71D6 34.9%, #8F80F7 54.17%, #F7CBFC 81.25%, #79C1FA 100%);",
    border: '1px solid rgba(255, 255, 255, 0.5)',
    fontSize: 13,
    padding: theme.spacing(1.5, 2.5),
    borderRadius: 10,
    borderColor: theme.palette.grey[500_8],

  },
}));
function IconWithLabel({ icon: IconComponent, label, hasDivider = false, tooltip }) {
  return (
    <Box sx={{ ...styles.iconWithLabelStyle, ...(hasDivider && styles.withDivider) }}>
      <HtmlTooltip title={tooltip}>
        <IconComponent sx={{fontSize: 18}} />
      </HtmlTooltip>
      <Typography variant="body2" component="span">{label}</Typography>
    </Box>
  );
}


export default function CourseCardCatalog({ course }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { cart, setCart } = useContext(CartContext);
  const handleAddToCart = (courseOrCertification, type) => {
      // Assuming type can be 'courses' or 'certificates'
      if (!cart[type].includes(courseOrCertification)) {
        setCart(prevCart => ({
          ...prevCart,
          [type]: [...prevCart[type], courseOrCertification]
        }));
      }
      logEvent(analytics, 'button_click', { name: 'addToCartCourse' });
      navigate('/cart');
    };

    useEffect(() => {
      localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  return (
   
    <Box sx={{ ...styles.cardStyle(theme)}}>
      <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
      <Box sx={{
          padding: [1, 1],  // 0 for xs, 1 for sm and above
        }}>
          { course.rating && (
          <Rating name="course-rating" 
          sx={{mt: 0.5}}
          value={course.rating} 
          size={theme.breakpoints.down('sm') ? 'small' : 'medium'} 
          readOnly 
          precision={0.1}
        />
          )}


        </Box>
      <Box sx={{
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          borderRadius: 5,
          border: '1px solid rgba(255, 255, 255, 0.2)',
          px: 1.5,
          py: 1
        }}>
            <Typography variant="subtitle1" sx={{fontSize: '1rem'}}>{course.specializations[0] === 'trading'? 'трейдинг 🦈' : course.specializations[0] === 'crypto'? "крипта 💵" : 'инвестиции 📊'}</Typography>

        </Box>
      </Box>
      <Box sx={styles.courseIconContainerStyle}>
        {!course.soon
        ? (
          <Link to={`/dashboard/catalog/course/${course.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          <Typography variant="subtitle1" sx={styles.courseTitleStyle}>{course.title}</Typography>
        </Link>
        ):(
          <Typography variant="subtitle1"  style={{ textDecoration: 'none', color: 'inherit' }} sx={styles.courseTitleStyle}>{course.title}</Typography>
        )}

        <Typography variant="subtitle1" sx={styles.courseDescriptionStyle}>{course.description}</Typography>
      </Box>
      {course.purchased ? (
        <Box sx={styles.linearProgressBox}>
            
        <Typography variant="caption" display="block" 
            sx={{ mt: -1, textAlign: 'right', fontSize: 14,
            [theme.breakpoints.down('sm')]: {
                mt: 0,
              },}}>
      {course.progress}% пройдено
    </Typography>

        <LinearProgress 
        variant="determinate" 
        value={course.progress} 
        sx={{ 
            width: '100%',
            borderRadius: 4, 
            height: 6,
            bgcolor: '#cccccc',
            '& .MuiLinearProgress-bar': {
                backgroundColor: '#3366FF',
            }
        }}
    />

        </Box>
) : null }
        {
  course.purchased ? (
    <>
    {isMobile ? (
      <Box sx={styles.infoContainerStyle}>
      <IconWithLabel icon={SchoolIcon} label={course.enrolled} hasDivider tooltip="Количество учеников" />
      <IconWithLabel icon={CheckCircleOutline} label={course.completed} tooltip="Завершено" />
    </Box>
    
    ) :(
      <Box sx={styles.infoContainerStyle}>
        <IconWithLabel icon={SchoolIcon} label={course.enrolled} hasDivider tooltip="Количество учеников" />
        <IconWithLabel icon={VideoLibraryIcon} label={course.lessons} hasDivider tooltip="Количество уроков" />
        <IconWithLabel icon={QuizIcon} label={course.quizes} hasDivider tooltip="Количество тестов" />
        <IconWithLabel icon={CheckCircleOutline} label={course.completed} tooltip="Завершено" />
      </Box>
    )}
  </>
  ) : (
    <>
      {isMobile ? (
        <Box sx={{  display: 'flex', flexDirection: 'row', gap: 1, width: '100%',}}>
        <Box sx={styles.infoContainerNotPurchasedMobileStyle}>
          <IconWithLabel icon={SchoolIcon} label={course.enrolled} hasDivider tooltip="Количество учеников" />
        <IconWithLabel icon={VideoLibraryIcon} label={course.lessons} hasDivider tooltip="Количество уроков" />
        <IconWithLabel icon={QuizIcon} label={course.quizes}  tooltip="Количество тестов" />
        </Box>
        { course.soon
          ? (
            <HtmlTooltipSoon title={'СКОРО'}>
                <Box sx={styles.buttonBuyMobileContainer}>
                  <ShoppingCartIcon />
              </Box>
            </HtmlTooltipSoon>
          ) : (
            <Box sx={styles.buttonBuyMobileContainer}  onClick={() => handleAddToCart(course.id, 'courses')}>
            <ShoppingCartIcon />
          </Box>
          )}
      </Box>
      ) : (
        <Box sx={{  display: 'flex', flexDirection: 'row', gap: 1, width: '100%',}}>
          <Box sx={styles.infoContainerNotPurchasedMobileStyle}>
            <IconWithLabel icon={SchoolIcon} label={course.enrolled} hasDivider tooltip="Количество учеников" />
            <IconWithLabel icon={VideoLibraryIcon} label={course.lessons} hasDivider tooltip="Количество уроков" />
            <IconWithLabel icon={QuizIcon} label={course.quizes}  tooltip="Количество тестов" />
          </Box>
          { course.soon
          ? (
            <HtmlTooltipSoon title={'СКОРО'}>
                <Box sx={styles.buttonBuyContainer}>
                  <ShoppingCartIcon />
              </Box>
            </HtmlTooltipSoon>
          ) : (
            <Box sx={styles.buttonBuyContainer}  onClick={() => handleAddToCart(course.id, 'courses')}>
            <ShoppingCartIcon />
          </Box>
          )}

        </Box>
      )}
    </>
  )
}

      </Box>
    
  );  
}

const styles = {
  cardStyle: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '250px',
    backdropFilter: 'blur(15px)',
    backgroundColor: alpha('#505050', 0.5),
    border: '1px solid rgba(255, 255, 255, 0.3)',
    p: 2,
    justifyContent: 'space-between', // Distribute space between first and last child
    alignItems: 'flex-start',
    borderRadius: 5,
    mr: 2,
    position: 'relative',
    color: '#fff'
  }),
      
  
  courseIconContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,  // Take up the available space
  },
  linearProgressBox: {
    width: '100%',
    py: 1,
    flex: 1, // Take up the available space
  },

  iconStyle: {
    borderRadius: '50%',
    backgroundColor: '#fff',
    p: 1,
    width: 50,
    height: 50,
  },

  courseTitleStyle: {
    fontWeight: 700,
    pt: 1,
    ml: 0.5,
    fontSize: '1.3rem',
  },
  courseDescriptionStyle: {
    color: '#E5E4E2',
    fontWeight: 400,
    pt: 1,
    ml: 0.5,
    fontSize: '1rem',
  },

  iconWithLabelStyle: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    position: 'relative',
    flexGrow: 1,
    justifyContent: 'center',
  },

  withDivider: {
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: '5%',
      bottom: '5%',
      width: '2px',
      background: '#EDEDFB',
    },
  },

  infoContainerStyle: {
    display: 'flex',
    gap: 1,
    width: '100%',
    borderRadius: 3,
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    mt: 2,
    p: 1,
    py: 1.5,
    flex: 1, // Take up the available space
  },
  infoContainerNotPurchasedStyle: {
    display: 'flex',
    gap: 0,
    width: '80%',
    borderRadius: 3,
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    mt: 2,
    p: 0,
    py: 1.5,
    alignItems: 'center',
    flex: 1, // Take up the available space
  },
  infoContainerNotPurchasedMobileStyle: {
    display: 'flex',
    gap: 0,
    width: '50%',
    borderRadius: 3,
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    mt: 2,
    p: 0,
    py: 1.5,
    alignItems: 'center',
    flex: 1, // Take up the available space
  },
  buttonBuyContainer: {
    display: 'flex',
    gap: 0,
    width: '20%',
    borderRadius: 3,
    color: '#FFF',
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    border: '1px solid var(--stroke-grey, #E5E7E9)',
    mt: 2,
    p: 0,
    py: 1.5,
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      color: 'white',
      cursor: 'pointer',
      boxShadow: '0 2px 4px rgba(0,0,0,0.08), 0 8px 24px rgba(0,0,0,0.05)',
      transition: 'box-shadow 0.2s ease-in-out', // Adjust the transition as per your requirement.
    },
  },
  buttonBuyMobileContainer: {
    display: 'flex',
    gap: 0,
    width: '50%',
    borderRadius: 3,
    color: '#FFF',
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    border: '1px solid var(--stroke-grey, #E5E7E9)',
    mt: 2,
    p: 0,
    py: 1.5,
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      color: 'white',
      cursor: 'pointer',
      boxShadow: '0 2px 4px rgba(0,0,0,0.08), 0 8px 24px rgba(0,0,0,0.05)',
      transition: 'box-shadow 0.2s ease-in-out', // Adjust the transition as per your requirement.
    },
  },
};
