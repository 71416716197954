import PropTypes from 'prop-types';
import { useEffect, useContext} from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';

// hookss
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import UserContext from '../../../UserContext';
import { CartContext } from '../../../CartContext';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const openLink = () => {
  window.open('https://app.defaust.com', '_blank');
}

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundColor: '#ffffff',
  border: '1px solid #E5E7E9'
}));

const StyledAnalytics = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundColor: '#ffffff',
  border: '1px solid #E5E7E9'
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { cart, setCart } = useContext(CartContext);
  const account = useContext(UserContext);
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  
  const renderContent = (
    <Box sx={{
      flexShrink: { lg: 0 },
      width: { lg: NAV_WIDTH },
      display: 'flex',
      flexDirection: 'column',
      height: 1
    }}>
    <Scrollbar
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}>
      <Box sx={{ mt: 6, ml: 8, pb: 0, alignContent: 'center', justifyContent: 'center'}}>
        <Logo />
      </Box>
  
      <NavSection data={navConfig} cartCount={cart.courses.length + cart.certificates.length} />    
    </Scrollbar>
    <Box onClick={openLink} sx={{ mb: 2, mx: 3, cursor: 'pointer' }}>
        <StyledAnalytics>
        <Avatar variant="rounded" src={'/assets/illustrations/back_illustration2x.png'} alt="photoURL" />
        <Box  sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: '#00', fontWeight: 700 }}>
              DEFAUST ANALYTICS
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            7 дней бесплатно🔥
            </Typography>
          </Box>
        </StyledAnalytics>

    </Box>
    <Box sx={{ mb: 5, mx: 3 }}>
      <RouterLink to="/settings" style={{ textDecoration: 'none' }}>
        <StyledAccount>
          <Avatar src={account?.avatarURL || '/assets/images/avatars/user_avatar.png'} alt="photoURL" />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: 'grey.0', fontWeight: 700 }}>
              {account?.name || 'Defaust Student'}
            </Typography>
            <Box display="flex" flexDirection="row">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Профиль
            </Typography>
            <ChevronRightIcon sx={{fontSize: 18, mt: 0.3,  color: 'text.secondary' }}/>
            </Box>
          </Box>
        </StyledAccount>
      </RouterLink>
    </Box>
  </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH }
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: '#F5F7F9',

            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
