import React, {useState, useEffect} from 'react';
import { Typography, Button, Table, TableBody, TableRow, TableCell, Box} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { collection, doc, setDoc, onSnapshot } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import { loadStripe } from "@stripe/stripe-js";
import { db, analytics } from '../../../firebase';
import { getFunctions, httpsCallable } from "firebase/functions";

import { getDoc, query,where,getDocs,runTransaction } from "firebase/firestore";
import { logEvent} from 'firebase/analytics';
import { LoadingButton } from '@mui/lab';

function TotalCart({ total, addOnTotal, cart, cartFull, loadingButton, selectedCourses, allCourses }) {
  const functions = getFunctions();
  const createCoinbaseCheckout = httpsCallable(functions, 'createCoinbaseCheckout');
  const [userPoints, setUserPoints] = useState(0); // Add this line
  const [usePoints, setUsePoints] = useState(false); // Add this line
  const [discountedTotal, setDiscountedTotal] = useState(total); // Add this line
  //console.log(cart);
  const auth = getAuth();
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
// Function to filter allCourses based on selectedCourses
const filterSelectedCourses = (allCourses, selectedCourses) => {
  return allCourses.filter(course => selectedCourses.includes(course.id));
};

// Existing function for creating line items
const createLineItemsFromCart = (filteredCourses) => {
  return filteredCourses
    .filter(item => item && item.price_id)  // Filter out items where price_id is undefined
    .map(item => ({
      price: item.price_id,
      quantity: 1
    }));
};
const createLineItemsFromCartCoinbase = (cart) => {
    // Create an array of item IDs
    const items = cart.map((item) => item.id);
    const names = cart.map((item) => item.name.ru);
  
    // Calculate the total sum of item prices
    const total = cart.reduce((acc, item) => acc + item.price, 0).toFixed(2);
  
    // Return a single object containing both items and total
    return {
      items,
      total,
      names,
    };
  };
  const calculateAddOnTotal = (selectedCourses, allCourses) => {
    return selectedCourses
      .map(courseId => {
        const course = allCourses.find(c => c.id === courseId);
        return course ? course.price : 0;
      })
      .reduce((acc, price) => acc + price, 0);
  };
  
  useEffect(() => {
    const addOnTotal = calculateAddOnTotal(selectedCourses, allCourses);
    setDiscountedTotal(total + addOnTotal);
  }, [selectedCourses, total, allCourses]);
  

  useEffect(() => {
    if (userId) {
      const userDocRef = doc(db, 'users', userId);
      onSnapshot(userDocRef, (snapshot) => {
        setUserPoints(snapshot.data().points || 0);
      });
    }
  }, [userId]);

  useEffect(() => {
    if (usePoints) {
      const discount = userPoints / 50; // 50 points is 1% of discount
      const newTotal = total * (1 - discount / 100);
      setDiscountedTotal(newTotal);
    } else {
      setDiscountedTotal(total);
    }
  }, [usePoints, total, userPoints]);

  const handleToggleUsePoints = (event) => {
    setUsePoints(event.target.checked);
  };

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const totalWithAddOns = total + addOnTotal;
    const savings = total - totalWithAddOns; // You might want to adjust this logic based on your needs.
    
    const stripePromise = loadStripe('pk_live_51Naf23CIeBGLQArtMHCLkfITSFISrqQ3DnJdXFIKM8xOqI85Mo5LIg9kzoUA0bnD8CbnmllOro3kHNAYKH5rSGM7006rh1NiAX'); // Ensure you replace this with your actual Stripe public key.

    
    const handleCheckout = async (paymentMethod) => {
      console.time('Total Checkout Time');
        logEvent(analytics, 'button_click', { name: 'subscription_start' });
        setLoading(true);
        ////console.log('Subscription creation started');
      
        try {
          const auth = getAuth();
          const user = auth.currentUser;
      
          if (!user) {
            throw new Error("No user is signed in.");
          }
      
          const userId = user.uid;

          if (paymentMethod === 'stripe') {
          // Convert cart items to Stripe line items format
    // Create Stripe line items using your cart and add-ons
          const cartLineItems = createLineItemsFromCart(cart);
          const filteredCourses = filterSelectedCourses(allCourses, selectedCourses);
          const addOnLineItems = createLineItemsFromCart(filteredCourses);
          const lineItems = [...cartLineItems, ...addOnLineItems];
          
          ////console.log('items', lineItems);
      
          // Create a checkout session in Firestore
          const checkoutSessionsCollectionRef = collection(db, 'customers', userId, 'checkout_sessions');
          const checkoutSessionId = uuidv4();  // or any other UUID generating method you're using
          //console.log("Logging line items: ", lineItems);

          await setDoc(doc(checkoutSessionsCollectionRef, checkoutSessionId), {
            mode: 'subscription',
            line_items: lineItems,
            success_url: `${window.location.origin}/dashboard/courses`,
            cancel_url: window.location.href
          });
      
          // Listen for the checkout session ID from Firestore
          const unsubscribe = onSnapshot(doc(db, 'customers', userId, 'checkout_sessions', checkoutSessionId), async (docSnap) => {
            const { sessionId } = docSnap.data();
            if (sessionId) {
              console.time('Firestore Session ID Fetch');

              // We have a session ID, let's redirect!
              unsubscribe(); // stop listening for updates
              console.timeEnd('Firestore Session ID Fetched');
              logEvent(analytics, 'subscription_success', { sessionId: sessionId });
              const stripe = await stripePromise;
              setLoading(false);
              console.time('Stripe Redirection');
              stripe.redirectToCheckout({ sessionId });
              console.timeEnd('Total Checkout Time');
              
            }
          });
        }
        if (paymentMethod === 'coinbase') {
          const data = createLineItemsFromCartCoinbase(cart);
          //console.log('data', data);
          try {
            const result = await createCoinbaseCheckout(data);
            const { checkoutUrl } = result.data;
            if (checkoutUrl) {
              //console.log("Checkout URL:", checkoutUrl);
              logEvent(analytics, 'coinbase_payment_created');
              window.location.href = checkoutUrl;  // Redirect the user to complete the payment
            }
            setLoading(false);
          } catch (e) {
            logEvent(analytics, 'coinbase_payment_failed', { error_message: e.message });
            console.error("Error initiating Coinbase Commerce payment:", e);
          }
        }
        
      } catch (e) {
          logEvent(analytics, 'subscription_failed', { error_message: e.message });
          console.error("Error creating subscription: ", e);
        }
        finally {
          console.timeEnd('Total Checkout Time');
        }
      };
      
    
    const handleCheckoutFree = async () => {
        setLoading(true);
        try {
          const auth = getAuth();
          const user = auth.currentUser;
      
          if (!user) {
            throw new Error("No user is signed in.");
          }
      
          const userId = user.uid;
          
          // Fetch the current 'purchased' array from Firestore
          const userDocRef = doc(db, 'users', userId);
          const userDocSnap = await getDoc(userDocRef);
      
          if (userDocSnap.exists()) {
            const currentPurchased = userDocSnap.data().purchased || [];  // Default to an empty array if it doesn't exist
            const lineItems = cartFull.courses;
            // Create a new 'purchased' array by merging the current and new courses
            const newPurchased = [...new Set([...lineItems, ...currentPurchased])];
      
              // Update enrolled count in each course document
            for (const courseId of lineItems) {
              const courseQuery = query(collection(db, 'courses'), where('id', '==', courseId));
              const querySnapshot = await getDocs(courseQuery);
              
              querySnapshot.forEach((docSnapshot) => {
                const courseDocRef = doc(db, 'courses', docSnapshot.id);

                runTransaction(db, async (transaction) => {
                  const courseDoc = await transaction.get(courseDocRef);
                  if (!courseDoc.exists()) {
                    //console.log('doc is not exist', courseId);
                    return;
                  }
                  
                  // Get the current enrolled count
                  const currentEnrolled = courseDoc.data().enrolled || 0;

                  // Update the enrolled count
                  const newEnrolled = currentEnrolled + 1;
                  transaction.update(courseDocRef, { enrolled: newEnrolled });
                });
              });
            }
            // Update the 'purchased' array in Firestore
            await setDoc(userDocRef, { purchased: newPurchased }, { merge: true });
            ////console.log("free purchase");
            setLoading(false); // Hide loading
            navigate('/dashboard');
          } else {
            console.error("User document doesn't exist");
          }
        } catch (e) {
          setLoading(false); // Hide loading
          console.error("Error purchasing free courses: ", e);
        }
      };
      ////console.log("Logging cart items: ", cart);
      ////console.log("Logging selected courses: ", selectedCourses);
      const onStripeCheckout = () => {
        handleCheckout('stripe');
      };
      
      const onCryptoCheckout = () => {
        handleCheckout('coinbase');
      };
    return (
        <>
            <Table sx={{ marginBottom: 3 }}>
                <TableBody>
                {addOnTotal === 0 && (
                        <>
                    <TableRow>
                        <TableCell>
                            <Typography variant="h5" sx={{color: '#707070',}}>Всего:</Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography variant="h5" sx={{px: 2,}}>${discountedTotal.toFixed(2)}</Typography>
                        </TableCell>
                    </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h4">Всего к оплате:</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Box sx={{ fontSize: 18,
                                            fontWeight: 700,
                                            color:  '#204631',
                                            background: '#6BEBA470',
                                            borderRadius: "50px",
                                            py: 0.5,
                                            px: 2,
                                            display: 'inline-block', // To make the box size according to content
                                            }}>
                                            ${discountedTotal.toFixed(2)}
                                            
                                    </Box>
                                </TableCell>
                            </TableRow>
                    </>
                    )}
                    {addOnTotal > 0 && (
                        <>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h5" sx={{color: '#707070',}}>Всего со скидкой:</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="h5" sx={{px: 2,}}>${totalWithAddOns}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h4">Вместе дешевле на:</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Box sx={{ fontSize: 18,
                                            fontWeight: 700,
                                            color:  '#204631',
                                            background: '#6BEBA470',
                                            borderRadius: "50px",
                                            py: 0.5,
                                            px: 2,
                                            display: 'inline-block', // To make the box size according to content
                                            }}>
                                            ${savings}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </>
                    )}
                </TableBody>
            </Table>    
{/*             <FormControlLabel
        control={
          <Checkbox
            checked={usePoints}
            onChange={handleToggleUsePoints}
            name="usePoints"
            color="primary"
          />
        }
        label={`Потратить ${(userPoints)} очков, чтобы применить скидку ${(userPoints / 50).toFixed(2)}%`}
        sx={{ px: 2, pb: 2 }}
      /> */}
{/*             <Button 
                sx={{
                    textTransform: 'none',
                    "&:hover": {
                        backgroundColor: '#00000098'
                    }
                }}
                variant='contained'
                size='large'
                fullWidth
                disabled={loading}
                onClick={discountedTotal === 0 ? handleCheckoutFree : handleCheckout }
                >
                  {loading ? <CircularProgress size={25} /> : 'Оплатить'}
                </Button> */}
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px', width: '100%'}}>
                <LoadingButton  
                      loading={loading}
                      disabled={loading}
                      variant="outlined" color="primary" sx={{ fontWeight: 400, color: '#2E2E2E', background: 'transparent' ,fontSize: ['14px','14px','14px','15px'],  lineHeight: ['16px','16px','16px','20px'],  borderRadius: 3, py: '10px', textTransform: 'none', px: '10px',
                     '&:hover': {
                      color: 'white', background: '#2E2E2E', 
                     }}} 
                     onClick={discountedTotal === 0 ? handleCheckoutFree : onStripeCheckout}>Оплатить картой Visa/Mastercard <img src={'/assets/images/card-payments.png'} height={'20px'} style={{marginLeft: '5px'}}/></LoadingButton>
                <LoadingButton
                 loading={loading}
                  disabled={loading}
                  variant="outlined" 
                  color="primary" 
                  sx={{ boxShadow: '3px 3px 1px 1px rgba(24, 24, 24, 0.25)', color: '#000', background: '#A0C0FF' ,fontSize: ['14px','14px','14px','15px'], fontWeight: '600', lineHeight: ['16px','16px','16px','20px'], px: '20px', borderRadius: 3, py: '10px', textTransform: 'none',
                      '&:hover': {
                      color: '#000', background: '#A0C0FF', 
                      }}}
                onClick={discountedTotal === 0 ? handleCheckoutFree : onCryptoCheckout}>Оплатить криптой <img src={'/assets/images/crypto-payments.png'} height={'20px'} style={{marginLeft: '5px'}}/></LoadingButton>
                </Box>
        </>
    );
}

export default TotalCart;
