import React from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, Box, Button } from '@mui/material';
import VideoPlayer from '../course/VideoPlayer';
import LockIcon from '@mui/icons-material/Lock';
import promoImage from './promo-recap.png'
import { useNavigate } from 'react-router-dom';
function VideoSectionPromo({ recaps, specifiedItemId }) {
  const navigate = useNavigate();
  const formatDateToRussian = (dateString) => {
    const date = new Date(dateString);
  
    const day = new Intl.DateTimeFormat('ru', { weekday: 'short' }).format(date);
    const dayOfMonth = date.getDate();
    const month = new Intl.DateTimeFormat('ru', { month: 'short' }).format(date);
    const year = date.getFullYear();
  
    return `${day}. ${dayOfMonth} ${month} ${year}`;
  };

  return (
    <Grid container spacing={3}>
      {recaps.map((item) => (
        <Grid item xs={12} sm={4} key={item.id}>
          <Card>
            { item.id === specifiedItemId ? (
              <VideoPlayer url={item.videoUrl} />
            ) : (
              <Box position="relative">
                <CardMedia
                  component="img"
                  alt="Unaccessible video"
                  height="140"
                  image={promoImage}
                  style={{ filter: 'blur(10px)', position: 'cover'}}
                />
<Box
  position="absolute"
  top="0"
  bottom="0"
  left="0"
  right="0"
  display="flex"
  alignItems="center"
  justifyContent="center"
  flexDirection="column"
>
  <LockIcon fontSize="large" />
  <Button sx={{mt: 1}} variant="contained" color="primary"  onClick={() => navigate('/settings/subscriptions')} >Открыть все за 50$/мес.</Button>
</Box>


              </Box>
            )}
            <CardContent>
              <Typography variant="h5" component="div">
                {item.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {formatDateToRussian(item.date)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default VideoSectionPromo;
