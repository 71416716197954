import React, {useContext} from 'react';
import { Box, Typography, Tooltip, Button, Grid, Card, CardActionArea, CardMedia, CardContent, CardActions, List, ListItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import proMax_sertification from '../../assets/logo-pro-max.png';
import UserContext from '../../../UserContext';

function CertificationsGrid({ certifications }) {
    const navigate = useNavigate();
    const account = useContext(UserContext);
    const handleButtonClick = (certificationId) => {
        navigate(`/dashboard/catalog/certification/${certificationId}`);
    };


    const getCertificationImageFull = (certificationId) => {
        switch(certificationId) {
            case 'defaust-trader':
                return proMax_sertification;
            default:
                return proMax_sertification; // or a default icon if any
        }
    }

    return (
        <Grid container spacing={3}>
            {certifications.map(certification => (
                <Grid item xs={12} sm={6} key={certification.id}>
                    <Card sx={{ display: 'flex', marginBottom: 2, backgroundColor: '#fff', border: '1px solid var(--stroke-grey, #E5E7E9)', borderRadius: 5, }} >
                    <Box sx={{ display: 'flex', flexDirection: 'column',alignItems: 'start', justifyContent: 'start', height: '100%', width: '100%' }}>
                            <CardContent sx={{mb: -3}} >
                                <Typography variant="h5">
                                    {certification.name.ru}
                                </Typography>

                                <Typography variant="body2" color="text.secondary">
                                    {certification.description.ru}
                                </Typography>
                                <List sx={{fontSize: 14}}>
                                {certification.features?.ru?.map((feature, index) => (
    <ListItem key={index}>
        <CheckIcon color="primary" sx={{ marginRight: 1 }} />
        {feature}
    </ListItem>
))}
                                </List>
                            </CardContent>
                            <CardActions sx={{pl: 3, pb: 3}}>
                            {
    account.purchased && account.purchased.includes(certification.id) ? 
    (
        <Button
            variant="outlined"
            onClick={() => handleButtonClick(certification.id)}
            sx={{my: 3, textTransform: 'none', color: '#000'}}
        >
            Продолжить изучение
        </Button>
    ) : 
    (
        <Button
            variant="outlined"
            onClick={() => handleButtonClick(certification.id)}
            sx={{my: 3, textTransform: 'none', color: '#000'}}
        >
            Узнать больше
        </Button>
    )
}
                            </CardActions>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'start', height: '100%', pt: 6, p: 2 }}>
    <CardMedia
        component="img"
        sx={{ 
            objectFit: 'contain', 
            width: 3900/11,
            height: 2848/11,
            ml: -3
        }}
        image={getCertificationImageFull(certification.id)}
        alt="Certificate"
    />
</Box>



                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}

export default CertificationsGrid;
