import { useContext, useEffect } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import { Button, Box, Typography, useTheme, LinearProgress } from "@mui/material";
import QuizIcon from '@mui/icons-material/Quiz';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { useMediaQuery } from '@mui/material';
import { CartContext } from '../../../CartContext';
import { analytics } from '../../../firebase';
import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import Tooltip from '@mui/material/Tooltip';
import Rating from '@mui/material/Rating';
import { alpha } from '@mui/material/styles';
import { styled } from '@mui/system';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: alpha(theme.palette.background.default, 0.3),
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 13,
    padding: theme.spacing(1.5, 2.5),
    backdropFilter: 'blur(8px)',
    WebkitBackdropFilter: 'blur(8px)', // Fix on Mobile
    borderRadius: 10,
    borderColor: theme.palette.grey[500_8],

  },
}));

function IconWithLabel({ icon: IconComponent, label, hasDivider = false, tooltip }) {
  return (
    <Box sx={{ ...styles.iconWithLabelStyle, ...(hasDivider && styles.withDivider) }}>
      <HtmlTooltip title={tooltip}>
        <IconComponent sx={{fontSize: 18}} />
      </HtmlTooltip>
      <Typography variant="body2" component="span">{label}</Typography>
    </Box>
  );
}

export default function CourseCard({ course }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const backgroundColors = ['#F8EFE2', '#E1E2F6', '#EFF7E2'];
  const randomBgColor = backgroundColors[Math.floor(Math.random() * backgroundColors.length)];
  const navigate = useNavigate();
  const { cart, setCart } = useContext(CartContext);

  const handleAddToCart = (courseOrCertification, type) => {
      // Assuming type can be 'courses' or 'certificates'
      if (!cart[type].includes(courseOrCertification)) {
        setCart(prevCart => ({
          ...prevCart,
          [type]: [...prevCart[type], courseOrCertification]
        }));
      }
      logEvent(analytics, 'button_click', { name: 'addToCartCourse' });
      navigate('/cart');
    };

    useEffect(() => {
      localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  return (
   
    <Box sx={{ ...styles.cardStyle(theme), background: randomBgColor }}>
        <Box sx={styles.courseIconContainerStyle}>
          <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
          <Box component="img" src={'/assets/icons/online-learning.png'} alt={course.title} sx={styles.iconStyle} />
          { course.rating && (
          <Rating name="course-rating" 
            value={course.rating} 
            size={theme.breakpoints.down('sm') ? 'small' : 'medium'} 
            readOnly 
            precision={0.1}
          />
           )}
          </Box>
          <Link to={`/course/take/${course.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          <Typography variant="subtitle1" sx={styles.courseTitleStyle}>{course.title}</Typography>
          </Link>
          
        </Box>
        <Box sx={styles.linearProgressBox}>
            
        <Typography variant="caption" display="block" 
            sx={{  textAlign: 'right', fontSize: '0.8rem'}}>
      {course.progress}% пройдено
    </Typography>
        <LinearProgress 
        variant="determinate" 
        value={course.progress} 
        sx={{ 
            width: '100%',
            borderRadius: 4, 
            height: 6,
            bgcolor: '#cccccc',
            '& .MuiLinearProgress-bar': {
                backgroundColor: '#3366FF',
            }
        }}
    />

        </Box>

        {
  course.purchased ? (
    <Box sx={styles.infoContainerStyle}>
        <IconWithLabel icon={VideoLibraryIcon} label={course.lessons} hasDivider tooltip="Количество уроков" />
        <IconWithLabel icon={QuizIcon} label={course.quizes} hasDivider tooltip="Количество тестов" />
        <IconWithLabel icon={CheckCircleOutline} label={course.completed} tooltip="Завершено" />
    </Box>
  ) : (
    <>
      {isMobile ? (
        <Box sx={styles.buttonBuyMobileContainer}  onClick={() => handleAddToCart(course.id, 'courses')}>
                {course.id === 'introduction-in-financial-markets' ? (
                    <HtmlTooltip title={'БЕСПЛАТНО'}>
                      <CardGiftcardIcon />
                    </HtmlTooltip>
             ):(
              <ShoppingCartIcon />
          )}

          
        </Box>
      ) : (
        <Box sx={{  display: 'flex', flexDirection: 'row', gap: 1, width: '100%',}}>
          <Box sx={styles.infoContainerNotPurchasedStyle}>
        <IconWithLabel icon={VideoLibraryIcon} label={course.lessons} hasDivider tooltip="Количество уроков" />
        <IconWithLabel icon={QuizIcon} label={course.quizes} hasDivider tooltip="Количество тестов" />
        <IconWithLabel icon={CheckCircleOutline} label={course.completed} tooltip="Завершено" />
          </Box>
          <Box sx={styles.buttonBuyContainer}  onClick={() => handleAddToCart(course.id, 'courses')}>
          {course.id === 'introduction-in-financial-markets' ? (
                    <HtmlTooltip title={'БЕСПЛАТНО'}>
                      <CardGiftcardIcon />
                    </HtmlTooltip>
             ):(
              <ShoppingCartIcon />
          )}
          </Box>
        </Box>
      )}
    </>
  )
}

      </Box>
    
  );  
}

const styles = {
  cardStyle: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '250px',
    minWidth: '200px',
    p: 2,
    justifyContent: 'space-between', // Distribute space between first and last child
    alignItems: 'flex-start',
    borderRadius: 5,
    mr: 2,
    position: 'relative',
  }),
      
  
  courseIconContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,  // Take up the available space
  },
  linearProgressBox: {
    width: '100%',
    py: 1,
    flex: 1, // Take up the available space
  },

  iconStyle: {
    borderRadius: '50%',
    backgroundColor: '#fff',
    p: 1,
    width: 50,
    height: 50,
  },

  courseTitleStyle: {
    fontWeight: 700,
    pt: 1,
    ml: 0.5,
    fontSize: '1rem',
  },

  iconWithLabelStyle: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    position: 'relative',
    flexGrow: 1,
    justifyContent: 'center',
  },

  withDivider: {
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: '5%',
      bottom: '5%',
      width: '2px',
      background: '#EDEDFB',
    },
  },

  infoContainerStyle: {
    display: 'flex',
    gap: 1,
    width: '100%',
    borderRadius: 3,
    background: '#ffffff99',
    border: '1px solid var(--stroke-grey, #E5E7E9)',
    mt: 1,
    p: 1,
    py: 1.5,
    flex: 1, // Take up the available space
  },
  infoContainerNotPurchasedStyle: {
    display: 'flex',
    gap: 0,
    width: '70%',
    borderRadius: 3,
    background: '#ffffff99',
    border: '1px solid var(--stroke-grey, #E5E7E9)',
    mt: 1,
    p: 0,
    py: 1.5,
    alignItems: 'center',
    flex: 1, // Take up the available space
  },
  buttonBuyContainer: {
    display: 'flex',
    gap: 0,
    width: '30%',
    borderRadius: 3,
    background: '#ffffff99',
    border: '1px solid var(--stroke-grey, #E5E7E9)',
    mt: 1,
    p: 0,
    py: 1.5,
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,1)',
      color: 'white',
      cursor: 'pointer',
      boxShadow: '0 2px 4px rgba(0,0,0,0.08), 0 8px 24px rgba(0,0,0,0.05)',
      transition: 'box-shadow 0.2s ease-in-out', // Adjust the transition as per your requirement.
    },
  },
  buttonBuyMobileContainer: {
    display: 'flex',
    gap: 0,
    width: '100%',
    borderRadius: 3,
    background: '#ffffff99',
    border: '1px solid var(--stroke-grey, #E5E7E9)',
    mt: 1,
    p: 0,
    py: 1.5,
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,1)',
      color: 'white',
      cursor: 'pointer',
      boxShadow: '0 2px 4px rgba(0,0,0,0.08), 0 8px 24px rgba(0,0,0,0.05)',
      transition: 'box-shadow 0.2s ease-in-out', // Adjust the transition as per your requirement.
    },
  },
};
