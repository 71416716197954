// @mui
import {
  Badge,
  IconButton,

} from '@mui/material';
// components
import Iconify from '../../../components/iconify';


// ----------------------------------------------------------------------



export default function CartIcon({navigate, items}) {

  return (
    <>
      <IconButton onClick={() => navigate('/cart')} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={items} color="info"  sx={{ '.MuiBadge-badge': {backgroundColor: '#548dff'}}}>
          <Iconify icon="uil:cart" />
        </Badge>
      </IconButton>
      </>
  )
}