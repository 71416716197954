import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { doc, setDoc, onSnapshot, collection,query,where,getDocs, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Snackbar,  Alert, Card,CardContent, Container, Typography, Button, Grid, Box,  List, ListItem, ListItemIcon, ListItemText, CardMedia, Dialog, DialogTitle, DialogContent, TextField, DialogActions, FormControlLabel, Checkbox} from '@mui/material';
import { styled } from '@mui/material/styles';
import { loadStripe } from '@stripe/stripe-js';
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from 'uuid'
import { db, functions } from '../../firebase';
import { alpha } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { LoadingButton } from '@mui/lab';
import copy_username_image from './copy-user-name.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const stripePromise = loadStripe('pk_live_51Naf23CIeBGLQArtMHCLkfITSFISrqQ3DnJdXFIKM8xOqI85Mo5LIg9kzoUA0bnD8CbnmllOro3kHNAYKH5rSGM7006rh1NiAX');

const prices = [
  {
    id: 'price_1Nvh8DCIeBGLQArtK27qTCe4',
    product: {
      name: 'DEFAUST MEMBERSHIP',
      images: ['/assets/images/products/logo-pro-max.png'],
      includedItems: [
        'Полный доступ ко всем каналам в закрытом Discord',
        '4 RECAPS в месяц с разрбором ваших сделок',
        'Доступ ко всем конференциям на тему трейдинга, крипте и инвестициям',
        'Записи всех конференций в личном кабинете',
        'Discord-сервер для общения и обмена опытом',
        'Ежедневный анализ рынка от DEFAUST',
        'Постоянная поддержка и ответы на вопросы',
        'Связь с DEFAUST'
        ],
      excludedItems: [
        ]
    },
    unit_amount: 5000
  },
];

const steps = [
  {
    title: 'Зарегистрируйтесь в Discord',
    description: 'Зарегистрируйтесь в Discord, если у вас нет аккаунта.',
  },
  {
    title: 'Подпишитесь на открытый канал DEFAUST',
    description: 'Подпишитесь на открытый канал DEFAUST, чтобы получить в нем доступ к закрытым чатам.',
    link: 'https://discord.gg/xHWF6SH7TK',
  },
  {
    title: 'Cкопируйте свой ник ',
    image: copy_username_image,
    description: 'Важно! Только на этот ник будет предоставлен доступ к Discord.',
  },
  {
    title: 'При оплате укажите свой ник в Discord ',
    description: 'После оплаты напишите менеджеру в Discord в открытом чате об оплате и вам дадут доступ. Доступ к RECAP будет сразу после оплаты.',
  },
];

const CardWrapper = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  marginBottom: '20px',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
  textAlign: 'left',
  backgroundColor: alpha("#fff", 0.025), // semi-transparent background
  backdropFilter: "blur(10px)", // glassmorphism effect
  border: "0.5px solid rgba(209, 213, 219, 0.3)", // border
  boxShadow: "0px 2px 1px -1px #ffffff10", //shadow
  borderRadius: '15px', 
});

export default function SettingsSubscription() {
  const [open, setOpen] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasAnySubscription, setHasAnySubscription] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const functions = getFunctions();
  const [subscriptions, setSubscriptions] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [discordUsername, setDiscordUsername] = useState('');
  const [isValid, setIsValid] = useState({status: true, message: ""});
  const toggleDialog = () => {
    setOpenDialog(!openDialog);
  };
  
  const validateInput = () => {
    const discordUsernameRegex = /^[a-zA-Z0-9-_]{1,32}$/;
  
    if (!discordUsername) {
      setIsValid({status: false, message: "Это поле обязательно"});
    } else if (!discordUsernameRegex.test(discordUsername)) {
      setIsValid({status: false, message: "Имя пользователя Discord должно содержать только буквы, цифры, подчеркивания и дефисы. Максимальная длина - 32 символа."});
    } else if (!checkboxChecked) {
      setIsValid({status: false, message: "Пожалуйста, подтвердите, что это ваш ник Discord"});
    } else {
      setIsValid({status: true, message: ""});
      handleSubscribe(prices[0].id);
    }
  };
  
  
  async function getSubscriptionForUser(userId) {
    try {
      setLoading(true);
      const activeQuery = query(collection(db, 'customers', userId, 'subscriptions'), where("status", "==", "active"));
      const trialingQuery = query(collection(db, 'customers', userId, 'subscriptions'), where("status", "==", "trialing"));
      
      const activeSubs = await getDocs(activeQuery);
      const trialingSubs = await getDocs(trialingQuery);
      
      const querySnapshot = [...activeSubs.docs, ...trialingSubs.docs];
      
      let userSubscriptions = [];
      querySnapshot.forEach((doc) => {
        userSubscriptions.push(doc.data());
      });
      ////console.log('subs',userSubscriptions)
      setLoading(false);
      return userSubscriptions;
    } catch (e) {
      console.error("Error getting subscriptions: ", e);
      setError(e);
    }
  }
  
useEffect(() => {
    // Separate the logic to fetch subscriptions into a function
    const fetchSubscriptions = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            const userId = user.uid;
            const subs = await getSubscriptionForUser(userId);
            setSubscriptions(subs);
        }
    };

    // Fetch subscriptions when the component mounts
    fetchSubscriptions();

    // Set up the event listener for the window focus event
    const handleWindowFocus = () => {
        fetchSubscriptions();
    };

    window.addEventListener('focus', handleWindowFocus);

    // Clean up the event listener when the component is unmounted
    return () => {
        window.removeEventListener('focus', handleWindowFocus);
    };
}, []);


  useEffect(() => {
    const checkAnySubscription = () => {
      for (const price of prices) {
        if (hasSubscription(price.id)) {
          setHasAnySubscription(true);
          return; // Exit the loop if a subscription is found
        }
      }
      setHasAnySubscription(false); // Set to false if no subscriptions are found
    };

    checkAnySubscription();
  }, [subscriptions]);

  function hasSubscription(priceId) {
    for (let i = 0; i < subscriptions.length; i++) {
      const sub = subscriptions[i];
      
      if (sub.items && Array.isArray(sub.items) && sub.items.length > 0) {
        for (let j = 0; j < sub.items.length; j++) {
          const item = sub.items[j];
          
          if (item.plan && item.plan.id === priceId) {
            
            return true;
            
          }
        }
      }
    }
  
    return false;
  }

  const handleSubscribe = async (priceId) => {
    try {
      setLoading(true);
      const auth = getAuth();
      const user = auth.currentUser;
  
      if (!user) {
        throw new Error("No user is signed in.");
      }

      const userId = user.uid;

/*       // Call the Cloud Function to check if the user is a Discord member
      const checkDiscordMembership = httpsCallable(functions,'checkDiscordMembership');
      const membershipResult = await checkDiscordMembership({ discordUsername: discordUsername });

      if (!membershipResult.data.success || !membershipResult.data.isMember) {
        setSnackbarMessage("Перед оплатой подписки вам необходимо присоединиться к каналу DEFAUST в Discord.");
        setOpen(true);
        setLoading(false);
        return;
      } */
      toggleDialog();
      const checkoutSessionId = uuidv4();
        // Update Firestore user document to add discord_username
        const userDoc = doc(db, 'users', userId);
        await updateDoc(userDoc, {
          discord_username: discordUsername,
        });
      // Create a new checkout session in Firestore
      await setDoc(doc(db, 'customers', userId, 'checkout_sessions', checkoutSessionId),
        { price: priceId,
          success_url: window.location.href,
          cancel_url: window.location.href});
  
      // Listen for the checkout session ID from Firestore
      const unsubscribe = onSnapshot(doc(db, 'customers', userId, 'checkout_sessions', checkoutSessionId), async (docSnap) => {
        const { sessionId } = docSnap.data();
  
        if (sessionId) {
          // We have a session ID, let's redirect!
          unsubscribe(); // stop listening for updates
  
          const stripe = await stripePromise;
          stripe.redirectToCheckout({ sessionId });

        }
      });
  
    } catch (e) {
      setSnackbarMessage(`Error starting checkout: ${e.message || e}`);
      setOpen(true);
    }
  };
  

const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');

const handleManageSubscription = async () => {
    try {
        setLoading(true);
        const link = await createPortalLink({});
        window.location.href = link.data.url;
    } catch (error) {
        console.error('Error creating customer portal link:', error);
    }
};


  return (
    <>
    <Helmet>
        <html lang="ru" /> 
        <title>DEFAUST MEMBERSHIP | DEFAUST</title>
        <meta name="description" content="Оформляйте подписку на DEFAUST и развиваться в трейдинг, крипте и инвестициях в умном окружении." />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "Подписки",
              "description": "Оформление подписки в DEFAUST"
            }
          `}
        </script>
    </Helmet>

    <Container maxWidth="lg">
    <Grid container spacing={3} sx={{mt: 4}}>
      {prices.map((price) => (
        <Grid item xs={12} sm={12} md={4} key={price.id}>
                    <CardWrapper>
                    <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center', height: 140, width: '100%' }}>
                    <CardMedia
                component="img"
                image={price.product.images[0]}
                alt={price.product.name}
                sx={{objectFit: 'cover', width: 150, height: 150}}
              />
              </Box>
            <Typography sx={{pt: 2}} fontSize={'1.5rem'} fontWeight={700}>{price.product.name}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0,  alignContent: 'center',
  alignItems: 'center', pt: 0}}>
            <Typography 
                textAlign={'center'}
                fontSize={'2.5rem'} 
                fontWeight={700}
            >
                {price.unit_amount/100} $
            </Typography>

            <Typography sx={{ml: 1}} fontSize={'1.25rem'} fontWeight={200} color={"#00000097"} lineHeight={0.8}>в <br></br>месяц</Typography>
            </Box>
            
            <LoadingButton 
  sx={{mt: 3,  textTransform: 'none', borderRadius: 3,}} 
  fullWidth 
  loading={loading}
  size="large" 
  type="submit" 
  variant="contained" 
  disabled={hasAnySubscription && !hasSubscription(price.id)}
  onClick={() => { 
    if (hasSubscription(price.id) === false) {
      toggleDialog();
    } else {
      handleManageSubscription();
    }
  }}  
>
  {hasAnySubscription ? 'Управлять подписками'  : 'Оформить подписку'}
</LoadingButton>

<Dialog open={openDialog} onClose={toggleDialog}
  PaperProps={{

    style: {
      backdropFilter: 'blur(5px)',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      border: "1px solid rgba(209, 213, 219, 0.8)", // border
      boxShadow: "0px 2px 1px -1px #00000010", //shadow
      borderRadius: 10,
      maxHeight: '90vh',
      
    },
  }}
>

  <DialogTitle>Введите ваш ник в Discord</DialogTitle>
  <DialogContent>
  <TextField
  fullWidth
  label="Например: defaust"
  value={discordUsername}
  onChange={(e) => setDiscordUsername(e.target.value)}
  error={!isValid.status}
  helperText={isValid.message}
/>

    <FormControlLabel
    sx={{mt:1}}
      control={
        <Checkbox
          checked={checkboxChecked}
          onChange={(e) => setCheckboxChecked(e.target.checked)}
          color="primary"
        />
      }
      label="Я подтверждаю, что это мой ник в Discord"
    />
<Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={() => setOpen(false)}
    >
      <Alert onClose={() => setOpen(false)} severity="error">
        {snackbarMessage}
      </Alert>
    </Snackbar>
  </DialogContent>
  <DialogActions>
    <Button onClick={toggleDialog} color="primary">
      Отмена
    </Button>
    <Button onClick={validateInput} color="primary">
      Стать частью комьюнити
    </Button>
  </DialogActions>

</Dialog>



<List>
                {price.product.includedItems.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <CheckCircleIcon sx={{color: '#548dff'}} />
                    </ListItemIcon>
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
                {price.product.excludedItems.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <ClearIcon color="error" /> {/* use ClearIcon with color error for cross icon */}
                    </ListItemIcon>
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>

          </CardWrapper>
        </Grid>
      ))}
      <Grid item xs={12} sm={12} md={8}>
        <CardWrapper>
        <CardContent>
        <Typography variant="h3" component="h2" gutterBottom>
          Как стать участником комьюнити?
        </Typography>
        {steps.map((step, index) => (
          <Container key={index} sx={{mt: 4}}>
            <Box display="flex" alignItems="start">
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  border: '2px solid rgba(0, 0, 0, 1)',
                  background: '#FFF',
                  color: '#000',
                  marginRight: 2,
                }}
              >
                <Typography sx={{fontWeight: 'bold'}} variant="subtitle1">{index + 1}</Typography>
              </Box>
              <Typography variant="h4">{step.title}</Typography>
            </Box>
            <Box>
            <Typography sx={{fontSize: '1rem'}} variant="body">{step.description}</Typography>
            </Box>
            {step.link && (
              <Button
                sx={{mt: 2, background: '#7289da'}}
                variant="contained"
                color="primary"
                onClick={() => window.open(step.link, '_blank')}
              >
                Присоединиться в Discord
              </Button>  )}
              {(step.image ? (
                 <Box display="flex" alignItems="flex-start" justifyContent={'flex-start'}>
                <CardMedia
                  sx={{
                    py: 2,
                    maxHeight: 400,
                    objectFit: 'contain',
                  }}
                  component="img"
                  image={step.image}
                  alt={step.title}
                />
                </Box>
              ) : null)}

            
          </Container>
        ))}
      </CardContent>
        </CardWrapper>
      </Grid>
    </Grid>
    </Container>
    </>
  );
}
