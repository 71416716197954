import { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography,  Alert, Snackbar, Chip, Stack } from '@mui/material';
import { addDoc, updateDoc, doc, collection, Timestamp, deleteDoc} from 'firebase/firestore';
import { db } from '../../firebase';
import { ImageUploader } from './AdminImageUploader';
export const MeetingForm = ({ initialData, onSave }) => {
    const [formData, setFormData] = useState(initialData || {});
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success'); // Can be 'error', 'warning', 'info', or 'success'
    const [tags, setTags] = useState(formData.tags || []);
    const [tagInput, setTagInput] = useState('');
    const handleDelete = async () => {
        if (window.confirm("Are you sure you want to delete this meeting?")) {
        try {
            if (initialData?.id) {
                const meetingRef = doc(db, 'resources', 'streams', 'meetings', initialData.id);
                await deleteDoc(meetingRef);
                onSave(); // Assuming `onSave` also serves as a refresh mechanism to reflect the changes
            } else {
                console.error("No meeting ID provided. Cannot delete.");
            }
        } catch (error) {
            console.error("Error deleting the meeting:", error);
        }
    }
    };
    const handleAddTag = () => {
        if (tagInput && !tags.includes(tagInput)) {
            setTags(prevTags => [...prevTags, tagInput]);
            setTagInput('');
        }
    };
    
    const handleDeleteTag = (tagToDelete) => () => {
        setTags(prevTags => prevTags.filter(tag => tag !== tagToDelete));
    };
    useEffect(() => {
        if (initialData) {
            // Convert Firestore's timestamp to YYYY-MM-DDTHH:MM format
            let dateStr = '';
            if (initialData.timestamp) {
                const jsDate = new Date(initialData.timestamp.seconds * 1000);
                dateStr = jsDate.toISOString().slice(0, 16);
            }
    
            // Get the tags
            setTags(initialData.tags || []);
    
            // Set form data
            setFormData({
                eventName: initialData.eventName || '',
                description: initialData.description || '',
                image: initialData.image || '',
                timestamp: dateStr,
                url: initialData.url || '',
                recordUrl: initialData.recordUrl || '',
                // ... Add other fields as necessary
            });
        }
    }, [initialData]);
    
    
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
  
    const handleSubmit = async () => {

    try {
        const dataToSave = { ...formData };

        if (!formData.recordUrl) {
        delete dataToSave.recordUrl;
        }
        if (formData.timestamp) {
            // Convert the ISO string to a JS Date object first
            const jsDate = new Date(formData.timestamp);

            // Now convert the JS Date to a Firestore timestamp
            formData.timestamp = Timestamp.fromDate(jsDate);
        }
        formData.tags = tags;
      if (initialData?.id) {
        const meetingRef = doc(db, 'resources', 'streams', 'meetings', initialData.id);
        await updateDoc(meetingRef, formData);
      } else {
        const meetingCollection = collection(db, 'resources', 'streams', 'meetings');
        await addDoc(meetingCollection, formData);
      }
      onSave();
      setAlertMessage('Meeting saved successfully!');
      setAlertSeverity('success');
      setAlertOpen(true);
    } catch (error) {
        console.error("Error saving the meeting:", error);
        setAlertMessage('Error saving the meeting. Please try again.');
        setAlertSeverity('error');
        setAlertOpen(true);
      console.error("Error saving the meeting:", error);
    }
  };
  const handleCloseAlert = () => {
    setAlertOpen(false);
};
  return (
    <Box>
      <Typography variant="h5">Add/Edit Meeting</Typography>
      <Stack gap={3}>
      <TextField label="Event Name" name="eventName" value={formData.eventName || ''} onChange={handleChange} fullWidth />
      <TextField label="Description" name="description" value={formData.description || ''} onChange={handleChange} fullWidth />
      <TextField label="Image URL" name="image" value={formData.image || ''} onChange={handleChange} fullWidth />
      <ImageUploader onUpload={(url) => setFormData(prev => ({ ...prev, image: url }))} />
      <TextField
        label="Timestamp"
        type="datetime-local"
        name="timestamp"
        value={formData.timestamp || ''}
        onChange={handleChange}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField label="URL" name="url" value={formData.url || ''} onChange={handleChange} fullWidth />
      <TextField label="Record URL" name="recordUrl" value={formData.recordUrl || ''} onChange={handleChange} fullWidth />

      <Box>
    {tags.map(tag => (
        <Chip
            key={tag}
            label={tag}
            onDelete={handleDeleteTag(tag)}
            variant="outlined"
            style={{ marginRight: '8px', marginBottom: '8px' }}
        />
    ))}
    <TextField
        label="Add Tag"
        value={tagInput}
        onChange={e => setTagInput(e.target.value)}
        onKeyDown={e => e.key === 'Enter' && handleAddTag()}
    />
    <Button onClick={handleAddTag}>Add Tag</Button>
</Box>
      <Button variant="contained" color="secondary" onClick={handleSubmit}>Save</Button>
      <Button variant="contained" color="primary" onClick={handleDelete}>Delete Meeting</Button>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            </Stack>
    </Box>
  );
};
