import React, { createContext, useState, useContext } from 'react';

const TourContext = createContext();

export function TourProvider({ children }) {
  const [isTourOpen, setTourOpen] = useState(false);
  return (
    <TourContext.Provider value={{ isTourOpen, setTourOpen }}>
      {children}
    </TourContext.Provider>
  );
}

export function useTour() {
  const context = useContext(TourContext);
  if (!context) {
    throw new Error("useTour must be used within a TourProvider");
  }
  return context;
}


const steps = [
  {
    target: '.my-first-step',  // Target element with class .my-first-step
    content: 'This is the first step!',
    title: 'Step 1',
  },
  {
    target: '#my-second-step',  // Target element with ID #my-second-step
    content: 'This is the second step!',
    title: 'Step 2',
  },
  // ... more steps
];

export default steps;
