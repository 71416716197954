import { useState, useContext} from 'react';
import { auth } from '../../../firebase'; 
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_

import UserContext from '../../../UserContext';
// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function AccountPopover() {
  const account = useContext(UserContext);
  const navigate = useNavigate();
const MENU_OPTIONS = [
  {
    label: 'Профиль',
    icon: 'eva:home-fill',
    handleClick: () => navigate('/settings/profile'),
  },

  {
    label: 'Подписка',
    icon: 'eva:settings-2-fill',
    handleClick: () => navigate('/settings/subscriptions'),
  },
];
  
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut(); 
      handleClose();
      navigate('/login');  // navigate to main page
    } catch (error) {
      console.error(error.message);
    }
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
      <Avatar src={account?.avatarURL || '/assets/images/avatars/user_avatar.png'} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          '.MuiPaper-root': {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            backgroundColor: alpha("#2E2E2E", 0.625),
            backdropFilter: "blur(15px)",
            border: "1px solid rgba(209, 213, 219, 0.3)",
            boxShadow: "0px 2px 1px -1px #ffffff10",
            borderRadius: 5,
          },
        }}>
        <Box sx={{ my: 1.5, px: 2.5}}>
        <Typography variant="subtitle2" color={'#FFF'} noWrap>
  {account?.name || 'Defaust Trader'}
</Typography>

          <Typography variant="body2" sx={{ color: '#FFF' }} noWrap>
            {account?.email || ''}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
        {MENU_OPTIONS.map((option) => (
          <MenuItem             sx={{
            borderRadius: 5,
            color: '#FFF',
            "&:hover": {
            cursor: 'pointer', 
            backdropFilter: "blur(15px)",
            backgroundColor: alpha("#101010", 0.425),
            border: "0.5px solid rgba(209, 213, 219, 0.3)",
            boxShadow: "0px 2px 1px -1px #ffffff10",
            borderRadius: 2,
            color: '#FFF'
          },}} key={option.label} onClick={() => {
            option.handleClick();
            handleClose();
          }}>
            {option.label}
          </MenuItem>
        ))}
      </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem  onClick={handleLogout}             sx={{
              m: 1,
              borderRadius: 2,
              color: '#FFF',
              "&:hover": {
              cursor: 'pointer', 
              backdropFilter: "blur(15px)",
              backgroundColor: alpha("#101010", 0.425),
              border: "0.5px solid rgba(209, 213, 219, 0.3)",
              boxShadow: "0px 2px 1px -1px #ffffff10",
              borderRadius: 2,
              color: '#FFF'
            },}}>
          Выйти
        </MenuItem>
      </Popover>
    </>
  );
}
