import { useState, useEffect } from 'react';
import { getDocs, query, where, collection, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

export const useFetchCourse = (courseId) => {
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [structureItems, setCourseStructure] = useState([]);

  const fetchCourseDataAndLessons = async () => {
    const coursesCollection = collection(db, "courses");
    const q = query(coursesCollection, where("id", "==", courseId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const courseDoc = querySnapshot.docs[0];
      const courseData = courseDoc.data();

      // Fetch lessons, reviews, and structure items in parallel
      const [lessonsData, reviewData, structureData] = await Promise.all([
        getDocs(collection(courseDoc.ref, "lessons")),
        getDocs(query(collection(courseDoc.ref, 'reviews'), where('verified', '==', true))),
        Promise.resolve(courseData.structure.map(async (item) => {
          const type = Object.keys(item)[0];
          const id = item[type];
          const pathType = type === 'lesson' ? 'lessons' : type;
          const itemDoc = await getDoc(doc(db, `courses/${courseDoc.id}/${pathType}/${id}`));
          return {
            type,
            id,
            data: itemDoc.exists() ? itemDoc.data() : "Not found",
          };
        }))
      ]);

      const parsedLessons = lessonsData.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
        };
      });

      const parsedReviews = reviewData.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      const allReviews = parsedReviews.map(r => r.rating);
      const avgRating = allReviews.reduce((acc, rating) => acc + rating, 0) / allReviews.length;
      courseData.rating = isNaN(avgRating) ? undefined : avgRating;

      // Batch state updates
      setCourse(courseData);
      setLessons(parsedLessons);
      setReviews(parsedReviews);
      setCourseStructure(await Promise.all(structureData));
    } else {
      console.error("No such course!");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchCourseDataAndLessons();
      setLoading(false);
    };
    fetchData();
  }, [courseId]);

  return { loading, course, lessons, reviews, structureItems };
};
