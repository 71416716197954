import React, { useEffect, useState, useContext, useMemo} from 'react';
import {useMediaQuery, useTheme, Rating, List, Tooltip, Grid, Box, Button, Container, Typography, ListItem, ListItemIcon, ListItemText, FormControl, InputLabel, Select, MenuItem , Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { alpha, styled } from '@mui/material/styles';
import { db, analytics } from '../../firebase';
import { getDoc, collection, doc, query, where, getDocs } from "firebase/firestore";
import { Helmet } from 'react-helmet-async';
import { useParams} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
//icons
import BackIcon from '@mui/icons-material/KeyboardBackspace';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import QuizIcon from '@mui/icons-material/Quiz';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import EditIcon from '@mui/icons-material/Edit';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {whyDefaust} from '../../components/mock/whyDefaust'
//other
import { Link } from 'react-router-dom';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';

import CheckIcon from '@mui/icons-material/Check';

import proMax_sertification_image from '../../sections/assets/certificate-proMax-small.png';

import proMax_sertification from '../../sections/assets/logo-pro-max.png'

import hard_level_icon from '../../sections/assets/hard-level-white.svg'
import medium_level_icon from '../../sections/assets/medium-level-white.svg'
import light_level_icon from '../../sections/assets/light-level-white.svg'
import Skeleton from '@mui/material/Skeleton';
import { CourseStructure, VideoPlayer, ReviewsSectionCourse } from '../../sections';

import UserContext from '../../UserContext';
import { CartContext } from '../../CartContext';
import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import {getLessonWordForm} from '../../utils/getLessonWordForm';
import {useFetchCourse} from '../../hooks/usefetchCourseDataAndLessons';
const TagBox = ({ children }) => (
  <Box
    sx={{
      fontSize: ['14px','14px','14px','16px'], lineHeight: ['24px','24px','24px','28px'],
      fontWeight: 400,
          color: '#FFF',
          borderRadius: 3,
          backdropFilter: 'blur(3px)',
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          border: '1px solid rgba(255, 255, 255, 0.2)',
      py: 0.5,
      px: 2,
      display: 'inline-block', // To make the box size according to content
    }}
  >
    {children}
  </Box>
);

const FeatureList = ({ features }) => (
  <>
    {features.length > 0 && (
      <>
        <Typography  sx={{color: '#fff',fontSize: ['16px','16px','16px','18px'], fontWeight: '600', lineHeight: ['24px','24px','24px','28px']}}>
          Навыки, которые вы получите:
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 1, mb: [1, 1], flexWrap: 'wrap' }}>
          {features.map((feature, index) => (
            <TagBox key={index}>{feature}</TagBox>
          ))}
        </Box>
      </>
    )}
  </>
);

//sections
const Tabs = styled(MuiTabs)(({ theme }) => ({
  borderBottom: '1px solid var(--stroke-grey, #E5E7E9)',
  display: 'flex',  // set as flex container
  width: '100%',
}));


const Tab = styled(MuiTab)(({ theme }) => ({
  minHeight: 70,
  minWidth: 96,
  flex: 1,   
  textTransform: 'initial',
  color: theme.palette.text.primary,
  fontSize: '0.9rem',
  borderLeft: '0.5px solid var(--stroke-grey, #E5E7E9)',
  borderRight: '0.5px solid var(--stroke-grey, #E5E7E9)',
  backgroundColor: '#F5F7F9',

  '&.Mui-selected': {
      
    borderBottom: '1px solid transparent',  // Remove bottom border for the selected tab
    borderRadius: 0,
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.common.black,
  },

  [theme.breakpoints.up('md')]: {
    minWidth: 120
  },
}));

  

const getCertificationImage = (certificationId) => {
  switch(certificationId) {
      case 'proMax':
          return proMax_sertification_image;
      default:
          return proMax_sertification_image;
  }
}

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    '& .MuiTooltip-tooltip': {
      backgroundColor: alpha(theme.palette.background.default, 0.3),
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[1],
      fontSize: 13,
      padding: theme.spacing(1.5, 2.5),
      backdropFilter: 'blur(8px)',
      WebkitBackdropFilter: 'blur(8px)', // Fix on Mobile
      borderRadius: 10,
      borderColor: theme.palette.grey[500_8],
  
    },
  }));

  export default function Course() {
    const { courseId } = useParams();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const account = useContext(UserContext);
    const { loading, course, lessons, reviews, structureItems } = useFetchCourse(courseId);
/*     const fetchCourseDataAndLessons = async () => {
        const coursesCollection = collection(db, "courses");
        const q = query(coursesCollection, where("id", "==", courseId));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
            const courseDoc = querySnapshot.docs[0];
            setCourseData(courseDoc.data());
    
            const lessonsCollection = collection(courseDoc.ref, "lessons");
            const lessonsSnapshot = await getDocs(lessonsCollection);
            
            const lessonsData = lessonsSnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    name: data.name,
                    shortDescription: data.shortDescription
                };
            });
            setCourseLessons(lessonsData);
    
            try {
              const reviewsQuery = query(collection(courseDoc.ref, 'reviews'), where('verified', '==', true));
              const querySnapshot = await getDocs(reviewsQuery);
              const reviews = [];
              querySnapshot.forEach((doc) => {
                reviews.push({ ...doc.data(), id: doc.id });
              });
              setReviews(reviews);
              ////console.log('reviews',reviews);
            } catch (error) {
              console.error('Error fetching reviews:', error);
            }
        } else {
            console.error("No such course!");
        }
          try {
            const courseDoc = querySnapshot.docs[0];
            const courseDocId = courseDoc.id;  // Getting the document ID
            const courseData = courseDoc.data();
            const structure = courseData.structure;
            
            // Fetch the lessons, quizzes, exams, etc.
            const items = await Promise.all(structure.map(async (item) => {
                const type = Object.keys(item)[0];
                const id = item[type];
                
                // If the type is 'lesson', adjust the path
                const pathType = type === 'lesson' ? 'lessons' : type;
                
                // Use courseDocId for querying nested collections and get directly by document ID
                const itemDoc = await getDoc(doc(db, `courses/${courseDocId}/${pathType}/${id}`));
                
                return {
                  type,
                  id,
                  data: itemDoc.exists() ? itemDoc.data() : "Not found",
                };
              }));
            setCourseStructure(items);
          } catch (error) {
            console.error('Error fetching CourseStructure:', error);
          }
    } */
    
    
    //console.log(course);


    
    const navigate = useNavigate();
    const { cart, setCart } = useContext(CartContext);
    ////console.log('cart', cart);
    const handleAddToCart = (courseOrCertification, type) => {
        // Assuming type can be 'courses' or 'certificates'
        if (!cart[type].includes(courseOrCertification)) {
          setCart(prevCart => ({
            ...prevCart,
            [type]: [...prevCart[type], courseOrCertification]
          }));
        }
        logEvent(analytics, 'button_click', { name: 'addToCartCourse' });
        navigate('/cart');
      };

      useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    const getTooltipTitle = (certification) => {
      return "Этот курс часть сертификации " + (
        certification === 'defaust-trader' ? 'DEFAUST TRADER' :
        certification === 'pro' ? 'DEFAUST TRADER: Pro' :
        certification === 'proMax' ? 'DEFAUST TRADER: Pro Max' : ''
      );
    };
    
    const CertificationTooltip = ({ certification }) => {
      const iconSrc = useMemo(() => getCertificationIcon(certification), [certification]);
      const tooltipTitle = useMemo(() => getTooltipTitle(certification), [certification]);
      const linkTo = "/dashboard/catalog/certification/" + certification;
    
      return (
        <HtmlTooltip title={tooltipTitle}>
          <Link to={linkTo}>
            <Box
              component="img"
              src={iconSrc}
              alt={'defaust trader обучение'}
              width={'auto'}
              height={40}
            />
          </Link>
        </HtmlTooltip>
      );
    };
        
    const ICONS = [
        { icon: OndemandVideoIcon, label: {'ru': "Видео-уроки"}},
        { icon: PictureAsPdfIcon, label: {'ru': "PDF презентации"}},
        { icon: QuizIcon, label: {'ru': "Тесты"}},
        { icon: VolumeUpIcon, label: {'ru': "Аудио"}},
        { icon: EditIcon, label: {'ru': "Экзамен"}},
        { icon: WorkspacePremiumIcon, label: {'ru': "Сертификат"}},
    ];

      
    const [activeTab, setActiveTab] = useState(0);
    const [showFullContent, setShowFullContent] = useState(false);
    const handleButtonBack = () => {
    navigate(`/dashboard/catalog/courses`); };
    const handleGoToTarif = (courseId) => {
      // Open the URL in a new window or tab
      window.open("https://defaust.com/pricing", '_blank');
  };
  
    const handleLearnClick = (courseId) => {
      logEvent(analytics, 'button_click', { name: 'learnClickCourse' });
      navigate(`/course/take/${courseId}`);
    };
    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 0,  // Autoplay the video
        },
    };

  



    const getCertificationIcon = (certification) => {
        switch(certification) {
            case 'defaust-trader':
                return proMax_sertification;
            default:
                return proMax_sertification; // or a default icon if any
        }
    }
    ////console.log(course);
    ////console.log(lessons);

    if (loading) {
        return (
            <Container maxWidth="lg">
                <Skeleton variant="text" width={210} height={40} />
                <Skeleton variant="rectangular" width="100%" height={300}/>
                <Skeleton variant="text" width={300} height={70} />
                <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                <Skeleton variant="text" width="100%" height={300} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                <Skeleton variant="text" width="100%" height={300} />
                </Grid>
                </Grid>
            </Container>
        );
      }
  return (
    <>
<Helmet>
  <html lang="ru" />
  <title>Курс {course.name.ru} от DEFAUST®</title>
  <meta name="description" content={`Выбирайте лучшие курсы по трейдингу, криптовалютам и инвестициям от DEFAUST ACADEMY!`} />
  <link rel="canonical" href="https://defaust.com/dashboard/catalog/course" />
  <meta name="robots" content="index, follow" />
  
  {/* OpenGraph tags */}
  <meta property="og:title" content="Курс от DEFAUST®" />
  <meta property="og:description" content="Выбирайте лучшие курсы по трейдингу, криптовалютам и инвестициям от DEFAUST ACADEMY!" />
  <meta property="og:image" content="https://defaust.com/assets/images/previews/preview-course.jpg" />
  <meta property="og:url" content="https://defaust.com/dashboard/catalog/course" />

  {/* Twitter Card tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Курс от DEFAUST®" />
  <meta name="twitter:description" content="Выбирайте лучшие курсы по трейдингу, криптовалютам и инвестициям от DEFAUST ACADEMY!" />
  <meta name="twitter:image" content="https://defaust.com/assets/images/previews/preview-course.jpg" />

  {/* Dynamic Schema */}
  <script type="application/ld+json">
    {`
      {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "Курс ${course.name.ru} от DEFAUST®",
        "description": "Выбирайте лучшие курсы по трейдингу, криптовалютам и инвестициям от DEFAUST ACADEMY!."
      }
    `}
  </script>
</Helmet>
    <Container maxWidth="lg">

<Grid container 
      sx={{ 
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between', 
          background: 'linear-gradient(45deg,#5A7750 30%,#F3FFAB 100%)',  
          border: '1px solid var(--stroke-grey, #E5E7E9)', 
          borderRadius: '10px 10px 0 0',
          pt: 4,
          pl: 4,
          pr: 4,
          pb: 4,
          mt: 4,
      }}
>
    <Grid item xs={12} sm={12} md={7} lg={7} >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0}}>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px'}}>
            <Typography  sx={{color: '#fff', fontSize: ['22px','22px','22px','26px'], fontWeight: 'bold', pb: 3, textTransform: 'uppercase'}}>
                {course.name.ru}            
            </Typography>
            <Typography>
              {isMobile === false && course.associatedCertification.map((certification, index) => (
                <CertificationTooltip key={index} certification={certification} />
              ))}
            </Typography>

          </Box>
            <Typography  sx={{color: '#fff', fontSize: ['16px','16px','16px','18px'], fontWeight: '400', lineHeight: ['24px','24px','24px','28px'], pb: '10px' }}>
                {course.longDescription.ru}
            </Typography>
            <FeatureList features={course.features ?? []} />
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
    <Box sx={{ display: 'flex', flexDirection: ['row'], gap: '5px', py: ['10px','10px','10px','14px'] }}>

<Typography variant="body2" sx={{color: '#fff', fontSize: ['16px','16px','16px','16px'], fontWeight: '400', lineHeight: ['24px','24px','24px','28px'],}}>
  <span style={{fontWeight: 600}}>{course.structure?.length ?? 10}</span> {getLessonWordForm(course.structure?.length ?? 10)}
</Typography>
{!isMobile && (
  <>
  <Typography variant="body2" sx={{color: '#fff', fontSize: ['16px','16px','16px','16px'], fontWeight: '400', lineHeight: ['24px','24px','24px','28px'],}}><span style={{fontWeight: 600}}>•</span></Typography>
<Typography variant="body2" sx={{color: '#fff', fontSize: ['16px','16px','16px','16px'], fontWeight: '400', lineHeight: ['24px','24px','24px','28px'],}}><span style={{fontWeight: 600}}>{course.duration}</span> ч.</Typography>
</>
)}
{course.rating && (
        <>
<Typography variant="body2" sx={{color: '#fff', fontSize: ['16px','16px','16px','16px'], fontWeight: '400', lineHeight: ['24px','24px','24px','28px'],}}><span style={{fontWeight: 600}}>•</span></Typography>
        <Rating name="course-rating" 
        value={course.rating} 
        size={'small'} 
        readOnly 
        precision={0.1}
        sx={{mt: '5px'}}
      />
<Typography variant="body2" sx={{color: '#fff', fontSize: ['16px','16px','16px','16px'], fontWeight: '400', lineHeight: ['24px','24px','24px','28px'],}}><span style={{fontWeight: 600}}>({course.enrolled}</span>)</Typography>
        </>
        )}


    </Box>
  </Box>
            
        </Box>

{
    account.purchased && account.purchased.includes(course.id) ? 
    (
        <Button
            width={isMobile ? '100%' : 'auto'}
            variant="contained"
            onClick={() => handleLearnClick(course.id)}
            sx={{
              backgroundColor: '#fff',
              color: '#000',
              mt: 2,
              mb: [3,0],
              textTransform: 'none',
              "&:hover": {
                  backgroundColor: '#FFFFFF90'
              }}}
        >
            Продолжить изучение
        </Button>
    ) : 
    (
        <Button
            variant="contained"
            width={isMobile ? '100%' : 'auto'}
            onClick={() => handleGoToTarif()}
            sx={{
              backgroundColor: '#fff',
              color: '#000',
              mt: 2,
              mb: [3,0],
              textTransform: 'none',
              "&:hover": {
                  backgroundColor: '#FFFFFF90'
              }}}
        >
           {course.frontendPrice === 0? 'Начать бесплатно' : `Выбрать тариф обучения`} 
        </Button>
    )
}

    </Grid>

    <Grid item xs={12} sm={12} md={5} lg={5} 
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
        <div style={{ width: '100%',  position: 'relative'}}>
        <VideoPlayer 
                url={course.introVideo?.ru}
                opts={{ ...opts, height: '100%', width: '100%' }}
                containerClassName="youtube-container"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}

            />
        </div>
        
    </Grid>
</Grid>
<Tabs 
  centered
  indicatorColor="transparent"
    value={activeTab}
    onChange={(event, newValue) => setActiveTab(newValue)}
    sx={{
      borderRadius: activeTab === 0 ? 'None' : '0px 0px 10px 10px',
      '.MuiTabs-flexContainer': {
        width: '100%',
        justifyContent: 'space-around',
      },
      '.MuiTab-root': {
        flexGrow: 1,
        maxWidth: 'none', // removes the maximum width constraint
      }
    }}
  >
    <Tab label="Почему DEFAUST" />
    <Tab label="Отзывы" />
    <Tab label="План обучения" />
    <Tab label="FAQ" />
  </Tabs>

  {activeTab === 0 && (
<>
<Grid container 
sx={{ 
display: 'flex',
flexDirection: 'row',
background: '#F5F7F9',
borderLeft: '1px solid var(--stroke-grey, #E5E7E9)', 
borderRight: '1px solid var(--stroke-grey, #E5E7E9)', 
borderBottom: '1px solid var(--stroke-grey, #E5E7E9)', 
borderRadius: '0 0 10px 10px',
pt: 4,
pl: 4,
pr: 4,
pb: 4,


}}
>
<Grid container spacing={3}>
<Grid item xs={12} sm={12} md={6} lg={6} 
sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
<Box  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', px: [5,10,10]}}>
<img 
src={getCertificationImage(course.id)} 
alt="Basic Certification" 
sx={{
  objectFit: 'fill'
}}
/>
</Box>
</Grid>





<Grid item xs={12} sm={12} md={6} lg={6}>
<Box sx={{ display: 'flex', flexDirection: 'column', gap: 0}}>
<List sx={{fontSize: '1rem', color: '#444444'}}>
                      {whyDefaust?.ru?.map((feature, index) => (
<ListItem key={index}>
<CheckIcon  sx={{ marginRight: 1 }} />
{feature}
</ListItem>
))}
                      </List>
</Box>
</Grid>
</Grid>
</Grid>
          <Typography variant="h3" sx={{ py: 2 }}>
            О курсе: {course.name.ru}
          </Typography>
          <div 
            style={{
              maxHeight: showFullContent ? 'none' : '980px', // Or any other height you prefer for truncation
              overflow: 'hidden',
            }}
            dangerouslySetInnerHTML={{ __html: course.htmlDescription.ru }} 
          />
          <Box display="flex" flexDirection='row' justifyContent={'center'} alignContent={'center'} alignItems={'center'} gap={1}  >
          <Button 
    size="medium" 
    color="primary" 
    sx={{
        fontSize: '1rem',
        textTransform: 'none', 
        "&:hover .increaseIcon": {
            width: 30,
            transition: 'width 0.3s'
        }
    }}
    onClick={() => setShowFullContent(!showFullContent)}>
    {(showFullContent? 
        <KeyboardArrowUpIcon 
        className="increaseIcon" 
        color="primary" 
        sx={{ 
            marginRight: 1,
            transition: 'width 0.3s'
        }} 
    /> :
        <KeyboardArrowDownIcon 
        className="increaseIcon" 
        color="primary" 
        sx={{ 
            marginRight: 1,
            transition: 'width 0.3s'
        }} 
    />
    )}

    {showFullContent? 'Меньше' : 'Больше'}
</Button>
          </Box>
</>
)}





  {activeTab === 2 && (
<>
          <Typography variant="h3" sx={{ py: 3 }}>
            План обучения
          </Typography>
          <CourseStructure structureItems={structureItems} loading={loading} />
          </>
  )}
{activeTab === 3 && (
      <>
                  <Typography variant="h3" sx={{ py: 3 }}>
            Ответы на ваши вопросы
          </Typography>
    <Box display="flex" flexDirection='column' justifyContent={'start'} alignContent={'start'} alignItems={'start'} gap={1}  paddingBottom={3} >
    {course.faq.ru.map((faq, index) => (
      <Accordion
  key={index}
  sx={{
    flex: 1,

    minWidth: '100%',
    backgroundColor: '#fff',
    borderRadius: 5,
    border: 'none',
    boxShadow: 'none',
    '&.MuiAccordion-root': {
      '&::before': {
        height: '0px',
        borderRadius: 5,
      },
    },
  }}
>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls={`faq-content-${index}`}
    id={`faq-summary-${index}`}
    sx={{border: "1px solid rgba(209, 213, 219, 0.3)", borderRadius: 5,}}
  >
    <Typography sx={{fontSize: 18, fontWeight: 700}}>{faq.question}</Typography>
  </AccordionSummary>
  <AccordionDetails sx={{borderRadius: 5}}>
    <Typography variant='p' sx={{fontSize: 16, fontWeight: 400}}>{faq.answer}</Typography>
  </AccordionDetails>
</Accordion>

    ))}
    </Box>
    </>
)}

{activeTab === 1 && (
<>
<ReviewsSectionCourse reviews={reviews} />
</>
)}
</Container>
    </>
  );
}