import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { List, ListItem, Button, Container } from '@mui/material';

export const MeetingList = ({ onSelect }) => {
  const [meetings, setMeetings] = useState([]);

  useEffect(() => {
    const fetchMeetings = async () => {
      const meetingCollection = collection(db, 'resources', 'streams', 'meetings');
      const querySnapshot = await getDocs(meetingCollection);
      const allMeetings = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setMeetings(allMeetings);
    };

    fetchMeetings();
  }, []);

  return (
    <List>
      {meetings.map((meeting) => (
        <ListItem key={meeting.id} button onClick={() => onSelect(meeting)}>
          {meeting.eventName}
        </ListItem>
      ))}
    </List>
  );
};
