import React, {useState, useEffect} from 'react';
import { Navigate, useRoutes, useNavigate, Route, Routes } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation } from 'react-router-dom';
// layouts
import {DashboardLayout,
        OtherPageLayout,
        PublicLayout,
        CatalogLayout,
        CourseLayout} from './layouts/dashboard';
import BasicLayout from './layouts/simple/BasicLayout';
import SimpleLayout from './layouts/simple/SimpleLayout';
import SettingsLayout from './layouts/settings/SettingsLayout';
import { AllCourses,
        EditCourse,
        ItemEditPage,
        CatalogCourses,
        CatalogCryptoCourses,
        CatalogInvestingCourses,
        CatalogTradingCourses,
        Course,
        Certification,
        DashboardAppPage,
        Recaps,
        Resources,
        Skills,
        MyCourses,
        Cart,
        SettingsSubscription,
        ItemPage,
        TakeCourse,
        Page404,
        PrivacyPolicy,
        TermsAndServices,
        ThankYou,
        LoginPage,
        RegistrationFlow,
        RegistrationPage,
        Homepage,
        ReviewsAdmin,
        GetCertificatePage,
        SettingsAccount,
        UserManagement,
        AdminMeetings,
        Prices,
        CatalogTradingLanding
        } from './pages';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { auth, db } from './firebase';
import PurchasedBasedRoute from './PurchasedBasedRoute.js';
import AdminBasedRoute from './adminBasedRoute';


import AdminLayout from './layouts/dashboard/admin/AdminLayout';
import AuthLayout from './layouts/auth/AuthLayout';
import {CatalogLayoutLanding,
        OnboardingLayout,
        LandingLayout} from './layouts/landing';
import { User } from 'react-feather';
// ----------------------------------------------------------------------



const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const [registrationCompleted, setRegistrationCompleted] = useState(false);
  
  useEffect(() => {
    if (loading) {
      // authentication state is being determined
      return;
    }
    if (!user) {
      // user is not authenticated
      navigate("/login");
    } else {
      // user is authenticated, check registration status
      const fetchRegistrationStatus = async () => {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        const registrationStep = userDoc.data()?.registrationStep || false;
        setRegistrationCompleted(registrationStep === 'end' || registrationStep === true);
        if (registrationStep !== 'end' && registrationStep !== true) {
          navigate("/onboarding");
        }
      };
      fetchRegistrationStatus();
    }
  }, [user, loading]);

  return loading ? null : (registrationCompleted === true && user) ? children : null;
};





export default function Router() {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  
  const routes = useRoutes([
    {
      path: '/',
      element: <ProtectedRoute> <DashboardLayout /> </ProtectedRoute>,
      children: [
        { path: '/', element: <DashboardAppPage />, index: true },
        { path: '/dashboard', element: <DashboardAppPage />,},
        { path: '/dashboard/courses', element: <MyCourses />},
        { path: '/dashboard/catalog', element: <CatalogCourses /> },
        { path: '/dashboard/progress', element: <Skills /> },
        { path: '/dashboard/resources', element: <Resources /> },
        { path: '/dashboard/conferences', element: <Recaps /> },
        { path: '/dashboard/thank-you', element: <ThankYou /> },
        { path: '/dashboard/course/take/:courseId/get-certificate', element: <GetCertificatePage /> },
        
      ],
    },
    {
      path: '/admin',
      element: <AdminBasedRoute component={AdminLayout} allowedRoles={['admin']} />,
      children: [
        { path: "/admin/courses", element: <AllCourses />, index: true },
        { path: "/admin/reviews", element: <ReviewsAdmin />, index: true },
        { path: "/admin/user-mamangement", element: <UserManagement />, index: true },
        { path: "/admin/meetings", element: <AdminMeetings />, index: true },
        { path: "/admin/course/edit/:courseId", element: <EditCourse />, index: true },
        { path: "/admin/course/edit/:courseId/:type/:itemId", element: <ItemEditPage />, index: true },

      ],
      
      
    },

    {
      path: '/register',
      element:  <AuthLayout />,
      children: [
        { path: '', element: !user ? <RegistrationPage /> : <Navigate to="/dashboard"  />, index: true }
      ]
    },
    {
      path: '/login',
      element:  <AuthLayout />,
      children: [
        { path: '', element: !user ? <LoginPage /> : <Navigate to="/dashboard" />, index: true }
      ]
    },
/*     {
      path: '/',
      element: <Homepage /> , index: true}, */

    {
      path: '/terms-and-services',
      element: <BasicLayout />,
      children: [
        { path: '', element: <TermsAndServices /> },
      ],
    },
    {
      path: '/privacy-policy',
      element: <BasicLayout />,
      children: [
        { path: '', element: <PrivacyPolicy /> },
      ],
    },
    {
      path: '/onboarding',
      element:  <AuthLayout />,
      children: [
        { path: '', element: <RegistrationFlow /> , index: true},
      ],
    },
    {
      path: '/onboarding',
      element: <RegistrationFlow /> , index: true
    },
    {
      path: '/dashboard/catalog',
      element: <ProtectedRoute><DashboardLayout /></ProtectedRoute>,
      children: [
        { element: <Navigate to="/dashboard/catalog/courses" /> , index: true },
        { path: '/dashboard/catalog/courses', element: <CatalogCourses /> },
        { path: '/dashboard/catalog/trading', element: <CatalogTradingCourses /> },
        { path: '/dashboard/catalog/crypto', element: <CatalogCryptoCourses /> },
        { path: '/dashboard/catalog/investing', element: <CatalogInvestingCourses /> },
        { path: '/dashboard/catalog/certifications', element:  <Navigate to="/dashboard/catalog/certification/defaust-trader" /> },
      ],
    },
    {
      path: '/course/take/:courseId',
      element: <ProtectedRoute><PurchasedBasedRoute component={CourseLayout} /></ProtectedRoute>,
      children: [
        { path: '/course/take/:courseId', element: <TakeCourse />, index: true },
        { path: ':type/:itemId', element: <ItemPage /> },
      ],
    },    
    
    {
      path: '/dashboard/catalog/course/:courseId',
      element: <ProtectedRoute><OtherPageLayout/></ProtectedRoute>,
      children: [
        { path: '/dashboard/catalog/course/:courseId', element: <Course />, index: true },
      ],
    },
    {
      path: '/resources',
      element: <ProtectedRoute><OtherPageLayout/></ProtectedRoute>,
      children: [
        { path: '/resources', element: <Resources />,  },
      ],
    },
    {
      path: '/recaps',
      element: <ProtectedRoute><DashboardLayout /></ProtectedRoute>,
      children: [
        { path: '/recaps', element: <Recaps />, index: true },
      ],
    },
    {
      path: '/cart',
      element: <ProtectedRoute><OtherPageLayout/></ProtectedRoute>,
      children: [
        { path: '/cart', element: <Cart />, index: true },
      ],
    },
    {
      path: '/dashboard/catalog/certification/:certificationId',
      element: <ProtectedRoute><OtherPageLayout/></ProtectedRoute>,
      children: [
        { path: '/dashboard/catalog/certification/:certificationId', element: <Certification />},
      ],
    },
    {
      path: '/settings',
      element: <ProtectedRoute><SettingsLayout /></ProtectedRoute>,
      children: [
        { element: <Navigate to="/settings/profile" />, index: true },
        { path: 'profile', element: <SettingsAccount /> },
        { path: 'subscriptions', element: <SettingsSubscription /> },
      ],
    },   
    {
      element: <SimpleLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
