
import { AdminEnrollComponent, AdminUpdateAllMembershipsComponent, AdminUpdateMembershipComponent, AdminGrantMentorshipComponent, AdminCRM } from "../../sections";
import { db } from "../../firebase";
import { Container } from "@mui/material";
export default function AllCourses() {
    return (
        <>
        <Container maxWidth="lg">
        <AdminCRM />
        <AdminEnrollComponent />
        <AdminGrantMentorshipComponent />
        <AdminUpdateMembershipComponent />
        <AdminUpdateAllMembershipsComponent />
    </Container>
    </>
    )}