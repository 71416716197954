import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, Typography, Box } from '@mui/material';
import { alpha, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function AddOns({ courses, selectedCourses, setSelectedCourses }) {
    const navigate = useNavigate();
    const handleRowClick = (courseId, type) => {
        navigate(`/dashboard/catalog/${type}/${courseId}`);
    };

    const handleToggle = (courseId) => {
        if (selectedCourses.includes(courseId)) {
          setSelectedCourses(prevSelected => prevSelected.filter(id => id !== courseId));
        } else {
          setSelectedCourses(prevSelected => [...prevSelected, courseId]);
        }
      };

    return (
        <TableContainer component={Paper} 
            sx={{ 
                backgroundColor: '#F5F7F9',
                border: "1px solid rgba(209, 213, 219, 0.3)",
                borderRadius: 5,
                px: 2,
                mt: 5,
            }}
        >
             <Typography variant="h5" sx={{pt: 3, pl: 1}}>Добавить еще курсов?</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{
                            background: 'transparent',
                            color: '#000',
                            py: 0,
                            px: 2,
                            textAlign: 'center',
                            fontWeight: 700,
                            borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                        }}></TableCell>
                        <TableCell 
                        sx={{
                            background: 'transparent',
                            color: '#000',
                            py: 0,
                            px: 2,
                            textAlign: 'center',
                            fontWeight: 700,
                            borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                        }}>Название</TableCell>
                        <TableCell sx={{
                            background: 'transparent',
                            color: '#000',
                            py: 0,
                            px: 2,
                            textAlign: 'center',
                            fontWeight: 700,
                            borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                        }}>Цена</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {courses.map((course) => (
                        <TableRow 
                            key={course.id}
                            sx={{
                                py: 0,
                                "&:hover": {
                                    backgroundColor: alpha("#FFF", 0.15),
                                },
                                "&:last-child td, &:last-child th": {
                                    borderBottom: 0,
                                },
                                borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                            }}
                        >
                            <TableCell 
                                sx={{
                                    background: 'transparent',
                                    color: '#000',
                                    py: 0,
                                    px: 2,
                                    textAlign: 'center',
                                    borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                                }}
                            >
                                <Checkbox
                                    checked={selectedCourses.includes(course.id)}
                                    onChange={() => handleToggle(course.id)}
                                />
                            </TableCell>
                            <TableCell 
                                sx={{
                                    
                                    background: 'transparent',
                                    color: '#000',
                                    py: 0,
                                    px: 2,
                                    fontWeight: 700,
                                    borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                                    textAlign: 'center',
                                    py: 4,
                                    maxWidth: '300px',
                                }}
                            >
                                <Box 
                                    sx={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        gap: 1,
                                        textAlign: 'start'
                                    }}
                                >
                                    <Typography onClick={() => handleRowClick(course.id, course.type)}
                                        sx={{
                                            color: '#3271d2', 
                                            fontWeight: 700,
                                            cursor: 'pointer',
                                            "&:hover": {
                                                textDecoration: 'underline',
                                              }
                                        }}
                                    >{course.name.ru}</Typography>
                                    <Typography 
                                        sx={{
                                            color: '#898989'
                                        }}
                                    >{course.description.ru}</Typography>
                                </Box>
                            </TableCell>
                            <TableCell 
                                sx={{
                                    background: 'transparent',
                                    color: '#F52525',
                                    py: 0,
                                    px: 2,
                                    textAlign: 'center',
                                    borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                                    fontWeight: 700,
                                    fontSize: 16
                                }}
                            >
                                    <Box sx={{
                                        color:  '#204631',
                                        background: '#6BEBA470',
                                        borderRadius: "50px",
                                        py: 0.5,
                                        px: 2,
                                        display: 'inline-block', // To make the box size according to content
                                    }}>
                                    {(course.price).toFixed(2)}$
                                    </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default AddOns;
