import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase'; // path to your firebase config file
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// @mui
import { TextField, Button, Stack, Box, Avatar, Typography, CircularProgress, Alert } from '@mui/material';
import useAuth from '../../hooks/useAuth';

export default function PersonalInfoForm({ userId, setRegistrationStep }) {
    const isAuth = useAuth();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [nickname, setNickname] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [avatarPreviewURL, setAvatarPreviewURL] = useState('/assets/images/avatars/user_avatar.png'); // default avatar
  const [avatarURL, setAvatarURL] = useState('/assets/images/avatars/user_avatar.png'); // default avatar
  const [isUploading, setUploading] = useState(false);
  const [nicknameExists, setNicknameExists] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    if (nickname) {  // Only run this check if nickname is truthy (not null, undefined, empty string, etc.)
      const checkNickname = async () => {
        const nicknameDocRef = doc(db, 'nicknames', nickname);
        const nicknameDoc = await getDoc(nicknameDocRef);
      
        if (nicknameDoc.exists()) {
          setNicknameExists(true);
          setUploading(false);
          return;
        }
        else {
          setNicknameExists(false);
        }
      };
      checkNickname();
    }
  }, [nickname]);





  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    setAvatar(file);
    setAvatarPreviewURL(URL.createObjectURL(file)); // set the preview URL here
  }

  const handleAvatarClick = () => {
    inputRef.current.click();
  }

  const handleSave = async (e) => {
    e.preventDefault();
    setUploading(true);
  
    let finalAvatarURL = avatarURL; // Initialize with the existing avatarURL
  
    // Upload avatar if one was selected
    if (avatar) {
      const storage = getStorage();
      const storageRef = ref(storage, `avatars/${userId}`);
      const uploadTask = uploadBytesResumable(storageRef, avatar);
      
      await new Promise((resolve, reject) => {
        uploadTask.on('state_changed', 
          (snapshot) => { },
          (error) => { reject(error); },
          async () => {  // Make this an async function
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            ////console.log(downloadURL);
            finalAvatarURL = downloadURL; // Directly set the finalAvatarURL
            resolve();
          }
        );
      });
    }
  
    // If the nickname is free, claim it for the current user
    const nicknameDocRef = doc(db, 'nicknames', nickname);
    await setDoc(nicknameDocRef, { userId });
  
    // Now you can save the rest of the user data
    const userDocRef = doc(db, 'users', userId);
    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      // Document exists, so update it
      await updateDoc(userDocRef, {
        name,
        nickname,
        registrationStep: true,
      });
    } else {
      // Document does not exist, so set it
      await setDoc(userDocRef, {
        name,
        nickname,
        points: 0,
        registrationStep: true,
        role: 'default',
        ...(finalAvatarURL ? { avatarURL: finalAvatarURL } : {}),
      });
    }
    
    setRegistrationStep(true);
    setUploading(false);
    navigate("/dashboard", { replace: true });
  };
  
  
  
  ////console.log('User auth status:', isAuth);

  return (
    <Box sx={{ padding: 3, minWidth: '30%', textAlign: 'center',   }}>
      <Typography color={'#FFF'} variant="h3" gutterBottom>Добро пожаловать!</Typography>
      <Avatar sx={{ width: 100, height: 100, margin: '0 auto', mt: 2,  marginBottom: 2 }} src={avatarPreviewURL} onClick={handleAvatarClick} style={{cursor: 'pointer'}} />
      <input
        type="file"
        hidden
        onChange={handleAvatarChange}
        disabled={isUploading}
        ref={inputRef}
      />
      <form onSubmit={handleSave}>
        <Stack spacing={2} direction="column">
          <TextField
            name="name"
            label="Имя"
            onChange={e => setName(e.target.value)}
            sx={{
              "& label.Mui-focused": {
                color: "white",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "rgba(255, 255, 255, 0.2)",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.6)",
                },
                "&:hover fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.6)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.6)",
                },
              },
            }}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            InputProps={{
              style: { color: 'white' },
            }}/>
          <TextField
            name="nickname"
            label="Никнейм"
            onChange={e => setNickname(e.target.value)}
            error={nicknameExists}
            helperText={nicknameExists ? 'Никнейм занят' : ""}
            sx={{
              "& label.Mui-focused": {
                color: "white",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "rgba(255, 255, 255, 0.2)",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.6)",
                },
                "&:hover fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.6)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.6)",
                },
              },
            }}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            InputProps={{
              style: { color: 'white' },
            }}/>
          <Button 
  fullWidth
  size="large"
  type="submit"
  variant="contained"
  disabled={!name || !nickname || isUploading || nicknameExists}
  sx={{
    borderRadius: 3,
    background: (!name || !nickname || isUploading || nicknameExists) ? 'none' : "#FFF",
    color: (!name || !nickname || isUploading || nicknameExists) ? 'grey' : 'black',
    '&:hover': {
      backgroundColor: (!name || !nickname || isUploading || nicknameExists) ? 'grey' : '#FFFFFF90',
    },
  }}
>
              Далее
            </Button>
            {isUploading && <CircularProgress size={24} sx={{ position: 'absolute', top: '50%', left: '50%', mt: -1.5, ml: -1.5 }} />}
        </Stack>
      </form>
    </Box>
  );
}
