import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { alpha } from '@mui/material';
const TermsAndServices = () => {
  return (
    <>
        <Helmet>
            <html lang="ru" /> 
            <title>Условия и Положения | DEFAUST</title>
            <meta name="description" content="Правила и условия использования DEFAUST Analytics." />
            <script type="application/ld+json">
              {`
                {
                  "@context": "http://schema.org",
                  "@type": "WebPage",
                  "name": "Условия и Положения",
                  "description": "Правила и условия использования DEFAUST Analytics."
                }
              `}
            </script>
        </Helmet>

        <Container maxWidth="xl">
        <Typography variant="h3" sx={{ mt: -12, mb: 5 }}>
        Условия и Положения
  </Typography>
      <Box sx={{
            px: 5,
            py: 2,
              backgroundColor: alpha("#111219", 0.225), // semi-transparent background
              backdropFilter: "blur(10px)", // glassmorphism effect
              border: "0.5px solid rgba(209, 213, 219, 0.3)", // border
              boxShadow: "0px 2px 1px -1px #ffffff10", //shadow
              borderRadius: '15px', 
      }}>

        <Typography paragraph>
            Feedback on our terms of service is highly encouraged. Kindly forward any inquiries or comments to support@defaust.com
          </Typography>
          <Typography variant="h6" gutterBottom>
          User's Acknowledgment and Acceptance of Terms
          </Typography>
          <Typography paragraph>
          <p>OneStake &Ouml;U, trading as Defaust (&quot;Defaust&quot;, &quot;Us&quot;, &quot;We&quot;, or &quot;Our&quot;) presents the Defaust platform and various connected services (collectively referred to as the &quot;platform&quot;) to you, the user, under the condition of your adherence to all the terms, conditions, and notifications contained or referenced in this document (the &quot;Terms of Service&quot;), as well as any other written agreement that might exist between us and you. Moreover, users will be subject to any posted rules relevant to such services or resources that may encompass terms and conditions additional to those within these Terms of Service whenever using specific services or materials on this platform. All such rules or guidelines are, by this reference, incorporated within these Terms of Service.</p>
<p></p>
<p>BY UTILIZING THIS PLATFORM, YOU CONSENT TO BE GOVERNED BY THESE TERMS OF SERVICE. SHOULD YOU NOT AGREE TO BE BOUND BY THESE TERMS OF SERVICE, YOU ARE ADVISED TO EXIT THE PLATFORM IMMEDIATELY. THE SOLE REMEDY FOR DISSATISFACTION WITH THIS PLATFORM, OR ANY PRODUCTS, SERVICES, CONTENT, OR OTHER INFORMATION AVAILABLE ON OR THROUGH THIS PLATFORM, IS TO CEASE USING THE PLATFORM AND/OR THOSE SPECIFIC PRODUCTS OR SERVICES. YOUR AGREEMENT WITH US ABOUT COMPLIANCE WITH THESE TERMS OF SERVICE BECOMES EFFECTIVE IMMEDIATELY WHEN YOU BEGIN USING THIS PLATFORM.</p>
<p></p>
<p>These Terms of Service are effective as of August 3rd, 2023. We distinctly reserve the right to alter these Terms of Service from time to time without giving you prior notice. You acknowledge and agree that it is your duty to review this platform and these Terms of Service periodically and familiarize yourself with any changes. Your continued use of this platform following such changes will signify your acknowledgment of the revised Terms of Service and agreement to respect and be bound by the revised Terms of Service.</p>
<p></p>
<p>As used in these Terms of Service, references to our &quot;Affiliates&quot; include our owners, subsidiaries, associated companies, officers, directors, suppliers, partners, sponsors, and advertisers, and encompasses (without limitation) all parties involved in designing, producing, and/or delivering this platform and/or its contents.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Description of Services
          </Typography>
          <Typography paragraph>
          <p>We offer a variety of services on this platform, which include, but are not limited to, strategy analysis, sharing or publishing of strategies, and similar services. You are accountable for supplying, at your personal cost, all the necessary equipment to use the services, such as a computer and Internet access (including the payment of all fees connected with such access).</p>
<p></p>
<p>We maintain the exclusive right to modify or discontinue the platform, or any of its features, at any given time with or without prior notice to you. We will not be held responsible to you or any third party in the event that we exercise this right. Any new features that supplement or enhance the existing services on this platform will also be governed by these Terms of Service.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Registration Data and Privacy
          </Typography>
          <Typography paragraph>
          <p>To utilize some of the services on this platform, you will need to use an account and password that can be secured by completing our online registration form, which asks for certain information and data. It is your responsibility to maintain and update your Registration Data as necessary. By registering, you affirm that all information provided in the Registration Data is truthful and precise, and that you will maintain and update this information as required to keep it up-to-date, complete, and accurate.</p>
<p>Furthermore, you grant us permission to disclose certain Registration Data about you to third parties, but only as expressly stated in our Privacy Policy.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Conduct on Platform
          </Typography>
          <Typography paragraph>
          <p>Your use of the platform must adhere to all applicable laws and regulations, and you are solely accountable for the contents of your communications through the platform. By publishing information in or otherwise using any communication service, chat room, message board, newsgroup, software library, or other interactive service that may be available to you on or through this platform, you agree not to upload, share, post, or otherwise distribute or facilitate the distribution of any content &mdash; including text, communications, software, images, sounds, data, or other information &mdash; that:</p>
          <ol style={{listStyleType: "lower-alpha"}}>
    <li>Is illegal, menacing, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another&apos;s privacy, tortious, contains explicit or graphic descriptions or accounts of sexual acts (including but not limited to sexual language of a violent or threatening nature aimed at another individual or group of individuals), or otherwise contravenes our rules or policies;</li>
    <li>Victimizes, harasses, degrades, or intimidates an individual or group of individuals based on religion, gender, sexual orientation, race, ethnicity, age, or disability;</li>
    <li>Infringes on any patent, trademark, trade secret, copyright, right of publicity, or other proprietary rights of any party;</li>
    <li>Constitutes unauthorized or unsolicited advertising, junk or bulk email (also known as &quot;spamming&quot;), chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling;</li>
    <li>Contains software viruses or any other computer code, files, or programs that are designed or intended to disrupt, damage, or limit the functioning of any software, hardware, or telecommunications equipment or to damage or gain unauthorized access to any data or other information of any third party; or</li>
    <li>Impersonates any person or entity, including any of our employees or representatives.</li>
</ol>
<p>We neither endorse nor assume any liability for the contents of any material uploaded or submitted by third-party users of the platform. We generally do not pre-screen, monitor, or edit the content posted by users of communication services, chat rooms, message boards, newsgroups, software libraries, or other interactive services that may be available on or through this platform. However, we and our agents reserve the right, at their sole discretion, to remove any content that, in our judgment, does not comply with these Terms of Service and any other rules of user conduct for our platform, or is otherwise harmful, objectionable, or inaccurate.</p>
<p>We are not responsible for any failure or delay in removing such content. You hereby consent to such removal and waive any claim against us arising out of such removal of content. See &quot;Use of Your Materials&quot; below for a description of the procedures to be followed in the event that any party believes that content posted on this platform infringes on any patent, trademark, trade secret, copyright, right of publicity, or other proprietary right of any party.</p>
<p>Additionally, you may not use your account to breach the security of another account or attempt to gain unauthorized access to another network or server. Not all areas of the platform may be accessible to you or other authorized users of the platform. You should not interfere with anyone else&apos;s use and enjoyment of the platform or similar services. Users who breach system or network security may face criminal or civil liability.</p>
<p>You acknowledge that we may, at any time, and at our sole discretion, terminate your membership, account, or other affiliation with our platform without prior notice to you for violating any of the above provisions. Additionally, you acknowledge that we will cooperate fully with investigations of violations of systems or network security at other sites, including cooperating with law enforcement authorities in investigating suspected criminal violations.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Subscriptions
          </Typography>
          <Typography paragraph>
          <p>We offer subscriptions on this platform, which are generally prepaid in order to access the service.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Third-Party Sites and Information
          </Typography>
          <Typography paragraph>
          <p>This platform may connect you to other sites on the Internet or may include references to information, documents, software, materials, and/or services provided by other parties. These sites might contain information or materials that some individuals may find unsuitable or offensive. These other sites and parties are not under our control, and you acknowledge that we are not liable for the accuracy, copyright compliance, legality, decency, or any other aspect of the content of such sites, nor are we responsible for errors or omissions in any references to other parties or their products and services. The inclusion of such a link or reference is solely for convenience and does not suggest endorsement of, or association with, the site or party by us, or any warranty of any kind, either expressed or implied.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Intellectual Property Information
          </Typography>
          <Typography paragraph>
          <p>Copyright &copy; 2023 OneStake &Ouml;U, All Rights Reserved.</p>
<p>In these Terms of Service, &quot;content&quot; is defined as any information, data, communications, software, photos, video, graphics, music, sounds, and other material and services that can be viewed by users on our site.</p>
<p>By accepting these Terms of Service, you acknowledge and agree that all content presented to you on this site is safeguarded by copyrights, trademarks, service marks, patents or other proprietary rights and laws, and is the exclusive property of OneStake &Ouml;U. You are only permitted to use the content as expressly authorized by us or the specific content provider. Except for a single copy made for personal use only, you may not copy, reproduce, modify, republish, upload, post, transmit, or distribute any documents or information from this site in any form or by any means without prior written permission from us or the specific content provider, and you are solely responsible for obtaining permission before reusing any copyrighted material that is available on this site. Any unauthorized use of the materials appearing on this site may violate copyright, trademark and other applicable laws and could result in criminal or civil penalties.</p>
<p>Neither we or our Affiliates warrant or represent that your use of materials displayed on, or obtained through, this site will not infringe the rights of third parties. See &quot;User&apos;s Materials&quot; below for a description of the procedures to be followed in the event that any party believes that content posted on this site infringes on any patent, trademark, trade secret, copyright, right of publicity, or other proprietary right of any party.</p>
<p>All custom graphics, icons, logos and service names are registered trademarks, trademarks or service marks of OneStake &Ouml;U. All other trademarks or service marks are property of their respective owners. Nothing in these Terms of Service grants you any right to use any trademark, service mark, logo, and/or the name of OneStake &Ouml;U or its Affiliates.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Information Accuracy
          </Typography>
          <Typography paragraph>
          <p>All of the information on this website is intended for educational and entertainment purposes. While the information is believed to be accurate, none of the details on this site should be exclusively relied upon for making concrete investment decisions. OneStake &Ouml;U is a distributor (not a publisher) of content provided by third parties. None of the information on this website is assured to be accurate, complete, useful, or timely. OneStake &Ouml;U and its third-party providers do not warrant or guarantee such accuracy, completeness, usefulness, or timeliness. OneStake &Ouml;U is neither responsible for nor endorses the accuracy or reliability of any opinion, advice or statement made on the app.defaust.com platform.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Investment Decisions
          </Typography>
          <Typography paragraph>
          <p>You bear all risk associated with investment decisions based on the information contained on this website. Our policy is to never advocate the purchase or sale of any specific investment vehicle. You should also understand that OneStake &Ouml;U, its third-party providers, and their respective officers, directors, and employees may be active investors in the market and may or may not have open positions in any investment vehicle mentioned on this website. Prior to executing a trade, you are advised to consult with your broker or other financial representative to verify pricing and other information. OneStake &Ouml;U, its third-party providers, and/or content partners shall have no liability for investment decisions based on the information found on this website.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Shared Trades
          </Typography>
          <Typography paragraph>
    <ol style={{listStyleType: "lower-alpha"}}>
        <li>Posting and Commenting
            <ol style={{listStyleType: "lower-roman"}}>
            <li>By posting and sharing your Content using the Services, you are granting an unrestricted, irrevocable, non-exclusive, royalty-free, perpetual, worldwide, and fully transferable, assignable, and sublicensable right and license to use, copy, reproduce, modify, adapt, publish, translate, create collective or derivative works from, distribute, perform and display your Content in whole or in part and to incorporate it in other works in any form, media, or technology now known or later developed, as necessary to provide the Services.</li>
            <li>By posting content to the service, you warrant and represent that you either own or otherwise control all of the rights to that content, including, without limitation, all the rights necessary for you to provide, post, upload, input or submit the content, or that your use of the content is a protected fair use. You agree that you will not knowingly provide material and misleading false information. You represent and warrant also that the content you supply does not violate these Terms. It is your sole responsibility to ensure that your postings do not disclose information covered by a nondisclosure agreement, and information that you are not authorized to disclose. We caution you not to disclose personal information about yourself or your children, such as social security numbers, credit card numbers, etc.</li>
            <li>You agree to indemnify and hold Us and Our affiliated companies, and their directors, officers and employees, harmless for any and all claims or demands, including reasonable attorney fees, that arise from or otherwise relate to your use of the service, any content you supply to the service, or your violation of these Terms or the rights of another.</li>
            <li>We shall have the right in Our sole discretion to terminate your access to and use of the Services and/or remove any of your Content should We consider your statements or conduct to be inaccurate, illegal, obscene, defamatory, threatening, infringing of intellectual property rights, invasive of privacy, injurious, objectionable, or otherwise in violation of these Terms or applicable law.</li>
        </ol>
    </li>
        <li>Accessing
            <ol style={{listStyleType: "lower-roman"}}>
            <li>You agree that We will not be liable, under any circumstances and in any way, for any errors or omissions, loss or damage of any kind incurred as a result of use of any content posted on this site. You agree that you must evaluate and bear all risks associated with the use of any content, including any reliance on the accuracy, completeness, or usefulness of such content. You agree not to collect information about others, including e-mail addresses, or to use information obtained from the Services to send other users unsolicited e-mail of any kind.</li>
            <li><span style={{textAlign: "inherit"}}>The service is provided for informational purposes only; we shall not be responsible or liable for the accuracy or availability of any information appearing or available on the service.</span></li>
            <li>Shared trades may provide links to other websites on the Internet. We are not responsible or liable for such content and we make no express or implied warranty about the accuracy, copyright compliance, legality, merchantability, or any other aspect of the content of such postings. We are not responsible or liable for any advertising, products, or other materials on or available from such websites or resources. The inclusion of links does not imply endorsement of the Websites by Us or any association with their operators.</li>
        </ol>
    </li>
</ol>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Disclaimer of Warranties
          </Typography>
          <Typography paragraph>
<p>ALL MATERIALS AND SERVICES ON THIS SITE ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR THE WARRANTY OF NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY THAT (A) THE SERVICES AND MATERIALS WILL MEET YOUR REQUIREMENTS, (B) THE SERVICES AND MATERIALS WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES OR MATERIALS WILL BE EFFECTIVE, ACCURATE OR RELIABLE, OR (D) THE QUALITY OF ANY PRODUCTS, SERVICES, OR INFORMATION PURCHASED OR OBTAINED BY YOU FROM THE SITE FROM US OR OUR AFFILIATES WILL MEET YOUR EXPECTATIONS OR BE FREE FROM MISTAKES, ERRORS OR DEFECTS.</p>
<p>THIS SITE COULD INCLUDE TECHNICAL OR OTHER MISTAKES, INACCURACIES OR TYPOGRAPHICAL ERRORS. WE MAY MAKE CHANGES TO THE MATERIALS AND SERVICES AT THIS SITE, INCLUDING THE PRICES AND DESCRIPTIONS OF ANY PRODUCTS LISTED HEREIN, AT ANY TIME WITHOUT NOTICE. THE MATERIALS OR SERVICES AT THIS SITE MAY BE OUT OF DATE, AND WE MAKE NO COMMITMENT TO UPDATE SUCH MATERIALS OR SERVICES.</p>
<p>THE USE OF THE SERVICES OR THE DOWNLOADING OR OTHER ACQUISITION OF ANY MATERIALS THROUGH THIS SITE IS DONE AT YOUR OWN DISCRETION AND RISK AND WITH YOUR AGREEMENT THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM SUCH ACTIVITIES.</p>
<p>Through your use of the site, you may have the opportunities to engage in commercial transactions with other users and vendors. You acknowledge that all transactions relating to any merchandise or services offered by any party, including, but not limited to the purchase terms, payment terms, warranties, guarantees, maintenance and delivery terms relating to such transactions, are agreed to solely between the seller or purchaser of such merchandize and services and you. WE MAKE NO WARRANTY REGARDING ANY TRANSACTIONS EXECUTED THROUGH, OR IN CONNECTION WITH THIS SITE, AND YOU UNDERSTAND AND AGREE THAT SUCH TRANSACTIONS ARE CONDUCTED ENTIRELY AT YOUR OWN RISK. ANY WARRANTY THAT IS PROVIDED IN CONNECTION WITH ANY PRODUCTS, SERVICES, MATERIALS, OR INFORMATION AVAILABLE ON OR THROUGH THIS SITE FROM A THIRD PARTY IS PROVIDED SOLELY BY SUCH THIRD PARTY, AND NOT BY US OR ANY OTHER OF OUR AFFILIATES.</p>
<p>Content available through this site often represents the opinions and judgments of an information provider, site user, or other person or entity not connected with us. We do not endorse, nor are we responsible for the accuracy or reliability of, any opinion, advice, or statement made by anyone other than an authorized TradeZella spokesperson speaking in his/her official capacity.</p>
<p>You understand and agree that temporary interruptions of the services available through this site may occur as normal events. You further understand and agree that we have no control over third party networks you may access in the course of the use of this site, and therefore, delays and disruption of other network transmissions are completely beyond our control.</p>
<p>You understand and agree that the services available on this site are provided &quot;AS IS&quot; and that we assume no responsibility for the timeliness, deletion, mis-delivery or failure to store any user communications or personalization settings.</p>
<p>SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Indemnification
          </Typography>
          <Typography paragraph>
<p>Upon a request by us, you agree to defend, indemnify, and hold us and our Affiliates harmless from all liabilities, claims, and expenses, including attorney&rsquo;s fees, that arise from your use or misuse of this site. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with us in asserting any available defenses.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Security and Password
          </Typography>
          <Typography paragraph>
          <p>You are solely responsible for maintaining the confidentiality of your password and account and for any and all statements made and acts or omissions that occur through the use of your password and account. Therefore, you must take steps to ensure that others do not gain access to your password and account. Our personnel will never ask you for your password. You may not transfer or share your account with anyone, and we reserve the right to immediately terminate your account if you do transfer or share your account. You agree to immediately notify us of any unauthorized use of your password or account or any other breach of security. Defaust is not responsible or liable for any loss or damage arising from your failure to comply with this provision.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          International Use
          </Typography>
          <Typography paragraph>
          <p>Although this site may be accessible worldwide, we make no representation that materials on this site are appropriate or available for use in locations outside the United States, and accessing them from territories where their contents are illegal is prohibited. Those who choose to access this site from other locations do so on their own initiative and are responsible for compliance with local laws. Any offer for any product, service, and/or information made in connection with this site is void where prohibited.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Termination of Use
          </Typography>
          <Typography paragraph>
          <p>You acknowledge and agree that OneStake &Ouml;U may, in its sole discretion, terminate or suspend your access to all or part of app.defaust.com with or without notice, for any reason, including, without limitation, breach of these Terms of Service. Any suspected fraudulent, abusive or illegal activity may lead to termination of your access and may be referred to appropriate law enforcement authorities.</p>
<p>Upon termination or suspension, for any reason whatsoever, your right to utilize the services available on this site immediately ceases. You understand and agree that OneStake &Ouml;U may immediately deactivate or delete your account and all related information and files associated with your account and/or prohibit any further access to such files or this site. OneStake &Ouml;U will not be liable to you or any third party for any claims or damages arising out of any termination or suspension or any other actions taken by us in connection with such termination or suspension.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Governing Law
          </Typography>
          <Typography paragraph>
          <p>app.defaust.com (excluding any linked sites) is controlled by OneStake &Ouml;U from its offices within Estonia. It can be accessed from various countries around the world. As each of these places has laws that may differ from those of Estonia, by accessing this site, both of us agree that the statutes and laws of the Republic of Estonia, without regard to the conflicts of laws principles thereof, will apply to all matters relating to the use of this site and the purchase of products and services available through this site. Both parties agree and hereby submit to the exclusive personal jurisdiction and venue of the courts of the Republic of Estonia with respect to such matters.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Notices
          </Typography>
          <Typography paragraph>
          <p>All notifications directed to a party must be provided in writing and should be made via email. Notices for OneStake &Ouml;U should be directed to the attention of Customer Service at <a data-fr-linked="true" href="mailto:support@defaust.com">support@defaust.com</a>.</p>
<p>Furthermore, we may transmit notices or messages through app.defaust.com to notify you of alterations to the site or other matters of importance, and such broadcasts shall be deemed as a notice to you at the time of sending.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Entire Agreement
          </Typography>
          <Typography paragraph>
          <p>These terms and conditions form the entire agreement and understanding between us concerning the subject matter of this agreement and overrule all previous agreements and understandings between the parties regarding that subject matter. These Terms of Service may not be changed, added to, or amended through the use of any other document(s). Any attempt to alter, supplement, or amend this document or to place an order for products or services subject to additional or altered terms and conditions will be considered null and void unless otherwise agreed to in a written agreement signed by both you and OneStake &Ouml;U. In the event that anything in or associated with app.defaust.com is in conflict or inconsistent with these Terms of Service, these Terms of Service will take precedence.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Miscellaneous
          </Typography>
          <Typography paragraph>
          <p>In any action to enforce these Terms of Service, the prevailing party will be entitled to costs and attorneys&rsquo; fees. Any cause of action brought by you against OneStake &Ouml;U or our Affiliates must be initiated within one year after the cause of action arises or be considered permanently waived and barred.</p>
<p>You may not assign your rights and obligations under these Terms of Service to any party, and any purported attempt to do so will be null and void. OneStake &Ouml;U may freely assign our rights and obligations under these Terms of Service.</p>
<p>You agree not to sell, resell, reproduce, duplicate, copy, or use for any commercial purposes any portion of this site, or use of or access to app.defaust.com.</p>
<p>In addition to any excuse provided by applicable law, we shall be excused from liability for non-delivery or delay in delivery of products and services available through app.defaust.com, arising from any event beyond our reasonable control, whether or not foreseeable by either party, including but not limited to, labor disturbance, war, fire, accident, adverse weather, inability to secure transportation, governmental act or regulation, and other causes or events beyond our reasonable control, whether or not similar to those which are enumerated above.</p>
<p>If any part of these Terms of Service is held invalid or unenforceable, that portion shall be construed in a manner consistent with applicable law to reflect, as nearly as possible, the original intentions of the parties, and the remaining portions shall remain in full force and effect.</p>
<p>Any failure by OneStake &Ouml;U to enforce or exercise any provision of these Terms of Service or related rights shall not constitute a waiver of that right or provision.</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Contact Information
          </Typography>
          <Typography paragraph>
          <p>Except as explicitly noted on app.defaust.com, the services available through this site are offered by OneStake &Ouml;U. If you notice that any user is violating these Terms of Service, please contact us at support@defaust.com</p>
          </Typography>
          <Typography variant="h6" gutterBottom>
          Refunds and Cancellations
          </Typography>
          <Typography paragraph>
          <p>OneStake &Ouml;U has a no refund policy after sign-up. All payments made are final and non-refundable. OneStake &Ouml;U is not responsible for continued subscriptions should a customer forget to cancel their account. It is the customer's responsibility to confirm the cancellation.</p>
          </Typography>

      </Box>
    </Container>
    </>
  );
}

export default TermsAndServices;
