import { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { Box, TextField, Button, Typography, Select, MenuItem, List, ListItem } from '@mui/material';
import { db } from '../../firebase';
import { getDocs, collection } from 'firebase/firestore';
import { getFunctions } from "firebase/functions";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

const fetchCourses = async (setCourses) => {
  const coursesCollection = collection(db, 'courses');
  const courseSnapshot = await getDocs(coursesCollection);
  const courseList = courseSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  setCourses(courseList);
};

export default function AdminEnrollComponent() {
    const [emails, setEmails] = useState('');
    const [selectedCourse, setSelectedCourse] = useState('');
    const [courses, setCourses] = useState([]);
    const [logs, setLogs] = useState([]);

  useEffect(() => {
    fetchCourses(setCourses);
  }, []);

  const handleEnroll = async () => {
    const functions = getFunctions();
    const enrollFunction = httpsCallable(functions, 'enrollUsers');  
    const emailArray = emails.split(/[\s,]+/).filter(email => email.length > 0);
    const response = await enrollFunction({ emails: emailArray, courseId: selectedCourse });
    setLogs(response.data.logs);
  };

  const handleUnenroll = async () => {
    const functions = getFunctions();
    const unenrollFunction = httpsCallable(functions, 'unenrollUsers');
    const emailArray = emails.split(/[\s,]+/).filter(email => email.length > 0);
    const response = await unenrollFunction({ emails: emailArray, courseId: selectedCourse });
    setLogs(response.data.logs);
  };
  

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4">Admin Page</Typography>
      <Box sx={{ marginY: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          label="Enter emails separated by comma"
          value={emails}
          onChange={e => setEmails(e.target.value)}
        />
      </Box>
      <Box sx={{ marginY: 2 }}>
        <Select
          fullWidth
          value={selectedCourse}
          onChange={e => setSelectedCourse(e.target.value)}
        >
          {courses.map(course => (
            <MenuItem key={course.id} value={course.id}>{course.name.ru}</MenuItem>
          ))}
        </Select>
      </Box>
      <Button variant="contained" color="primary" onClick={handleEnroll}>Enroll</Button>
      <Button variant="outlined" color="secondary" onClick={handleUnenroll} sx={{ ml: 2 }}>Unenroll</Button>
      <Box sx={{ marginY: 2 }}>
      <Typography variant="h6">Logs</Typography>
      <List>
        {logs.map((log, index) => (
          <ListItem key={index}>
            {log.startsWith('Successfully') ? <CheckCircleOutlineIcon color="success" /> : <CancelIcon color="error" />}
            <Typography color={log.startsWith('Successfully') ? 'green' : 'red'}>
              {log}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  </Box>
);
}
