import React from 'react';
import { Box, Skeleton } from '@mui/material';
import CourseCardCatalog from './CourseCardCatalog';
import { alpha } from '@mui/material';
import { Masonry } from '@mui/lab';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
const CourseCardSkeleton = () => {
  return (
    <Box sx={{ padding: '1em', backdropFilter: 'blur(15px)', borderRadius: 5,
    backgroundColor: alpha('#505050', 0.05),
    border: '1px solid rgba(0, 0, 0, 0.3)'}}>
      <Skeleton variant="rectangular" height={118} 
      sx={{backdropFilter: 'blur(15px)', borderRadius: 5,
          backgroundColor: alpha('#505050', 0.05),
          border: '1px solid rgba(0, 0, 0, 0.3)'}}/>
      <Skeleton variant="text" height={80}  sx={{backdropFilter: 'blur(15px)',
          backgroundColor: alpha('#505050', 0.05), borderRadius: 5,
          border: '1px solid rgba(0, 0, 0, 0.3)'}}/>

    </Box>
  );
};

export default function CoursesCatalogSection({ courses, loading }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
      }}
    >
      {loading
        ? Array.from(new Array(6)).map((_, index) => (
            <Box
              sx={{
                flex: '0 0 auto',
                margin: '0.5em',
                display: 'flex',
                flexDirection: 'column',
                width: {
                  xs: 'calc(100% - 1em)',
                  md: 'calc(50% - 1em)',
                  lg: 'calc(33.33% - 1em)',
                },
                minHeight: '10em',
              }}
              key={index}
            >
              <CourseCardSkeleton />
            </Box>
          ))
        : (
          <Masonry columns={isSmallScreen ? 1 : 3} spacing={isSmallScreen ? 2 : 3}>
            {courses.map((course) => (
              <CourseCardCatalog course={course} key={course.id} />
            ))}
          </Masonry>
        )}
    </Box>
  );
}