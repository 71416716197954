import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../../firebase'; // path to your firebase config file
// @mui
import { Button, Stack, IconButton, InputAdornment, TextField, Checkbox, FormControlLabel,  Alert, Snackbar } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { sendPasswordResetEmail } from 'firebase/auth';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isResetMode, setResetMode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // error state

  const handleForgotPassword = (email) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // Prompt the user to check their email
        setOpenSnackbar(true);
      })
      .catch((error) => {
        // Error occurred
        console.error(error);
        switch (error.code) {
          case "auth/invalid-email":
            setError("Неправильный формат email.");
            break;
          case "auth/user-not-found":
            setError("Пользователь с таким email не найден.");
            break;
          case "auth/missing-android-pkg-name":
            setError("Отсутствует имя пакета Android.");
            break;
          case "auth/missing-continue-uri":
            setError("Отсутствует URL продолжения.");
            break;
          case "auth/missing-ios-bundle-id":
            setError("Отсутствует идентификатор пакета iOS.");
            break;
          case "auth/invalid-continue-uri":
            setError("Недействительный URL продолжения.");
            break;
          case "auth/unauthorized-continue-uri":
            setError("Домен URL продолжения не в белом списке.");
            break;
          default:
            setError("Произошла неизвестная ошибка.");
        }
      });
  };
  
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  
  const handleResetPassword = (e) => {
    e.preventDefault();
    handleForgotPassword(email);
  };
  let navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // reset the error before a new attempt
  
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      navigate("/dashboard", { replace: true });
    } catch (error) {
      console.error(error.message);

      // handle error messages
      switch (error.code) {
        case "auth/invalid-email":
          setError("Неправильный формат email.");
          break;
        case "auth/user-disabled":
          setError("Аккаунт пользователя отключен.");
          break;
        case "auth/user-not-found":
          setError("Нет пользователя с данной почтой.");
          break;
        case "auth/wrong-password":
          setError("Неправильный пароль.");
          break;
        default:
          setError("Произошла ошибка при входе в аккаунт.");
      }
    }
  }

  return (
    <>
      {isResetMode ? (
        <Stack spacing={3}>
          <TextField
            name="email"
            label="Email"
            onChange={e => setEmail(e.target.value)}
            sx={{
              "& label.Mui-focused": {
                color: "white",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "rgba(255, 255, 255, 0.2)",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.6)",
                },
                "&:hover fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.6)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.6)",
                },
              },
            }}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            InputProps={{
              style: { color: 'white' },
            }}
          />
          {error && (
            <Alert
              severity="error"
              sx={{ backgroundColor: '#F5252510', color: '#F52525', borderRadius: 2, mt: 2 }}
            >
              {error}
            </Alert>
          )}
        </Stack>
      ) : (
        <Stack spacing={3}>
          <TextField name="email" label="Email" onChange={e => setEmail(e.target.value)}
                    sx={{
                      "& label.Mui-focused": {
                        color: "white",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "rgba(255, 255, 255, 0.2)",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgba(255, 255, 255, 0.6)",
                        },
                        "&:hover fieldset": {
                          borderColor: "rgba(255, 255, 255, 0.6)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "rgba(255, 255, 255, 0.6)",
                        },
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },
                    }}
                    InputProps={{
                      style: { color: 'white' },
                    }} />
          <TextField
            name="password"
            label="Пароль"
            type={showPassword ? 'text' : 'password'}
            onChange={e => setPassword(e.target.value)}
            sx={{
              "& label.Mui-focused": {
                color: "white",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "rgba(255, 255, 255, 0.2)",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.6)",
                },
                "&:hover fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.6)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.6)",
                },
              },
            }}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            InputProps={{
              style: { color: 'white' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {error && <Alert severity="error" sx={{ backgroundColor: '#F5252510', color: '#F52525', borderRadius: 2, mt: 2 }}>{error}</Alert>}
        </Stack>
      )}
      
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {isResetMode ? (
          <Button onClick={() => setResetMode(false)}>

          </Button>
        ) : (
          <FormControlLabel

            control={<Checkbox name="remember" />}
            label="Запомнить меня"
          />
        )}
        <Button onClick={() => setResetMode(!isResetMode)} 
        sx={{color: "#FFF"}}>
          {isResetMode ? 'Назад' : 'Забыли пароль?'}
        </Button>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={isResetMode ? handleResetPassword : handleLogin}
        sx={{
          borderRadius: 3,
          backgroundColor: "#FFF",
          color: 'black',
          '&:hover': {
            backgroundColor: "#FFFFFF99",
          },
        }}
      >
        {isResetMode ? 'Восстановить пароль' : 'Войти'}
      </LoadingButton>
      <Snackbar
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
    >
      <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
        Проверьте свою электронную почту для сброса пароля!
      </Alert>
    </Snackbar>
    </>
  );
}
