import { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase'; 

export default function useAuth() {
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuth(Boolean(user));

    });

    // Clean-up function
    return () => unsubscribe();
  }, []);
  ////console.log(isAuth);
  return isAuth;
}