import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';
import logo_dark from './logo-dark.svg'; 


// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, logoColor = false, landing = false, white, customSize, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  const logo = (
    <Box
      component="img"
      src={logoColor? '/assets/illustrations/logoColor.svg' : white? '/assets/illustrations/logo-white.svg' : logo_dark}
      sx={{ 
        width: logoColor? customSize? customSize[0] : 90 : customSize? customSize[0] : 145,
        height: logoColor? customSize? customSize[1] : 90 : customSize? customSize[1] : 35,
        cursor: 'pointer', ...sx }}
    />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  if (landing) {
    return (
      <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
  else {
    return (
      <Link to="/dashboard" component={RouterLink} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
