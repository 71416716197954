import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Box, useTheme, useMediaQuery, Container} from "@mui/material";
import { styled } from '@mui/material/styles';
import LandingHeader from './LandingHeader';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 20;
const APP_BAR_DESKTOP = 60;

const HEADER_MOBILE = 50;
const HEADER_DESKTOP = 15;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundImage: '#FFF',  // added this line
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: `calc(${APP_BAR_MOBILE + 24}px + ${HEADER_MOBILE}px)`, // Updated paddingTop value
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: `calc(${APP_BAR_DESKTOP + 24}px + ${HEADER_DESKTOP}px)`,  // Updated paddingTop value
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function OnboardingLayout() {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const defaultColor = '#FFFFFF'; // The color you want as default
  const homepageColor = '#000'; // The color you want for the Homepage
  useEffect(() => {
    // Set the background color when the component mounts
    document.body.style.backgroundColor = homepageColor;
  
    // Reset the background color when the component unmounts
    return () => {
      document.body.style.backgroundColor = defaultColor;
    };
  }, []);
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    navigate(newValue);
  };

  return (
    <StyledRoot>
      <LandingHeader isUserAuthenticated={false} openNav={open} onCloseNav={() => setOpen(false)} onOpenNav={() => setOpen(true)}/>
      <Main>
            <Outlet /> 
          </Main>
        </StyledRoot>
      );
  }