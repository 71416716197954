// component
import SvgColor from '../../../components/svg-color';


// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Главная',
    path: '/dashboard',
    icon: icon('home-icon'),
  },
  {
    title: 'Трейдинг',
    path: '/dashboard/catalog/certification/defaust-trader',
    icon: icon('candlestick-chart-outline-rounded'),
  },
  {
    title: 'Крипта',
    path: '/dashboard/catalog/crypto',
    icon: icon('money-currency-bitcoin-crypto-circle-payment-blokchain-finance-bitcoin-currency-money'),
  },
  {
    title: 'Прогресс',
    path: '/dashboard/progress',
    icon: icon('skill'),
  },
/*   {
    title: 'Ресурсы',
    path: '/resources',
    icon: icon('material-icon'),
  }, */
  {
    title: 'Конференции',
    path: '/dashboard/conferences',
    icon: icon('recap-icon'),
  },
  {
    title: 'Аналитика',
    path: 'https://analytics.defaust.com/',
    icon: icon('journal'),
  },
];

export default navConfig;
