import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { useContext, useState } from 'react';
import { Grid, Container, Typography, Box} from '@mui/material';
// ----------------------------------------------------------------------

export default function Resources() {

  const [loading, setLoading] = useState(false);



if (loading === true) {
  return (
    <>
      <Helmet>
        <title>Ресурсы | DEFAUST</title>
      </Helmet>
      
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
      </Box>
    </>
  );
} else {
  return (
    <>
      <Helmet>
        <title>Ресурсы | DEFAUST</title>
      </Helmet>

      <Container maxWidth="xl" style={{ minHeight: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

      <Typography variant="h3" sx={{ color: '#000', textAlign: 'center' }}>Эта страница находится в разработке 😁</Typography>
      {/* <SkillTree /> */}
      </Container>
    </>
);
}}