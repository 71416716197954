import { useState, useEffect, useContext } from "react";
import UserContext from "../UserContext";

export function useQuizResult(courseId, quizId) {
    const account = useContext(UserContext);
    const [loadingQuizResult, setLoadingQuizResult] = useState(true);
    const [errorQuizResult, setErrorQuizResult] = useState(null);
    const [quizResult, setQuizResult] = useState(null);
    
    useEffect(() => {
        try {
            if (account) {
                // Navigate through the nested structure
                const courseResults = account.quizResults && account.quizResults[courseId];
                const allQuizAttempts = courseResults && courseResults[quizId];
    
                if (allQuizAttempts && allQuizAttempts.length > 0) {
                    // Find the attempt with the highest mark
                    const highestMarkAttempt = allQuizAttempts.reduce((highest, current) => {
                        const currentMark = parseFloat(current.mark);
                        const highestMark = parseFloat(highest.mark);
                        return (currentMark > highestMark) ? current : highest;
                    }, allQuizAttempts[0]);  // Initialize with the first attempt
    
                    setQuizResult(highestMarkAttempt);
                } else {
                    ////console.log('Quiz not taken yet or quizResults field is missing.');
                }
            }
        } catch (err) {
            setErrorQuizResult(err);
        } finally {
            setLoadingQuizResult(false);
        }
    }, [account, courseId, quizId]);
    
    

    return { loadingQuizResult, errorQuizResult, quizResult };
}
export function useExamResult(courseId, quizId) {
    const account = useContext(UserContext);
    const [loadingQuizResult, setLoadingQuizResult] = useState(true);
    const [errorQuizResult, setErrorQuizResult] = useState(null);
    const [quizResult, setQuizResult] = useState(null);
    
    useEffect(() => {
        try {
            if (account) {
                // Navigate through the nested structure
                const courseResults = account.examResults && account.examResults[courseId];
                const allQuizAttempts = courseResults && courseResults[quizId];
    
                if (allQuizAttempts && allQuizAttempts.length > 0) {
                    // Find the attempt with the highest mark
                    const highestMarkAttempt = allQuizAttempts.reduce((highest, current) => {
                        const currentMark = parseFloat(current.mark);
                        const highestMark = parseFloat(highest.mark);
                        return (currentMark > highestMark) ? current : highest;
                    }, allQuizAttempts[0]);  // Initialize with the first attempt
    
                    setQuizResult(highestMarkAttempt);
                } else {
                    ////console.log('Quiz not taken yet or quizResults field is missing.');
                }
            }
        } catch (err) {
            setErrorQuizResult(err);
        } finally {
            setLoadingQuizResult(false);
        }
    }, [account, courseId, quizId]);
    
    

    return { loadingQuizResult, errorQuizResult, quizResult };
}
