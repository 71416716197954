import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { deleteDoc, addDoc, collection, query, where, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { Typography,Container, TextField, Button, RadioGroup, FormControlLabel, Radio, Input, Box, Snackbar, Alert, Grid } from '@mui/material';
import { db, storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export default function QuizEditSection({ courseId, quizId }) {
    const [questions, setQuestions] = useState([]);
    const [deletedQuestions, setDeletedQuestions] = useState([]);
    const [imageFile, setImageFile] = useState(null);

    const uploadImage = async (courseId, quizId, questionIndex) => {
        const filePath = `${courseId}/quizId/${questionIndex}/${imageFile.name}`;
        const storageRef = ref(storage, filePath);
    
        const uploadTask = uploadBytesResumable(storageRef, imageFile);
    
        uploadTask.on('state_changed',
          (snapshot) => {
            // Handle progress here if you want
          },
          (error) => {
            console.error("Error uploading image: ", error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              updateQuestion(questionIndex, 'image', downloadURL);
            });
          }
        );
      };
const [courseDocState, setCourseDocState] = useState(null);
  const [quizFields, setQuizFields] = useState({
    id: '',
    duration: 0,
    name: { en: '', ru: '' },
    description: { en: '', ru: '' },
    type: '',
    image: ''
  });
  const [error, setError] = useState(null);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const generateUniqueId = () => {
    return uuidv4();
  };

  const removeQuestion = (questionIndex) => {
    const newQuestions = [...questions];
    const [removed] = newQuestions.splice(questionIndex, 1);
    setQuestions(newQuestions);

    if (removed.docName) {
      setDeletedQuestions([...deletedQuestions, removed.docName]);
    }
};


  const addNewQuestion = () => {
    setQuestions([...questions, {
        text: '',
        correctChoice: 0,
        choices: [{ ru: '', correct: true }, { ru: '', correct: false }]
    }]);
};

const updateQuestion = (questionIndex, field, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex][field] = value;
    setQuestions(newQuestions);
};

const addChoice = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].choices.push({ ru: '', correct: false });
    setQuestions(newQuestions);
};

const updateChoice = (questionIndex, choiceIndex, field, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].choices[choiceIndex][field] = value;
    setQuestions(newQuestions);
};

const removeChoice = (questionIndex, choiceIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].choices.splice(choiceIndex, 1);
    setQuestions(newQuestions);
};

const updateCorrectChoice = (questionIndex, newCorrectChoice) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].correctChoice = newCorrectChoice;
    setQuestions(updatedQuestions);
    // You could also update the Firestore doc here if needed
};

  useEffect(() => {
    // Fetch quiz data from Firestore and set initial values
    // Replace db with your actual Firestore database instance
    const fetchQuiz = async () => {
        // First find the course with the provided courseId
        const coursesCollection = collection(db, 'courses');
        const q = query(coursesCollection, where('id', '==', courseId));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          const courseDoc = querySnapshot.docs[0];
          setCourseDocState(courseDoc.id);
          // Now find the lesson with the provided lessonId
          const quizDoc = await getDoc(doc(db, `courses/${courseDoc.id}/quiz/${quizId}`));

        setQuizFields(quizDoc.data());

      } else {
        setError("Quiz not found");
      }
    };
    fetchQuiz();
  }, [courseId, quizId]);

  useEffect(() => {
    const fetchQuestions = async () => {
      const questionsCollection = collection(doc(db, `courses/${courseDocState}/quiz/${quizId}`), 'questions');
      const questionsSnapshot = await getDocs(questionsCollection);
      const questionsData = questionsSnapshot.docs.map(doc => ({ docName: doc.id, ...doc.data() }));
      setQuestions(questionsData);
    };
    fetchQuestions();
  }, [courseDocState]);
  

  const updateQuiz = async () => {
    try {
      // Update the Quiz Document
      const quizDoc = doc(db, `courses/${courseDocState}/quiz/${quizId}`);
      quizFields.duration = parseInt(quizFields.duration, 10);
      await updateDoc(quizDoc, { ...quizFields });
  
      const questionDocs = collection(doc(db, `courses/${courseDocState}/quiz/${quizId}`), 'questions');
  
      const promises = questions.map(async (question, index) => {
        // Update the 'correct' field of each choice based on correctChoice
        const updatedChoices = question.choices.map((choice, choiceIndex) => ({
          ...choice,
          correct: choiceIndex === question.correctChoice,
        }));
        
        // Create the final question object
        const questionWithCorrectChoice = { ...question, choices: updatedChoices };
        
        const questionDocRef = doc(db, `courses/${courseDocState}/quiz/${quizId}/questions/${question.docName || generateUniqueId()}`);
        
        if (question.docName) {
          return updateDoc(questionDocRef, questionWithCorrectChoice);
        } else {
          const docRef = await addDoc(collection(db, `courses/${courseDocState}/quiz/${quizId}/questions`), questionWithCorrectChoice);
          question.docName = docRef.id;  // Update the local state with the new id
        }
      });
      
      // Execute all Firestore promises
      await Promise.all(promises);
      
        // Delete removed questions from Firestore
        const deletePromises = deletedQuestions.map((docName) => {
            const questionDocRef = doc(db, `courses/${courseDocState}/quiz/${quizId}/questions/${docName}`);
            return deleteDoc(questionDocRef);
          });
          await Promise.all(deletePromises);
  
          // Clear the deletedQuestions array as they've been removed from Firestore
          setDeletedQuestions([]);
  
          setOpenSuccess(true);
    } catch (err) {
      setError("An error occurred while updating data");
      console.error(err);
      setOpenError(true);
    }
  };
  
  
  
  const handleInputChange = (field, subfield = null) => event => {
    if (subfield) {
      setQuizFields({
        ...quizFields,
        [field]: {
          ...quizFields[field],
          [subfield]: event.target.value
        }
      });
    } else {
        setQuizFields({
        ...quizFields,
        [field]: event.target.value
      });
    }
  };

  return (
    <Container>
              <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField fullWidth label="ID" variant="outlined" value={quizFields.id} onChange={handleInputChange('id')} />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Type" variant="outlined" value={quizFields.type} onChange={handleInputChange('type')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Name (EN)" variant="outlined" value={quizFields.name.en} onChange={handleInputChange('name', 'en')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Name (RU)" variant="outlined" value={quizFields.name.ru} onChange={handleInputChange('name', 'ru')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Description (EN)" variant="outlined" value={quizFields.description.en} onChange={handleInputChange('description', 'en')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Description (RU)" variant="outlined" value={quizFields.description.ru} onChange={handleInputChange('description', 'ru')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Duration" variant="outlined" value={quizFields.duration} onChange={handleInputChange('duration')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Image" variant="outlined" value={quizFields.image} onChange={handleInputChange('image')} />
          </Grid>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={updateQuiz}>Save Changes</Button>
      </Box>

      <Snackbar open={openSuccess} autoHideDuration={6000} onClose={() => setOpenSuccess(false)}>
        <Alert onClose={() => setOpenSuccess(false)} severity="success">
          Quiz updated successfully!
        </Alert>
      </Snackbar>

      <Snackbar open={openError} autoHideDuration={6000} onClose={() => setOpenError(false)}>
        <Alert onClose={() => setOpenError(false)} severity="error">
          An error occurred while updating the quiz.
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
      <Typography variant="h3">Вопросы</Typography>
      {questions.length > 0 ? (
        <>
 {questions.map((question, questionIndex) => (
        <Box key={questionIndex}>
          <TextField 
            label="Question"
            value={question.text}
            onChange={e => updateQuestion(questionIndex, 'text', e.target.value)}
          />

          <RadioGroup 
            value={String(question.correctChoice)} 
            onChange={(e) => updateQuestion(questionIndex, 'correctChoice', Number(e.target.value))}
          >
            {question.choices.map((choice, choiceIndex) => (
              <FormControlLabel
                key={choiceIndex}
                value={String(choiceIndex)}
                control={<Radio />}
                label={
                  <div>
                    <TextField 
                      value={choice.ru}
                      onChange={e => updateChoice(questionIndex, choiceIndex, 'ru', e.target.value)}
                    />
                    <Button onClick={() => removeChoice(questionIndex, choiceIndex)}>Remove Choice</Button>
                  </div>
                }
              />
            ))}
          </RadioGroup>
          
          <input type="file" onChange={(e) => setImageFile(e.target.files[0])} />
          <Button onClick={() => uploadImage('courseId', 'quizId', questionIndex)}>Upload Image</Button>

          <Button onClick={() => addChoice(questionIndex)}>Add Choice</Button>
          <Button color="secondary" onClick={() => removeQuestion(questionIndex)}>Delete Question</Button>
        </Box>
      ))}
            </>
            
            ) : (
                <Typography variant="body1">No questions yet. Add a new question.</Typography>
              )}
        
              {/* Add New Question Button */}
              <Button variant="contained" color="secondary" onClick={addNewQuestion}>
                Add New Question
              </Button>
        
              <Button variant="contained" color="primary" onClick={updateQuiz}>
                Save Changes
              </Button>
            </Grid>
      </Grid>
      </form>
    </Container>
  );
}

