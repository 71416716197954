import React, { useEffect, useState } from 'react';
import { Card, Button } from '@mui/material';
import { doc, getDocs, collection, query, where, updateDoc, deleteDoc } from 'firebase/firestore';

const fetchUnverifiedReviews = async (db) => {
  const coursesSnapshot = await getDocs(collection(db, 'courses'));
  let allUnverifiedReviews = [];

  for (const courseDoc of coursesSnapshot.docs) {
    const reviewsCollection = collection(courseDoc.ref, 'reviews');
    const q = query(reviewsCollection, where('verified', '==', false));
    const reviewsSnapshot = await getDocs(q);
    reviewsSnapshot.forEach((reviewDoc) => {
      allUnverifiedReviews.push({
        id: reviewDoc.id,
        courseId: courseDoc.id,
        ...reviewDoc.data()
      });
    });
  }
  return allUnverifiedReviews;
};

const ReviewVerification = ({ db }) => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      const unverifiedReviews = await fetchUnverifiedReviews(db);
      setReviews(unverifiedReviews);
    };
    fetchReviews();
  }, [db]);

  const verifyReview = async (courseId, reviewId) => {
    const reviewRef = doc(db, 'courses', courseId, 'reviews', reviewId);
    await updateDoc(reviewRef, {
      verified: true
    });
    setReviews(reviews.filter(review => review.id !== reviewId));
  };

  const deleteReview = async (courseId, reviewId) => {
    const reviewRef = doc(db, 'courses', courseId, 'reviews', reviewId);
    await deleteDoc(reviewRef);
    setReviews(reviews.filter(review => review.id !== reviewId));
  };

  return (
    <div>
      {reviews.map((review) => (
        <Card key={review.id}
          sx={{
            backgroundColor: '#F5F7F9',
            border: "1px solid rgba(209, 213, 219, 0.3)",
            borderRadius: 5,
            padding: 2,
            mt: 5,
          }}>
          <h3>{review.name}</h3>
          <p>{review.text}</p>
          <Button variant="contained" color="primary" onClick={() => verifyReview(review.courseId, review.id)}>
            Verify
          </Button>
          <Button sx={{ ml: 2 }} variant="contained" color="secondary" onClick={() => deleteReview(review.courseId, review.id)}>
            Delete
          </Button>
        </Card>
      ))}
    </div>
  );
};

export default ReviewVerification;
