import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const CircleBackground = styled('circle')({
  stroke: '#EEE',
  fill: 'transparent'
});

const CircleProgress = styled('circle')(({ theme, circleColor }) => ({
  fill: 'transparent',
  stroke: circleColor || theme.palette.primary.main,
  strokeLinecap: 'round',
  transition: 'stroke-dashoffset 0.35s',
  transform: 'rotate(-90deg)',
  transformOrigin: '50% 50%'
}));

function UserPoints({ points, maxPoints = 100 }) {
  const radius = 70;
  const circumference = 2 * Math.PI * radius;
  
  // Calculate overlaps
  const overlaps = Math.floor(points / maxPoints);
  const colors = ['#853EF6', '#440AE4', '#D92EF0', '#E93323', '#EEBD40']; // Add more colors if needed

  const currentLevel = overlaps + 1;

  const getLevelName = (level) => {
    const levelNames = ['Наблюдатель', 'Ученик', 'Падаван', 'Трейдер', 'Трейдер-Джедай'];
    if (level <= levelNames.length) return levelNames[level - 1];
    return 'Ашаман';
  };
  const getLevelIcon= (level) => {
    const levelIcons = ['👀', '👨‍🎓', '👨‍💻', '😎', '🥷🏿'];
    if (level <= levelIcons.length) return levelIcons[level - 1];
    return '🧙‍♂️';
  };

  return (
    <Box>
    <Box sx={{ textAlign: 'center', mt: 5, position: 'relative', width: 150, height: 150, mx: 'auto' }}>
      <svg width="150" height="150" viewBox="0 0 150 150">
        <CircleBackground r={radius} cx="75" cy="75" strokeWidth="10" />

        {Array.from({ length: overlaps }).map((_, index) => (
          <CircleProgress
            key={index}
            r={radius}
            cx="75"
            cy="75"
            strokeWidth="10"
            strokeDasharray={circumference}
            strokeDashoffset={0}
            circleColor={colors[index % colors.length]}
          />
        ))}
        
        <CircleProgress
          r={radius}
          cx="75"
          cy="75"
          strokeWidth="10"
          strokeDasharray={circumference}
          strokeDashoffset={circumference - ((points - overlaps * maxPoints) / maxPoints) * circumference}
          circleColor={colors[overlaps % colors.length]}
        />
      </svg>

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center'
        }}
      >
        <Typography variant="body" sx={{ textAlign: 'center', fontSize: '0.7rem', fontWeight: 700}} component="div">Уровень {currentLevel}</Typography>
        <Typography variant="body" sx={{ textAlign: 'center', fontSize: '1rem', fontWeight: 700}} component="div">{getLevelName(currentLevel)}</Typography>
        <Typography variant="body" sx={{ mt: -0.5, textAlign: 'center', fontSize: '1.3rem'}} component="div">{getLevelIcon(currentLevel)}</Typography>
      </Box>


    </Box>
    <Box  display="flex" flexDirection="column" alignContent="center" alignItems='center'>
          {/* User Points Description */}
          <Typography variant="h6" sx={{ mt: 2, textAlign: 'center' }}>
        Очки: {points}
      </Typography>
          <Typography variant="body" sx={{ textAlign: 'center', fontSize: '1rem', fontWeight: 700}}>
          {points > 0 ? `Ваши очки дают скидку ${(points / 50).toFixed(2)}% на следующий курс!` : 'Ваши очки дают скидку на покупку следующих курсов'}
          </Typography>
          <Typography variant="body" sx={{ mt: 0.5, textAlign: 'center', fontSize: 14, color: 'grey', fontWeight: 400}}>
              До следующего уровня: {currentLevel * maxPoints - points} очков
          </Typography>
        </Box>
    </Box>
  );
}

export default UserPoints;
