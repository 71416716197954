import React, { useContext } from 'react';
import { alpha, Badge, Box, Typography, Avatar, IconButton } from '@mui/material';
import styled from '@emotion/styled';
import EditIcon from '@mui/icons-material/Edit';
import UserContext from '../../../UserContext';
import { Drawer } from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
import CertificateIcon  from '@mui/icons-material/CheckCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useNavigate } from 'react-router-dom';
// You can set PROFILE_SIDEBAR_WIDTH to whatever you like
const PROFILE_SIDEBAR_WIDTH = 300;

export default function ProfileSidebar({ openSidebar, onCloseSidebar }) {
  const account = useContext(UserContext);
  const navigate = useNavigate();
  const completedCourses = account.completed?.courses ?? {};
  const coursesCompleted = Object.keys(completedCourses).length;
  const lessonsCompleted = Object.values(completedCourses).flatMap(course => course.lessons || []).length;
  const quizzesCompleted = Object.values(completedCourses).flatMap(course => course.quiz || []).length;
  const isDesktop = useResponsive('up', 'lg');
      // Calculate overlaps
      const overlaps = Math.floor((account.points || 0) / 100);
    
      const currentLevel = overlaps + 1;
    
      const getLevelName = (level) => {
        const levelNames = ['Наблюдатель 👀', 'Ученик 👨‍🎓', 'Падаван 👨‍💻', 'Трейдер 😎', 'Трейдер-Джедай 🥷🏿'];
        if (level <= levelNames.length) return levelNames[level - 1];
        return 'Ашаман 🧙‍♂️';
      };

    const stats = [
        {  value: coursesCompleted, icon: CertificateIcon, label: 'Курсов'},
        {  value: lessonsCompleted, icon: CheckCircleOutlineIcon, label: 'Уроков' },
        {  value: quizzesCompleted, icon: AccessTimeIcon, label: 'Тестов' }
    ];
    
    const navigateToProfileSettings = () => {
      navigate('/settings/profile');
    };
  const renderProfileContent = (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: PROFILE_SIDEBAR_WIDTH },
        display: 'flex',
        flexDirection: 'column',
        height: 1,
      }}
    >
      <Box sx={{ mt: 4, pb: 0, px: 2, alignContent: 'center', justifyContent: 'center'}}>

            <HeaderBox>
                <Typography variant="h6">Профиль</Typography>
                <IconContainer  >
                    <IconButton size="small" color="inherit"  >
                        <EditIcon  onClick={navigateToProfileSettings}/>
                    </IconButton>
                </IconContainer>
            </HeaderBox>

            <AvatarBox>
                <UserAvatar src={account?.avatarURL ?? '/assets/images/avatars/user_avatar.png'} alt="photoURL" />
                <Typography variant="subtitle2" sx={{pt: 2, fontSize: 18, color: 'grey.0', fontWeight: 700 }}>
                    {account?.name || 'Defaust Student'}
                </Typography>
                <Typography variant="body2" sx={{ color: 'grey.0', }}>
                {getLevelName(currentLevel)}
                </Typography>
            </AvatarBox>

            <Box
              sx={{
                display: 'flex',
                borderRadius: 5,
                background: '#fff',
                border: '1px solid var(--stroke-grey, #E5E7E9)',
                marginTop: 3,
                paddingLeft: 2,
                paddingRight: 2,
                paddingTop: 1,
                paddingBottom: 2,
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              {stats.map((stat, index) => (
                <InfoContainer key={index}>
                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <Box sx={{          mt: 1,
                                        color:  '#204631',
                                        background: '#EFF7E2',
                                        borderRadius: "50px",
                                        py: 0,
                                        px: 2,
                                        display: 'inline-block', // To make the box size according to content
                                    }}>
                                   {stat.value}
                                    </Box>
                    <Typography sx={{fontWeight: 700 , mt: 1,}} variant={'body'}>{stat.label}</Typography>
                    </Box>
                </InfoContainer>
              ))}
            </Box>

            <Box
  sx={{
    display: 'flex',
    flex: 1,
    flexDirection: 'column',  // flexDirection set to 'row' to align items horizontally
    background: '#fff',
    borderRadius: 5,
    border: '1px solid var(--stroke-grey, #E5E7E9)',
    marginTop: 1,
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 1,
    paddingBottom: 2,
  }}
>
            <Box
  sx={{
    display: 'flex',
    flex: 1,
    flexDirection: 'row',  // flexDirection set to 'row' to align items horizontally
    alignItems: 'center',  // center-align items along the cross-axis
    justifyContent: 'space-between',  // space out items along the main-axis
  }}
>
  <Typography sx={{fontWeight: 700, mt: 1, }} variant={'body'}>Баланс:</Typography>
  <Box sx={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
    <Box sx={{
      mt: 1,
      color: '#204631',
      background: '#EFF7E2',
      borderRadius: "50px",
      py: 0,
      px: 2,
      display: 'inline-block',  // To make the box size according to content
      fontWeight: 700
    }}>
      {account.points}
    </Box>
  </Box>

                     
            </Box>
            <Typography variant="body" sx={{ mt: 1, textAlign: 'left', fontSize: 14, fontWeight: 400, fontStyle: 'italic'}}>
          {account.points > 0 ? `Ваши очки дают скидку ${(account.points / 50).toFixed(2)}% на следующий курс!` : 'Ваши очки дают скидку на покупку следующих курсов'}
          </Typography>  
    </Box>

      </Box>
    </Box>
  );

  return (
    <Box
      component="aside"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: PROFILE_SIDEBAR_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          anchor="right"  // make it appear from the right
          variant="permanent"
          PaperProps={{
            sx: {
              width: PROFILE_SIDEBAR_WIDTH,
              bgcolor: 'transparent',
            },
          }}
        >
          {renderProfileContent}
        </Drawer>
      ) : (
        <Drawer
        anchor="right"  // make it appear from the right
          open={openSidebar}
          onClose={onCloseSidebar}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: PROFILE_SIDEBAR_WIDTH },
          }}
        >
          {renderProfileContent}
        </Drawer>
      )}
    </Box>
  );
}


const ContainerBox = styled(Box)(({ theme }) => ({
  borderLeft: '1px solid #e0e0e0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
  width: '100%',
  justifyContent: 'flex-start',
}));

const HeaderBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',  // this will stretch the box to the full width of the container
    padding: theme.spacing(1, 2),  // to give some space from the edges
    paddingBottom: 30,
  }));
  
  const IconContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 10,
    border: '1px solid #E7EAE9',
    padding: 5,
    "&:hover": {
      backgroundColor: alpha("#FFF", 0.15),
    },
  }));
  
  const InfoContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center', // Adjusted for centering


}));

const StatIcon = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const AvatarBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginY: theme.spacing(2),
}));

const UserAvatar = styled(Avatar)({
  width: 100,
  height: 100,
});



  
  const StyledBadge = styled(Badge)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    position: 'relative',
    flexGrow: 1,
    justifyContent: 'center',    // to center the label

  }));