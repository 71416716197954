import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from 'firebase/performance';

const firebaseConfig = {
  apiKey: "AIzaSyBy7IYE1rPDQ3Txne7KaV9mSSF88Q3ue3s",
  authDomain: "defaust-2023.firebaseapp.com",
  databaseURL: "https://defaust-2023-default-rtdb.firebaseio.com",
  projectId: "defaust-2023",
  storageBucket: "defaust-2023.appspot.com",
  messagingSenderId: "977453728482",
  appId: "1:977453728482:web:4bf5c168139248791bd4bb"
};





// Initialize Firebase App
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth
const auth = getAuth(app);

// Initialize Firestore
const db = getFirestore(app);

const functions = getFunctions(app);

const storage = getStorage();

const analytics = getAnalytics();
const perf = getPerformance();



export { app, auth, db, functions, storage, analytics, perf };