import React from 'react';
import Confetti from 'react-confetti';
import './Congratulations.css';

const Congratulations = () => (
  <div className="congrats-container">
    <Confetti
      width={window.innerWidth}
      height={window.innerHeight}
    />
  </div>
);

export default Congratulations;
