import React, {useContext}  from 'react';
import { Box, Typography,  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { alpha, styled } from '@mui/material';
import { fCurrency , fNumber, fPercent} from '../../../utils/formatNumber.js';
import { Link } from 'react-router-dom';
import UserContext from '../../../UserContext.js';
import hard_level_icon from '../../assets/hard-level.svg'
import medium_level_icon from '../../assets/medium-level.svg'
import light_level_icon from '../../assets/light-level.svg'
import proMax_sertification from '../../assets/logo-pro-max.png'


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: alpha(theme.palette.background.default, 0.3),
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 13,
    padding: theme.spacing(1.5, 2.5),
    backdropFilter: 'blur(8px)',
    WebkitBackdropFilter: 'blur(8px)', // Fix on Mobile
    borderRadius: 10,
    borderColor: theme.palette.grey[500_8],

  },
}));

function CoursesList({ courses }) {
  const account = useContext(UserContext);
  const navigate = useNavigate();
  const handleRowClick = (courseId) => {
      navigate(`/dashboard/catalog/course/${courseId}`);
    };
    const handleLearnClick = (courseId) => {
      navigate(`/course/take/${courseId}`);
    };

    const getCertificationIcon = (certification) => {
      switch(certification) {
        case 'defaust-trader':
            return proMax_sertification;
        default:
            return proMax_sertification; // or a default icon if any
    }
  }
  

  return (
<TableContainer component={Paper} 
        sx={{ 
            backgroundColor: '#F5F7F9', // semi-transparent background
            border: "1px solid rgba(209, 213, 219, 0.3)", // border
            borderRadius: 5,
            padding: 2,
            mt: 0,
         }}>
      <Table>
        <TableHead>
        <TableRow>
            {['Название', 'Длительность', 'Сложность', 'Прогресс', ''].map((header, index) => (
                <TableCell key={index} sx={{
                    background: 'transparent',
                    color: '#000',
                    padding: "15px",
                    textAlign: 'center',
                    fontWeight: 700,
                    borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                }}>
                  {header}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
            {courses?.map((course, index) => (
                <TableRow key={index} 
                sx={{
                  py: 4,
                  "&:hover": {
                    backgroundColor: alpha("#FFF", 0.15),
                  },
                  "&:last-child td, &:last-child th": {
                    borderBottom: 0,
                  },
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)', // Set color of border
                }}>
                <TableCell onClick={() => handleRowClick(course.id)}
                sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  fontWeight: 700,
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
                  py: 4,
                  maxWidth: '300px',
              }}>
                <Box sx={{
                  display: "flex",
                  flexDirection: 'column',
                  gap: 1,
                  textAlign: 'start'}}>
               <Typography sx={{color: '#3271d2', fontWeight: 700, cursor: 'pointer',
                                 "&:hover": {
                                  textDecoration: 'underline',
                                }}}>{course.name.ru}</Typography>
               <Typography sx={{color: '#898989'}}>{course.description.ru}</Typography> 
               </Box>
              </TableCell>




              <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>
                {(course.duration)} ч.
              </TableCell>

              <TableCell 
                sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
                }}>
                {
                  (() => {
                    switch(course.difficulty.value) {
                      case 3: 
                      return (
                        <Box display="flex" flexDirection='row' justifyContent={'center'} alignContent={'center'} alignItems={'center'} gap={1} >
                          <img src={hard_level_icon} alt="Hard Level" width={20} height={20}/>
                          <Typography sx={{fontSize: 14,}}>{course.difficulty.ru}</Typography>
                        </Box>
                      );
                      
                      case 2: 
                      return (
                        <Box display="flex" flexDirection='row' justifyContent={'center'} alignContent={'center'} alignItems={'center'} gap={1} >
                          <img src={medium_level_icon} alt="Medium Level" width={20} height={20}/>
                          <Typography sx={{fontSize: 14,}}>{course.difficulty.ru}</Typography>
                        </Box>
                      );
                      case 1:
                        return (
                          <Box display="flex" flexDirection='row' justifyContent={'center'} alignContent={'center'} alignItems={'center'} gap={1} >
                            <img src={light_level_icon} alt="Light Level" width={20} height={20}/>
                            <Typography sx={{fontSize: 14,}}>{course.difficulty.ru}</Typography>
                          </Box>
                        );
                      default:
                        return (
                          <Box display="flex" flexDirection='row' justifyContent={'center'} alignContent={'center'} alignItems={'center'} gap={1} >
                            <img src={light_level_icon} alt="Hard Level" width={20} height={20}/>
                            <Typography sx={{fontSize: 14,}}>{course.difficulty.ru}</Typography>
                          </Box>
                        );
                    }
                  })()
                }
              </TableCell>


              <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>{course?.progress || 0}
              </TableCell> 
              <TableCell
              sx={{
                cursor: 'pointer', 
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>
{
    account.purchased && account.purchased.includes(course.id) ? 
    (
        <Button
            variant="outlined"
            onClick={() => handleLearnClick(course.id)}
            sx={{my: 3, textTransform: 'none', color: '#000'}}
        >
            Продолжить изучение
        </Button>
    ) : 
    (
        <Button
            variant="outlined"
            onClick={() => handleRowClick(course.id)}
            sx={{my: 3, textTransform: 'none', color: '#000'}}
        >
            Узнать больше
        </Button>
    )
}

              </TableCell> 
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


export default CoursesList;
