import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { useTheme, useMediaQuery} from "@mui/material";
//
import {LandingHeader} from '../landing';
// ----------------------------------------------------------------------

const HEADER_MOBILE = 120;
const HEADER_DESKTOP = 120;


const StyledRoot = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
    width: '100%',
  });
  

  const Main = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingBottom: theme.spacing(10),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: HEADER_DESKTOP,
    [theme.breakpoints.down('sm')]: {
      paddingTop: HEADER_MOBILE,  // Updated paddingTop value
    },
  }));

// ----------------------------------------------------------------------

export default function AuthLayout() {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const defaultColor = '#FFFFFF'; // The color you want as default
  const homepageColor = '#2E2E2E'; // The color you want for the Homepage
  
  useEffect(() => {
    // Set the background color when the component mounts
    document.body.style.backgroundColor = homepageColor;
  
    // Reset the background color when the component unmounts
    return () => {
      document.body.style.backgroundColor = defaultColor;
    };
  }, []);
  return (
    <StyledRoot>
            <LandingHeader isUserAuthenticated={false} openNav={open} onCloseNav={() => setOpen(false)} onOpenNav={() => setOpen(true)}/>

      <Main>
      <Outlet />
      </Main>
      
    </StyledRoot>
    
  );
}
