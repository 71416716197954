import React from 'react';
import { Avatar, Box, Card, CardContent, Grid, Typography, Rating } from '@mui/material';
import { Masonry } from '@mui/lab';
import { useTheme } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { differenceInDays, differenceInMonths, differenceInWeeks } from 'date-fns';

const formatDate = (timestamp) => {
  if (!timestamp) return "Больше месяца назад";
  
  const reviewDate = new Date(timestamp);
  const now = new Date();
  const dayDifference = differenceInDays(now, reviewDate);
  const weekDifference = differenceInWeeks(now, reviewDate);
  const monthDifference = differenceInMonths(now, reviewDate);

  if (dayDifference === 0) return "Сегодня";
  if (dayDifference === 1) return "Вчера";
  if (weekDifference < 1) return "На этой неделе";
  
  // Format the date in Russian locale
  return reviewDate.toLocaleDateString('ru-RU', {
    year: 'numeric', 
    month: 'long', 
    day: 'numeric'
  });
};

const ReviewCard = ({ review }) => (
  <Card elevation={3}>
    <CardContent>
      <Grid container spacing={2}>
        <Grid item>
          <Avatar src={review.avatar} alt={review.name} />
        </Grid>
        <Grid item xs>
          <Box sx={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
            <Typography variant="h6">{review.name}</Typography>
            <Typography variant="body2">{formatDate(review.timestamp)}</Typography>
          </Box>
          <Rating value={review.rating} readOnly />
          <Typography variant="body2">{review.text}</Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

const ReviewsSectionCourse = ({ reviews }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const sortedReviews = reviews.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
  return (
    <Box paddingTop={3}>
      {reviews.length > 0 ? (
        <Masonry
          columns={isSmallScreen ? 1 : 3}
          spacing={isSmallScreen ? 2 : 3}
        >
          {sortedReviews.map((review, index) => (
            <ReviewCard review={review} key={index} />
          ))}
        </Masonry>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Typography variant="h4" gutterBottom>
            Ой, отзывов пока нет! 🤔
          </Typography>
          <Typography variant="body1">
            Похоже, что никто ещё не оставил отзыв. Будьте первым, кто поделится своим мнением! 🌟
          </Typography>
        </Box>
      )}
    </Box>
  );
};


export default ReviewsSectionCourse;
