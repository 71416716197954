import PropTypes from 'prop-types';
import { useEffect, useContext} from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Badge, Box, Container, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
import { AppBar, Toolbar } from '@mui/material';
// hookss
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import UserContext from '../../../UserContext';
import { CartContext } from '../../../CartContext';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccountPopover from '../header/AccountPopover';
import PromoMembership from '../header/PromoMembership';
import CartIcon from '../header/CartIcon';
import NotificationsPopover from '../header/NotificationsPopover';
import { useNavigate } from 'react-router-dom';
import SvgColor from '../../../components/svg-color/SvgColor';
// ----------------------------------------------------------------------
const MARGIN_TOP = 20;
const HEADER_MOBILE = 0;
const HEADER_DESKTOP = 0;
const NAV_WIDTH = 260;

const openLink = () => {
  window.open('https://app.defaust.com', '_blank');
}
const MenuItemButton = styled(Button)(({ theme }) => ({
  color: '#2E2E2E',
  textAlignment: 'start',
  justifyContent: 'start',
  fontSize: '0.9rem',
  fontWeight: '700',
  marginTop: '5px',
  marginRight: 15,
  paddingLeft: 10,
  paddingRight: 10,
  textTransform: 'none',
  borderRadius: 10,
  border: '0px solid rgba(209, 213, 219, 0.2)',
  backdropFilter: 'blur(4px)',
  '&:hover': {
    backgroundColor: alpha('#2E2E2E99', 0.2),
  },
  '&:active': {
    border: '1px solid rgba(209, 213, 219, 0.2)',
    backgroundColor: 'transparent',
  },
}));

const StyledAppBar = styled((props) => <AppBar variant="dense" position="absolute" {...props} />)(({ theme }) => ({
  backgroundColor: 'transparent',
  zIndex: 999,
  marginTop: MARGIN_TOP,
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.down('lg')]: {
    minHeight: HEADER_DESKTOP,

  },
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    
  },
  top: 0,  // Ensures that the AppBar sticks to the top
}));



const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 2,
  
}));

const StyledAnalytics = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundImage: 'linear-gradient(103deg, #FFF 2.11%, #FFF 83.3%)',
  border: '1px solid #E5E7E9'
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { cart, setCart } = useContext(CartContext);
  const account = useContext(UserContext);
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');
  const navigate = useNavigate();
  const icon = (name) => `/assets/icons/navbar/${name}.svg`
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderContent = (
    <Box sx={{
      flexShrink: { lg: 0 },
      width: { lg: NAV_WIDTH },
      display: 'flex',
      flexDirection: 'column',
      height: 1
    }}>
    <Scrollbar
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}>
      <Box sx={{ mt: 6, pb: 0, display: 'flex', flexFlow: 'column', alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
        <Logo logoColor={true} />
      </Box>
  
      <NavSection data={navConfig} cartCount={cart.courses.length + cart.certificates.length} />    
    </Scrollbar>
    <Box onClick={openLink} sx={{ mb: 2, mx: 3, cursor: 'pointer' }}>
        <StyledAnalytics>
        <Avatar variant="rounded" src={'/assets/illustrations/back_illustration2x.png'} alt="photoURL" />
        <Box  sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ fontSize: '0.6rem', color: '#000', fontWeight: 700 }}>
              DEFAUST ANALYTICS
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '0.8rem', color: 'text.secondary', fontWeight: 'medium', textDecoration: 'underline' }}>
            Журнал трейдера 🔥
            </Typography>
          </Box>
        </StyledAnalytics>

    </Box>
    <Box sx={{ mb: 5, mx: 3 }}>
      <RouterLink to="/settings/subscriptions" style={{ textDecoration: 'none' }}>
        <StyledAccount>
          <Avatar src={account?.avatarURL || '/assets/images/avatars/user_avatar.png'} alt="photoURL" />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: 'grey.0', fontWeight: 700 }}>
              {account?.name || 'Defaust Student'}
            </Typography>
            <Box display="flex" flexDirection="row">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Профиль
            </Typography>
            <ChevronRightIcon sx={{fontSize: 18, mt: 0.3,  color: 'text.secondary' }}/>
            </Box>
          </Box>
        </StyledAccount>
      </RouterLink>
    </Box>
  </Box>
  );

  const DesktopMenu = () => (
    <StyledAppBar>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '78%', width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
          {/* Left Section: Logo */}
          <Logo logoColor={true} customSize={[60, 60]} />
          <Logo  customSize={[90, 60]} />
        </Box>
          {/* Right Section: Menu Items */}
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <MenuItemButton onClick={() => navigate('/dashboard/catalog/certification/defaust-trader')}>
              Трейдинг 
              <SvgColor src={icon('candlestick-chart-outline-rounded')} sx={{ color: '#2E2E2E',  width: 22, height: 22}} />
            </MenuItemButton>
            <MenuItemButton onClick={() => navigate('/dashboard/catalog/crypto')}>
              Крипта
              <SvgColor src={icon('ethereum')} sx={{ color: '#2E2E2E', width: 18, height: 18 }} />
            </MenuItemButton>
            <MenuItemButton onClick={() => navigate('/dashboard/progress')}>Прогресс</MenuItemButton>
            <MenuItemButton onClick={() => navigate('/dashboard/conferences')}>Конференции</MenuItemButton>
            <MenuItemButton onClick={() => window.open('https://analytics.defaust.com/', '_blank')}>
              Аналитика
            </MenuItemButton>

            {account && account.membership !== 'active' && (
              <Box sx={{display: 'flex', flexDirection: 'row', pr: '20px'}}>
                <Badge badgeContent={'pro'} color="info"  sx={{ '.MuiBadge-badge': {backgroundColor: '#548dff'}}}>
                  <MenuItemButton sx={{color: '#548dff', mr: 0}} onClick={() => navigate('/settings/subscriptions')}>Membership</MenuItemButton>
                </Badge>
              </Box>
            )}

            {/* <PromoMembership navigate={navigate}/> */}
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2}}>
              <CartIcon navigate={navigate} items={cart.courses?.length + cart.certificates?.length} />
              {/* <NotificationsPopover /> */}
              <AccountPopover />
              
            </Box>
            {/* Add more menu items here */}
          </Box>
  
        </Toolbar>
      </Box>
    </StyledAppBar>
  );
  

  const MobileMenu = () => (
    <Drawer
      open={openNav}
      onClose={onCloseNav}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: { width: NAV_WIDTH },
      }}
    >
      {renderContent}
    </Drawer>
  );

  return (
    <Box component="nav">
      {isDesktop ? <DesktopMenu /> : <MobileMenu />}
    </Box>
  );
}

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};