import React, { useState, useEffect, useCallback, useContext } from 'react';
import UserContext from '../../UserContext';
import { Container, Typography, TextField, Button, Avatar, Stack } from '@mui/material';
import { auth, db, storage } from '../../firebase';  // Import Firebase SDKs
import { doc,  updateDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import { signOut } from 'firebase/auth';
import { updateProfile, reauthenticateWithCredential, EmailAuthProvider, sendEmailVerification, updateEmail, updatePassword } from 'firebase/auth';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { Helmet } from 'react-helmet-async';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
export default function SettingsAccount() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [discordUsername, setDiscordUsername] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState(null); // state for error messages
  const [success, setSuccess] = useState(null);
  const [open, setOpen] = useState(false); // state for Snackbar
  const account = useContext(UserContext);
  const uploadAvatar = async (currentUser, avatar) => {
    const storageRef = ref(storage, `avatars/${currentUser.uid}`);
    const uploadTask = uploadBytesResumable(storageRef, avatar);
    
  
    return new Promise((resolve, reject) => {
      uploadTask.on('state_changed',
        (snapshot) => {},
        (error) => { reject(error); },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          await updateProfile(currentUser, {
            photoURL: downloadURL,
          });
          resolve();
        }
      );
    });
  };
  const functions = getFunctions();
const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
  const handleManageSubscription = async () => {
    try {
        setLoading(true);
        const link = await createPortalLink({});
        window.location.href = link.data.url;
    } catch (error) {
        console.error('Error creating customer portal link:', error);
    }
};

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      
      const user = auth.currentUser;
      if (user && account) {
        setCurrentUser(user);
        setName(account.name);
        setEmail(user.email);
        setDiscordUsername(account.discord_username ?? '');
      }
    };
  
    fetchUserData();
  }, []);


  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        setCurrentUser(null); // Reset the current user state
      })
      .catch((error) => {
        // An error happened.
        console.error("Error signing out: ", error);
      });
  };

  const handleSave = useCallback(async () => {
    try {
      setSuccess(null); // Reset previous success messages
      setError(null); // Reset previous errors
      
      if (!currentUser) {
        throw new Error('Пользователь не авторизован');
      }
  
      const userDocRef = doc(db, 'users', currentUser.uid);
  
      // Update display name and Firestore name field
      await updateProfile(currentUser, {
        displayName: name,
      });
      await updateDoc(userDocRef, {
        name,
      });
  
      // Update avatar without re-authentication
      if (avatar) {
        await uploadAvatar(currentUser, avatar);
      }
  
      // Check if email or password needs to be updated
      if (email !== currentUser.email || password) {
        if (!password) {
          throw new Error('auth/missing-password');
        }
        
        // Re-authenticate before updating email or password
        const userCredential = EmailAuthProvider.credential(currentUser.email, password);
        await reauthenticateWithCredential(currentUser, userCredential);
  
        // Update Firestore email field
        await updateDoc(userDocRef, {
          email,
        });
  
        if (email !== currentUser.email) {
          await updateEmail(currentUser, email);
          await sendEmailVerification(currentUser);
        }
  
        if (password) {
          await updatePassword(currentUser, password);
        }
      }
      setSuccess('Профиль успешно обновлён');
      setOpen(true); // Open the Snackbar for success
    } catch (error) {
      console.error("Error saving profile: ", error);
      let errorMessage;
      switch (error.code) {
        case 'auth/missing-password':
          errorMessage = 'Пароль не может быть пустым';
          break;
        case 'auth/wrong-password':
          errorMessage = 'Неправильный пароль';
          break;
        default:
          errorMessage = "Ошибка при сохранении профиля: " + error.message;
      }
      setError(errorMessage);
      setOpen(true); // Open the Snackbar
    }
  }, [currentUser, avatar, name, email, password]);
  

  return (
    <>
        <Helmet>
            <html lang="ru" /> 
            <title>Настройки профиля | DEFAUST</title>
            <meta name="description" content="Отслеживайте и ведите статистику сделок с помощью Журнал Трейдера." />
            <script type="application/ld+json">
              {`
                {
                  "@context": "http://schema.org",
                  "@type": "WebPage",
                  "name": "Настройки профиля",
                  "description": "Отслеживайте и ведите статистику сделок с помощью Журнал Трейдера."
                }
              `}
            </script>
        </Helmet>
        <Container maxWidth="lg">
        <Typography variant="h4" sx={{ mt: 4, pb: 4}}>
    Профиль
  </Typography>
      <Stack spacing={2} direction="column" width={['100%', '100%','50%']}>
      <Avatar src={currentUser?.photoURL} />
        <TextField 
          label="Имя"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField 
          label="Никнейм"
          value={account.nickname ?? ''}
          disabled={true}
        />
  <TextField 
    label="Discord никнейм"
    value={discordUsername}
    disabled={true}
  />
        <TextField 
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField 
          label="Пароль"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
                <Button 
          variant="contained"
          color="secondary"
          onClick={handleLogout} // Attach the handleLogout function to onClick
        >
          Выйти из аккаунта
        </Button>
        <Button 
        loading={loading}
          variant="contained"
          color="primary"
          onClick={handleSave}
        >
          Сохранить
        </Button>
        <LoadingButton 
          variant="contained"
          color="primary"
          onClick={() => {handleManageSubscription()}}
        >
          Управлять подписками на курсы
        </LoadingButton>
        {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
        )}
        {success && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {success}
        </Alert>
      </Snackbar>
        )}
      </Stack>
</Container>
</>
  );
}
