import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { Container, Box} from '@mui/material';
import { db } from '../../firebase.js';
import { getDocs, collection, doc, getDoc, query, where} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
// @mui
import { Skeleton } from '@mui/material';
import { CoursesList } from '../../sections/index.js';

export default function Skills() {
  const [loading, setLoading] = useState(true);
  const [fetchedCourses, setFetchedCourses] = useState([]);
  const [userPurchasedCourses, setUserPurchasedCourses] = useState([]);

  useEffect(() => {
      const fetchCoursesAndUserData = async () => {
          setLoading(true);
            const coursesCollection = collection(db, "courses");
            const courseQuery = query(coursesCollection, where('private', '==', false));
            const courseSnapshot = await getDocs(courseQuery);
            const courseList = courseSnapshot.docs.map(doc => {
              return {
                id: doc.id,
                ...doc.data()
              }
            });
            setFetchedCourses(courseList);


          // Fetch user's purchased courses
          const userId = getAuth().currentUser?.uid;
          const userDoc = await getDoc(doc(db, 'users', userId));
    
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserPurchasedCourses(userData.purchased || []);
          setLoading(false);
      };
    };

      fetchCoursesAndUserData();
  }, []);

  const purchasedCourses = fetchedCourses.filter(course => userPurchasedCourses.includes(course.id));

            

if (loading) {
return (
        <Container maxWidth="lg">
        <Skeleton variant="rectangular" width="100%" height={300} sx={{
                            border: "1px solid rgba(209, 213, 219, 0.3)", // border
                            borderRadius: 5,
                            padding: 2,
                            mt: 5,
        }}/>
        </Container>
);
}

if (loading === true) {
  return (
    <>
      <Helmet>
        <title>Аналитика трейдера | DEFAUST</title>
      </Helmet>
      
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
      </Box>
    </>
  );
} else {
  return (
    <>
      <Helmet>
        <title>Аналитика трейдера | DEFAUST</title>
      </Helmet>


      <Container maxWidth="lg">
<CoursesList courses={purchasedCourses} />

</Container>

    </>
);
}}