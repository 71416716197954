import React from 'react';
import styled from '@emotion/styled';

const FooterContainer = styled.div`
    display: flex;
    justify-content: right;
    align-items: right;
    padding: 20px;
    color: #606060;
    position: fixed; // Added this line
    bottom: 0; // Added this line
    left: 320px; // Added this line

`;


function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <FooterContainer>
            DEFAUST {currentYear}, all rights reserved.
        </FooterContainer>
    );
}

export default Footer;
