import React, { useState, useEffect, useCallback } from 'react';
import { getDocs, getDoc, query, where, collection, doc, updateDoc, arrayUnion, setDoc, arrayRemove, deleteDoc } from 'firebase/firestore';
import {Popover, Typography, Box, TextField, alpha, Table, TableBody, TableCell, TableHead, TableRow, Button, Dialog, DialogContent } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useNavigate } from 'react-router-dom';

const CourseStructure = ({ courseId, db }) => {
    const navigate = useNavigate();
  const [courseFields, setCourseFields] = useState({});
  const [structureItems, setCourseStructure] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [courseDocIdState, setCourseDocId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [inputId, setInputId] = useState('');
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [typeDelete, setTypeDelete] = React.useState(null);
  const [itemIdDelete, setItemIdDelete] = React.useState(null);

  const handleMoveItem = (fromIndex, toIndex) => {
    if (toIndex < 0 || toIndex >= structureItems.length) return;

    const updatedStructure = [...structureItems];
    const [movedItem] = updatedStructure.splice(fromIndex, 1);
    updatedStructure.splice(toIndex, 0, movedItem);
    setCourseStructure(updatedStructure);
    handleSaveNewOrder();
  };

  const handleSaveNewOrder = async () => {
    // Save the updated structure to Firestore
    try {
      const courseRef = doc(db, 'courses', courseDocIdState);
      const updatedStructure = structureItems.map(item => ({ [item.type]: item.id }));
      await updateDoc(courseRef, {
        structure: updatedStructure,
      });
    } catch (err) {
      console.error("Error saving new order: ", err);
    }
  };

  const handleDeleteClick = (event, type, id) => {
    setAnchorEl(event.currentTarget);
    setTypeDelete(type);
    setItemIdDelete(id);
};

  

  const handleDeleteClose = () => {
    setAnchorEl(null);
  };



  useEffect(() => {
    const fetchCourseData = async () => {
        try {
          const courseQuery = query(collection(db, 'courses'), where('id', '==', courseId));
          const courseSnapshot = await getDocs(courseQuery);
      
          if (!courseSnapshot.empty) {
            const courseDoc = courseSnapshot.docs[0];
            setCourseFields(courseDoc.data());
            const courseDocId = courseDoc.id;  // Getting the document ID
            setCourseDocId(courseDocId);
            const courseData = courseDoc.data();
            const structure = courseData.structure;
            
            // Fetch the lessons, quizzes, exams, etc.
            const items = await Promise.all(structure.map(async (item) => {
                const type = Object.keys(item)[0];
                const id = item[type];
                
                // If the type is 'lesson', adjust the path
                const pathType = type === 'lesson' ? 'lessons' : type;
                
                // Use courseDocId for querying nested collections and get directly by document ID
                const itemDoc = await getDoc(doc(db, `courses/${courseDocId}/${pathType}/${id}`));
                
                return {
                  type,
                  id,
                  data: itemDoc.exists() ? itemDoc.data() : "Not found",
                };
              }));
      
            setCourseStructure(items);
            ////console.log('Course structure', items);
            setLoading(false);
          } else {
            setError('Course not found');
            setLoading(false);
          }
        } catch (err) {
          setError('An error occurred while fetching data.');
          setLoading(false);
          console.error(err);
        }
      };
      

    fetchCourseData();
  }, [courseId]);

  const handleDeleteItem = useCallback(async (type, id) => {
    try {
      // Step 1: Update the structure field in the Firestore document to remove the item
      const courseRef = doc(db, 'courses', courseDocIdState); // courseDocId needs to be defined
      const itemToRemove = { [type]: id };
      await updateDoc(courseRef, {
        structure: arrayRemove(itemToRemove)
      });
      
      // Step 2: Delete the specific document from its respective collection (lessons, quizzes, or exams)
      const itemDocPath = `courses/${courseDocIdState}/${type === 'lesson' ? 'lessons' : type}/${id}`;
      const itemDocRef = doc(db, itemDocPath);
      await deleteDoc(itemDocRef);
      
      // Update local state
      const newStructureItems = structureItems.filter(item => !(item.type === type && item.id === id));
      setCourseStructure(newStructureItems);
      setAnchorEl(null);
      setItemIdDelete(null);
    } catch (err) {
      console.error("Error deleting item: ", err);
    }
}, [itemIdDelete]);

  
  const handleAddItem = async (type) => {
    const newId = inputId;
    const newItem = { [type]: newId };
  
    try {
      const courseRef = doc(db, 'courses', courseDocIdState); // use courseDocId
      await updateDoc(courseRef, {
        structure: arrayUnion(newItem)
      });
  
      const newItemDocRef = doc(db, `courses/${courseDocIdState}/${type === 'lesson' ? 'lessons' : type}`, newId);
  
      if (type === 'lesson') {
        await setDoc(newItemDocRef, {
          duration: 3,
          id: newId,
          name: { en: "New Lesson", ru: "Новый урок" },
          shortDescription: { en: "Short description of newly created lesson", ru: "Короткое описание нового урока." },
          type: "video",
          url: { en: "", ru: "" }
        });
      } else if (type === 'quiz') {
        await setDoc(newItemDocRef, {
          description: { en: "description of newly created quiz", ru: "Первый тест описание" },
          duration: 60,
          id: newId,
          name: { en: "New Test", ru: "Новый тест" },
          type: "selectOne"
        });
      } else if (type === 'exam') {
        await setDoc(newItemDocRef, {
          description: { en: "description of newly created exam", ru: "Первый экзамен описание" },
          duration: 60,
          id: newId,
          name: { en: "New Exam", ru: "Новый экзамен" },
          type: "selectOne"
        });
      }
  
      setCourseStructure([...structureItems, {
        type,
        id: newId,
        data: "Newly added item"
      }]);
  
    } catch (err) {
      console.error("Error adding new item: ", err);
    }
    setInputId('');
    setButtonDisabled(true);
    setOpenDialog(false);
  };
  

  return (
    <div>
      {error && <p>{error}</p>}
      {!loading && (
        <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{
                    background: 'transparent',
                    color: '#000',
                    padding: "15px",
                    textAlign: 'center',
                    fontWeight: 700,
                    borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                }}>Type</TableCell>
              <TableCell sx={{
                    background: 'transparent',
                    color: '#000',
                    padding: "15px",
                    textAlign: 'center',
                    fontWeight: 700,
                    borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                }}>ID</TableCell>
              <TableCell sx={{
                    background: 'transparent',
                    color: '#000',
                    padding: "15px",
                    textAlign: 'center',
                    fontWeight: 700,
                    borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                }}>Title</TableCell>
              <TableCell sx={{
                    background: 'transparent',
                    color: '#000',
                    padding: "15px",
                    textAlign: 'center',
                    fontWeight: 700,
                    borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {structureItems.map((item, index) => (
              <TableRow sx={{
                py: 4,
                "&:hover": {
                  backgroundColor: alpha("#FFF", 0.15),
                },
                "&:last-child td, &:last-child th": {
                  borderBottom: 0,
                },
                borderBottom: '1px solid rgba(209, 213, 219, 0.3)', // Set color of border
              }} key={index}>
                          <TableCell>
                          <Box border="1px solid rgba(209, 213, 219, 0.3)" borderRadius={3} display="flex" flexDirection="column" >
            <Button onClick={() => handleMoveItem(index, index - 1)}><ArrowUpwardIcon /></Button>
            <Button onClick={() => handleMoveItem(index, index + 1)}><ArrowDownwardIcon /></Button>
            </Box>
          </TableCell>
                <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>{item.type}</TableCell>
                <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>{item.id}</TableCell>
                <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>{item.data?.name?.ru}</TableCell>
                <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/admin/course/edit/${courseFields.id}/${item.type}/${item.id}`)}
                  >
                    Править
                  </Button>
                </TableCell>
                <TableCell
              sx={{
                cursor: 'pointer', 
                  background: 'transparent',
                  color: '#000',
                  padding: "5px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>
                                <Button 
                                    sx={{color: '#F52525',
                                        backgroundColor: 'transparent',
                                        borderColor: '#F52525',
                                        "&:hover": { 
                                            backgroundColor: '#F5252510',
                                            borderColor: '#F52525',
                                            }}}
                                    variant="outlined" 
                                    onClick={(event) => handleDeleteClick(event, item.type, item.id)}
                                >
                                    Удалить
                                </Button>
                                <Popover
  sx={{
    '.MuiPaper-root': {
      p: 2,
      backgroundColor: alpha("#fff", 1),
      border: "1px solid rgba(209, 213, 219, 0.3)",
      boxShadow: "0px 2px 1px -1px #ffffff10",
      borderRadius: 5,
    }
  }}
  open={Boolean(anchorEl)}
  anchorReference="anchorPosition"
  onClose={handleDeleteClose}
  anchorPosition={{ top: window.innerHeight / 2, left: window.innerWidth / 2 }}
  anchorOrigin={{
    vertical: 'center',
    horizontal: 'center',
  }}
  transformOrigin={{
    vertical: 'center',
    horizontal: 'center',
  }}
>
  <Typography sx={{ p: 2 }}>Вы уверены, что хотите удалить этот аккаунт?</Typography>
  <Box sx={{flex: 1, display: 'flex', justifyContent: 'center', gap: 2}}>
  <Button
  sx={{
    border: "0.5px solid #F52525",
    backgroundColor: alpha("#F52525", 0.125),
    color: '#F52525',

    "&:hover": {
      border: "0.5px solid #F52525",
      cursor: 'pointer',
      backgroundColor: alpha("#F52525", 0.225),
    },
  }}
  onClick={() => handleDeleteItem(item.type, item.id)}
>
    Удалить
</Button>
  <Button sx={{      
      border: "0.5px solid #6BEBA4",
      backgroundColor: alpha("#6BEBA4", 0.125),
      color: '#6BEBA4',
      "&:hover": {
        border: "0.5px solid #6BEBA4",
        cursor: 'pointer', 
        backgroundColor: alpha("#6BEBA4", 0.225),
      },}}
  onClick={handleDeleteClose}>Отмена</Button>
  </Box>
</Popover>
              </TableCell> 
              </TableRow>
            ))}

          </TableBody>
        </Table>
        <Button variant="contained" color="primary" onClick={handleSaveNewOrder}>Save New Order</Button>
          <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>Add Item</Button>
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
  <DialogContent>
    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
      <TextField 
        label="Enter ID"
        fullWidth
        onChange={e => {
          setInputId(e.target.value);
          setButtonDisabled(!e.target.value);
        }} 
      />
    </div>
    <Box style={{ display: "flex", justifyContent: "space-around", gap: 10 }}>
      <Button variant="outlined" onClick={() => handleAddItem("lesson")} disabled={isButtonDisabled}>
        Lesson
      </Button>
      <Button variant="outlined" onClick={() => handleAddItem("quiz")} disabled={isButtonDisabled}>
        Quiz
      </Button>
      <Button variant="outlined" onClick={() => handleAddItem("exam")} disabled={isButtonDisabled}>
        Exam
      </Button>
    </Box>
  </DialogContent>
</Dialog>



                  </>
      )}
    </div>
  );
};

export default CourseStructure;
