import { useState } from "react";
import { MeetingForm } from "../../sections/admin/AdminMeetingForm";
import { ImageUploader } from "../../sections/admin/AdminImageUploader";
import { MeetingList } from "../../sections/admin/AdminMeetingsList";
import { Container } from "@mui/material";
const AdminMeetings = () => {
    const [selectedMeeting, setSelectedMeeting] = useState(null);
  
    return (
      <Container width={'lg'}>
        <MeetingList onSelect={(meeting) => setSelectedMeeting(meeting)} />
        <MeetingForm initialData={selectedMeeting} onSave={() => setSelectedMeeting(null)} />
      </Container>
    );
  };
  
  export default AdminMeetings;
  