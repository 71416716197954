import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
  cartCount: PropTypes.number,
};

export default function NavSection({ data = [], cartCount, ...other }) {
  return (
    <Box {...other} sx={{ display: 'flex', flexDirection: 'column' }}>
      <List sx={{ display: 'flex', flexDirection: 'column', gap: 0.7, px: 3, pt: 6 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} cartCount={cartCount} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object.isRequired,
  cartCount: PropTypes.number.isRequired,
};

  function NavItem({ item, cartCount }) {
    const { title, path, icon, dropdownItems = [] } = item;
    const [isOpen, setIsOpen] = useState(false);
  
    const location = useLocation();
    const currentPath = location.pathname;
  
    useEffect(() => {
      if (currentPath === path || dropdownItems.some(child => currentPath === child.path)) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }, [currentPath, path, dropdownItems]);

  return (
    <Box sx={{ flexDirection: 'column' }}>
      <StyledNavItem
        component={RouterLink}
        to={path}
        sx={{
          color: '#050505',
          fontWeight: 'medium',
          backgroundColor: isOpen ? '#E8E8E8' : 'inherit',
          '&:hover': {
            backgroundColor: '#E8E8E8',
          },
          display: 'flex', // Added to align items
          justifyContent: 'space-between' // Added to push arrow to the end
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}> {/* Wrapper for icon, text, and cart count */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}> {/* Inner wrapper for icon and text */}
            <StyledNavItemIcon>{icon}</StyledNavItemIcon>
            <ListItemText disableTypography primary={title} />
          </Box>
        </Box>
        {title.toLowerCase() === 'корзина' && (
            <Box sx={{ padding: 1, borderRadius: 3, px: 2, py: 0.25, color: cartCount > 0? '#F52525' : '#204631', background: cartCount > 0? '#F5252530' : '#EFF7E2', mr: 1,}}>
              {cartCount}
            </Box>
          )}
        {dropdownItems.length > 0 && <KeyboardArrowDownIcon />} {/* Dropdown arrow */}
      </StyledNavItem>

      {isOpen && dropdownItems.length > 0 && (
        <List sx={{ pl: 4, }}>
          {dropdownItems.map((dropdownItem, index) => (
            <StyledNavItem
              component={RouterLink}
              to={dropdownItem.path}
              sx={{
                color: '#050505',
                '&:hover': {
                  backgroundColor: '#E8E8E8',
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <StyledNavItemIcon>{dropdownItem.icon}</StyledNavItemIcon>
                <ListItemText disableTypography primary={dropdownItem.title} />
              </Box>
            </StyledNavItem>
          ))}
        </List>
      )}
    </Box>
  );
}