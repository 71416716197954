import React, { useEffect, useState } from 'react';
import { Grid, Container, Typography, FormControl, InputLabel, Select, MenuItem , Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { alpha } from '@mui/material/styles';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { db } from '../../firebase';
import { getAuth } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';
import { roundValue } from '../../utils/formatNumber';
import { collection, getDocs, query, where } from "firebase/firestore";
import { Helmet } from 'react-helmet-async';
//sections
import {CoursesCatalogSection} from '../../sections/@dashboard/app';
export default function CatalogCourses() {
  const mockCourses = [
    {
      id: '1',
      title: 'Volume Profile',
      description: 'Изучение методов использования Volume Profile для определение уровней ликвидности при объемном анализе рынка.',
      progress: 0,
      lessons: 12,
      quizes: 5,
      completed: 0,
      purchased: false,
      enrolled: 0,
      specializations: ['trading'],
      soon: true
    },
    {
        id: '2',
        title: 'Cumulative Delta',
        description: 'Применение кумулятивной дельты для определения баланса рынка и подтверждения провокаций от смарт-мани с помощью расхождения объемов и цены.',
        progress: 0,
        lessons: 15,
        quizes: 6,
        completed: 0,
        purchased: false,
        enrolled: 0,
        specializations: ['trading'],
        soon: true
      },
      {
        id: '3',
        title: 'Создание торговой стратегии',
        description: 'Изучение принципов создания торговой стратегии от идеи до бек-тестетирования на In-Sample и Out-of-Sample периодах.',
        progress: 0,
        lessons: 32,
        quizes: 8,
        completed: 0,
        purchased: false,
        enrolled: 0,
        specializations: ['trading'],
        soon: true
      },
      {
        id: '4',
        title: 'Торговый сетап: Provocation',
        description: 'Изучение главной точки входа стратегии DEFAUST. Пошаговый алгоритм определения точки входа и ее реализация.',
        progress: 0,
        lessons: 34,
        quizes: 12,
        completed: 0,
        purchased: false,
        enrolled: 0,
        specializations: ['trading'],
        soon: true
      },
      {
        id: '5',
        title: 'Торговый сетап: Trend Continuation',
        description: 'Трендовая точка входа стратегии DEFAUST. Изучение нахождения точки входа и механика исполнения.',
        progress: 0,
        lessons: 25,
        quizes: 10,
        completed: 0,
        purchased: false,
        enrolled: 0,
        specializations: ['trading'],
        soon: true
      },
      {
        id: '6',
        title: 'Риск-менеджмент',
        description: 'Полное изучение риск-менеджмента в трейдинге. Методы контроля риска, просчета объемов и основные параметры риска (leverage, margin, risk).',
        progress: 0,
        lessons: 28,
        quizes: 13,
        completed: 0,
        purchased: false,
        enrolled: 0,
        specializations: ['trading'],
        soon: true
      },
      {
        id: '7',
        title: 'Психология в трейдинге',
        description: 'Изучение принципов котроля эмоций в трейдинге. Вероятностное мышление, пятерка психологии и дисциплина.',
        progress: 0,
        lessons: 14,
        quizes: 5,
        completed: 0,
        purchased: false,
        enrolled: 0,
        specializations: ['trading'],
        soon: true
      },
      {
        id: '8',
        title: 'Статистика в трейдинге',
        description: 'Изучение всех параметров, показателей и коэффициентов, которые влияют на трейдинг и инвестиции',
        progress: 0,
        lessons: 33,
        quizes: 30,
        completed: 0,
        purchased: false,
        enrolled: 0,
        specializations: ['trading'],
        soon: true
      },
      {
        id: '9',
        title: 'Журнал трейдера',
        description: 'Изучение методов ведения журнала трейдера. Как ведения журнала влияет на результативность в трейдинге',
        progress: 0,
        lessons: 12,
        quizes: 5,
        completed: 0,
        purchased: false,
        enrolled: 0,
        specializations: ['trading'],
        soon: true
      },
      {
        id: '10',
        title: 'Проп-компании: получение капитала',
        description: 'Изучение всех принципов работы с проп-компанией: от прохождения отбора до вывода прибыли.',
        progress: 0,
        lessons: 15,
        quizes: 7,
        completed: 0,
        purchased: false,
        enrolled: 0,
        specializations: ['trading'],
        soon: true
      },
      {
        id: '11',
        title: 'Налоги в трейдинге',
        description: 'Изучение основных методово уплаты налогов с трейдинга. Структурирование доходов в юрисдикциях: Украина, Евросоюз, Дубай (ОАЭ).',
        progress: 0,
        lessons: 12,
        quizes: 5,
        completed: 0,
        purchased: false,
        enrolled: 0,
        specializations: ['trading'],
        soon: true
      },
      {
        id: '12',
        title: 'MetaTrader 4/5',
        description: 'Изучение механики работы с главным терминалом для торговли на FOREX.',
        progress: 0,
        lessons: 12,
        quizes: 4,
        completed: 0,
        purchased: false,
        enrolled: 0,
        specializations: ['trading'],
        soon: true
      },
      {
        id: '13',
        title: 'Введение в портфельное управление',
        description: 'Изучение принципов работы с инвестиционным портфелем. Составление портфеля, тактическая и стратегическая ребалансировка, аллокация, DCA модель и основные параметры портфеля.',
        progress: 0,
        lessons: 40,
        quizes: 32,
        completed: 0,
        purchased: false,
        enrolled: 0,
        specializations: ['investing'],
        soon: true
      },
      {
        id: '14',
        title: 'DeFi: Применение смарт-стратегий',
        description: 'Изучение DEX, money-market, фарминга, стейкинга и LP провайдинга. Изучение, разработка и применение смарт-стратегий с пассивной доходностью до 700% годовых.',
        progress: 0,
        lessons: 35,
        quizes: 20,
        completed: 0,
        purchased: false,
        enrolled: 0,
        specializations: ['crypto'],
        soon: true
      },

        ]
        const [loading, setLoading] = useState(true);
        const [fetchedCourses, setFetchedCourses] = useState([]);
        
        const fetchCourses = async () => {
          setLoading(true);
        
          // Fetch user data
          const userId = getAuth().currentUser?.uid;
          const userDoc = await getDoc(doc(db, 'users', userId));
        
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const purchasedCourses = userData.purchased || [];
        
            // Fetch all course data
            const coursesCollection = collection(db, "courses");
            const courseQuery = query(coursesCollection, where('private', '==', false));
            const courseSnapshot = await getDocs(courseQuery);
            const allCourses = courseSnapshot.docs.map(doc => {
              const data = doc.data();
              return {
                ...data,
                docId: doc.id,
              };
            });
        
            // Asynchronously fetch course reviews and update course data
            const fetchCourseReviews = async (courseDocId) => {
              const reviewsCollection = collection(db, "courses", courseDocId, "reviews");
              const reviewsQuery = query(reviewsCollection, where('verified', '==', true));
              const reviewSnapshot = await getDocs(reviewsQuery);
              const allReviews = reviewSnapshot.docs.map(doc => doc.data());
              const averageRating = allReviews.reduce((acc, review) => acc + review.rating, 0) / allReviews.length;
              return isNaN(averageRating) ? undefined : averageRating;
            };
        
            const coursesWithRatings = await Promise.all(allCourses.map(async (course) => {
              const lessonCount = course.structure.filter(item => item.lesson).length;
              const quizCount = course.structure.filter(item => item.quiz).length;
              const isPurchased = purchasedCourses.includes(course.id);
              const completedLessons = (userData.completed?.courses?.[course.id]?.lessons || []).length;
              const completedQuizzes = (userData.completed?.courses?.[course.id]?.quiz || []).length;
              const completed = completedLessons + completedQuizzes;
              const averageRating = await fetchCourseReviews(course.docId);
        
              return {
                id: course.id,
                title: course.name?.ru,
                description: course.description?.ru,
                progress: roundValue((completed / (lessonCount + quizCount)) * 100),
                lessons: lessonCount,
                quizes: quizCount,
                completed: completed,
                purchased: isPurchased,
                enrolled: course.enrolled ?? 0,
                rating: averageRating,
                specializations: course.specializations ?? [],
                soon: false,
              };
            }));
        
            const sortedCourses = [...coursesWithRatings, ...mockCourses].sort((a, b) => {
              return b.enrolled - a.enrolled;
            });
            setFetchedCourses(sortedCourses);
            setLoading(false);
          }
        };
        
        
            useEffect(() => {
                const fetchData = async () => {
                    await fetchCourses();
                };
        
                fetchData();
            }, []);
            


  return (
    <>
<Helmet>
  <html lang="ru" />
  <title>Каталог курсов и программ обучения от DEFAUST®</title>
  <meta name="description" content={`Выбирайте лучшие курсы по трейдингу, криптовалютам и инвестициям от DEFAUST ACADEMY!`} />
  <link rel="canonical" href="https://defaust.com/dashboard/catalog/courses" />
  <meta name="robots" content="index, follow" />
  
  {/* OpenGraph tags */}
  <meta property="og:title" content="Каталог курсов и программ обучения от DEFAUST®" />
  <meta property="og:description" content="Выбирайте лучшие курсы по трейдингу, криптовалютам и инвестициям от DEFAUST ACADEMY!" />
  <meta property="og:image" content="https://defaust.com/assets/images/previews/preview-catalog.jpg" />
  <meta property="og:url" content="https://defaust.com/dashboard/catalog/courses" />

  {/* Twitter Card tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Каталог курсов и программ обучения от DEFAUST®" />
  <meta name="twitter:description" content="Выбирайте лучшие курсы по трейдингу, криптовалютам и инвестициям от DEFAUST ACADEMY!" />
  <meta name="twitter:image" content="https://defaust.com/assets/images/previews/preview-catalog.jpg" />

  {/* Dynamic Schema */}
  <script type="application/ld+json">
    {`
      {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "Каталог курсов и программ обучения от DEFAUST®",
        "description": "Выбирайте лучшие курсы по трейдингу, криптовалютам и инвестициям от DEFAUST ACADEMY!."
      }
    `}
  </script>
</Helmet>
    <Container maxWidth="xl">
    <CoursesCatalogSection courses={fetchedCourses} loading={loading}/>

</Container>
    </>
  );
}