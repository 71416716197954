import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { CartProvider } from './CartContext';
import { useLocation } from 'react-router-dom';
import Joyride from 'react-joyride';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import {  useTour} from './TourProvider';
import steps from './TourProvider'; 
// components
import ScrollToTop from './components/scroll-to-top';
import { AccountProvider } from './AccountContext';
import UserProvider from './UserProvider';

import React, { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { analytics } from './firebase';
import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';

import useUser from './hooks/userHook';
// ----------------------------------------------------------------------

function ChildComponent() {
  const location = useLocation();

  useEffect(() => {
      logEvent(analytics, 'page_view', {
          page_path: location.pathname,
          page_title: document.title,
          page_location: window.location.href
      });
      logEvent(analytics, 'screen_view', {
          screen_name: location.pathname,
          screen_class: 'React Component Class or Function Name',
      });
  }, [location]);

  return null;
}

export default function App() {
  const user = useUser();
  const { isTourOpen } = useTour();
  // Check if user data is still loading
  if (user === undefined) {
    return <div>Loading...</div>; // or your loading component
  }
  if (user) {
    setUserId(analytics, user.uid);
    setUserProperties(analytics, { 'user_email': user.email });
  }
  

    return (

      <UserProvider>
         <CartProvider>
        <AccountProvider>
          <HelmetProvider>
            <BrowserRouter>
              <ThemeProvider>
                <ScrollToTop />
                <Router />
                        <Joyride 
          steps={steps} 
          run={isTourOpen}
          continuous={true}
          scrollToFirstStep={true}
          showProgress={true}
        />
                <ChildComponent />
              </ThemeProvider>
            </BrowserRouter>
          </HelmetProvider>
        </AccountProvider>
        </CartProvider>
      </UserProvider>

    );
  }
  
