import { useEffect, useState } from 'react';
import { doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore'; // Assuming Firestore
import { getAuth } from 'firebase/auth';
import { db } from '../firebase';

export function useFetchFreeCourses() {
  const [loading, setLoading] = useState(false);
  const [freeCourses, setFreeCourses] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
  
      const userId = getAuth().currentUser?.uid;
      const userDoc = await getDoc(doc(db, 'users', userId));
  
      if (userDoc.exists()) {
        const coursesCollection = collection(db, 'courses');
        const courseQuery = query(coursesCollection, where('private', '==', false), where('free', '==', true));
        const courseSnapshot = await getDocs(courseQuery);
  
        const allFreeCourses = courseSnapshot.docs.map(doc => ({ ...doc.data(), docId: doc.id }));

        // Fetch free courses with additional details
        const fetchedFreeCourses = await Promise.all(
          allFreeCourses.map(async (course) => {
            const lessonCount = course.structure.filter(item => item.lesson).length;
  
            return {
              id: course.id,
              title: course.name?.ru,
              lessons: lessonCount,
            };
          })
        );
        setFreeCourses(fetchedFreeCourses);
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);

  return { freeCourses, loading };
}
