import React, {useContext}  from 'react';
import { Popover, Box, Typography,  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getDocs, getDoc, query, where, collection, doc, updateDoc, arrayUnion, addDoc, setDoc, arrayRemove, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase.js';
import { alpha, styled } from '@mui/material';
import { fCurrency , fNumber, fPercent} from '../../utils/formatNumber.js';
import { Link } from 'react-router-dom';
import UserContext from '../../UserContext.js';
import hard_level_icon from '../../sections/assets/hard-level.svg'
import medium_level_icon from '../../sections/assets/medium-level.svg'
import light_level_icon from '../../sections/assets/light-level.svg'
import proMax_sertification from '../../sections/assets/logo-pro-max.png'


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: alpha(theme.palette.background.default, 0.3),
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 13,
    padding: theme.spacing(1.5, 2.5),
    backdropFilter: 'blur(8px)',
    WebkitBackdropFilter: 'blur(8px)', // Fix on Mobile
    borderRadius: 10,
    borderColor: theme.palette.grey[500_8],

  },
}));

function CoursesListAdmin({ courses }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [courseToDelete, setCourseToDelete] = React.useState(null); // store the course to be deleted

  const account = useContext(UserContext);
  const navigate = useNavigate();
  const handleRowClick = (courseId) => {
      navigate(`/dashboard/catalog/course/${courseId}`);
    };
    const handleEditlick = (courseId) => {
      navigate(`/admin/course/edit/${courseId}`);
    };

    const getCertificationIcon = (certification) => {
      switch(certification) {
        case 'defaust-trader':
            return proMax_sertification;
        default:
            return proMax_sertification; // or a default icon if any
    }
  }
  
  const handleDeleteClick = (event, courseId) => {
    setAnchorEl(event.currentTarget);
    setCourseToDelete(courseId);
  };
  
  // Function to handle Popover close
  const handleDeleteClose = () => {
    setAnchorEl(null);
    setCourseToDelete(null);
  };
  const handleDeleteItem = async () => {
    try {
      const courseQuery = query(collection(db, 'courses'), where('id', '==', courseToDelete));
      const courseSnapshot = await getDocs(courseQuery);
  
      if (!courseSnapshot.empty) {
        const courseDocRef = courseSnapshot.docs[0].ref; // Get the DocumentReference
        await deleteDoc(courseDocRef);
      } else {
        ////console.log("No matching documents");
      }
  
    } catch (err) {
      console.error("Error deleting item: ", err);
      return; // Exit the function here if an error occurs
    }
    handleDeleteClose(); // Only closes the dialog if no error occurs
  };
  

  return (
<TableContainer component={Paper} 
        sx={{ 
            backgroundColor: '#F5F7F9', // semi-transparent background
            border: "1px solid rgba(209, 213, 219, 0.3)", // border
            borderRadius: 5,
            padding: 2,
            mt: 5,
         }}>
      <Table>
        <TableHead>
        <TableRow>
            {['Название', 'Сертификация', 'Длительность', 'Сложность', 'Прогресс', ''].map((header, index) => (
                <TableCell key={index} sx={{
                    background: 'transparent',
                    color: '#000',
                    padding: "15px",
                    textAlign: 'center',
                    fontWeight: 700,
                    borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                }}>
                  {header}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
            {courses?.map((course, index) => (
                <TableRow key={index} 
                sx={{
                  py: 4,
                  "&:hover": {
                    backgroundColor: alpha("#FFF", 0.15),
                  },
                  "&:last-child td, &:last-child th": {
                    borderBottom: 0,
                  },
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)', // Set color of border
                }}>
                <TableCell onClick={() => handleRowClick(course.id)}
                sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  fontWeight: 700,
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
                  py: 4,
                  maxWidth: '300px',
              }}>
                <Box sx={{
                  display: "flex",
                  flexDirection: 'column',
                  gap: 1,
                  textAlign: 'start'}}>
               <Typography sx={{color: '#3271d2', fontWeight: 700, cursor: 'pointer',
                                 "&:hover": {
                                  textDecoration: 'underline',
                                }}}>{course.name.ru}</Typography>
               <Typography sx={{color: '#898989'}}>{course.description.ru}</Typography> 
               </Box>
              </TableCell>

              <TableCell sx={{
                    background: 'transparent',
                    color: '#000',
                    padding: "10px",
                    borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                    textAlign: 'center',
                }}>
                    <Box display="flex" flexDirection='row' justifyContent={'start'} alignContent={'start'} alignItems={'start'} gap={1} marginLeft={5}>
                        {course.associatedCertification.map((certification, index) => {
                            const iconSrc = getCertificationIcon(certification);
                            const tooltipTitle = "Этот курс часть сертификации " + (
                                certification==='basic'? 'DEFAUST TRADER: Basic' :
                                certification==='pro'? 'DEFAUST TRADER: Pro' :
                                certification==='proMax'? 'DEFAUST TRADER: Pro Max' : ''
                            );
                            const linkTo = "/dashboard/catalog/certification/" + certification; // Adjust link path as required

                            return (
                                <HtmlTooltip key={index} title={tooltipTitle}>
                                    <Link to={linkTo}>
                                        <img src={iconSrc} alt={certification} width={25} height={25} />
                                    </Link>
                                </HtmlTooltip>
                            );
                        })}
                    </Box>
                </TableCell>




              <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>
                {(course.duration)} ч.
              </TableCell>

              <TableCell 
                sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
                }}>
                {
                  (() => {
                    switch(course.difficulty.value) {
                      case 3: 
                      return (
                        <Box display="flex" flexDirection='row' justifyContent={'center'} alignContent={'center'} alignItems={'center'} gap={1} >
                          <img src={hard_level_icon} alt="Hard Level" width={20} height={20}/>
                          <Typography sx={{fontSize: 14,}}>{course.difficulty.ru}</Typography>
                        </Box>
                      );
                      
                      case 2: 
                      return (
                        <Box display="flex" flexDirection='row' justifyContent={'center'} alignContent={'center'} alignItems={'center'} gap={1} >
                          <img src={medium_level_icon} alt="Medium Level" width={20} height={20}/>
                          <Typography sx={{fontSize: 14,}}>{course.difficulty.ru}</Typography>
                        </Box>
                      );
                      case 1:
                        return (
                          <Box display="flex" flexDirection='row' justifyContent={'center'} alignContent={'center'} alignItems={'center'} gap={1} >
                            <img src={light_level_icon} alt="Light Level" width={20} height={20}/>
                            <Typography sx={{fontSize: 14,}}>{course.difficulty.ru}</Typography>
                          </Box>
                        );
                      default:
                        return (
                          <Box display="flex" flexDirection='row' justifyContent={'center'} alignContent={'center'} alignItems={'center'} gap={1} >
                            <img src={light_level_icon} alt="Hard Level" width={20} height={20}/>
                            <Typography sx={{fontSize: 14,}}>{course.difficulty.ru}</Typography>
                          </Box>
                        );
                    }
                  })()
                }
              </TableCell>


              <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>{course?.progress || 0}
              </TableCell> 
              <TableCell
              sx={{
                cursor: 'pointer', 
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>

        <Button
            variant="outlined"
            onClick={() => handleEditlick(course.id)}
            sx={{my: 3, textTransform: 'none', color: '#000'}}
        >
            Редактировать
        </Button>

              </TableCell> 
              <TableCell
  sx={{
    cursor: 'pointer', 
    background: 'transparent',
    color: '#000',
    padding: "10px",
    borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
    textAlign: 'center',
  }}>
    <Button 
      sx={{color: '#F52525'}}
      variant="outlined" 
      onClick={(event) => handleDeleteClick(event, course.id)}
    >
      Удалить
    </Button>
    <Popover  sx={{
    '.MuiPaper-root': {
      p: 2,
      backgroundColor: alpha("#fff", 1),
      border: "1px solid rgba(209, 213, 219, 0.3)",
      boxShadow: "0px 2px 1px -1px #ffffff10",
      borderRadius: 5,
    }
  }}
  open={Boolean(anchorEl)}
  anchorReference="anchorPosition"
  onClose={handleDeleteClose}
  anchorPosition={{ top: window.innerHeight / 2, left: window.innerWidth / 2 }}
  anchorOrigin={{
    vertical: 'center',
    horizontal: 'center',
  }}
  transformOrigin={{
    vertical: 'center',
    horizontal: 'center',
  }}
>
<Typography sx={{ p: 2 }}>Вы уверены, что хотите удалить этот курс?</Typography>
      <Box sx={{flex: 1, display: 'flex', justifyContent: 'center', gap: 2}}>
        <Button
          sx={{
            border: "0.5px solid #F52525",
            backgroundColor: alpha("#F52525", 0.125),
            color: '#F52525',
        
            "&:hover": {
              border: "0.5px solid #F52525",
              cursor: 'pointer',
              backgroundColor: alpha("#F52525", 0.225),
            },
          }}
          onClick={handleDeleteItem}
        >
          Удалить
        </Button>
        <Button 
          sx={{      
            border: "0.5px solid #6BEBA4",
            backgroundColor: alpha("#6BEBA4", 0.125),
            color: '#6BEBA4',
            "&:hover": {
              border: "0.5px solid #6BEBA4",
              cursor: 'pointer', 
              backgroundColor: alpha("#6BEBA4", 0.225),
            },}}
          onClick={handleDeleteClose}
        >
          Отмена
        </Button>
      </Box>
    </Popover>
</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


export default CoursesListAdmin;
