import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Container} from '@mui/material';
import UserContext from '../../UserContext';
import { Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFetchFreeCourses } from '../../hooks/useFetchFreeCourses';
import useFetchEvents from '../../hooks/useFetchEvents'
import { db } from '../../firebase';
// sections
import {
        ProgramSection,
        FreeCoursesSection,
        AppCalendar,
      } from '../../sections';
// ----------------------------------------------------------------------

export default function DashboardAppPage() {

  const account = useContext(UserContext);
  const navigate = useNavigate();
  const {loading, freeCourses} = useFetchFreeCourses();
  const { events, loadingEvents, error } = useFetchEvents();

  const renderSkeleton = () => (
    <Container maxWidth="lg">
    <Grid container spacing={1} alignItems="stretch">
    <Grid item xs={6} sm={6} md={4} lg={4} >
    <Skeleton variant="text" width={250} height={70} />
    <Skeleton variant="text" width={300} height={70} />
    <Skeleton variant="text" width={120} height={70} />

  </Grid>


  </Grid>
  </Container>
  );

  if (loading) {
    return (
      renderSkeleton()
    );
    } else {
      return (
        <Container maxWidth="lg">
          <Helmet>
            <title>Обучение от DEFAUST ACADEMY | DEFAUST</title>
          </Helmet>
          
          {loading || loadingEvents ? renderSkeleton() : (
            <>
              <ProgramSection account={account} navigate={navigate} />
              <AppCalendar navigate={navigate} events={events} db={db} account={account}/>
              <FreeCoursesSection courses={freeCourses}/>
                {/* {loadingLeaderboard ? renderSkeleton() : <Leaderboard leaderboardData={leaderboardData} />} */}
            </>
          )}
        </Container>
      );
    };
  }
