import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase'; // path to your firebase config file
import PersonalInfoForm from './PersonalInfoForm';
import { Container, Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(8, 0),
}));

export default function RegistrationFlow() {
  const [user] = useAuthState(auth);
  const [registrationStep, setRegistrationStep] = useState(null); // initialize with null
  const [isLoading, setIsLoading] = useState(true); // introduce loading state
  const navigate = useNavigate();

  const stepComponents = {
    1: PersonalInfoForm,
  };

  useEffect(() => {
    if (user) {
      const fetchRegistrationStep = async () => {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        setRegistrationStep(userDoc.data()?.registrationStep || 1);
        setIsLoading(false); // set loading to false after fetching data
      };
      fetchRegistrationStep();
    } else {
      setIsLoading(false); // if no user, set loading to false
    }
  }, [user]);

  if (isLoading) {
    return ; // or a fancy spinner
  }

  const StepComponent = stepComponents[registrationStep] || (() => navigate("/", { replace: true }));


  return (
    <>
    <Helmet>
    <html lang="ru" /> 
    <title>Регистрация | DEFAUST</title>
    <meta name="description" content="Выбирайте курсы по трейдингу, криптовалютам и инвестициям от DEFAUST ACADEMY!" />
    <script type="application/ld+json">
      {`
        {
          "@context": "http://schema.org",
          "@type": "WebPage",
          "name": "Регистрация",
          "description": "Выбирайте курсы по трейдингу, криптовалютам и инвестициям от DEFAUST ACADEMY!"
        }
      `}
    </script>
</Helmet>
    <Container maxWidth="sm" sx={{
      mt: 2,
      color: '#FFF',
      borderRadius: 10,
      backdropFilter: 'blur(10px)',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      border: '1px solid rgba(255, 255, 255, 0.2)',
    }}>
      <StyledContent>
        <StepComponent userId={user.uid} setRegistrationStep={setRegistrationStep} />
        </StyledContent>
    </Container>
    </>
  );
}
