import React from "react";

const AccountContext = React.createContext();

function AccountProvider({ children }) {
  const [selectedAccount, setSelectedAccount] = React.useState(null);

  return (
    <AccountContext.Provider value={{ selectedAccount, setSelectedAccount }}>
      {children}
    </AccountContext.Provider>
  );
}

export { AccountContext, AccountProvider };