import React, { useState, useEffect } from 'react';
import {db} from '../../../firebase'
import { Box, Typography, Container } from '@mui/material';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { query, where } from 'firebase/firestore';
import VideoPlayer from './VideoPlayer';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import useUser from '../../../hooks/userHook';
import { updateDoc, arrayUnion } from 'firebase/firestore';
import { useNavigate, useLocation } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material';
import { increment } from 'firebase/firestore';
import Skeleton from '@mui/material/Skeleton';

function LessonsSection({ courseId, lessonId }) {
  const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [lessonData, setLessonData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    ////console.log(courseId, lessonId);
    const user = useUser();
    const handleCloseDialog = () => {
      setOpenDialog(false);
  };
  const handleVideoEnd = async () => {
    setOpenDialog(true);
};
  
    const handleNavigate = async () => {
      try {     
        handleCloseDialog();
          const userId = user.uid;  
          ////console.log(userId);
          const userRef = doc(db, "users", userId);
          const userDoc = await getDoc(userRef);
          const userData = userDoc.data();

          const completedLessonsForCourse = userData?.completed?.courses?.[courseId]?.lessons || [];

          if (!completedLessonsForCourse.includes(lessonId)) {
              // If the lesson is not completed, increment the points and add the lessonId to the completed lessons
              await updateDoc(userRef, {
                  points: increment(5),
                  [`completed.courses.${courseId}.lessons`]: arrayUnion(lessonId)
              });
          } else {
              ////console.log("Lesson already completed, no points added.");
          }




          ////console.log('completed', lessonId );
          // TODO: Add navigation to the next item (as per previous message)
          fetchCourseAndNavigate(courseId, lessonId, navigate);
      } catch (error) {
          console.error("Failed to update completed lessons:", error);
      }
    };
    function navigateToNextItem(course, currentItemId, currentType, navigateFunction) {
      // Find the index of the current item
      ////console.log('currentItemId:', currentItemId, 'currentType:', currentType);
      const currentIndex = course.structure.findIndex((item) => {
        return item.hasOwnProperty(currentType) && item[currentType] === currentItemId;
    });
    
      ////console.log(currentIndex, course.structure.length);
      // If the current item is not found or it's the last item, do nothing or handle it differently
      if (currentIndex === -1 || currentIndex === course.structure.length - 1) {
          ////console.log("Item not found or it's the last item");
          return;
      }
      
      // Get the next item's type and id
      const nextItem = course.structure[currentIndex + 1];
      const nextItemType = Object.keys(nextItem)[0];
      const nextItemId = nextItem[nextItemType];
  
      // Construct the path for the next item
      const itemPath = `/course/take/${course.id}/${nextItemType}/${nextItemId}`;
  
      // Navigate to the next item
      navigateFunction(itemPath);
  }
    async function fetchCourseAndNavigate(courseId, currentItemId, navigateFunction) {
      try {
          // Fetch the course document based on the provided courseId
          const courseCollection = collection(db, 'courses');
          const courseSnapshot = await getDocs(query(courseCollection, where('id', '==', courseId)));
  
          // If no course matches the provided courseId, handle the error
          if (courseSnapshot.empty) {
              console.error("No matching course found");
              return;
          }
  
          // Extract the course data from the first (and likely only) matching document
          const courseData = courseSnapshot.docs[0].data();
  
          // Navigate to the next item using the previously provided function
          navigateToNextItem(courseData, currentItemId,'lesson',  navigateFunction);
  
      } catch (error) {
          console.error("Error fetching course:", error);
      }
  }

  
    useEffect(() => {
        async function fetchLesson() {
            try {
                // Fetch course by its ID
                const coursesCollection = collection(db, 'courses');
                const courseSnapshot = await getDocs(coursesCollection);
                let specificCourseDoc;

                courseSnapshot.forEach(docSnapshot => {
                    if (docSnapshot.data().id === courseId) {
                        specificCourseDoc = docSnapshot;
                    }
                });

                if (!specificCourseDoc) {
                    throw new Error('Course not found');
                }

                // Fetch lesson by its ID within the specific course
                const lessonsCollection = collection(specificCourseDoc.ref, 'lessons');
                const lessonSnapshot = await getDocs(lessonsCollection);
                let specificLessonData;

                lessonSnapshot.forEach(docSnapshot => {
                    if (docSnapshot.data().id === lessonId) {
                        specificLessonData = docSnapshot.data();
                    }
                });

                if (!specificLessonData) {
                    throw new Error('Lesson not found');
                }

                setLessonData(specificLessonData);
            } catch (e) {
                setError(e.message);
            } finally {
                setLoading(false);
            }
        }

        fetchLesson();
    }, [courseId, lessonId]);

    if (loading) {
      return (
              <Container maxWidth="lg">
              <Skeleton variant="text" width={200} height={70} />
              <Skeleton variant="text" width={100} height={70} />
              <Skeleton variant="rectangular" width="100%" height={500} sx={{
                                  border: "1px solid rgba(209, 213, 219, 0.3)", // border
                                  borderRadius: 5,
                                  padding: 2,
                                  mt: 2,
              }}/>
              </Container>
      );
      }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
      <>
      <Typography variant="h3">{lessonData.name.ru}</Typography>
      <Typography variant="p" sx={{ mt: 1, color: 'grey', fontStyle: 'italic'}}>
        {lessonData.shortDescription.ru}
        </Typography>
      <Box display="flex" flexDirection='row' justifyContent={'start'} alignContent={'start'} alignItems={'start'} gap={1} sx={{pt: 1, pb: 3}} >
        <OndemandVideoIcon />
        <Typography variant="p">{lessonData.duration} минут</Typography>
      </Box>

      <VideoPlayer url={lessonData.url.ru} onEnded={handleVideoEnd} />
      <Dialog open={openDialog} onClose={handleCloseDialog} BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' } }}  sx={{
            '.MuiPaper-root': {
              width: '50%',
              p: 2,
              backgroundColor: alpha("#121211", 0.1),
              backdropFilter: "blur(15px)",
              border: "0.5px solid rgba(209, 213, 219, 0.3)",
              boxShadow: "0px 2px 1px -1px #ffffff10",
              borderRadius: 5,
              color: '#fff',

            }
      }}>
            <DialogTitle>Конец урока</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{color: '#fff',}}>
                    Перейти к следующему уроку?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Box sx={{flex: 1, display: 'flex', justifyContent: 'center', gap: 2}}>
            <Button fullWidth sx={{      

      border: "0.5px solid #F52525",
      backgroundColor: alpha("#F52525", 0.125),
      color: '#F52525',

      "&:hover": {
        border: "0.5px solid #F52525",
        cursor: 'pointer', 
        backgroundColor: alpha("#F52525", 0.225),
      },}} onClick={handleCloseDialog}>Закрыть</Button>
  <Button fullWidth sx={{      
      border: "0.5px solid #6BEBA4",
      backgroundColor: alpha("#6BEBA4", 0.125),
      color: '#6BEBA4',

      "&:hover": {
        border: "0.5px solid #6BEBA4",
        cursor: 'pointer', 
        backgroundColor: alpha("#6BEBA4", 0.225),
      },}}
  onClick={handleNavigate}>Перейти</Button>
  </Box>
            </DialogActions>
        </Dialog>

      </>
    );
}

export default LessonsSection;
