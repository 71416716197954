import React, {useState} from 'react';
import { Container, Box, Card, CardMedia, Skeleton, Dialog } from '@mui/material';
import { Masonry } from '@mui/lab';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
const localUrls = Array.from({ length: 34 }, (_, i) => `/assets/images/reviews/review-${i + 1}.png`); // Assuming images are named as review-1.png, review-2.png, etc., and located in public/images

const ReviewsSection = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClickOpen = (url) => {
    setSelectedImage(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Container maxWidth="xl" sx={{mt: 3}}>
      <Box sx={{ marginBottom: 4 }}>
      <Masonry columns={isSmallScreen ? 1 : 3} spacing={isSmallScreen ? 2 : 3}>
          {localUrls.length === 0 ? (
            Array.from(new Array(9)).map((_, index) => (
              <Card key={index}>
                <Skeleton variant="rectangular" height={200} />
              </Card>
            ))
          ) : (
            localUrls.map((url, index) => (
              <Card key={index} onClick={() => handleClickOpen(url)}>
                <CardMedia
                  component="img"
                  alt={`review-${index}`}
                  image={url}
                />
              </Card>
            ))
          )}
        </Masonry>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <img src={selectedImage} alt="Zoomed" style={{ width: '100%' }} />
      </Dialog>
    </Container>
  );
};

export default ReviewsSection;
