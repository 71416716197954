import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import LandingHeader from './LandingHeader';
import { Box } from '@mui/material';
import background_image from '../../sections/assets/back-main.png';
import { AnimatedText } from '../../sections/landing';
import { useTheme, useMediaQuery, Container} from "@mui/material";
// ----------------------------------------------------------------------



const HEADER_MOBILE = 20;
const HEADER_DESKTOP = 15;


const StyledRoot = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
    width: '100%',
  });
  

  const Main = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingBottom: theme.spacing(10),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: HEADER_DESKTOP,
    [theme.breakpoints.down('sm')]: {
      paddingTop: HEADER_MOBILE,  // Updated paddingTop value
    },
  }));

// ----------------------------------------------------------------------

export default function LandingLayout() {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const defaultColor = '#FFFFFF'; // The color you want as default
  const homepageColor = '#2E2E2E'; // The color you want for the Homepage
  useEffect(() => {
    // Set the background color when the component mounts
    document.body.style.backgroundColor = homepageColor;
  
    // Reset the background color when the component unmounts
    return () => {
      document.body.style.backgroundColor = defaultColor;
    };
  }, []);
  const textStyle = {
    textTransform: 'none',
    fontSize: isMobile ? '2rem' : '4.4rem',
    color: '#202832',
    fontWeight: 'medium',
    lineHeight: isMobile ? '2.7rem' : '5.8rem',
  };
  return (
    <StyledRoot>

            <LandingHeader isUserAuthenticated={false} openNav={open} onCloseNav={() => setOpen(false)} onOpenNav={() => setOpen(true)}/>

      <Main>
        {!isMobile && (
      <Box sx={{
        fontWeight: 'semi-bold',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 5,
        background: `url(${background_image})`,
        backgroundSize: 'cover',
        height: '60vh'
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexGrow: 1,
          alignItems: 'center',
          flexWrap: 'nowrap',
          ml: '20%',
        }}>
          <div style={textStyle}>
            <span style={{ fontWeight: '600' }}>Научись</span> <span style={{ fontWeight: '200' }}>зарабатывать</span><br />
            <span style={{ fontWeight: '200' }}>с помощью</span> <span style={{ fontWeight: '600' }}>стратегии</span><br />
            <Box sx={{
        display: 'flex',
        alignItems: 'start',
        flexWrap: 'wrap',
      }}>
        <span style={{ fontWeight: '600', flexShrink: 0 }}>Liquidity Alpha</span>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          maxWidth: "35%",
          ml: '2%',
          flexWrap: 'wrap',
          whiteSpace: 'pre-wrap',
          overflowWrap: 'break-word'
        }}>
          <span style={{ fontWeight: '400', fontSize: '1rem', flex: '1', lineHeight: '1.2rem'}}>
          Liquidity Alpha – торговая стратегия с использованием Volume Profile и Cumulative Delta и определения провокаций от смарт-мани для торговли вместе с крупными участниками рынка.
          </span>
        </Box>
      </Box>
      
          </div>
        </Box>
      </Box>
        )}
{isMobile && (
        <Box sx={{
          fontWeight: 'semi-bold',
          display: 'flex',
          flexDirection: 'row',
          borderRadius: 5,
          background: `url(${background_image})`,
          backgroundSize: 'cover',
          height: '50vh'
        }}>
          <Box sx={{
            display: 'flex',
            flexGrow: 1,
            flexWrap: 'nowrap',
            p: 5,
            mt: 8
          }}>
            <div style={textStyle}>
              <span style={{ fontWeight: '600' }}>Научись</span> <span style={{ fontWeight: '200' }}>зарабатывать</span><br />
              <span style={{ fontWeight: '200' }}>с помощью</span> <span style={{ fontWeight: '600' }}>стратегии</span><br />
              <Box sx={{
          display: 'flex',
          alignItems: 'start',
          flexWrap: 'wrap',
        }}>
          <span style={{ fontWeight: '600', flexShrink: 0 }}>Liquidity Alpha</span>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: "100%",
            mt: 2,
            flexWrap: 'wrap',
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word'
          }}>
            <span style={{ fontWeight: '400', fontSize: '1rem', flex: '1', lineHeight: '1.2rem'}}>
            Liquidity Alpha – торговая стратегия с использованием Volume Profile и Cumulative Delta и определения провокаций от смарт-мани для торговли вместе с крупными участниками рынка.
            </span>
          </Box>
        </Box>
        
            </div>
          </Box>
        </Box>
)}


      <Outlet />
      </Main>
      
    </StyledRoot>
    
  );
}
