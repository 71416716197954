import React, { useEffect, useState } from 'react';
import { Grid, Container, Typography, FormControl, InputLabel, Select, MenuItem , Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { alpha } from '@mui/material/styles';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { db } from '../../firebase';
import { getAuth } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';
import { roundValue } from '../../utils/formatNumber';
import { collection, getDocs, query, where } from "firebase/firestore";
import { Helmet } from 'react-helmet-async';
//sections
import {CoursesCatalogSection} from '../../sections/@dashboard/app';

export default function CatalogCryptoCourses() {
  const mockCourses = [
    {
      id: '14',
      title: 'DeFi: Применение смарт-стратегий',
      description: 'Изучение DEX, money-market, фарминга, стейкинга и LP провайдинга. Изучение, разработка и применение смарт-стратегий с пассивной доходностью до 700% годовых.',
      progress: 0,
      lessons: 35,
      quizes: 20,
      completed: 0,
      purchased: false,
      enrolled: 0,
      specializations: ['crypto'],
      soon: true
    },

      ]
        const [loading, setLoading] = useState(true);
        const [fetchedCourses, setFetchedCourses] = useState([]);
        const fetchCourses = async () => {
          setLoading(true);
        
          // Fetch user data
          const userId = getAuth().currentUser?.uid;
          const userDoc = await getDoc(doc(db, 'users', userId));
        
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const purchasedCourses = userData.purchased || [];
        
            // Fetch all course data
            const coursesCollection = collection(db, "courses");
            const courseQuery = query(
              coursesCollection, 
              where('private', '==', false),
              where('specializations', 'array-contains', 'crypto')
            );
            const courseSnapshot = await getDocs(courseQuery);
            const allCourses = courseSnapshot.docs.map(doc => {
              const data = doc.data();
              return {
                ...data,
                docId: doc.id,
              };
            });
        
            // Asynchronously fetch course reviews and update course data
            const fetchCourseReviews = async (courseDocId) => {
              const reviewsCollection = collection(db, "courses", courseDocId, "reviews");
              const reviewsQuery = query(reviewsCollection, where('verified', '==', true));
              const reviewSnapshot = await getDocs(reviewsQuery);
              const allReviews = reviewSnapshot.docs.map(doc => doc.data());
              const averageRating = allReviews.reduce((acc, review) => acc + review.rating, 0) / allReviews.length;
              return isNaN(averageRating) ? undefined : averageRating;
            };
        
            const coursesWithRatings = await Promise.all(allCourses.map(async (course) => {
              const lessonCount = course.structure.filter(item => item.lesson).length;
              const quizCount = course.structure.filter(item => item.quiz).length;
              const isPurchased = purchasedCourses.includes(course.id);
              const completedLessons = (userData.completed?.courses?.[course.id]?.lessons || []).length;
              const completedQuizzes = (userData.completed?.courses?.[course.id]?.quiz || []).length;
              const completed = completedLessons + completedQuizzes;
              const averageRating = await fetchCourseReviews(course.docId);
        
              return {
                id: course.id,
                title: course.name?.ru,
                description: course.description?.ru,
                progress: roundValue((completed / (lessonCount + quizCount)) * 100),
                lessons: lessonCount,
                quizes: quizCount,
                completed: completed,
                purchased: isPurchased,
                enrolled: course.enrolled ?? 0,
                rating: averageRating,
                specializations: course.specializations ?? [],
                soon: false,
              };
            }));
        
            const sortedCourses = [...coursesWithRatings, ...mockCourses].sort((a, b) => {
              return b.enrolled - a.enrolled;
            });
            setFetchedCourses(sortedCourses);
            setLoading(false);
          }
        };
        
            useEffect(() => {
                const fetchData = async () => {
                    setLoading(true);
                    await fetchCourses();
                    setLoading(false);
                };
        
                fetchData();
            }, []);
            

            const renderSkeleton = () => (
              <Container maxWidth="lg">
              <Grid container spacing={1} alignItems="stretch">
              <Grid item xs={6} sm={6} md={4} lg={4} >
            <Skeleton variant="rectangular" width="100%" height={250} sx={{
                                border: "1px solid rgba(209, 213, 219, 0.3)", // border
                                borderRadius: 5,
                                padding: 2,
                                mt: 2,
            }}/>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} >
            <Skeleton variant="rectangular" width="100%" height={250} sx={{
                                border: "1px solid rgba(209, 213, 219, 0.3)", // border
                                borderRadius: 5,
                                padding: 2,
                                mt: 2,
            }}/>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} >
            <Skeleton variant="rectangular" width="100%" height={250} sx={{
                                border: "1px solid rgba(209, 213, 219, 0.3)", // border
                                borderRadius: 5,
                                padding: 2,
                                mt: 2,
            }}/>
            </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4} >
            <Skeleton variant="rectangular" width="100%" height={250} sx={{
                                border: "1px solid rgba(209, 213, 219, 0.3)", // border
                                borderRadius: 5,
                                padding: 2,
                                mt: 2,
            }}/>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} >
            <Skeleton variant="rectangular" width="100%" height={250} sx={{
                                border: "1px solid rgba(209, 213, 219, 0.3)", // border
                                borderRadius: 5,
                                padding: 2,
                                mt: 2,
            }}/>
            </Grid>

            </Grid>
            </Container>
            );
          

  return (
    <>
    <Helmet>
        <html lang="ru" /> 
        <title>Курсы | DEFAUST</title>
        <meta name="description" content="Выбирайте курсы по трейдингу, криптовалютам и инвестициям от DEFAUST ACADEMY!" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "Курсы",
              "description": "Выбирайте курсы по трейдингу, криптовалютам и инвестициям от DEFAUST ACADEMY!"
            }
          `}
        </script>
    </Helmet>
    <Container maxWidth="lg">
    <CoursesCatalogSection courses={fetchedCourses} loading={loading}/>

</Container>
    </>
  );
}