// component
import SvgColor from '../../../components/svg-color/SvgColor';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Курсы',
    path: '/admin/courses',
    icon: icon('users'),
  },
  {
    title: 'Отзывы',
    path: '/admin/reviews',
    icon: icon('users'),
  },
  {
    title: 'Ученики',
    path: '/admin/user-mamangement',
    icon: icon('users'),
  },
  {
    title: 'Конференции',
    path: '/admin/meetings',
    icon: icon('users'),
  },
];

export default navConfig;
