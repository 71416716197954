import { useState, useEffect } from 'react';
import { collection, query, getDocs, where, getDoc, doc } from 'firebase/firestore'; // Assuming Firestore
import { db } from '../firebase';
import { mockCourses } from '../components/mock/courses-mock';
import { getAuth } from 'firebase/auth';
export const useFetchCertification = (certificationId) => {
  const [loading, setLoading] = useState(true);
  const [certification, setCertification] = useState(null);
  const [courses, setCourses] = useState([]);

  // Function to fetch course reviews and calculate average rating
  const fetchCourseReviews = async (courseDocId) => {
    if (!courseDocId) return { averageRating: undefined, reviews: [] };
    const reviewsCollection = collection(db, 'courses', courseDocId, 'reviews');
    const reviewsQuery = query(reviewsCollection, where('verified', '==', true));
    const reviewSnapshot = await getDocs(reviewsQuery);
    const allReviews = reviewSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    const averageRating = allReviews.reduce((acc, review) => acc + review.rating, 0) / allReviews.length;
    return { 
      averageRating: isNaN(averageRating) ? undefined : averageRating, 
      reviews: allReviews 
    };
  };


  const calculateProgress = (course, userData) => {
    if (!userData || !course.docId) return 0;
    const completedLessons = (userData.completed?.courses?.[course.id]?.lessons || []).length;
    const completedQuizzes = (userData.completed?.courses?.[course.id]?.quiz || []).length;
    const completed = completedLessons + completedQuizzes;
    const lessonCount = course.structure.filter(item => item.lesson).length;
    const quizCount = course.structure.filter(item => item.quiz).length;
    return Math.round((completed / (lessonCount + quizCount)) * 100);
  };

  const calculateCompletion = (course, userData) => {
    if (!userData || !course.docId) return 0;
    const completedLessons = (userData.completed?.courses?.[course.id]?.lessons || []).length;
    const completedQuizzes = (userData.completed?.courses?.[course.id]?.quiz || []).length;
    return completedLessons + completedQuizzes;
  };

  
  useEffect(() => {
    
    const fetchCertification = async () => {
      try {
        const certificationCollection = collection(db, "certifications");
        const q = query(certificationCollection, where("id", "==", certificationId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const certificationDoc = querySnapshot.docs[0];
          const coursesCollection = collection(db, "courses");
          const coursesQuery = query(coursesCollection, where("id", "in", certificationDoc.data().courses));
          const courseSnapshot = await getDocs(coursesQuery);

          const courseList = courseSnapshot.docs.map(doc => ({
            ...doc.data(),
            docId: doc.id
          }));

          const courseListWithMock = ([...courseList]);
          const userId = getAuth().currentUser?.uid;
          const userDoc = await getDoc(doc(db, 'users', userId));
          const userData = userDoc.data();
          let aggregatedReviews = [];
          const courseListWithRatings = await Promise.all(courseListWithMock.map(async (course) => {
            const { averageRating, reviews } = await fetchCourseReviews(course.docId);
            aggregatedReviews = aggregatedReviews.concat(reviews);
    
            return {
              ...course,
              rating: averageRating,
              completed: calculateCompletion(course, userData),
              progress: calculateProgress(course, userData)
            };
          }));

          
      // Sorting reviews from recent to oldest
      aggregatedReviews.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

      setCertification(certificationDoc.data());
      setCourses(courseListWithRatings);
      setReviews(aggregatedReviews);
        } else {
          console.error("Certification not found!");
        }
      } catch (error) {
        console.error("Error fetching certification and courses:", error);
      } finally {
        setLoading(false);
      }
    };

  fetchCertification();
}, [certificationId]);

// Add a new state for reviews
const [reviews, setReviews] = useState([]);

return { loading, certification, courses, reviews };
};