import React, { useState, useEffect } from 'react';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, IconButton, TextField, Dialog, List, ListItem, Checkbox, Button, TablePagination } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import styled from '@emotion/styled';
import { alpha } from '@mui/material';
import { Select, MenuItem } from '@mui/material';

function AdminCRM() {
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({
      nickname: '',
      role: '',
      membership: '',
      discord: '',
  });
  const [courses, setCourses] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [editRole, setEditRole] = useState('');
  const [editMembership, setEditMembership] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
      setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
  };
  const filteredUsers = users.filter(user => {
    return (
        (user.nickname ? user.nickname.includes(filters.nickname) : !filters.nickname) &&
        (user.role ? user.role.includes(filters.role) : !filters.role) &&
        (user.membershipStatus ? user.membershipStatus.toLowerCase() === filters.membership.toLowerCase() : !filters.membership) && // Case-insensitive comparison
        (user.discord_username ? user.discord_username.includes(filters.discord) : !filters.discord)
    );
});

  const paginatedUsers = filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const handleEdit = (user) => {
    setEditingUser(user);
    setEditRole(user.role);
    setEditMembership(user.membership);
};

const handleCancelEdit = () => {
    setEditingUser(null);
};

const handleSaveEdit = async () => {
    const userRef = doc(db, 'users', editingUser.id);
    await updateDoc(userRef, { role: editRole, membership: editMembership });
    setUsers(users.map(u => u.id === editingUser.id ? { ...u, role: editRole, membership: editMembership } : u));
    setEditingUser(null);
};

useEffect(() => {
  const fetchData = async () => {
    const usersSnapshot = await getDocs(collection(db, 'users'));
    let usersData = await Promise.all(usersSnapshot.docs.map(async (userDoc) => {
        const user = { id: userDoc.id, ...userDoc.data() };

        const subscriptionsSnapshot = await getDocs(collection(db, `customers/${user.id}/subscriptions`));
        const subscriptions = subscriptionsSnapshot.docs.map(subDoc => subDoc.data());

        // Filter for DEFAUST MEMBERSHIP subscriptions and find the one with the latest end period
        const defaustMemberships = subscriptions.filter(sub => 
            sub.items[0]?.price?.product?.name === "DEFAUST MEMBERSHIP"
        ).sort((a, b) => b.current_period_end.seconds - a.current_period_end.seconds);

        const latestDefaustMembership = defaustMemberships[0]; // Latest membership

        if (latestDefaustMembership && latestDefaustMembership.current_period_end?.seconds) {
            user.membershipStatus = latestDefaustMembership.status;
            user.membershipEnd = new Date(latestDefaustMembership.current_period_end.seconds * 1000);
        }

        return user;
    }));


    // Debug: print usersData before sorting
    //console.log('Users data before sorting:', usersData);

    usersData = usersData.sort((a, b) => {
        const dateA = a.membershipEnd || new Date(0);
        const dateB = b.membershipEnd || new Date(0);
        return dateB - dateA;
    });

    setUsers(usersData);
};


  fetchData();
  fetchCourses();
}, []);


const renderMembership = (user) => {
  return (
      <TableCell>
          {user.membership}
          {user.membershipStatus && (
              <>
                  <span> Stripe: {user.membershipStatus}</span>
              </>
          )}
      </TableCell>
  );
};

const renderMembershipEnds = (user) => {
  const formatDate = (date) => {
      return date ? date.toLocaleDateString() : 'N/A';
  };

  const calculateDaysRemaining = (endDate) => {
      if (!endDate) return 'N/A';
      const today = new Date();
      const difference = endDate - today;
      const daysRemaining = Math.ceil(difference / (1000 * 3600 * 24));
      return daysRemaining >= 0 ? `${daysRemaining} days` : 'Expired';
  };

  return (
      <TableCell>
          {user.membershipStatus && (
              <>
                  <span>{formatDate(user.membershipEnd)}</span>
                  <br></br>
                  <span>({calculateDaysRemaining(user.membershipEnd)})</span>
              </>
          )}
      </TableCell>
  );
};


const handleFilterChange = (event) => {
  const { name, value } = event.target;
  setFilters(prevFilters => ({ ...prevFilters, [name]: value.trim() })); // Trim the value to remove extra spaces
};


  
  const fetchCourses = async () => {
    const coursesCollection = collection(db, 'courses');
    const courseSnapshot = await getDocs(coursesCollection);
    const courseList = courseSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCourses(courseList);
};


const handleCourseDialogOpen = (user) => {
  setSelectedUser(user);
  setOpenDialog(true);
};


const handleCourseUpdate = async (courseId, isChecked) => {
    const userRef = doc(db, 'users', selectedUser.id);
    let updatedCourses = [...selectedUser.purchased];

    if (isChecked) {
        updatedCourses = [...updatedCourses, courseId];
    } else {
        updatedCourses = updatedCourses.filter(id => id !== courseId);
    }

    await updateDoc(userRef, { purchased: updatedCourses });
    setSelectedUser({ ...selectedUser, purchased: updatedCourses });
    setUsers(users.map(u => u.id === selectedUser.id ? { ...u, purchased: updatedCourses } : u));
};




    const HtmlTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      '& .MuiTooltip-tooltip': {
        backgroundColor: alpha(theme.palette.background.default, 0.3),
        color: theme.palette.text.primary,
        boxShadow: theme.shadows[1],
        fontSize: 13,
        padding: theme.spacing(1.5, 2.5),
        backdropFilter: 'blur(8px)',
        WebkitBackdropFilter: 'blur(8px)', // Fix on Mobile
        borderRadius: 10,
        borderColor: theme.palette.grey[500_8],
    
      },
    }));

    const renderCourseDialog = () => {
      if (!selectedUser) return null; // Ensure selectedUser is set
  
      return (
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
              <List>
                  {courses.length > 0 ? (
                      courses.map(course => (
                          <ListItem key={course.id}>
                              <Checkbox
                                  checked={selectedUser.purchased.includes(course.id)}
                                  onChange={(e) => handleCourseUpdate(course.id, e.target.checked)}
                              />
                              {course.name.ru} {/* Access the English name */}
                          </ListItem>
                      ))
                  ) : (
                      <ListItem>No courses available</ListItem>
                  )}
              </List>
              <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </Dialog>
      );  
  };
  

    return (
      <>
          <TextField
              label="Filter by Nickname"
              name="nickname"
              value={filters.nickname}
              onChange={handleFilterChange}
          />
          <TextField
              label="Filter by Memebership Status"
              name="membership"
              value={filters.membership}
              onChange={handleFilterChange}
          />
          {/* Repeat for other filters */}
<TableContainer component={Paper} 
        sx={{ 
            backgroundColor: '#F5F7F9', // semi-transparent background
            border: "1px solid rgba(209, 213, 219, 0.3)", // border
            borderRadius: 5,
            padding: 2,
            mt: 5,
         }}>
            <Table>
                <TableHead>
                    <TableRow>
                    <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>Nickname</TableCell>
                        <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>Courses</TableCell>
                        <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>Role</TableCell>
                        <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>Membership</TableCell>
                        <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>Membership Ends</TableCell>
                        <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>Discord</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {paginatedUsers.map((user) => (
                        <TableRow key={user.id}>
                            <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>
                                {user.nickname}
                                <HtmlTooltip title={user.id}>
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </HtmlTooltip>
                            </TableCell>
                            <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>{user.purchased ? user.purchased.join(', ') : 'No courses'}
                                    <IconButton onClick={() => handleCourseDialogOpen(user)}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>{editingUser && editingUser.id === user.id ? (
                <Select value={editRole} onChange={(e) => setEditRole(e.target.value)}>
                    <MenuItem value="default">Default</MenuItem>
                    <MenuItem value="basic">Basic</MenuItem>
                    <MenuItem value="pro">Pro</MenuItem>
                    <MenuItem value="proMax">ProMax</MenuItem>
                </Select>
            ) : (
                user.role
            )}</TableCell>
                            <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>{editingUser && editingUser.id === user.id ? (
                <Select value={editMembership} onChange={(e) => setEditMembership(e.target.value)}>
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                </Select>
            ) : (<>
              {renderMembership(user)}
              </>
            )}</TableCell>
                            <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}> {user.membershipStatus && (
                <>{renderMembershipEnds(user)}
                </>
              )}
              </TableCell>
                            <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>{user.discord_username}</TableCell>
              <TableCell>
                                {editingUser && editingUser.id === user.id ? (
                                    <>
                                        <Button onClick={handleSaveEdit}>Save</Button>
                                        <Button onClick={handleCancelEdit}>Cancel</Button>
                                    </>
                                ) : (
                                    <Button onClick={() => handleEdit(user)}>Edit</Button>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
                component="div"
                count={filteredUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        {renderCourseDialog()}
        </>
    );
}

export default AdminCRM;
