import React, { useState, useContext, useEffect } from "react";
import UserContext from "../../UserContext";
import useUser from "../../hooks/userHook";
import {
  Container,
  Typography,
  TextField,
  Step,
  Stepper,
  StepLabel,
  Alert,
  Box,
  alpha,
  Button,
  Rating,
  CircularProgress
} from "@mui/material";
import { v4 as uuidv4 } from 'uuid';
import LinearProgress from '@mui/material/LinearProgress';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { storage, db } from "../../firebase";
import { ref, uploadBytes, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import fontkit from '@pdf-lib/fontkit'
import { LoadingButton } from "@mui/lab";
import { useParams } from 'react-router-dom';
import { getDocs, collection, doc, getDoc, updateDoc, addDoc } from 'firebase/firestore';

import {Congratulations} from '../../sections/';

export default function GetCertificatePage() {
  const [showCongrats, setShowCongrats] = useState(false);
  const [userName, setName] = useState("");
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(4.9);
  const isReviewValid = review.length > 50;
  const user = useUser();
  const { courseId } = useParams();
  const account = useContext(UserContext);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  
  const [downloadLink, setDownloadLink] = useState(null);
  const steps = ["Write Review"];

  const getAdjustedFontSize = (font, text, maxWidth) => {
    let fontSize = 250; // Start with your default font size
    let textWidth = font.widthOfTextAtSize(text, fontSize);
  
    while (textWidth > maxWidth) {
      fontSize -= 5; // Reduce the font size
      textWidth = font.widthOfTextAtSize(text, fontSize);
    }
  
    return fontSize;
  };

  
  useEffect(() => {
    const fetchData = async () => {
      if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      // Check if the document exists and if it contains the 'certificates' field
      if (userDocSnapshot.exists() && userDocSnapshot.data().hasOwnProperty('certificates')) {
        const certificates = userDocSnapshot.data().certificates;

        // Check if there's a certificate for this specific course
        if (certificates.hasOwnProperty(courseId)) {
          setDownloadLink(certificates[courseId]);
        }
      }};
    };

    fetchData();
  }, [courseId, user]);

  const getNestedData = (obj, path) => {
    return path.split('.').reduce((prev, curr) => {
      return prev ? prev[curr] : null;
    }, obj);
  };

  
  const handleNext = async () => {
    if (activeStep !== steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    }
    
   
  
    try {
      setShowCongrats(false);
      setLoading(true);
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (!userDoc.exists()) {
        setMessage('User does not exist.');
        throw new Error('User does not exist.');
      }
  
      const userData = userDoc.data();
      const examDataPath = `completed.courses.${courseId}.exam`;
      const exams = getNestedData(userData, examDataPath);

  
      if (!Array.isArray(exams) || !exams.includes('final')) {
        setMessage('Вы еще не сдали экзамен для получения сертификата о прохождении курса.');
        throw new Error('Вы еще не сдали экзамен для получения сертификата о прохождении курса.');
      }
  
      const coursesSnapshot = await getDocs(collection(db, 'courses'));
      const specificCourseRef = coursesSnapshot.docs.find((courseDoc) => courseDoc.data().id === courseId)?.ref;
  
      if (!specificCourseRef) {
        setMessage('Курса не найден');
        throw new Error('Course does not exist.');
      }
  
      const courseDocFinal = await getDoc(specificCourseRef);
      const courseName = String(courseDocFinal.data().name.ru);
              // Your existing logic for generating certificate
              const generateUniqueID = () => {
                const fullUUID = uuidv4();
                return fullUUID.slice(0, 8).toUpperCase();
              };
              // Fetch the image and its dimensions
              const imageUrl = 'https://firebasestorage.googleapis.com/v0/b/defaust-2023.appspot.com/o/certificate-mock.png?alt=media&token=f74e555d-d558-4632-8896-cb414b7c8c07';
          
              // Fetch the font as a buffer (assuming it's located in the public directory)
              const fontResponse = await fetch('/assets/fonts/Bebas-Neue-Cyrillic.otf');
              if (!fontResponse.ok) {
                  setMessage(`Font loading failed with status: ${fontResponse.status}`);
              }
              const fontBytes = await fontResponse.arrayBuffer();
              const fontResponseBody = await fetch('/assets/fonts/PTSans-Regular.otf');
              if (!fontResponseBody.ok) {
                  setMessage(`Font loading failed with status: ${fontResponse.status}`);
              }
              const fontBytesBody = await fontResponseBody.arrayBuffer();
          
              const imageBytes = await fetch(imageUrl).then(res => res.arrayBuffer());
              const imageDimensions = await new Promise((resolve) => {
              const img = new Image();
              img.src = URL.createObjectURL(new Blob([imageBytes]));
              img.onload = () => resolve({ width: img.width, height: img.height });
              });
              const pdfDoc = await PDFDocument.create();
              pdfDoc.registerFontkit(fontkit);
              const page = pdfDoc.addPage([imageDimensions.width, imageDimensions.height]);
              const image = await pdfDoc.embedPng(imageBytes);
              page.drawImage(image, { x: 0, y: 0, width: imageDimensions.width, height: imageDimensions.height });
          
              // Embed a font and draw text on top of the image
              const customFont = await pdfDoc.embedFont(fontBytes);
              const customFontBody = await pdfDoc.embedFont(fontBytesBody);
          
              const fontSizeName = 250; // for example
              // Calculate center y-coordinate for the text
              const yCenterName = (imageDimensions.height / 2) + 110;
              // Calculate text width and x-coordinate to start drawing so that text is centered
              const name = `${account?.name}`;
              const nameWidth = customFont.widthOfTextAtSize(name, fontSizeName);
              const xCenterName = (imageDimensions.width - nameWidth) / 2;
          
          
              // Your existing logic for course name width and x-coordinate
              const maxWidthCourse = imageDimensions.width - 200; // Define maximum allowed width, you can adjust this value
              const fontSizeCourse = getAdjustedFontSize(customFont, courseName, maxWidthCourse);
              const courseWidth = customFont.widthOfTextAtSize(courseName, fontSizeCourse);
              const xCenterCourse = (imageDimensions.width - courseWidth) / 2;
              
              // Calculate center y-coordinate for the text
              const yCenterCourse = (imageDimensions.height / 2) - 380;
          
              const fontSizeUniqueNumber = 70; // for example
              // Calculate center y-coordinate for the text
              const yCenterUniqueNumber = (imageDimensions.height / 2) - 990;
              // Calculate text width and x-coordinate to start drawing so that text is centered
              const uniqueNumber = `${generateUniqueID()}`;
              const uniqueNumberWidth = customFontBody.widthOfTextAtSize(uniqueNumber, fontSizeUniqueNumber);
              const xCenterUniqueNumber = ((imageDimensions.width - uniqueNumberWidth) / 2) - 10;
          
          
              const fontSizeDate = 70; // for example
              // Calculate center y-coordinate for the text
              const yCenterDate = (imageDimensions.height / 2) - 990;
              // Calculate text width and x-coordinate to start drawing so that text is centered
              // Get the current date and format it as (MM.DD.YYYY)
              const currentDate = new Date();
              const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}.${(currentDate.getMonth() + 1).toString().padStart(2, '0')}.${currentDate.getFullYear()}`;
              const date = `${formattedDate}`;
              const dateWidth = customFontBody.widthOfTextAtSize(date, fontSizeDate);
              const xCenterDate = ((imageDimensions.width - dateWidth) / 2) - 1020;
          
              // Draw the user's name
              page.drawText(name, {
              x: xCenterName,
              y: yCenterName,
              size: fontSizeName,
              font: customFont,
              color: rgb(1, 1, 1), // white color
              });
          
              page.drawText(courseName, {
              x: xCenterCourse,
              y: yCenterCourse,
              size: fontSizeCourse,
              font: customFont,
              color: rgb(1, 1, 1), // white color
              });
              page.drawText(date, {
              x: xCenterDate,
              y: yCenterDate,
              size: fontSizeDate,
              font: customFontBody,
              color: rgb(1, 1, 1), // white color
              });
          
              page.drawText(uniqueNumber, {
              x: xCenterUniqueNumber,
              y: yCenterUniqueNumber,
              size: fontSizeUniqueNumber,
              font: customFontBody,
              color: rgb(1, 1, 1), // white color
              });
          
              const pdfBytes = await pdfDoc.save();
              const storageRef = ref(storage, `certificates/${courseId}/${courseId}-${account.name}-${uniqueNumber}-${date}.pdf`);
          
              const uploadTask = uploadBytesResumable(storageRef, pdfBytes);
              uploadTask.on('state_changed', 
              (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);  // Notify the caller about the progress
              },
              (error) => {
                setMessage("Upload failed:", error);
              console.error("Upload failed:", error);
              },
              async () => {
                  const pdfUrl = await getDownloadURL(storageRef);
                  setDownloadLink(pdfUrl);
                    // Save the download link to the user's Firestore document
                  const userDocRef = doc(db, 'users', user.uid);
                  const userDocSnapshot = await getDoc(userDocRef);
                  const existingCertificates = userDocSnapshot.data().certificates || {};

                  // Update or set the certificate URL for this course
                  existingCertificates[courseId] = pdfUrl;
                  
                  // Update the Firestore document
                  await updateDoc(userDocRef, {
                    certificates: existingCertificates
                  });
                  // Save the review to Firestore
                  const reviewsCollection = collection(specificCourseRef, 'reviews');

                  const reviewData = {
                    userId: user.uid,
                    text: review,  
                    rating: rating, 
                    name: account.name, 
                    avatar: account.avatarURL,
                    timestamp: new Date().toISOString(),
                    verified: false
                  };
              
                  await addDoc(reviewsCollection, reviewData);

                  setShowCongrats(true);
                  setTimeout(() => {
                    setShowCongrats(false);
                  }, 10000);
                  setLoading(false);
                },
                (error) => {
                  console.error("Upload failed:", error);
                  setLoading(false); // set the loading state back to false in case of an error
                });
              } catch (error) {
                setLoading(false);
                setMessage(error.message);
                return;
              }

            
              // If all went well, proceed to the next step.
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            };

  

            return (

              <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', gap: 1, minHeight: '100vh'}}>
                {showCongrats && <Congratulations />}
                {!downloadLink && (
                  <>
                <Typography variant="h3" textAlign={"center"}>
                Получите сертификат о прохождении курса!
                </Typography>
                <Typography variant="body" sx={{  textAlign: 'center', fontSize: 18, fontWeight: 400}}>
                            Чтобы получить сертификат вам нужно оставить отзыв о курсе 😉</Typography>
                </>
                )}
                {downloadLink && (
                  <>
                <Typography variant="h3" textAlign={"center"}>
                Поздравляем с получением сертификата 🥳
                </Typography>
                </>
                )}
                <div style={{ flexGrow: 1 }}>
                  {activeStep === 0 && !downloadLink && (<>
                                          <Box
                                          display="flex"
                                          flexDirection="column"
                                          alignItems="center"
                                          justifyContent="center"
                                          marginTop={2}
                                        >
                                          <img src="/assets/illustrations/certificate-mocking.png" alt="Congratulations"  />
                                          </Box>
                                          <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        marginTop={5}
                        width={'100%'}
                        height={110}
                        sx={{py: 4, mt: "-12%", 
                          backgroundColor: alpha("#fff", 0.00225),
                          backdropFilter: "blur(10px)",
                          borderTop: "0.5px solid rgba(209, 213, 219, 0.3)",
                          }}
                      >
                                      <Rating
                          size="large"
                          name="user-rating"
                          value={rating}
                          onChange={(event, newRating) => {
                            setRating(newRating);
                          }}
                        />
                    </Box>
                    <TextField
                      sx={{ 
                        mt: 2,
                        backgroundColor: alpha("#fff", 1),
                        borderColor: "#fff",
                        }}
                        fullWidth
                      multiline
                      rows={4}
                      label="Отзыв"
                      value={review}
                      onChange={(e) => setReview(e.target.value)}
                    />
                    </>
                  )}
            

                {(!downloadLink) &&  (
                          <Box                         
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          sx={{mt: 2}}>

                    <LoadingButton
                      loading={loading}
                      disabled={
                        (activeStep === 0 && !isReviewValid) ||
                        loading
                      }
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      {activeStep === steps.length - 1 ? 'Сгенерировать сертификат 🚀' : 'Далее'}
                    </LoadingButton>
                    
            
                    {loading && (
                      <LinearProgress variant="determinate" value={progress} sx={{ 
                        mt: 2,
                        width: '100%',
                        borderRadius: 4, 
                        height: 6,
                        bgcolor: '#cccccc',
                        '& .MuiLinearProgress-bar': {
                          backgroundImage: 'linear-gradient(to right, #853EF6 10%, #440AE4 30%, #D92EF0 50%, #E93323 60%, #EEBD40 100%)',
                          borderRadius: 2,
                        }
                    }} />
                    
                    )}
            
                    {message && (
                      <Alert severity="error">{message}</Alert>
                    )}
                    </Box>)}

                  {downloadLink && (
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        marginTop={2}
                      >
                        <img src="/assets/illustrations/certificate-mocking.png" alt="Congratulations"  />

                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        marginTop={5}
                        width={'100%'}
                        sx={{py: 4, mt: -15, 
                          backgroundColor: alpha("#fff", 0.0225),
                          backdropFilter: "blur(10px)",
                          borderTop: "0.5px solid rgba(209, 213, 219, 0.3)",


                          }}
                      >
                        <Typography  color={'#FFF'} variant="h3" textAlign={"center"}>Спасибо за прохождение курса!</Typography>
                        <Button
                            sx={{mt: 10, borderRadius: 10}}
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={() => window.open(downloadLink, '_blank')}
                          >
                            Скачать сертификат 🚀
                          </Button>
                          <Box                         
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          sx={{px: '20%', mt: 2,}}>
                          <Typography variant="body" sx={{  textAlign: 'center', fontSize: 14, fontWeight: 400, fontStyle: 'italic'}}>
                            Все ваши сертификаты доступны в личном кабинете в разделе "Мои сертификаты"
                          </Typography>
                          </Box>
                        </Box>
                      </Box>

                      </>
                    )}
                </div>
              </Container>
            );
}



