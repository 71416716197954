export function getLessonWordForm(count) {
    let wordForm = 'уроков';
  
    if (count % 10 === 1 && count % 100 !== 11) {
      wordForm = 'урок';
    } else if ([2, 3, 4].includes(count % 10) && ![12, 13, 14].includes(count % 100)) {
      wordForm = 'урока';
    }
  
    return wordForm;
  }