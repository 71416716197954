
import { ReviewVerification } from "../../sections";
import { db } from "../../firebase";
import { Container } from "@mui/material";
export default function AllCourses() {
    return (
        <>

        <Container maxWidth="lg">
        <ReviewVerification db={db} />
    </Container>
    </>
    )}