import { useEffect, useState } from 'react';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../firebase';

// Custom hook to fetch pending checkouts
const usePendingCheckouts = (userId) => {
  const [pendingCheckouts, setPendingCheckouts] = useState([]);
  const [loadingPendingCheckouts, setLoading] = useState(true);

  const fetchPendingCheckouts = async () => {
    setLoading(true);
    const checkoutCollection = collection(db, 'cryptoCustomers', userId, 'checkout_sessions');
    const checkoutQuery = query(
      checkoutCollection,
      where('status', '==', 'pending'),
      orderBy('timestamp') // Replace 'timestamp' with your field for sorting
    );

    const querySnapshot = await getDocs(checkoutQuery);
    const checkouts = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setPendingCheckouts(checkouts);
    setLoading(false);
  };

  useEffect(() => {
    fetchPendingCheckouts();
  }, [userId]);

  return { pendingCheckouts, loadingPendingCheckouts, refetch: fetchPendingCheckouts };
};

export default usePendingCheckouts;
