import React, { useEffect, useContext } from 'react';
import { useNavigate, Routes } from 'react-router-dom';
import UserContext from './UserContext';

const AdminBasedRoute = ({ component: Component, children, allowedRoles }) => {
  const user = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !allowedRoles.includes(user.admin)) {
      ////console.log('user role is not allowed');
      navigate('/');
    }
  }, [user, allowedRoles, navigate]);



  return user && allowedRoles.includes(user.admin) ? (
    <Component>{children}</Component>
  ) : null;
};



export default AdminBasedRoute;
