import React, { useEffect, useState, useContext, useRef} from 'react';
import { useTheme, ListItemText, List, ListItem, Grid, Card, Button, Box, Container, Typography, FormControl, InputLabel, Select, MenuItem , Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import proMax_sertification_image from '../../sections/assets/certificate-proMax-small.png';

import UserContext from '../../UserContext';
import { CartContext } from '../../CartContext';
import { ReviewsSection, TradesSection, SpecificCoursesSection } from '../../sections';
import {ReviewsSectionCourse} from '../../sections';
import { useFetchCertification } from '../../hooks/useFetchCertification';
//icons
import BackIcon from '@mui/icons-material/KeyboardBackspace';
import Skeleton from '@mui/material/Skeleton';




const TagBox = ({ children }) => (
  <Box
    sx={{
      fontSize: ['14px','14px','14px','16px'], lineHeight: ['24px','24px','24px','28px'],
      fontWeight: 400,
          color: '#FFF',
          borderRadius: 3,
          backdropFilter: 'blur(3px)',
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          border: '1px solid rgba(255, 255, 255, 0.2)',
      py: 0.5,
      px: '8px',
      display: 'inline-block', // To make the box size according to content
    }}
  >
    {children}
  </Box>
);



export default function Certification() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const learnSeparate = useRef(null);
    const atTop = useRef(null);
    const { certificationId } = useParams();
    const account = useContext(UserContext);
    const { loading, certification, courses, reviews } = useFetchCertification(certificationId);
    
    const Tabs = styled(MuiTabs)(({ theme }) => ({
      borderBottom: '1px solid var(--stroke-grey, #E5E7E9)',
  }));

    const Tab = styled(MuiTab)(({ theme }) => ({
      minHeight: 70,
      minWidth: 96,
      textTransform: 'initial',
      color: theme.palette.text.primary,
      fontSize: isMobile? '14px' : '16px', fontWeight: '600', lineHeight:   isMobile? '16px' : '22px',
      borderLeft: '0.5px solid var(--stroke-grey, #E5E7E9)',
      borderRight: '0.5px solid var(--stroke-grey, #E5E7E9)',
      backgroundColor: '#F5F7F9',
      flex: 1,
      '&.Mui-selected': {
          
        borderBottom: '1px solid transparent',  // Remove bottom border for the selected tab
        borderRadius: 0,
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.common.black,
      },
  
      [theme.breakpoints.up('md')]: {
        minWidth: 120
      },
  }));

    
    const scrollToRef = (targetRef) => {
      if (targetRef.current) {
        targetRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    };
    const navigate = useNavigate();
    const { cart, setCart } = useContext(CartContext);
    const handleTariff = (courseId) => {
      // Open the URL in a new window or tab
      window.open("https://defaust.com/pricing", '_blank');
  };
  
    const handleAddToCart = (courseOrCertification, type) => {
        // Assuming type can be 'courses' or 'certificates'
        if (!cart[type].includes(courseOrCertification)) {
          setCart(prevCart => ({
            ...prevCart,
            [type]: [...prevCart[type], courseOrCertification]
          }));
        }
        navigate('/cart');
      };

      useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);
    const [activeTab, setActiveTab] = useState(0);
    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 0,  // Autoplay the video
        },
    };
    
    const handleRowClick = (courseId) => {
        navigate(`/dashboard/catalog/course/${courseId}`);
      };



          function renderSpecificCourses(idsToDisplay, step) {
            // Fetch only those courses whose IDs are in idsToDisplay
            const filteredCourses1 = courses.filter(course => idsToDisplay.includes(course.id.toString()));
          
            // Sort the filtered courses according to the order of IDs in idsToDisplay
            const filteredCourses = filteredCourses1.sort((a, b) => {
              return idsToDisplay.indexOf(a.id.toString()) - idsToDisplay.indexOf(b.id.toString());
            });
            
            //console.log(filteredCourses);

            return (
              <SpecificCoursesSection filteredCourses={filteredCourses} account={account} handleRowClick={handleRowClick} isMobile={isMobile} step={step}/>
            );
}
////console.log(courses);
if (loading) {
    return (
            <Container maxWidth="lg">
            <Skeleton variant="text" width='100%' height={70} />
            <Skeleton variant="rectangular" width="100%" height={300} sx={{
                                border: "1px solid rgba(209, 213, 219, 0.3)", // border
                                borderRadius: 5,
                                padding: 2,
                                mt: 5,
            }}/>
            </Container>
    );
    }

  return (
    <>
<Helmet>
  <html lang="ru" />
  <title>Программа {certification.name.ru} от DEFAUST®</title>
  <meta name="description" content={`Выбирайте лучшие курсы по трейдингу, криптовалютам и инвестициям от DEFAUST ACADEMY!`} />
  <link rel="canonical" href="https://defaust.com/dashboard/catalog/certification/defaust-trader" />
  <meta name="robots" content="index, follow" />
  
  {/* OpenGraph tags */}
  <meta property="og:title" content="Программа DEFAUST TRADER от DEFAUST®" />
  <meta property="og:description" content="Выбирайте лучшие курсы по трейдингу, криптовалютам и инвестициям от DEFAUST ACADEMY!" />
  <meta property="og:image" content="https://defaust.com/assets/images/previews/preview-defaust-trader.jpg" />
  <meta property="og:url" content="https://defaust.com/dashboard/catalog/certification/defaust-trader" />

  {/* Twitter Card tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Программа DEFAUST TRADER от DEFAUST®" />
  <meta name="twitter:description" content="Выбирайте лучшие курсы по трейдингу, криптовалютам и инвестициям от DEFAUST ACADEMY!" />
  <meta name="twitter:image" content="https://defaust.com/assets/images/previews/preview-defaust-trader.jpg" />

  {/* Dynamic Schema */}
  <script type="application/ld+json">
    {`
      {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "Программа ${certification.name.ru} от DEFAUST®",
        "description": "Выбирайте лучшие курсы по трейдингу, криптовалютам и инвестициям от DEFAUST ACADEMY!."
      }
    `}
  </script>
</Helmet>
    <Container maxWidth="lg">    
<Typography ref={atTop} variant="h2" sx={{ fontSize: '1.8rem', pt: 1, textTransform: 'uppercase'}}>
  {certification.name.ru} <span style={{ fontSize: '0.5em', verticalAlign: 'super' }}>®</span>
</Typography>


          <Grid container
      sx={{ 
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          background: 'radial-gradient(44.36% 39.44% at 18.44% 45.65%, rgba(105, 255, 201, 0.20) 0%, rgba(117, 165, 248, 0.20) 53.71%, rgba(191, 172, 247, 0.20) 94.79%), linear-gradient(114deg, #75A5F8 0%, #BFACF7 41.67%, #75A5F8 67.71%, #69FFC9 100%)',
          border: '1px solid var(--stroke-grey, #E5E7E9)', 
          borderRadius: '10px 10px 0 0',
          pt: 4,
          pl: 4,
          pr: 4,
          pb: 4,
          mt: 4,

      }}
>
    <Grid item xs={12} sm={12} md={7} lg={7} sx={{pr: 3}}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, mb: 3}}>
            <Typography  sx={{color: '#fff',fontSize: ['22px','22px','22px','26px'], fontWeight: 'bold', pb: 3, textTransform: 'uppercase'}}>
                {certification.name.ru} <span style={{ fontSize: '0.55em', verticalAlign: 'super' }}>®</span>
            </Typography>
            <Typography  sx={{color: '#fff', fontSize: ['16px','16px','16px','18px'], fontWeight: '400', lineHeight: ['24px','24px','24px','28px'], pb:3 }}>
                {certification.description.ru} 
            </Typography> 
            <Typography  sx={{color: '#fff',fontSize: ['16px','16px','16px','18px'], fontWeight: '600', lineHeight: ['24px','24px','24px','28px']}}>
              Навыки, которые вы получите:
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 2, mb: [3,0], flexWrap: 'wrap'}}>
              <TagBox>volume analysis</TagBox>
              <TagBox>liquidity levels</TagBox>
              <TagBox>liquidity sweeps</TagBox>
              <TagBox>volume profile</TagBox>
              <TagBox>cumulative delta</TagBox>
              <TagBox>риск-менеджмент</TagBox>
              <TagBox>психология</TagBox>
              <TagBox>проп-компании</TagBox>

            </Box>
        </Box>

    </Grid>

    <Grid item xs={12} sm={12} md={5} lg={5} 
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
          <Box
    sx={{
      width: '100%',
      position: 'relative',
    }}
  >
        <Box
            component="img"
            src={proMax_sertification_image}
            alt="Description of image"
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: 5,
            }}
          />
          
{/*         <VideoPlayer 
                url={certification.introYouTube.ru}
                opts={{ ...opts, height: '100%', width: '100%' }}
                containerClassName="youtube-container"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}

            /> */}
</Box>
        
    </Grid>
</Grid>
<Tabs 
  centered
  indicatorColor="transparent"
  value={activeTab}
  onChange={(event, newValue) => setActiveTab(newValue)}
  sx={{
    borderRadius: '0px 0px 10px 10px',
    '.MuiTabs-flexContainer': {
      width: '100%',
      justifyContent: 'space-around',
    },
    '.MuiTab-root': {
      flexGrow: 1,
      maxWidth: 'none', // removes the maximum width constraint
    }
  }}
>
{/*     <Tab label="Почему DEFAUST" /> */}
    <Tab label="Программа обучения" />
    <Tab label="Результаты учеников" />
    <Tab label="Отзывы" />

  </Tabs>

{/*   {activeTab === 0 && (
<>
          <Grid container 
      sx={{ 
          display: 'flex',
          flexDirection: 'row',
          background: '#F5F7F9',
          borderLeft: '1px solid var(--stroke-grey, #E5E7E9)', 
          borderRight: '1px solid var(--stroke-grey, #E5E7E9)', 
          borderBottom: '1px solid var(--stroke-grey, #E5E7E9)', 
          borderRadius: '0 0 10px 10px',
          pt: 4,
          pl: 4,
          pr: 4,
          pb: 4,


      }}
>
<Grid container spacing={3}>
<Grid item xs={12} sm={12} md={6} lg={6} 
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
<Box  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', px: [5,10,10]}}>
<img 
src={getCertificationImage(certificationId)} 
alt="Basic Certification" 
sx={{
  objectFit: 'fill'
}}
/>
</Box>
</Grid>





    <Grid item xs={12} sm={12} md={6} lg={6}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0}}>
        <List sx={{fontSize: '1rem', color: '#444444'}}>
                                {whyDefaust?.ru?.map((feature, index) => (
    <ListItem key={index}>
        <CheckIcon  sx={{ marginRight: 1 }} />
        {feature}
    </ListItem>
))}
                                </List>
        </Box>
    </Grid>
</Grid>
</Grid>

<Typography variant="h3" sx={{ pt: 4, pb: 2 }}>
            Программа обучения
          </Typography>
          <Typography variant="p" sx={{ pb: 4, fontSize: '1.1rem', color: '#555555' }}>
            Ниже приведен список всех курсов, которые необходимо закончить для получения сертификации.
          </Typography>

          <Box display="flex" flexDirection='column' justifyContent={'start'} alignContent={'start'} alignItems={'center'} gap={2} marginTop={4}>

          <Box sx={{width: '100%', backgroundColor: '#F5F7F9', border: '1px solid var(--stroke-grey, #E5E7E9)', borderRadius: 3, py: 3, px: 5, }}>
          <Typography variant="h4" sx={{ pt: 3, pb: 2 }}>
            Шаг 1: Начало
          </Typography>
          <Typography variant="p" sx={{ pb: 3, fontSize: '1rem', color: '#555555' }}>
            Изучение структуры рынка, типов финансовых активов, устройства рынка FOREX, фондового рынка, фьючерсного рынка. Глубокое изучение механики работы деривативных инструментов: фьючерсов и опционов.
          </Typography>
          {renderSpecificCourses(['introduction-in-financial-markets','introduction-in-trading'])}
            </Box>

            <Box sx={{width: '100%', backgroundColor: '#F5F7F9', border: '1px solid var(--stroke-grey, #E5E7E9)', borderRadius: 3, py: 3, px: 5, }}>
          <Typography variant="h4" sx={{ pt: 3, pb: 2 }}>
            Шаг 2: Market Structure
          </Typography>
          <Typography variant="p" sx={{ pb: 3, fontSize: '1rem', color: '#555555' }}>
            Изучение структуры рынка. Практические навыки анализа графика и структуры: определение баланса, структуры тренда (ипульсы и боковики), а также изучение манипуляйи от смарт-мани. Также изучение Buy/Sell Liquidity и Liquidity Sweep.          </Typography>
          {renderSpecificCourses(['market-structure', 'orderflow'])}
            </Box>

            <Box sx={{width: '100%', backgroundColor: '#F5F7F9', border: '1px solid var(--stroke-grey, #E5E7E9)', borderRadius: 3, py: 3, px: 5, }}>
          <Typography variant="h4" sx={{ pt: 3, pb: 2 }}>
            Шаг 3: Volume Analysis
          </Typography>
          <Typography variant="p" sx={{ pb: 3, fontSize: '1rem', color: '#555555' }}>
            Изучение объемного анализа графика. Изучение основных инструментов объемного анализа: Volume Profile, Cumulative Delta и Big Trades.
          </Typography>
          {renderSpecificCourses(['volume-profile', 'cumulative-delta'])}
            </Box>

            <Box sx={{width: '100%', backgroundColor: '#F5F7F9', border: '1px solid var(--stroke-grey, #E5E7E9)', borderRadius: 3, py: 3, px: 5, }}>
          <Typography variant="h4" sx={{ pt: 3, pb: 2 }}>
            Шаг 4: Торговые сетапы
          </Typography>
          <Typography variant="p" sx={{ pb: 3, fontSize: '1rem', color: '#555555' }}>
            Практическое изучение точек входа по объемному анализу на основе манипуляций крупных участников рынка, после проведения анализа структуры рынка, определения провокаций и уровней ликвидности (по Volume Profile и Cumulative Delta).
          </Typography>
          {renderSpecificCourses(['trading-setup-trend-continuation', 'trading-setup-provocation'])}
            </Box>

            <Box sx={{width: '100%', backgroundColor: '#F5F7F9', border: '1px solid var(--stroke-grey, #E5E7E9)', borderRadius: 3, py: 3, px: 5, }}>
          <Typography variant="h4" sx={{ pt: 3, pb: 2 }}>
            Шаг 5: Риск-менеджмент и психология
          </Typography>
          <Typography variant="p" sx={{ pb: 3, fontSize: '1rem', color: '#555555' }}>
            Полное изучение эффективной системы риск-менеджмента, правил контроля риска и управление прибылью. Работа с ордерами, стоп-лоссами, б/у и тейк-профитами. Проработка психологии в трейдинге для контроля эмоций и снижения вероятности импульсных решений.
          </Typography>
          {renderSpecificCourses(['statistics-in-trading','risk-management', 'psychology-in-trading',])}
            </Box>

            <Box sx={{width: '100%', backgroundColor: '#F5F7F9', border: '1px solid var(--stroke-grey, #E5E7E9)', borderRadius: 3, py: 3, px: 5, }}>
          <Typography variant="h4" sx={{ pt: 3, pb: 2 }}>
            Шаг 6: Дополнительные курсы
          </Typography>
          <Typography variant="p" sx={{ pb: 3, fontSize: '1rem', color: '#555555' }}>
            Изучение дополнительных тем, расширяющих понимание трейдера при работе на финансовых рынках. Принципы работы с проп-компаниями, ведения журнала трейдера, работы с терминалом MetaTrader и TradingView.
          </Typography>
          {renderSpecificCourses(['trading-journal','prop-firms','taxes','metatrader'])}
            </Box>

</Box>
</>
      )} */}

  {activeTab === 0 && (
<>
            <Grid container spacing={3} sx={{ mt: '23px', mb: '40px' }}>
                <Grid item xs={12} sm={6} md={7}>
                <Box sx={{...boxStyle, }}>
                {/* Course name */}
                <Typography variant="body" sx={{fontSize: ['26px','26px','26px','32px'], fontWeight: 'medium', lineHeight: ['34px','34px','34px','42px']}}>Полная программа обучения трейдингу</Typography>
                {/* Course description */}
                <Typography variant="body" sx={{mt: 2,fontSize: ['16px','16px','16px','18px'], fontWeight: '400', lineHeight: ['24px','24px','24px','28px']}}>
                  DEFAUST TRADER® состоит из 6-шаговово обучения, где каждый шаг состоит из отдельных модулей. Программа разработана таким образом, чтобы обучить абсолютного новичка трейдингу с самых основ. <br/><br/>
                  <span style={{fontWeight: '600'}}>Вы можете оплатить всю программу сразу, или проходить каждый модуль отдельно – как вам удобней.</span>
                </Typography>
                </Box>

              <Box  sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', pt: '20px', gap: '20px' }}>
              <Box sx={{display: 'inline-flex' }}>
              <Button  variant="outlined" color="primary" sx={{ width: ['100%','100%','100%', '100%'], boxShadow: '3px 3px 1px 1px rgba(24, 24, 24, 0.25)', color: '#000', background: '#A0C0FF' ,fontSize: ['14px','14px','14px','15px'], fontWeight: '600', lineHeight: ['16px','16px','16px','20px'], px: '20px',  borderRadius: 3, py: '15px', textTransform: 'none',
                     '&:hover': {
                      color: '#000', background: '#A0C0FF', 
                     }}} onClick={() => handleTariff()}>
                    Тарифы обучения
                </Button>
                </Box>
{/*                 <Box sx={{display: 'inline-flex' }}>
                <Button variant="text" color="primary" sx={{ width: ['100%','100%','100%', '100%'],color: '#2E2E2E', background: 'transparent' ,fontSize: ['14px','14px','14px','15px'], fontWeight: '400', lineHeight: ['16px','16px','16px','20px'],  borderRadius: 3, py: '15px', textTransform: 'none', px: '20px',
                     '&:hover': {
                      color: 'white', background: '#2E2E2E', 
                     }}} onClick={() => scrollToRef(learnSeparate)}>
                    Проходить отдельно по модулю <ArrowDownwardIcon  sx={{pl: '5px',fontSize: ['18px','18px','18px','20px']}}/>
                </Button>
                </Box> */}
              </Box>
              </Grid>

            </Grid>

          <Box display="flex" flexDirection='column' justifyContent={'start'}  alignItems={'start'} gap={2} marginTop={4}>

          <Box sx={{display: 'flex', flexDirection: 'column', width: ['100%','100%','100%', '55%' ],}}>
          <Typography ref={learnSeparate} variant="body" sx={{ fontSize: ['22px','22px','22px','32px'], fontWeight: 'medium', lineHeight: ['24px','24px','24px','40px']}}>
            Шаг 1: Погружение в трейдинг 🤿
          </Typography>
          <Typography variant="body" sx={{mt: 2,fontSize: ['16px','16px','16px','18px'], fontWeight: '400', lineHeight: ['24px','24px','24px','28px']}}>
            Изучение структуры рынка, типов финансовых активов, устройства рынка FOREX, фондового рынка, фьючерсного рынка. Глубокое изучение механики работы деривативных инструментов: фьючерсов и опционов.
          </Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row' }}>

          {renderSpecificCourses(['introduction-in-financial-markets','introduction-in-trading'], 'Шаг 1')}
            </Box>

            <Box sx={{display: 'flex', flexDirection: 'column', width: ['100%','100%','100%', '60%' ],}}>
          <Typography  variant="body" sx={{ fontSize: ['22px','22px','22px','32px'], fontWeight: 'medium', lineHeight: ['24px','24px','24px','40px']}}>
            Шаг 2: Market Structure 🧠
          </Typography>
          <Typography variant="body" sx={{mt: 2,fontSize: ['16px','16px','16px','18px'], fontWeight: '400', lineHeight: ['24px','24px','24px','28px']}}>
            Изучение структуры рынка. Практические навыки анализа графика и структуры: определение баланса, структуры тренда (ипульсы и боковики), а также изучение манипуляйи от смарт-мани. Также изучение Buy/Sell Liquidity и Liquidity Sweep.
            </Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row' }}>

          {renderSpecificCourses(['market-structure', 'orderflow'], 'Шаг 2')}
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', width: ['100%','100%','100%', '60%' ],}}>
          <Typography variant="body" sx={{ fontSize: ['22px','22px','22px','32px'], fontWeight: 'medium', lineHeight: ['24px','24px','24px','40px']}}>
           Шаг 3: Volume Analysis 📊
           </Typography>
          <Typography variant="body" sx={{mt: 2,fontSize: ['16px','16px','16px','18px'], fontWeight: '400', lineHeight: ['24px','24px','24px','28px']}}>
            Изучение объемного анализа графика. Изучение основных инструментов объемного анализа: Volume Profile, Cumulative Delta и Big Trades.
          </Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row' }}>

          {renderSpecificCourses(['volume-profile', 'cumulative-delta'], 'Шаг 3')}
            </Box>

            <Box sx={{display: 'flex', flexDirection: 'column', width: ['100%','100%','100%', '60%' ],}}>
          <Typography variant="body" sx={{ fontSize: ['22px','22px','22px','32px'], fontWeight: 'medium', lineHeight: ['24px','24px','24px','40px']}}>
            Шаг 4: Торговые сетапы 😎
            </Typography>
          <Typography variant="body" sx={{mt: 2,fontSize: ['16px','16px','16px','18px'], fontWeight: '400', lineHeight: ['24px','24px','24px','28px']}}>
            Практическое изучение точек входа по объемному анализу на основе манипуляций крупных участников рынка, после проведения анализа структуры рынка, определения провокаций и уровней ликвидности (по Volume Profile и Cumulative Delta).
            </Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row' }}>
          {renderSpecificCourses(['trading-in-trend', 'trading-in-range'],'Шаг 4')}
            </Box>

            <Box sx={{display: 'flex', flexDirection: 'column', width: ['100%','100%','100%', '60%' ],}}>
          <Typography  variant="body" sx={{ fontSize: ['22px','22px','22px','32px'], fontWeight: 'medium', lineHeight: ['24px','24px','24px','40px']}}>
             Шаг 5: Риск-менеджмент и психология 🧘🏼‍♂️
          </Typography>
          <Typography variant="body" sx={{mt: 2,fontSize: ['16px','16px','16px','18px'], fontWeight: '400', lineHeight: ['24px','24px','24px','28px']}}>
            Полное изучение эффективной системы риск-менеджмента, правил контроля риска и управление прибылью. Работа с ордерами, стоп-лоссами, б/у и тейк-профитами. Проработка психологии в трейдинге для контроля эмоций и снижения вероятности импульсных решений.
            </Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row' }}>

          {renderSpecificCourses(['statistics','risk-management', 'psychology',],'Шаг 5')}
            </Box>

            <Box sx={{display: 'flex', flexDirection: 'column', width: ['100%','100%','100%', '60%' ],}}>
          <Typography  variant="body" sx={{ fontSize: ['22px','22px','22px','32px'], fontWeight: 'medium', lineHeight: ['24px','24px','24px','40px']}}>
            Шаг 6: Дополнительные курсы ➕
          </Typography>
          <Typography variant="body" sx={{mt: 2,fontSize: ['16px','16px','16px','18px'], fontWeight: '400', lineHeight: ['24px','24px','24px','28px']}}>
            Изучение дополнительных тем, расширяющих понимание трейдера при работе на финансовых рынках. Принципы работы с проп-компаниями, ведения журнала трейдера, работы с терминалом MetaTrader и TradingView.
          </Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row' }}>
          {renderSpecificCourses(['trading-journal','prop-firms','taxes','metatrader'],'Шаг 6')}
            </Box>

</Box>
</>
)}
  {activeTab === 1 && (
<>
<TradesSection />
</>
)}
  {activeTab === 2 && (
<>
<ReviewsSectionCourse reviews={reviews}  />
</>
)}


<Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{display: 'inline-flex' }}>
                <Button variant="outlined" color="primary" sx={{ width: ['100%','100%','100%', '100%'],color: '#2E2E2E', background: 'transparent' ,fontSize: ['14px','14px','14px','15px'], fontWeight: '400', lineHeight: ['16px','16px','16px','20px'],  borderRadius: 3, py: '15px', textTransform: 'none', px: '20px',
                     '&:hover': {
                      color: 'white', background: '#2E2E2E', 
                     }}} onClick={() => scrollToRef(atTop)}>
                    В самое начало <ArrowUpwardIcon  sx={{pl: '5px',fontSize: ['18px','18px','18px','20px']}}/>
                </Button>
                </Box>
                </Box>

</Container>
    </>
  );
}
const boxStyle = {

borderRadius: 5,

display: 'flex',
flexDirection: 'column'
};