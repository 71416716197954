import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Typography } from '@mui/material';
import { LessonSection, WrappedExamSection, WrappedQuizSection } from '../../sections';
import { Helmet } from 'react-helmet-async';
import { db } from '../../firebase';
import { collection, query, where, getDocs } from "firebase/firestore";

export default function ItemPage() {
  const [loading, setLoading] = useState(true);
  const { courseId, type, itemId } = useParams();
  const [course, setCourseData] = useState(null);
  const renderContentByType = () => {
    switch (type) {
      case 'lesson':
        return <LessonSection courseId={courseId} lessonId={itemId} />;
      case 'quiz':
        return <WrappedQuizSection courseId={courseId} quizId={itemId} />;
      case 'exam':
        return <WrappedExamSection courseId={courseId} quizId={itemId} />;
      default:
        return <Typography variant="h5">Invalid item type</Typography>;
    }
  };


    const fetchCourseData = async () => {
        const coursesCollection = collection(db, "courses");
        const q = query(coursesCollection, where("id", "==", courseId));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
            const courseDoc = querySnapshot.docs[0];
            setCourseData(courseDoc.data());
        } else {
            console.error("No such course!");
        }
    }
    
    
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await fetchCourseData();
            setLoading(false);
        };

        fetchData();
    }, [courseId]);

  return (
    <>
    <Helmet>
        <html lang="ru" /> 
        <title>{course?.name?.ru || 'Курс'} | DEFAUST</title>
        <meta name="description" content="Профессиональное обучение трейдингу, крипте и инвестициям на DEFAUST ACADEMY" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "${course?.name?.ru || 'Курс'} | DEFAUST",
              "description": "Профессиональное обучение трейдингу, крипте и инвестициям на DEFAUST ACADEMY"
            }
          `}
        </script>
    </Helmet>
    <Container maxWidth="xl">
      {renderContentByType()}
    </Container>
    </>
  );
}