import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Button, Drawer, Typography, LinearProgress } from '@mui/material';
import { ListItem, ListItemIcon, ListItemText, List, IconButton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import QuizIcon from '@mui/icons-material/Quiz';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useResponsive from '../../../hooks/useResponsive';
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import BackIcon from '@mui/icons-material/KeyboardBackspace';
import SchoolIcon from '@mui/icons-material/School';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const NAV_WIDTH = 320;

export default function Nav({ openNav, onCloseNav, lessons, quiz, exam, structure, completedItems, course, progress}) {
  const { pathname } = useLocation();
  const [scrollPos, setScrollPos] = useState(0);
  const navContainerRef = useRef(null);
  const handleScroll = () => {
    const navContainer = document.getElementById('navContainer'); // Make sure to add this id to your Scrollbar or Box component
    setScrollPos(navContainer.scrollTop);
  };
  useEffect(() => {
    const currentRef = navContainerRef.current;
  
    if (currentRef) {
      const saveScroll = () => {
        localStorage.setItem('savedScrollPos', currentRef.scrollTop);
      };
  
      const restoreScroll = () => {
        const savedScroll = localStorage.getItem('savedScrollPos');
        if (savedScroll) {
          currentRef.scrollTop = parseInt(savedScroll, 10);
        }
      };
  
      currentRef.addEventListener('scroll', saveScroll);
      window.addEventListener('popstate', restoreScroll);
  
      return () => {
        currentRef.removeEventListener('scroll', saveScroll);
        window.removeEventListener('popstate', restoreScroll);
      };
    }
  }, [navContainerRef.current]); // Listening for changes to navContainerRef.current
  
  const isDesktop = useResponsive('up', 'lg');
  
  const calculateProgressPercentage = () => {
    ////console.log('completedItems', completedItems);
    if (completedItems.length === 0) {
      return 0;
    }
    else {
    const totalItems = lessons.length + quiz.length + exam.length;
    const totalCompleted = completedItems?.completedLessons?.length || 0 + 
                           completedItems?.completedQuiz?.length  || 0 + 
                           completedItems?.completedExam?.length || 0;

    const progressPercentage = (totalCompleted / totalItems) * 100;

    return Math.round(progressPercentage);  // Rounding it to the nearest whole number
  }};

  const renderCourseStructure = () => {
    return (
      <List>
        {structure.map((item, index) => {
          const itemType = Object.keys(item)[0]; // lesson, quiz, or exam
          const itemId = item[itemType];
          
          let courseItem;
          switch (itemType) {
            case 'lesson':
              courseItem = lessons.find(lesson => lesson.id === itemId);
              break;
            case 'quiz':
              courseItem = quiz.find(q => q.id === itemId);
              break;
            case 'exam':
              courseItem = exam.find(q => q.id === itemId);
              break;
            default:
              courseItem = null;
          }

          if (!courseItem) {
            console.error(`Item not found for type "${itemType}" and id "${itemId}"`);
            return null; // Skip if the course item is not found
          }

          const getCompletionColor = (type, id) => {
            try {
              if (
                (type === 'lesson' && completedItems.completedLessons.includes(id)) ||
                (type === 'quiz' && completedItems.completedQuiz.includes(id)) ||
                (type === 'exam' && completedItems.completedExam.includes(id))
              ) {
                return '#3366FF';
              }
              return 'inherit';
            } catch (e) {
              return 'inherit';
            }
          };
          
          
          const itemPath = `/course/take/${course.id}/${itemType}/${itemId}`;
          return (
            <ListItem 
            key={index} 
            component={RouterLink} 
            to={itemPath} 
            button
            sx={{ 
              borderBottom: "1px solid rgba(209, 213, 219, 1)",
              backgroundColor: pathname === itemPath ? 'rgba(3, 169, 244, 0.2)' : 'transparent', // set the active item background here
              '&:hover': { backgroundColor: 'rgba(0,0,0,0.1)' }
            }}
          >
              <ListItemIcon sx={{px: 2}}>
              

              <Box display="flex" flexDirection='column' justifyContent={'center'} alignContent={'center'} alignItems={'center'} >
                {(index != 0) ? 
                  <MoreVertIcon sx={{color: getCompletionColor(itemType, itemId), mt: 'auto'}} /> : ""}
                <CheckCircleOutlineIcon sx={{color: getCompletionColor(itemType, itemId), mt: 'auto'}} />
                {(itemType !== 'exam') ? 
                  <MoreVertIcon sx={{color: getCompletionColor(itemType, itemId), mt: 'auto'}} /> : ""}
              </Box>

               
              </ListItemIcon>

              <ListItemText primary={courseItem.name.ru} 
              secondary={
                  <>
                  <AccessTimeIcon sx={{ verticalAlign: 'middle', fontSize: '1rem' }} /> 
                  {` ${courseItem.duration} минут`}
                </>
                } 
                sx={{
                  '& .MuiListItemText-primary': {
                    // Add styles for the primary text here
                    fontSize: '1rem',
                    lineHeight: '1.4rem',
                    fontWeight: 'bold',
                  },
                  '& .MuiListItemText-secondary': {
                    // Add styles for the secondary text here,
                    fontSize: '0.9rem',
                    fontStyle: 'italic',
                  },
                }}/>
               <ListItemIcon>
                {(itemType==='lesson')? <OndemandVideoIcon /> : 
                (itemType==='quiz')? <QuizIcon /> : 
                (itemType==='exam')? <WorkspacePremiumIcon /> :  <OndemandVideoIcon />
                }
               
               </ListItemIcon>
            </ListItem>
            
          );
        })}
      </List>
    );
  };


  const renderContent = (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
        display: 'flex',
        flexDirection: 'column',
        height: 1,
      }}
    >
      <Scrollbar
        ref={navContainerRef}
        id="navContainer"
        onScroll={handleScroll}      
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box sx={{ mt: 5, ml: 11, pb: 0, alignContent: 'center', justifyContent: 'center'}}>
          <Logo disabledLink={true} />
        </Box>
      {/* Back to Dashboard */}
      <Box sx={{ mt: 3, ml: 2, mr: 2}}>
      <Button 
    size="medium" 
    color="primary" 
    component={RouterLink}
    sx={{
        textTransform: 'none', 
        "&:hover .increaseIcon": {
            width: 30,
            transition: 'width 0.3s'
        }
    }}
    to="/dashboard"
>
    <BackIcon 
        className="increaseIcon" 
        color="primary" 
        sx={{ 
            marginRight: 1,
            transition: 'width 0.3s'
        }} 
    />
    На главную
</Button>
      </Box>
        {/* Course Progress */}
        
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, mt: 3, ml: 2, mr: 2,}}>
          <SchoolIcon />
        <Typography variant="body" sx={{fontSize: '1.2rem', fontWeight: 'medium'}}>Курс: {course.name.ru}</Typography>
        </Box>
        <Box sx={{ mt: 3, ml: 2, mr: 2, px: 1, }}>

    <LinearProgress 
        variant="determinate" 
        value={calculateProgressPercentage()} 
        sx={{ 
            borderRadius: 4, 
            height: 6,
            bgcolor: '#e0e0e0',
            '& .MuiLinearProgress-bar': {
                backgroundColor: '#3366FF',
            }
        }}
    />
    <Typography variant="caption" display="block" sx={{ mt: 1, textAlign: 'right', fontSize: 14,}}>
      {calculateProgressPercentage()}% пройдено
    </Typography>

        </Box>

        <Box sx={{ mt: 1, }}>

          {renderCourseStructure()}
        </Box>

      </Scrollbar>


    </Box>
  );


  
  return (
    <Box
      component="nav"
      id="navContainer"
      onScroll={handleScroll}
    
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: '#F5F7F9',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
  lessons: PropTypes.array.isRequired,
  quiz: PropTypes.array.isRequired,
  exam: PropTypes.array.isRequired,
  completedItems: PropTypes.shape({
    completedLessons: PropTypes.arrayOf(PropTypes.string).isRequired,
    completedQuiz: PropTypes.arrayOf(PropTypes.string).isRequired,
    completedExam: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,  
  progress: PropTypes.number,
};
