import React, {useContext, useEffect, useState} from 'react';
import { Box, Typography,  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { alpha, styled, useTheme} from '@mui/material';
import { fCurrency , fNumber, fPercent} from '../../../utils/formatNumber.js';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { CartContext } from '../../../CartContext.js';
import hard_level_icon from '../../assets/hard-level.svg'
import medium_level_icon from '../../assets/medium-level.svg'
import light_level_icon from '../../assets/light-level.svg'
import useMediaQuery from '@mui/material/useMediaQuery';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: alpha(theme.palette.background.default, 0.3),
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 13,
    padding: theme.spacing(1.5, 2.5),
    backdropFilter: 'blur(8px)',
    WebkitBackdropFilter: 'blur(8px)', // Fix on Mobile
    borderRadius: 10,
    borderColor: theme.palette.grey[500_8],

  },
}));

function ProductsList({ courses }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { cart, setCart } = useContext(CartContext);
  const [removedItems, setRemovedItems] = useState([]);
  const navigate = useNavigate();
  const handleRowClick = (courseId, type) => {
    navigate(`/dashboard/catalog/${type}/${courseId}`);
};


    const handleDelete = (itemId, type) => {
      ////console.log("Trying to delete:", itemId, "from", type);
      // Ensure type is either 'courses' or 'certificates'
      if (type !== 'courses' && type !== 'certificates') return;
      
      // Filter out the itemId from the appropriate list in the cart
      const updatedList = cart[type].filter(id => id !== String(itemId));

      ////console.log("Updated List:", updatedList);

      setRemovedItems(itemId => ({
        ...itemId
    }));
      // Update the cart state
      setCart(prevCart => ({
          ...prevCart,
          [type]: updatedList
      }));
  };
  
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
}, [removedItems]);


  

  return (
<TableContainer component={Paper} 
        sx={{ 
            backgroundColor: '#F5F7F9', // semi-transparent background
            border: "1px solid rgba(209, 213, 219, 0.3)", // border
            borderRadius: 5,
            padding: 2,
            mt: 5,
         }}>
      <Table>
        <TableHead>
        {(!isMobile) && (
        <TableRow>
        {['Название',  'Длительность', 'Сложность', 'Цена'].map((header, index) => (
            <TableCell key={index} sx={{
                background: 'transparent',
                color: '#000',
                padding: "15px",
                textAlign: 'center',
                fontWeight: 700,
                borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
            }}>
              {header}
            </TableCell>
          ))}
      </TableRow>
        )}
        {(isMobile) && (
        <TableRow>
        {['Название', 'Цена'].map((header, index) => (
            <TableCell key={index} sx={{
                background: 'transparent',
                color: '#000',
                padding: "15px",
                textAlign: 'center',
                fontWeight: 700,
                borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
            }}>
              {header}
            </TableCell>
          ))}
      </TableRow>
        )}

        </TableHead>
        <TableBody>
            {courses?.map((course, index) => (
                <TableRow key={index} 
                sx={{
                  py: 4,
                  "&:hover": {
                    backgroundColor: alpha("#FFF", 0.15),
                  },
                  "&:last-child td, &:last-child th": {
                    borderBottom: 0,
                  },
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)', // Set color of border
                }}>
                <TableCell 
                sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  fontWeight: 700,
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
                  py: 4,
                  maxWidth: '300px',
              }}>
                <Box sx={{
                  display: "flex",
                  flexDirection: 'column',
                  gap: 1,
                  textAlign: 'start'}}>
               <Typography onClick={() => handleRowClick(course.id, course.type)}
               sx={{color: '#3271d2', fontWeight: 700, cursor: 'pointer',
                                 "&:hover": {
                                  textDecoration: 'underline',
                                }}}>{course.name.ru}</Typography>
               <Typography sx={{color: '#898989'}}>{course.description.ru}</Typography> 
               </Box>
              </TableCell>

              {(!isMobile) && (
                <>
              <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>
                {(course.duration)} ч.
              </TableCell>

              <TableCell 
                sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
                }}>
                {
                  (() => {
                    switch(course.difficulty.value) {
                      case 3: 
                      return (
                        <Box display="flex" flexDirection='row' justifyContent={'center'} alignContent={'center'} alignItems={'center'} gap={1} >
                          <img src={hard_level_icon} alt="Hard Level" width={20} height={20}/>
                          <Typography sx={{fontSize: 14,}}>{course.difficulty.ru}</Typography>
                        </Box>
                      );
                      
                      case 2: 
                      return (
                        <Box display="flex" flexDirection='row' justifyContent={'center'} alignContent={'center'} alignItems={'center'} gap={1} >
                          <img src={medium_level_icon} alt="Medium Level" width={20} height={20}/>
                          <Typography sx={{fontSize: 14,}}>{course.difficulty.ru}</Typography>
                        </Box>
                      );
                      case 1:
                        return (
                          <Box display="flex" flexDirection='row' justifyContent={'center'} alignContent={'center'} alignItems={'center'} gap={1} >
                            <img src={light_level_icon} alt="Light Level" width={20} height={20}/>
                            <Typography sx={{fontSize: 14,}}>{course.difficulty.ru}</Typography>
                          </Box>
                        );
                      default:
                        return (
                          <Box display="flex" flexDirection='row' justifyContent={'center'} alignContent={'center'} alignItems={'center'} gap={1} >
                            <img src={light_level_icon} alt="Hard Level" width={20} height={20}/>
                            <Typography sx={{fontSize: 14,}}>{course.difficulty.ru}</Typography>
                          </Box>
                        );
                    }
                  })()
                }
              </TableCell>
              </>
              )}
              <TableCell sx={{
                  background: 'transparent',
                  color: '#000',
                  padding: "10px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
                  fontWeight: 700,
                  fontSize: 16
              }}>
                                    <Box sx={{
                                        color:  '#204631',
                                        background: '#6BEBA470',
                                        borderRadius: "50px",
                                        py: 0.5,
                                        px: 2,
                                        display: 'inline-block', // To make the box size according to content
                                    }}>
                                    {course.price === 0? 'Бесплатно' : course.price + "$"}
                                    </Box>
              </TableCell>

              <TableCell
              sx={{
                cursor: 'pointer', 
                  background: 'transparent',
                  color: '#000',
                  padding: "5px",
                  borderBottom: '1px solid rgba(209, 213, 219, 0.3)',
                  textAlign: 'center',
              }}>
                <Button
                variant="text"
                onClick={() => handleDelete(course.id, (course.type === 'certification'? 'certificates' : 'courses'))}
                sx={{my: 1,
                  "&:hover": {
                    backgroundColor: '#F5252540',
                    color: '#F52525'
                  }}}
                >
                  <CloseIcon />
                </Button>
              </TableCell> 
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


export default ProductsList;
