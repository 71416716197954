import { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { Box, Button, Typography, Select, MenuItem, List, ListItem } from '@mui/material';
import { getFunctions } from "firebase/functions";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

export default function AdminUpdateAllMembershipsComponent() {
  const [membershipStatus, setMembershipStatus] = useState('active');
  const [logs, setLogs] = useState([]);

  const handleUpdateAllMemberships = async () => {
    const functions = getFunctions();
    const updateAllMembershipsFunction = httpsCallable(functions, 'updateAllMembershipsStatus');
    const response = await updateAllMembershipsFunction({ status: membershipStatus });
    setLogs(response.data.logs);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4">Admin Update All Memberships</Typography>
      <Box sx={{ marginY: 2 }}>
        <Select
          fullWidth
          value={membershipStatus}
          onChange={e => setMembershipStatus(e.target.value)}
        >
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="inactive">Inactive</MenuItem>
        </Select>
      </Box>
      <Button variant="contained" color="primary" onClick={handleUpdateAllMemberships}>
        Update All Memberships
      </Button>
      <Box sx={{ marginY: 2 }}>
        <Typography variant="h6">Logs</Typography>
        <List>
          {logs.map((log, index) => (
            <ListItem key={index}>
              {log.startsWith('Successfully') ? <CheckCircleOutlineIcon color="success" /> : <CancelIcon color="error" />}
              <Typography color={log.startsWith('Successfully') ? 'green' : 'red'}>
                {log}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
}
